import { SET_INPUT, RESET_INPUT } from '../constants/formFields'

export default function formsReducer(state = {}, action) {

    switch (action.type) {
        case SET_INPUT:
            const { name, value } = action.payload;
            return { ...state, [name]: value };
        case RESET_INPUT:
            return action.payload
        default:
            return state;
    }
}