import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import TalentoButton from "../../TalentoButton";

const DigitalDocumentsArea = ({
  documentsToSign,
  displayDigitalDocumentSection,
  createDocument,
  canUserCreateDigitalDocument,
  classes,
}) => {
  if (!displayDigitalDocumentSection) return null;
  return (
    <Paper elevation={3} className={classes.documentActions}>
      <Grid style={{ margin: 10 }}>
        <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
          Documentos para assinatura digital
        </Typography>
        <div>
          {documentsToSign.map((documentToSign) => (
            <div key={documentToSign.type} style={{ marginBottom: "0.8rem" }}>
              <TalentoButton
                variant="contained"
                color="secondary"
                onClick={() => createDocument(documentToSign)}
                disabled={canUserCreateDigitalDocument}
              >
                {documentToSign.title}
              </TalentoButton>
            </div>
          ))}
        </div>
      </Grid>
    </Paper>
  );
};

export default DigitalDocumentsArea;
