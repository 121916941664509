import {
  addSearchFilter,
  addTableFilters,
  checkIfIsAdmin,
  filterAccordingToUserPermissions,
} from "./helpers";
import {
  CREATE,
  GET_MULTIPLE,
  GET_MULTIPLE_BY_OBJECT,
  GET_MULTIPLE_FILTERING_CONNECTED,
  GET_ONE,
  UPDATE_ONE,
} from "./types";

export const KnackUrl = ({
  type,
  scene,
  view,
  id,
  connectedField,
  userPermissionsField,
  userId,
  profileKeys,
  params = {},
}) => {
  let filters = {
    match: "and",
    rules: [],
  };
  let isAdmin = checkIfIsAdmin(profileKeys);

  switch (type) {
    case GET_MULTIPLE:
      return getMultiple({
        scene,
        view,
        params,
        filters,
        isAdmin,
        userPermissionsField,
        userId,
      });
    case GET_ONE:
      return `https://api.knack.com/v1/pages/${scene}/views/${view}/records/${id}?format=raw`;
    case CREATE:
      return `https://api.knack.com/v1/pages/${scene}/views/${view}/records?format=raw`;
    case GET_MULTIPLE_BY_OBJECT:
      return `https://api.knack.com/v1/objects/${scene}/records?rows_per_page=500`;
    case UPDATE_ONE:
      return `https://api.knack.com/v1/pages/${scene}/views/${view}/records/${id}?format=raw`;
    case GET_MULTIPLE_FILTERING_CONNECTED:
      return getMultipleFilteringConnected({
        scene,
        view,
        id,
        params,
        filters,
        isAdmin,
        userPermissionsField,
        userId,
        connectedField,
      });
    default:
      return null;
  }
}

const getMultiple = ({
  scene,
  view,
  params,
  filters,
  isAdmin,
  userPermissionsField,
  userId,
}) => {
  const page = params?.page || ""
  const paramsForSort = params?.paramsForSort || ""
  const tableFilters = params?.tableFilters || ""
  const itemsPerPage = params?.itemsPerPage || 30

  filterAccordingToUserPermissions({
    filters,
    isAdmin,
    userPermissionsField,
    userId,
  });
  addTableFilters(filters, tableFilters);
  const searchFilter = addSearchFilter(tableFilters);
  const encodedFilters = encodeURIComponent(JSON.stringify(filters));
  return `https://api.knack.com/v1/pages/${scene}/views/${view}/records?format=raw&filters=${encodedFilters}&rows_per_page=${itemsPerPage}&page=${page}${paramsForSort}${searchFilter}`;
};

const getMultipleFilteringConnected = ({
  scene,
  view,
  id,
  params,
  filters,
  isAdmin,
  userPermissionsField,
  userId,
  connectedField,
}) => {
  const page = params?.page || ""
  const paramsForSort = params?.paramsForSort || ""
  const tableFilters = params?.tableFilters || ""
  const itemsPerPage = params?.itemsPerPage || 30
  const filterOperator = params?.filterOperator || "is"
  const filterByParent = params?.filterByParent || undefined

  const rules = [
    {
      field: connectedField,
      operator: filterOperator,
      value: id,
    },
  ];

  filterByParent &&
    rules.push({
      field: filterByParent.field,
      operator: "is",
      value: filterByParent.value,
    });

  filters = {
    match: "and",
    rules,
  };

  filterAccordingToUserPermissions({
    filters,
    isAdmin,
    userPermissionsField,
    userId,
  });
  addTableFilters(filters, tableFilters);
  const searchFilter = addSearchFilter(tableFilters);
  const encodedFilters = encodeURIComponent(JSON.stringify(filters));
  return `https://api.knack.com/v1/pages/${scene}/views/${view}/records?format=raw&filters=${encodedFilters}&rows_per_page=${itemsPerPage}&page=${page}${paramsForSort}${searchFilter}`;
};
