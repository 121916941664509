const noAuthHeaders = {
  "Content-Type": "application/json",
};

const authHeaders = ({ token }) => ({
  ...noAuthHeaders,
  'session-id': sessionStorage.getItem('sessionId'),
  Authorization: `Bearer ${token}`,
});

export const dbCallType = {
  VIEW: "view",
  OBJECT: "object",
};

const dbHeaders = ({ token, callType = "view" }) => {
  if (callType === dbCallType.VIEW)
    return {
      "X-Knack-Application-Id": process.env.REACT_APP_KNACK_APP_ID,
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    };
  else if (callType === dbCallType.OBJECT)
    return {
      "X-Knack-Application-Id": process.env.REACT_APP_KNACK_APP_ID,
      "X-Knack-REST-API-KEY": process.env.REACT_APP_KNACK_API_KEY,
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    };
};

export { authHeaders, noAuthHeaders, dbHeaders };
