import React, { useState, useEffect } from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import { Link } from 'react-router-dom';
import { KnackAndFrontEndRelConfigMenu } from '../../utils/KnackAndFrontEndRel'
import { connect } from 'react-redux';
import { setMenuOpen } from '../../redux/actions/menu';
import { makeStyles } from '@material-ui/core';
import { Box } from '@material-ui/core';
import TalentoButton from './TalentoButton';

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: '15px',
        backgroundColor: 'transparent',
        transition: 'all 200ms ease-in',
        '&:hover': {
            backgroundColor: theme.palette.secondary.lighter
        }
    },
    icon: {
        color: theme.palette.dark.main
    }
}));

const MenuConfigs = (props) => {

    const classes = useStyles();
    const { profile_keys } = props.auth;
    const [anchorElConfgurations, setAnchorElConfigurations] = useState(null);
    const [anchorElManagement, setAnchorElManagement] = useState(null);

    const handleClickConfigurations = (event) => {
        setAnchorElConfigurations(event.currentTarget);
    };

    const handleClickManagement = (event) => {
        setAnchorElManagement(event.currentTarget);
    };

    const handleCloseConfigurations = () => {
        setAnchorElConfigurations(null);
        props.setMenuOpen(false)
    };

    const handleCloseManagement = () => {
        setAnchorElManagement(null);
        props.setMenuOpen(false)

    };

    const [showConfigMenu, setShowConfigMenu] = useState(false);

    const checkMenuConfigAccess = (profile_keys) => {
        if (profile_keys) {
            profile_keys.forEach(profile => {
                if (profile === 'profile_27') {
                    setShowConfigMenu(true)
                }
            })
        }

    }

    useEffect(() => {
        checkMenuConfigAccess(profile_keys);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (showConfigMenu === false) {
        return null
    }

    return (
        <div style={{ position: "fixed", bottom: 0, marginBottom: 15 }}>

            <ListItem className={classes.listItem}>
                <ListItemIcon style={{ minWidth: 'min-content', marginRight: '0.6rem' }}>
                    <BuildIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText>
                    <TalentoButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickManagement}>
                        <Box fontStyle="italic" fontSize={16}>Gestão</Box>
                    </TalentoButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElManagement}
                        keepMounted
                        open={Boolean(anchorElManagement)}
                        onClose={handleCloseManagement}
                    >
                        {KnackAndFrontEndRelConfigMenu.management.map(configItem => (
                            configItem.hideMenuForAll !== true ?
                                <MenuItem key={configItem.object} button component={Link} to={configItem.url} onClick={handleCloseConfigurations}>{configItem.text}</MenuItem>
                                : null
                        ))}
                    </Menu>
                </ListItemText>
            </ListItem>

            <ListItem className={classes.listItem}>
                <ListItemIcon style={{ minWidth: 'min-content', marginRight: '0.6rem' }}>
                    <SettingsIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>
                    <TalentoButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickConfigurations}>
                        <Box fontStyle="italic" fontSize={16}>Configurações</Box>
                    </TalentoButton>
                    <Menu
                        id="simple-menu-2"
                        anchorEl={anchorElConfgurations}
                        keepMounted
                        open={Boolean(anchorElConfgurations)}
                        onClose={handleCloseConfigurations}
                    >
                        {KnackAndFrontEndRelConfigMenu.configurations.map(configItem => {
                            return <MenuItem button component={Link} to={configItem.url} onClick={handleCloseConfigurations} key={configItem.object}>{configItem.text} </MenuItem>
                        })}
                    </Menu>
                </ListItemText>
            </ListItem>



        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { setMenuOpen })(MenuConfigs)