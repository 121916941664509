import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import ReactQuill from "react-quill";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import {
  editRecord,
  cleanRecord,
} from "../../../../redux/actions/api/dataApiCalls";
import { setRichPopUpOpen } from "../../../../redux/actions/popUps";
import "react-quill/dist/quill.snow.css";
import TalentoButton from "../../TalentoButton";
import { createNotification } from "../../../../redux/actions/notifications";

const PopUpRichEditor = (props) => {
  const {
    field,
    recordValues,
    isRichPopUpOpen,
    recordId,
    scene,
    view,
    auth,
    createNotification,
  } = props;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const onSubmit = async (formData) => {
    const result = await props.editRecord(
      formData,
      recordId,
      scene,
      view,
      auth
    );
    if (result !== "error") props.cleanRecord();
    else {
      // await fetchRecord(recordId, scene, view, auth);
      return createNotification().error({
        message:
          "Desculpa, falhou a edição do campo de texto! 🤦 Respira e tenta de novo 🧘<br/>Se estás no login é porque precisámos de refrescar a tua sessão 🚿",
      });
    }
    props.setRichPopUpOpen(false);
  };

  return (
    <Dialog open={isRichPopUpOpen} maxWidth="xl" fullWidth={true}>
      <DialogTitle>
        <Typography>Editar {field.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs={12} item={true}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name={field.key}
                  defaultValue={recordValues}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      theme="snow"
                      placeholder={"Adicione texto..."}
                    />
                  )}
                />
                <Grid
                  xs={12}
                  item={true}
                  style={{ marginTop: 20, display: "flex" }}
                >
                  <TalentoButton
                    onClick={() => props.setRichPopUpOpen(false)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ margin: 4, marginLeft: "auto" }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    type="submit"
                    size="small"
                    variant="contained"
                    color="secondary"
                    style={{ margin: 4 }}
                  >
                    Gravar
                  </TalentoButton>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    isRichPopUpOpen: state.richPopUp,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  editRecord,
  setRichPopUpOpen,
  cleanRecord,
  createNotification,
})(PopUpRichEditor);
