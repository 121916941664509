import { getDynamicData, getPartnerData } from ".";
import { partnershipProtocol } from "../../../components/documents/partnershipProtocol";
import { partnershipProtocolWithDiscount } from "../../../components/documents/partnershipProtocolWithDiscount";
import notify from "../helper";
import { ERROR } from "../notifications";
import {
  PARTNERSHIP_PROTOCOL,
  PARTNERSHIP_PROTOCOL_DISCOUNTED,
} from "./constants";

const createPartnershipProtocol = (id) => async (dispatch) => {
  try {
    const partner = await getPartnerData({ partnerId: id });
    const contractPartnerInfo = getDynamicData({
      data: { partner },
      type: PARTNERSHIP_PROTOCOL,
    });

    const document = partnershipProtocol(contractPartnerInfo);

    dispatch({
      type: "CREATE_PARTNERSHIP_PROTOCOL",
      payload: document,
    });
  } catch (error) {
    notify({
      dispatch,
      type: ERROR,
      message: `Por favor verifique se tem o campo de parceiro e/ou contrato preenchido. Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

const createPartnershipProtocolWithDiscount = (id) => async (dispatch) => {
  const partner = await getPartnerData({ partnerId: id });

  const contractPartnerInfo = getDynamicData({
    data: { partner },
    type: PARTNERSHIP_PROTOCOL_DISCOUNTED,
  });

  const document = partnershipProtocolWithDiscount(contractPartnerInfo);

  dispatch({
    type: "CREATE_PARTNERSHIP_PROTOCOL_WITH_DISCOUNT",
    payload: document,
  });
};

export { createPartnershipProtocol, createPartnershipProtocolWithDiscount };
