import { GET_CLASSES_FOR_STUDENT } from "./customApiCallsTypes";

export const getcustomApiCall = async ({ customApiCall, callArguments, props }) => {
    let results
    if (customApiCall) {
        switch (customApiCall.name) {
            case GET_CLASSES_FOR_STUDENT: {
                results = await props.getClassesForStudent(callArguments)
                break;
            }
            default:
        }
    }
    return results
}