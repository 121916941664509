import React from "react";

const UnderMaintenanceIcon = ({ className }) => {
  return (
    <svg
      id="Group_28390"
      data-name="Group 28390"
      xmlns="http://www.w3.org/2000/svg"
      width="157.991"
      height="147.732"
      className={className}
      viewBox="0 0 157.991 147.732"
    >
      <path
        id="Path_101586"
        data-name="Path 101586"
        d="M1183.321,470.871a25.782,25.782,0,0,1-3.036-4.109l.58-.908a6.147,6.147,0,0,1-2.2-4.187c-.192.235-.724.385-.785.884-1.745-.433.475-1.346-.708-1.889.362.135.691.239.992-.063q-1.36-.969-2.9-2.22c.291-.224.383-1.167.547-1.684.1-.61,0-1.215-.515-1.417a1.237,1.237,0,0,0-.62.975l-.627-.285,1.17-1.334-1.711.128,1.179-.811c-.579-.363-2.239.451-2.413-.043-.283-2.091.336-2.041.729-3.913-2.364,1.385-1.088-1.842-2.913-1.047.705-2.531-1.552-4-2.432-6.151-2.947-.552-5.367-1.119-7.886-1.694-.661,2.466-1.578,6.1-2.288,10.174a2.1,2.1,0,0,0,.25,1.68,1.54,1.54,0,0,0,2.375.183c.334-.253.556-.463.906-.73,1.41-1.077,3.316.063,2.976,2.793-.336,2.708-2.457,3.379-3.57,1.979-.474-.6-1.554-2.573-3.047-1.334a2.1,2.1,0,0,0-.654,1.568,47.059,47.059,0,0,0-.064,10.451l-.195.294c3.432.8,6.8,1.552,10.258,2.111a2.1,2.1,0,0,0,1.68-.25,1.54,1.54,0,0,0,.182-2.375c-.253-.334-.463-.556-.729-.905-1.062-1.392.036-3.319,2.793-2.976,2.708.337,3.379,2.457,1.98,3.57-.624.5-2.57,1.556-1.334,3.047a2.1,2.1,0,0,0,1.568.654,72.931,72.931,0,0,0,10.481.318l.017-.166c-.014-.105-.026-.212-.038-.318m-10.845-14.9-.06-.171.342-.022Zm1.491,1.352.267.112c-.162.034-.241-.016-.267-.112m2.148,4.415a2.368,2.368,0,0,1,.441.15c-.142-.053-.289-.109-.441-.15"
        transform="translate(-1033.873 -364.151)"
        fill="#ff694d"
      />
      <path
        id="Path_101587"
        data-name="Path 101587"
        d="M819.532,52.385c2.4-1.71,4.571-3.289,6.613-4.824,10.53-8.581,8.87-7.048,11.531-9.439,1.36-1.36,2.167-1.812,3.272-1.847,2.535-.078,3.519,1.793,3.546,3.9.012.928-.051,1.6-.042,2.571.033,3.272,3.4,5.353,7.431,2.668a12.552,12.552,0,0,0,2.758-2.758c2.684-4.027.6-7.4-2.669-7.431-.973-.01-1.644.054-2.571.042-2.1-.028-3.974-1.011-3.9-3.547.034-1.1.487-1.912,1.847-3.272A146.761,146.761,0,0,0,861.616,10.3c14.526,9.374,32.6,28.809,42.058,41.665l.026.419c-3.71,6.306-8.87,12.412-14.313,18.147-1.36,1.36-2.167,1.812-3.272,1.847-2.535.078-3.519-1.793-3.546-3.9-.012-.928.052-1.6.042-2.571-.039-3.862-4.569-5.745-8.917-1.4-4.3,4.3-2.518,8.879,1.4,8.917.973.01,1.644-.054,2.571-.042,2.1.028,3.975,1.011,3.9,3.547-.034,1.1-.487,1.912-1.846,3.272-6.777,6.149-13.314,11.239-17.819,14.644l.2-.369c-4.9-6.069-9.763-11.953-15.03-17.624-1.36-1.36-2.167-1.812-3.272-1.847-2.535-.078-3.519,1.793-3.547,3.9-.012.928.052,1.6.042,2.571-.033,3.272-3.4,5.353-7.431,2.668a14.586,14.586,0,0,1-1.487-1.271c-4.3-4.3-2.518-8.879,1.4-8.917.973-.01,1.644.054,2.571.042,2.1-.028,3.975-1.011,3.9-3.547-.034-1.1-.487-1.912-1.846-3.271a161.267,161.267,0,0,0-17.855-14.8"
        transform="translate(-755.78 -8.504)"
        fill="#148ff6"
      />
      <path
        id="Path_101588"
        data-name="Path 101588"
        d="M1241.137,238.74a.117.117,0,0,0-.052.006Z"
        transform="translate(-1103.79 -197.091)"
        fill="#364a59"
      />
      <path
        id="Path_101589"
        data-name="Path 101589"
        d="M1020.032,224.45l-.114.018a.083.083,0,0,0,.114-.018"
        transform="translate(-921.206 -185.294)"
        fill="#364a59"
      />
      <path
        id="Path_101590"
        data-name="Path 101590"
        d="M1238.848,238.776c-.156.022-.315.028-.473.037a1.247,1.247,0,0,0,.473-.037"
        transform="translate(-1101.552 -197.121)"
        fill="#364a59"
      />
      <path
        id="Path_101591"
        data-name="Path 101591"
        d="M1014.554,223.393l.022-.108-.095.07Z"
        transform="translate(-916.719 -184.332)"
        fill="#364a59"
      />
      <path
        id="Path_101592"
        data-name="Path 101592"
        d="M927.372,166.348c.072.041.077.261-.03.305.171.2.168.047.191-.12a21.612,21.612,0,0,1-.054-3.461c-.028-2.1-1.011-3.975-3.547-3.9-1.635.051-2.434,1.1-3.879,2.388.509.339-.043,1.487.7,1.277.282-.218.579-.781.875-.194l-.357.449c.537.4.62-.583,1.061.14-.185.15-.506-.088-.447.028.214.661.385-.144.675.033l-.121.519c.626.285,1.413.315,2.107.6-.087.125-.256.215-.15.451a6.252,6.252,0,0,1,2.522,1.753Zm-2.776-2.056-.205-.085.208.077,0,.008m.464.219c.02-.155.1-.283.177-.232-.076.07.085.254.165.438a2.573,2.573,0,0,0-.342-.206"
        transform="translate(-838.765 -131.406)"
        fill="#148ff6"
      />
      <path
        id="Path_101593"
        data-name="Path 101593"
        d="M1228.905,239.72a.711.711,0,0,0-.3-.25.443.443,0,0,0-.04.123Z"
        transform="translate(-1093.455 -197.694)"
        fill="#364a59"
      />
      <path
        id="Path_101594"
        data-name="Path 101594"
        d="M1012.546,50.467c.5.113.88-.367,1.287-.186.185-.529.838.073.827-.621.057.706.9.408,1.154.011-.069.237.288.153.215.475.338-.1.628-.308.515-.651.753.673,1.14-1,1.664-.018.766-.516,2.2-1.356,2.671-1.605.1.7-.968.389-.794,1.1.8-.562,1.162-1.311,2.03-1.419.237.023-.06.338-.109.479a3.979,3.979,0,0,1,1.165-.513c-9.971-12.553-25.72-28.873-38.652-37.22a146.752,146.752,0,0,1-14.263,18.145c-1.36,1.36-1.813,2.167-1.847,3.272-.078,2.535,1.793,3.519,3.9,3.547.928.012,1.6-.052,2.571-.042,3.272.033,5.353,3.4,2.669,7.431a12.561,12.561,0,0,1-2.758,2.758,8.4,8.4,0,0,1-1.292.711c.032.042.064.083.094.129.3-.578.96.676,1.234-.187.489.666.283.762,1.054,1.376.217.114.458-.449.756-.452l-.093.47.482-.369.084.625.287-.036a.427.427,0,0,1-.134-.411c.45-.081.928.648.891.892l1.045-.086c-.043.138-.154.18-.269.225.411-.146-.072.555.445.288-.044-.127.087-.269.115-.362a1.244,1.244,0,0,0,1.148.5l-.135.306c.713.276,1.559.251,2.372.473-.269-.23-.111-.78.134-.772l.017.528c.2-.269-.219-.255.155-.552.293.138.117.626-.083.743l.4-.077c0,.156-.1.212-.15.4.2-.265.569.663.868.162v.154c.822.177,1.219.081,1.891.2.069-.434.318-.282.559-.594a4.43,4.43,0,0,0,1.575.757c.441-.153.161-.352.377-.583-.012.4.586.458.248.7a5.294,5.294,0,0,0,1.851-.126c-.083-.18-.187-.267-.118-.395.169-.041.356.225.316.414l-.047.035c1.01-.423,2.229,1.372,3.082.726l.863-.354c.383.107-.247.279.026.556.938-.569,1.432.2,2.2-.209.547,1.209,2.057.008,2.829.786l-.043-.279c.2-.277.244.156.394.218-.02-.217-.219-.248-.092-.4,1.711-.381,3.541.409,5.323.484.65.028.3-1.471,1.139-.864l-.2.535c1.264-.684,2.657-.247,3.88-.941-.065.129-.054.284-.177.3.3.339.992-.114.966-.419.2.008.072.269.209.409.291.186.626-.566.8-.229.02.037-.015.085-.054.124.214-.15.742-.1.548-.461"
        transform="translate(-878.681 -8.504)"
        fill="#ff694d"
      />
      <path
        id="Path_101595"
        data-name="Path 101595"
        d="M974.253,369.164a.509.509,0,0,0-.6-.815.443.443,0,0,0-.232.411.582.582,0,0,0-.137.718.416.416,0,0,0,.564.148.43.43,0,0,0,.2-.3.888.888,0,0,0,.209-.159"
        transform="translate(-882.651 -304.031)"
        fill="#364a59"
      />
      <path
        id="Path_101596"
        data-name="Path 101596"
        d="M1001.249,411.266a.379.379,0,0,0-.185-.563.435.435,0,0,0-.647-.1,6.411,6.411,0,0,0-.872.92c.314.356.624.715.936,1.073l.768-1.331"
        transform="translate(-904.388 -338.888)"
        fill="#364a59"
      />
      <path
        id="Path_101597"
        data-name="Path 101597"
        d="M1039.273,462.844c.085-.253-.2-.426-.412-.339l.371.449c.015-.036.03-.072.042-.109"
        transform="translate(-936.845 -381.8)"
        fill="#364a59"
      />
      <path
        id="Path_101598"
        data-name="Path 101598"
        d="M1042.443,466.142c.117-.234-.148-.6-.4-.4l-.081.062.4.488a1.555,1.555,0,0,0,.084-.147"
        transform="translate(-939.403 -384.443)"
        fill="#364a59"
      />
      <path
        id="Path_101599"
        data-name="Path 101599"
        d="M1031.207,380.164a.415.415,0,0,0-.688-.4c-.177.213-.213.171-.55.509a1.7,1.7,0,0,0-.757.888.415.415,0,0,0,.287.507.393.393,0,0,0,.424-.144,2.25,2.25,0,0,0,1.284-1.36"
        transform="translate(-928.865 -313.413)"
        fill="#364a59"
      />
      <path
        id="Path_101600"
        data-name="Path 101600"
        d="M1021.787,430.908a2.485,2.485,0,0,0,2.141-1.965.3.3,0,0,0-.432-.332,5.073,5.073,0,0,0-1.03.683.287.287,0,0,0-.231.074,2.411,2.411,0,0,0-.732,1.167c-.047.164.09.4.284.373"
        transform="translate(-922.508 -353.809)"
        fill="#364a59"
      />
      <path
        id="Path_101601"
        data-name="Path 101601"
        d="M1045.21,348.681c-.167-.1-.286-.034-.468.043a.309.309,0,0,0-.449.028l-.553.612a.355.355,0,0,0,.2.592.485.485,0,0,0,.571.313c.072-.052.14-.107.206-.163a.323.323,0,0,0,.423-.045,1.808,1.808,0,0,0,.431-.7.336.336,0,0,0-.078-.325.364.364,0,0,0-.282-.358"
        transform="translate(-940.793 -287.811)"
        fill="#364a59"
      />
      <path
        id="Path_101602"
        data-name="Path 101602"
        d="M1000.044,330.621a.356.356,0,0,0,.13.341c-.012.021-.026.04-.038.06-.171.292.183.688.483.483a3.685,3.685,0,0,0,1.172-1.268.377.377,0,0,0-.419-.529c.009-.016.019-.03.027-.048a.355.355,0,0,0-.555-.428,2.364,2.364,0,0,0-.394.389.359.359,0,0,0-.359.147l-.248.342a.348.348,0,0,0,.2.512"
        transform="translate(-904.591 -271.721)"
        fill="#364a59"
      />
      <path
        id="Path_101603"
        data-name="Path 101603"
        d="M1000.327,365.783a1.892,1.892,0,0,0,.549.583.329.329,0,0,0,.467-.116l.011,0a.458.458,0,0,0,.25.1c.651.062,1.137-1.113,1.273-1.6a.363.363,0,0,0-.475-.425.306.306,0,0,0-.247-.014.406.406,0,0,0-.04-.06c.044-.057.09-.113.132-.172.232-.323-.3-.734-.555-.428a10.635,10.635,0,0,1-1.16,1.173.391.391,0,0,0-.164.023c-.248-.106-.5.2-.449.439a.692.692,0,0,0,.409.5"
        transform="translate(-904.691 -300.12)"
        fill="#364a59"
      />
      <path
        id="Path_101604"
        data-name="Path 101604"
        d="M1141.691,372.654a.361.361,0,0,0,.353.353,1.163,1.163,0,0,0,.142-.006.612.612,0,0,0,.064,0,.34.34,0,0,0,.522-.186.793.793,0,0,0,.129-.148,1.323,1.323,0,0,0,.318-.481.286.286,0,0,0-.025-.254c-.422,0-.806.02-1.213.03a.848.848,0,0,0-.291.686"
        transform="translate(-1021.734 -307.051)"
        fill="#364a59"
      />
      <path
        id="Path_101605"
        data-name="Path 101605"
        d="M1140.112,417.1a2.392,2.392,0,0,0,.147-.3.355.355,0,0,0-.435-.434l-.092.039a.318.318,0,0,0-.116-.156c.214-.291-.105-.77-.444-.534a4.02,4.02,0,0,0-.73.641.406.406,0,0,0-.035.447c-.039.041-.08.081-.117.125a.356.356,0,0,0,.263.6.317.317,0,0,0,.4.109.39.39,0,0,0,.073.09.378.378,0,0,0,.236.239q.424-.368.851-.742a.363.363,0,0,0,0-.121"
        transform="translate(-1018.851 -343.136)"
        fill="#364a59"
      />
      <path
        id="Path_101606"
        data-name="Path 101606"
        d="M1061.56,379.528l.555-.584c.057,0,.113-.01.169-.018a.379.379,0,0,0,.675.152,2.567,2.567,0,0,0,.428-.943.357.357,0,0,0-.34-.447l-.18,0c-.072-.359-.584-.442-.893-.085a1.876,1.876,0,0,1-.555.11.365.365,0,0,0-.285.562.389.389,0,0,0-.105.236.281.281,0,0,0-.122.282l.051.482a.359.359,0,0,0,.6.25"
        transform="translate(-955.041 -311.542)"
        fill="#364a59"
      />
      <path
        id="Path_101607"
        data-name="Path 101607"
        d="M1064.975,408.532a1.441,1.441,0,0,0-.662.594.443.443,0,0,0,.19.6.392.392,0,0,0,.06.052.416.416,0,0,0,.614.315,3.218,3.218,0,0,0,1.293-1.117.35.35,0,0,0,.06-.3c.356-.984-.9-.871-1.556-.142"
        transform="translate(-957.815 -336.791)"
        fill="#364a59"
      />
      <path
        id="Path_101608"
        data-name="Path 101608"
        d="M1065.42,320.749a.369.369,0,0,0,.272-.068.682.682,0,0,0,.229-.18l.3-.239a.354.354,0,0,0,.056-.428.366.366,0,0,0-.4-.163c-.028.007-.054.015-.081.024a.29.29,0,0,0-.082-.068.363.363,0,0,0-.483.127.814.814,0,0,0-.136.575c0,.022,0,.044,0,.066a.355.355,0,0,0,.333.352"
        transform="translate(-958.492 -263.831)"
        fill="#364a59"
      />
      <path
        id="Path_101609"
        data-name="Path 101609"
        d="M1081.34,477.313q.414-.32.84-.654c-.158-.02-.239.046-.84.654"
        transform="translate(-971.914 -393.501)"
        fill="#364a59"
      />
      <path
        id="Path_101610"
        data-name="Path 101610"
        d="M1104.121,441.587l-.377.377a.412.412,0,1,0,.583.583l.377-.377a.412.412,0,1,0-.583-.583"
        transform="translate(-990.31 -364.452)"
        fill="#364a59"
      />
      <path
        id="Path_101611"
        data-name="Path 101611"
        d="M1104.8,397.615c.294-.1.391-.359.541-.619l.64-1.107a.339.339,0,0,0-.1-.454.329.329,0,0,0-.033-.245l.043-.079a.359.359,0,0,0-.4-.519l-1.256.313a.444.444,0,0,0-.18.069,2.343,2.343,0,0,0-.623.628.329.329,0,0,0,.115.466.4.4,0,0,0,.216.369q-.12.243-.222.5c-.142.353.317.536.568.35a.3.3,0,0,0,.223-.082.415.415,0,0,0,.123.318.346.346,0,0,0,.344.09"
        transform="translate(-990.106 -325.746)"
        fill="#364a59"
      />
      <path
        id="Path_101612"
        data-name="Path 101612"
        d="M1068.129,446.231a1.321,1.321,0,0,0,.544-.665l.53-.678a.339.339,0,0,0-.141-.51.346.346,0,0,0-.068-.292.295.295,0,0,0-.481-.311l-.24.21a6.843,6.843,0,0,0-.86.76.273.273,0,0,0,.073.454.313.313,0,0,0,.107.147.353.353,0,0,0,.163.445c-.162.244.071.631.37.442"
        transform="translate(-960.342 -366.289)"
        fill="#364a59"
      />
      <path
        id="Path_101613"
        data-name="Path 101613"
        d="M1096.648,365.646a.27.27,0,0,0,.276,0,.406.406,0,0,0,.256.138.379.379,0,0,0,.577.122,2.521,2.521,0,0,0,.63-.774.366.366,0,0,0-.209-.517.389.389,0,0,0-.324-.428.36.36,0,0,0-.168-.138.376.376,0,0,0-.577-.122q-.183.156-.354.322a2.922,2.922,0,0,0-.327.338c-.071.1-.17.192-.09.369-.055.06-.109.118-.165.176a.352.352,0,0,0,.475.517"
        transform="translate(-984.075 -300.364)"
        fill="#364a59"
      />
      <path
        id="Path_101614"
        data-name="Path 101614"
        d="M1095.637,342.346c.158-.262.02-.68-.5-.626a.385.385,0,0,0-.209.06c-.346.17-.338.485-.182.628a.335.335,0,0,0,.041.031.37.37,0,0,0,.105.094.588.588,0,0,0,.742-.187"
        transform="translate(-982.898 -282.102)"
        fill="#364a59"
      />
      <path
        id="Path_101615"
        data-name="Path 101615"
        d="M651.981,482.14c-.421-2.065-.946-4.115-1.419-6.168l-1.613-7c-.341-1.484-1.456-3.961-3.4-3.757-3.7.389-4.221,2.9-5.5,5.44-.755,1.494-2.529,2.92-3.707,4.14-5.476,5.668-15.695,2.943-22.891,5.75-1.391.543-3.755,1.424-4.121,3.062-.223,1,.383,2.123.7,3.045l1.159,3.4c.06.176,11.641.265,22.9-1.282a132.868,132.868,0,0,0,18.875-4.261,13.491,13.491,0,0,1-.977-2.369"
        transform="translate(-582.209 -384.043)"
        fill="#f2ac57"
      />
      <path
        id="Path_101616"
        data-name="Path 101616"
        d="M656.124,464.005c2.265-2.361,2.655-5.227,6.33-11.628.163-.284-.263-.53-.469-.3a21.437,21.437,0,0,0-3.174,5.518c-.464,1-.923,2-1.441,2.97-.548,1.03-1.223,1.949-1.855,2.923a.4.4,0,0,0,.608.519"
        transform="translate(-620.309 -373.135)"
        fill="#364a59"
      />
      <path
        id="Path_101617"
        data-name="Path 101617"
        d="M620.959,551.069c.738-1.812,1.373-3.677,2.044-5.515A37.009,37.009,0,0,0,624.9,540a.187.187,0,0,0-.342-.141,36.528,36.528,0,0,0-2.177,5.365c-.718,1.878-1.482,3.753-2.129,5.655-.154.451.531.609.7.187"
        transform="translate(-591.251 -445.605)"
        fill="#364a59"
      />
      <path
        id="Path_101618"
        data-name="Path 101618"
        d="M664.728,570.334q2.526-1.6,5-3.285,1.171-.8,2.324-1.622c.544-.386,1.616-.865,1.724-1.569a.114.114,0,0,0-.08-.137c-.684-.127-1.348.593-1.885.96q-1.233.844-2.475,1.673-2.481,1.667-5,3.27c-.44.281-.044.99.4.71"
        transform="translate(-627.494 -465.365)"
        fill="#364a59"
      />
      <path
        id="Path_101619"
        data-name="Path 101619"
        d="M658.312,408.736a9.963,9.963,0,0,0,.18-2.362q.051-1.372.065-2.744a25.612,25.612,0,0,0-.192-5.074c-.03-.15-.282-.158-.305,0a41.177,41.177,0,0,0-.187,5.073c-.111,4.2-.3,4.217-.145,5.107.054.313.505.274.585,0"
        transform="translate(-622.155 -328.931)"
        fill="#364a59"
      />
      <path
        id="Path_101620"
        data-name="Path 101620"
        d="M699.281,509.641c3.205.593,6.962,1.292,10.122.1.246-.093.251-.531-.063-.5-3.374.318-4.185.818-9.864-.329-.477-.1-.674.643-.195.732"
        transform="translate(-656.28 -420.121)"
        fill="#364a59"
      />
      <path
        id="Path_101621"
        data-name="Path 101621"
        d="M591.175,413.956a38.406,38.406,0,0,0,3.561-3.51c1.154-1.2,2.261-2.451,3.351-3.712.237-.273-.155-.676-.4-.4-3.916,4.377-5.525,5.587-6.94,7.2a.3.3,0,0,0,.426.429"
        transform="translate(-566.84 -335.369)"
        fill="#364a59"
      />
      <path
        id="Path_101622"
        data-name="Path 101622"
        d="M788.7,500.094c.171-1.646.5-3.277.69-4.923a15.9,15.9,0,0,0,.26-4.689c-.03-.14-.218-.118-.258,0-.334.964-.594,6.49-1.351,9.525a.333.333,0,1,0,.658.087"
        transform="translate(-729.771 -404.835)"
        fill="#364a59"
      />
      <path
        id="Path_101623"
        data-name="Path 101623"
        d="M770.018,551.7c1.721-.848,3.446-1.689,5.168-2.536a34.032,34.032,0,0,0,5.1-2.663c.139-.1.071-.35-.12-.3-1.682.458-1.809.708-10.533,4.808-.46.216-.068.915.389.688"
        transform="translate(-714.413 -450.908)"
        fill="#364a59"
      />
      <path
        id="Path_101624"
        data-name="Path 101624"
        d="M741.423,549.1a33.649,33.649,0,0,0,.553-4.3,28.868,28.868,0,0,0-.079-4.562.091.091,0,1,0-.182,0c-.047,1.632-.283,4.858-.485,6.465a20.281,20.281,0,0,0-.337,2.33.271.271,0,0,0,.531.07"
        transform="translate(-690.859 -445.913)"
        fill="#364a59"
      />
      <path
        id="Path_101625"
        data-name="Path 101625"
        d="M753.693,408.652c.662.257.714.154,4.468.453,3.122.248,3.482.436,4.148.2.05-.017.09-.093.037-.134a3.681,3.681,0,0,0-1.732-.532c-1.235-.2-3.349-.378-4.578-.484a8.246,8.246,0,0,0-2.341-.093c-.325.068-.259.485,0,.585"
        transform="translate(-701.247 -336.827)"
        fill="#364a59"
      />
      <path
        id="Path_101626"
        data-name="Path 101626"
        d="M717.618,445.3a6.749,6.749,0,0,0,2.046-1.245c.667-.479,1.324-.972,1.962-1.489s1.276-1.077,1.882-1.653a5.246,5.246,0,0,0,1.4-1.676c.1-.258-.243-.487-.46-.356a8.1,8.1,0,0,0-1.475,1.493,24.056,24.056,0,0,1-1.83,1.706c-.643.546-1.308,1.066-1.975,1.581a6.9,6.9,0,0,0-1.686,1.46.123.123,0,0,0,.136.18"
        transform="translate(-671.52 -362.285)"
        fill="#364a59"
      />
      <path
        id="Path_101629"
        data-name="Path 101629"
        d="M971.687,668.37c-9.311-5.639-15.517-14.432-16.78-18.6-1.15-3.794-.88-11.964-.243-16.4l.518.074c-.63,4.391-.9,12.455.226,16.177,1.287,4.251,7.756,12.977,16.55,18.3Z"
        transform="translate(-866.885 -522.875)"
        fill="#364a59"
      />
      <path
        id="Path_101630"
        data-name="Path 101630"
        d="M938.73,519.069c-1.52-4.035-12.076-35.344-9.388-40.469a1.409,1.409,0,0,1,.9-.774c2.223-.556,4.122,4.668,5.511,8.481.15.413.294.805.428,1.167,2.317,6.208,4.5,13.087,5.6,16.635l-.5.154c-1.094-3.543-3.275-10.411-5.588-16.607-.135-.362-.278-.756-.429-1.17-1.17-3.214-3.128-8.593-4.892-8.153a.9.9,0,0,0-.569.509c-2.439,4.65,7.17,34.084,9.414,40.041Z"
        transform="translate(-846.073 -394.434)"
        fill="#364a59"
      />
      <path
        id="Path_101631"
        data-name="Path 101631"
        d="M997.546,579.172a38.758,38.758,0,0,0-2.308-5.625,3.2,3.2,0,0,0-3.315-1.622,3.784,3.784,0,0,0-2.669,3.254l-.516-.087a4.3,4.3,0,0,1,3.072-3.679,3.7,3.7,0,0,1,3.86,1.837,37.909,37.909,0,0,1,2.372,5.754Z"
        transform="translate(-895.467 -471.664)"
        fill="#364a59"
      />
      <path
        id="Path_101632"
        data-name="Path 101632"
        d="M1037.074,571.591a38.835,38.835,0,0,0-2.308-5.625,3.2,3.2,0,0,0-3.315-1.621,3.783,3.783,0,0,0-2.668,3.254l-.516-.087a4.3,4.3,0,0,1,3.072-3.679,3.7,3.7,0,0,1,3.86,1.837,37.917,37.917,0,0,1,2.372,5.754Z"
        transform="translate(-928.098 -465.406)"
        fill="#364a59"
      />
      <path
        id="Path_101633"
        data-name="Path 101633"
        d="M1085.593,609.947l-.483-.2a25.292,25.292,0,0,0,1.788-7.241c.108-4.233-.955-7.451-2.081-10.856-.389-1.176-.791-2.394-1.137-3.648-.612-2.212-1.159-4.332-1.641-6.2-.694-2.69-1.242-4.815-1.679-6.059-1.241-3.531-2.488-5.6-4.046-6.727a3.356,3.356,0,0,0-3.328-.288,2.456,2.456,0,0,0-1.452,2.166l-.523-.027a3,3,0,0,1,1.748-2.61,3.894,3.894,0,0,1,3.86.336c1.672,1.2,2.938,3.291,4.233,6.978.445,1.265,1,3.4,1.692,6.1.482,1.868,1.028,3.986,1.639,6.194.344,1.243.744,2.454,1.131,3.624,1.09,3.3,2.218,6.709,2.108,11.034a25.294,25.294,0,0,1-1.828,7.427"
        transform="translate(-963.387 -468.82)"
        fill="#364a59"
      />
      <path
        id="Path_101634"
        data-name="Path 101634"
        d="M936.626,499.747a1.829,1.829,0,0,1-.753-.15l.216-.476a1.77,1.77,0,0,0,2.046-.708l.447.272a2.307,2.307,0,0,1-1.955,1.064"
        transform="translate(-851.824 -411.462)"
        fill="#364a59"
      />
      <path
        id="Path_101635"
        data-name="Path 101635"
        d="M1140.711,549.606a50.955,50.955,0,0,1,.069-11.542l.521.051a50.542,50.542,0,0,0-.072,11.423Z"
        transform="translate(-1020.683 -444.196)"
        fill="#364a59"
      />
      <path
        id="Path_101636"
        data-name="Path 101636"
        d="M1147.838,469.714l-.515-.09c.348-2,.778-4.11,1.28-6.267l.509.12c-.5,2.148-.928,4.247-1.274,6.238"
        transform="translate(-1026.385 -382.524)"
        fill="#364a59"
      />
      <path
        id="Path_101637"
        data-name="Path 101637"
        d="M1148.335,506.917a2.179,2.179,0,0,1-1.706-.88c-.067-.083-.144-.191-.23-.312-.4-.554-1-1.392-1.721-1.485a1.327,1.327,0,0,0-1.025.371c-.32.266-.5.579-.626,1.554l-.519-.065a2.536,2.536,0,0,1,.811-1.892,1.836,1.836,0,0,1,1.426-.488c.95.123,1.63,1.072,2.08,1.7.081.113.152.214.215.291a1.607,1.607,0,0,0,1.713.624c.708-.2,1.538-1,1.745-2.669.176-1.414-.209-2.539-1.028-3.01a1.616,1.616,0,0,0-1.823.181c-.186.142-.342.271-.492.4s-.314.262-.5.405a2.348,2.348,0,0,1-1.74.553,1.859,1.859,0,0,1-1.239-.808,2.539,2.539,0,0,1-.324-2.033l.52.065c-.12.975-.019,1.323.226,1.659a1.358,1.358,0,0,0,.893.6,1.868,1.868,0,0,0,1.347-.452c.179-.137.329-.26.485-.389s.314-.262.509-.41a2.122,2.122,0,0,1,2.4-.219c1.008.579,1.489,1.9,1.287,3.528s-1,2.791-2.122,3.108a2.054,2.054,0,0,1-.557.078"
        transform="translate(-1022.41 -412.238)"
        fill="#364a59"
      />
      <path
        id="Path_101638"
        data-name="Path 101638"
        d="M1308.878,570.517a50.929,50.929,0,0,1,.07-11.542l.521.051a50.527,50.527,0,0,0-.072,11.423Z"
        transform="translate(-1159.513 -461.459)"
        fill="#364a59"
      />
      <path
        id="Path_101639"
        data-name="Path 101639"
        d="M1316.008,467.273l-.516-.09c.6-3.461,1.427-7.125,2.519-11.2l.505.136c-1.088,4.062-1.909,7.711-2.509,11.156"
        transform="translate(-1165.216 -376.434)"
        fill="#364a59"
      />
      <path
        id="Path_101640"
        data-name="Path 101640"
        d="M1316.518,527.826a2.206,2.206,0,0,1-1.723-.875c-.067-.083-.144-.191-.231-.313-.4-.554-1-1.391-1.721-1.485a1.324,1.324,0,0,0-1.024.371c-.32.265-.5.579-.626,1.554l-.519-.065a2.537,2.537,0,0,1,.811-1.892,1.843,1.843,0,0,1,1.426-.488c.951.123,1.63,1.072,2.079,1.7.082.113.153.214.215.292a1.651,1.651,0,0,0,1.579.654,2.411,2.411,0,0,0,1.7-1.8,5.988,5.988,0,0,0,.226-1.832,2.4,2.4,0,0,0-1.2-2.145,1.652,1.652,0,0,0-1.692.247c-.186.142-.342.272-.492.4s-.314.261-.5.4a2.342,2.342,0,0,1-1.74.553,1.859,1.859,0,0,1-1.24-.808,2.536,2.536,0,0,1-.323-2.033l.519.065c-.12.975-.019,1.323.226,1.659a1.36,1.36,0,0,0,.893.6,1.879,1.879,0,0,0,1.346-.452c.18-.136.329-.26.484-.389s.314-.262.509-.411a2.167,2.167,0,0,1,2.224-.309,2.9,2.9,0,0,1,1.511,2.6,6.483,6.483,0,0,1-.249,2.014,2.911,2.911,0,0,1-2.1,2.157,2.05,2.05,0,0,1-.357.032"
        transform="translate(-1161.239 -429.504)"
        fill="#364a59"
      />
      <path
        id="Path_101641"
        data-name="Path 101641"
        d="M1253.76,623.435c-2.637,0-5.368-.141-8.267-.425l.051-.521a79.365,79.365,0,0,0,11.478.348l.025.523q-1.624.074-3.287.075"
        transform="translate(-1107.429 -513.893)"
        fill="#364a59"
      />
      <path
        id="Path_101642"
        data-name="Path 101642"
        d="M1153.308,607.252c-3.929-.636-7.748-1.493-11.289-2.323l.12-.509c3.531.828,7.339,1.682,11.253,2.316Z"
        transform="translate(-1022.008 -498.976)"
        fill="#364a59"
      />
      <path
        id="Path_101643"
        data-name="Path 101643"
        d="M1213.531,585a2.535,2.535,0,0,1-1.891-.811,1.859,1.859,0,0,1-.479-1.4,2.344,2.344,0,0,1,.962-1.551c.186-.15.353-.269.515-.389s.322-.234.506-.381a1.608,1.608,0,0,0,.625-1.713c-.2-.708-1-1.538-2.669-1.745-1.414-.176-2.539.209-3.01,1.028a1.614,1.614,0,0,0,.181,1.824c.142.186.272.342.4.492s.261.314.405.5a2.344,2.344,0,0,1,.553,1.739,1.86,1.86,0,0,1-.808,1.24,2.539,2.539,0,0,1-2.033.323l.065-.519c.974.121,1.323.019,1.659-.227a1.356,1.356,0,0,0,.6-.893,1.87,1.87,0,0,0-.452-1.346c-.136-.179-.26-.329-.389-.484s-.262-.314-.411-.509a2.125,2.125,0,0,1-.219-2.4c.579-1.008,1.9-1.49,3.529-1.287s2.791,1,3.107,2.122a2.116,2.116,0,0,1-.8,2.264c-.193.153-.361.276-.524.394s-.32.233-.5.374a1.869,1.869,0,0,0-.769,1.2,1.358,1.358,0,0,0,.362,1.012c.265.32.579.505,1.553.626Z"
        transform="translate(-1075.474 -475.881)"
        fill="#364a59"
      />
      <path
        id="Path_101644"
        data-name="Path 101644"
        d="M1274.94,453.071c-2.638,0-5.368-.141-8.267-.425l.051-.521a79.377,79.377,0,0,0,11.478.349l.024.523q-1.624.075-3.287.075"
        transform="translate(-1124.915 -373.25)"
        fill="#364a59"
      />
      <path
        id="Path_101645"
        data-name="Path 101645"
        d="M1193.823,441.281c-2.209-.359-4.5-.8-7.2-1.393l.112-.511c2.7.59,4.976,1.031,7.176,1.387Z"
        transform="translate(-1058.826 -362.726)"
        fill="#364a59"
      />
      <path
        id="Path_101646"
        data-name="Path 101646"
        d="M1234.712,414.619a2.532,2.532,0,0,1-1.892-.811,1.838,1.838,0,0,1-.488-1.426c.123-.95,1.072-1.63,1.7-2.08.113-.081.213-.153.291-.215a1.606,1.606,0,0,0,.624-1.713c-.2-.708-1-1.538-2.668-1.745a7.092,7.092,0,0,0-.931-.042,2.4,2.4,0,0,0-2.146,1.2,1.651,1.651,0,0,0,.247,1.692c.143.187.272.343.4.493s.261.313.4.5a2.345,2.345,0,0,1,.553,1.74,1.861,1.861,0,0,1-.808,1.239,2.539,2.539,0,0,1-2.033.323l.065-.519c.974.121,1.323.019,1.66-.227a1.358,1.358,0,0,0,.6-.893,1.87,1.87,0,0,0-.452-1.346c-.136-.18-.259-.327-.389-.484s-.262-.315-.411-.51a2.167,2.167,0,0,1-.309-2.224,2.9,2.9,0,0,1,2.6-1.51,7.429,7.429,0,0,1,1.022.046c1.63.2,2.791,1,3.108,2.122a2.114,2.114,0,0,1-.8,2.264c-.083.066-.191.143-.312.23-.554.4-1.392,1-1.486,1.721a1.323,1.323,0,0,0,.371,1.025c.265.32.579.5,1.554.626Z"
        transform="translate(-1092.959 -335.223)"
        fill="#364a59"
      />
      <path
        id="Path_101647"
        data-name="Path 101647"
        d="M588.294,536.817a75.637,75.637,0,0,1-4.27-16.593l.517-.084a75.147,75.147,0,0,0,4.238,16.479Z"
        transform="translate(-561.357 -429.4)"
        fill="#364a59"
      />
      <path
        id="Path_101648"
        data-name="Path 101648"
        d="M573.284,382.4c-.446-5.2-.651-10.774-.627-17.049l.523,0c-.024,6.259.18,11.821.624,17Z"
        transform="translate(-551.972 -301.612)"
        fill="#364a59"
      />
      <path
        id="Path_101649"
        data-name="Path 101649"
        d="M837.953,503.213a109.347,109.347,0,0,1-4.286-16.547l.515-.093a108.79,108.79,0,0,0,4.263,16.462Z"
        transform="translate(-767.449 -401.689)"
        fill="#364a59"
      />
      <path
        id="Path_101650"
        data-name="Path 101650"
        d="M824.06,348.716a143.953,143.953,0,0,1-.256-17.077l.523.016a143.584,143.584,0,0,0,.255,17.018Z"
        transform="translate(-759.198 -273.783)"
        fill="#364a59"
      />
      <path
        id="Path_101651"
        data-name="Path 101651"
        d="M613.1,586.611q-.9,0-1.761-.015l.009-.523c12.264.21,31.579-1.836,43.221-6.039l.178.492c-11.157,4.028-29.311,6.085-41.647,6.085"
        transform="translate(-583.905 -478.845)"
        fill="#364a59"
      />
      <path
        id="Path_101652"
        data-name="Path 101652"
        d="M577.617,467.7a3.669,3.669,0,0,1,.426-2.936,2.643,2.643,0,0,1,1.747-1.169,3.365,3.365,0,0,1,2.5.772c.281.209.52.4.75.591s.461.376.742.586a2.581,2.581,0,0,0,2.649.355,3.7,3.7,0,0,0,1.829-3.333,10.68,10.68,0,0,0-.079-1.392c-.291-2.151-1.3-3.662-2.712-4.042a2.523,2.523,0,0,0-2.688,1c-.216.277-.391.524-.56.761s-.351.493-.567.769a3.366,3.366,0,0,1-2.205,1.406,2.644,2.644,0,0,1-1.994-.664,3.665,3.665,0,0,1-1.19-2.718l.519-.07c.179,1.334.423,1.912,1,2.382a2.139,2.139,0,0,0,1.606.55,2.9,2.9,0,0,0,1.852-1.209c.209-.267.376-.5.552-.75s.351-.493.574-.778a3.033,3.033,0,0,1,3.237-1.179c1.618.436,2.775,2.11,3.094,4.477a10.963,10.963,0,0,1,.083,1.483,4.207,4.207,0,0,1-2.132,3.787,3.1,3.1,0,0,1-3.181-.411c-.291-.217-.529-.411-.76-.6s-.459-.374-.731-.576a2.9,2.9,0,0,0-2.106-.675,2.141,2.141,0,0,0-1.4.958,3.2,3.2,0,0,0-.334,2.562Z"
        transform="translate(-554.951 -376.882)"
        fill="#364a59"
      />
      <path
        id="Path_101653"
        data-name="Path 101653"
        d="M768.825,440.38a2.931,2.931,0,0,1-.764-.1c-1.629-.439-2.792-2.115-3.112-4.482a10.973,10.973,0,0,1-.083-1.483A4.206,4.206,0,0,1,767,430.525a3.1,3.1,0,0,1,3.182.411c.291.217.529.411.76.6s.459.374.731.576a2.9,2.9,0,0,0,2.106.675,2.14,2.14,0,0,0,1.4-.958,3.2,3.2,0,0,0,.334-2.562l.519-.07a3.669,3.669,0,0,1-.426,2.936,2.643,2.643,0,0,1-1.747,1.169,3.361,3.361,0,0,1-2.5-.772c-.282-.209-.52-.4-.75-.591s-.461-.376-.742-.586a2.58,2.58,0,0,0-2.649-.355,3.7,3.7,0,0,0-1.829,3.334,10.623,10.623,0,0,0,.079,1.391c.342,2.533,1.634,3.751,2.729,4.047a2.513,2.513,0,0,0,2.671-1c.216-.278.391-.524.56-.762s.35-.492.567-.768a3.365,3.365,0,0,1,2.2-1.406,2.641,2.641,0,0,1,1.994.664,3.663,3.663,0,0,1,1.19,2.718l-.519.07c-.18-1.334-.423-1.913-1-2.382a2.143,2.143,0,0,0-1.606-.55,2.9,2.9,0,0,0-1.852,1.209c-.209.267-.375.5-.552.748s-.351.493-.574.78a3.114,3.114,0,0,1-2.456,1.287"
        transform="translate(-710.649 -354.322)"
        fill="#364a59"
      />
      <path
        id="Path_101654"
        data-name="Path 101654"
        d="M730.907,334.678l-.093-.515a108.778,108.778,0,0,0,16.462-4.263l.18.492a109.35,109.35,0,0,1-16.547,4.286"
        transform="translate(-682.539 -272.347)"
        fill="#364a59"
      />
      <path
        id="Path_101655"
        data-name="Path 101655"
        d="M583.044,362.979c-2.281,0-4.62-.051-7.154-.131l.016-.523a143.551,143.551,0,0,0,17.019-.255l.042.522c-3.469.281-6.637.389-9.923.389"
        transform="translate(-554.642 -298.906)"
        fill="#364a59"
      />
      <path
        id="Path_101656"
        data-name="Path 101656"
        d="M678.682,366.844h-.121a4.206,4.206,0,0,1-3.787-2.132,3.1,3.1,0,0,1,.41-3.181c.217-.291.412-.53.6-.76s.374-.459.575-.731a2.9,2.9,0,0,0,.675-2.106,2.141,2.141,0,0,0-.957-1.4,3.2,3.2,0,0,0-2.562-.334l-.07-.519a3.668,3.668,0,0,1,2.936.426,2.642,2.642,0,0,1,1.169,1.747,3.365,3.365,0,0,1-.772,2.5c-.209.282-.4.519-.59.75s-.376.461-.586.743a2.581,2.581,0,0,0-.355,2.649,3.7,3.7,0,0,0,3.333,1.829h.086a8.99,8.99,0,0,0,2.679-.376,3.7,3.7,0,0,0,2.718-2.644,2.58,2.58,0,0,0-1.045-2.46c-.278-.216-.524-.391-.762-.56s-.492-.35-.768-.567a3.365,3.365,0,0,1-1.406-2.205,2.642,2.642,0,0,1,.664-1.994,3.665,3.665,0,0,1,2.718-1.19l.07.519c-1.334.18-1.913.423-2.382,1a2.141,2.141,0,0,0-.55,1.606,2.9,2.9,0,0,0,1.209,1.852c.267.209.5.376.748.552s.493.351.78.574a3.1,3.1,0,0,1,1.24,2.959,4.2,4.2,0,0,1-3.075,3.057,9.489,9.489,0,0,1-2.825.4"
        transform="translate(-635.179 -292.512)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11655"
        data-name="Rectangle 11655"
        width="5.216"
        height="0.523"
        transform="translate(19.393 34.163) rotate(-46.539)"
        fill="#364a59"
      />
      <path
        id="Path_101657"
        data-name="Path 101657"
        d="M535.466,219.692l-.456-.257a6.109,6.109,0,0,1,2.286-2.3l.26.454a5.582,5.582,0,0,0-2.09,2.107"
        transform="translate(-520.894 -179.253)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11656"
        data-name="Rectangle 11656"
        width="5.245"
        height="0.523"
        transform="translate(7.627 50.284) rotate(-52.19)"
        fill="#364a59"
      />
      <path
        id="Path_101658"
        data-name="Path 101658"
        d="M483.243,330.97l-.473-.224a17.836,17.836,0,0,0,1.116-3.052l.506.134a18.339,18.339,0,0,1-1.149,3.142"
        transform="translate(-477.768 -270.526)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11657"
        data-name="Rectangle 11657"
        width="0.523"
        height="3.823"
        transform="translate(3.729 68.449)"
        fill="#364a59"
      />
      <path
        id="Path_101659"
        data-name="Path 101659"
        d="M473.7,462.494a9.474,9.474,0,0,1-1.8-4.458l.519-.066a8.947,8.947,0,0,0,1.7,4.211Z"
        transform="translate(-468.795 -378.075)"
        fill="#364a59"
      />
      <path
        id="Path_101660"
        data-name="Path 101660"
        d="M491.14,532.548a5.465,5.465,0,0,1-1.753-4.019l.523,0a4.934,4.934,0,0,0,1.583,3.628Z"
        transform="translate(-483.23 -436.324)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11658"
        data-name="Rectangle 11658"
        width="0.523"
        height="3.184"
        transform="translate(11.628 104.394) rotate(-31.571)"
        fill="#364a59"
      />
      <path
        id="Path_101661"
        data-name="Path 101661"
        d="M558.928,649.927a8.164,8.164,0,0,1-2.014-2.788l.479-.209a7.632,7.632,0,0,0,1.885,2.608Z"
        transform="translate(-538.977 -534.069)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11659"
        data-name="Rectangle 11659"
        width="0.523"
        height="4.479"
        transform="matrix(0.611, -0.792, 0.792, 0.611, 24.839, 121.654)"
        fill="#364a59"
      />
      <path
        id="Path_101662"
        data-name="Path 101662"
        d="M653.148,733.049a24.106,24.106,0,0,1-4.668-2l.255-.457a23.56,23.56,0,0,0,4.567,1.953Z"
        transform="translate(-614.569 -603.14)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11660"
        data-name="Rectangle 11660"
        width="0.523"
        height="5.181"
        transform="translate(45.937 133.748) rotate(-71.275)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11661"
        data-name="Rectangle 11661"
        width="0.524"
        height="4.977"
        transform="translate(56.646 137.381) rotate(-83.303)"
        fill="#364a59"
      />
      <path
        id="Path_101663"
        data-name="Path 101663"
        d="M869.829,795.292l-.008-.523a39.689,39.689,0,0,0,4.6-.342l.069.518a40.221,40.221,0,0,1-4.665.347"
        transform="translate(-797.295 -655.834)"
        fill="#364a59"
      />
      <path
        id="Path_101664"
        data-name="Path 101664"
        d="M931.555,789.239q-.385,0-.768-.025l.034-.523a11.515,11.515,0,0,0,4.512-.614l.172.494a12.056,12.056,0,0,1-3.95.667"
        transform="translate(-847.626 -650.594)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11662"
        data-name="Rectangle 11662"
        width="3.88"
        height="0.523"
        transform="translate(124.27 122.35) rotate(-40.214)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11663"
        data-name="Rectangle 11663"
        width="4.831"
        height="0.523"
        transform="matrix(0.727, -0.686, 0.686, 0.727, 130.213, 116.836)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11664"
        data-name="Rectangle 11664"
        width="0.523"
        height="3.832"
        transform="translate(147.305 65.473) rotate(-4.759)"
        fill="#364a59"
      />
      <rect
        id="Rectangle_11665"
        data-name="Rectangle 11665"
        width="0.523"
        height="3.416"
        transform="translate(144.75 56.775) rotate(-25.335)"
        fill="#364a59"
      />
      <path
        id="Path_101665"
        data-name="Path 101665"
        d="M875.344,56.382a13.3,13.3,0,0,0-4.027-.182l-.056-.52a13.824,13.824,0,0,1,4.186.189Z"
        transform="translate(-798.485 -45.901)"
        fill="#364a59"
      />
      <path
        id="Path_101666"
        data-name="Path 101666"
        d="M946.551,59.39a9.2,9.2,0,0,0-4.816-.305l-.105-.513a9.718,9.718,0,0,1,5.09.323Z"
        transform="translate(-856.577 -48.191)"
        fill="#364a59"
      />
      <path
        id="Path_101667"
        data-name="Path 101667"
        d="M1219.563,269.688l-.38-.359c6.753-7.114,11.456-13.076,14.8-18.764l.451.265c-3.367,5.722-8.092,11.715-14.873,18.859"
        transform="translate(-1085.709 -206.854)"
        fill="#364a59"
      />
      <path
        id="Path_101668"
        data-name="Path 101668"
        d="M1056.313,431.118,1056,430.7c7.008-5.3,13.043-10.258,18.452-15.164l.351.389c-5.42,4.917-11.467,9.887-18.488,15.194"
        transform="translate(-950.993 -343.044)"
        fill="#364a59"
      />
      <path
        id="Path_101669"
        data-name="Path 101669"
        d="M969.787,19.577l-.387-.353A152.848,152.848,0,0,0,984.158.45l.44.283a153.3,153.3,0,0,1-14.812,18.844"
        transform="translate(-879.502 -0.372)"
        fill="#364a59"
      />
      <path
        id="Path_101670"
        data-name="Path 101670"
        d="M903.37,167.241l-.345-.393c.517-.453,1.045-.921,1.567-1.391l.35.389c-.524.471-1.053.94-1.572,1.395"
        transform="translate(-824.707 -136.593)"
        fill="#364a59"
      />
      <path
        id="Path_101671"
        data-name="Path 101671"
        d="M804.63,226.881l-.3-.426c2.689-1.919,4.866-3.506,6.85-5l.314.419c-1.987,1.493-4.168,3.082-6.86,5"
        transform="translate(-743.226 -182.824)"
        fill="#364a59"
      />
      <path
        id="Path_101672"
        data-name="Path 101672"
        d="M822.81,265.84a165.95,165.95,0,0,0-18.48-15.316l.3-.427A166.494,166.494,0,0,1,823.17,265.46Z"
        transform="translate(-743.23 -206.468)"
        fill="#364a59"
      />
      <path
        id="Path_101673"
        data-name="Path 101673"
        d="M983.259,414.336c-4.758-5.9-9.934-12.191-15.568-18.255l.383-.356c5.643,6.074,10.829,12.377,15.592,18.283Z"
        transform="translate(-878.091 -326.69)"
        fill="#364a59"
      />
      <path
        id="Path_101674"
        data-name="Path 101674"
        d="M1097.974,43.57c-10.2-13.873-28.925-33.7-43.53-43.126l.284-.44c14.657,9.458,33.437,29.346,43.667,43.256Z"
        transform="translate(-949.71 -0.004)"
        fill="#364a59"
      />
      <path
        id="Path_101675"
        data-name="Path 101675"
        d="M867.28,357.5a7.084,7.084,0,0,1-3.862-1.32,13.272,13.272,0,0,1-2.925-2.922c-1.409-2.113-1.71-4.27-.833-5.928a4.241,4.241,0,0,1,3.808-2.191c.509,0,.939.009,1.356.022s.832.028,1.309.021a4.177,4.177,0,0,0,3-1.07,3.106,3.106,0,0,0,.781-2.336c-.033-1.075-.479-1.855-1.838-3.215l.37-.37c1.451,1.451,1.953,2.351,1.991,3.569a3.607,3.607,0,0,1-.928,2.714,4.719,4.719,0,0,1-3.371,1.232c-.489.006-.918-.008-1.333-.021s-.838-.027-1.333-.022a3.725,3.725,0,0,0-3.351,1.912c-.784,1.483-.493,3.445.8,5.384a12.766,12.766,0,0,0,2.8,2.793c1.93,1.287,3.892,1.577,5.375.793A3.725,3.725,0,0,0,871,353.191c0-.5-.009-.921-.022-1.332s-.028-.844-.021-1.334a4.726,4.726,0,0,1,1.232-3.371,3.587,3.587,0,0,1,2.714-.926c1.217.038,2.118.539,3.568,1.99l-.37.37c-1.358-1.358-2.139-1.8-3.215-1.838a3.1,3.1,0,0,0-2.336.781,4.175,4.175,0,0,0-1.071,3c-.006.479.007.882.021,1.31s.028.847.023,1.355A4.24,4.24,0,0,1,869.335,357a4.365,4.365,0,0,1-2.057.493"
        transform="translate(-788.5 -279.191)"
        fill="#364a59"
      />
      <path
        id="Path_101676"
        data-name="Path 101676"
        d="M1119.91,326.338l-.37-.37c1.358-1.358,1.8-2.139,1.838-3.215a3.106,3.106,0,0,0-.781-2.336,4.173,4.173,0,0,0-3-1.07c-.477-.007-.881.007-1.309.021s-.847.027-1.356.022a4.154,4.154,0,0,1-3.973-2.543c-.872-2.136,0-4.808,2.342-7.147s5.016-3.213,7.16-2.337a4.145,4.145,0,0,1,2.531,3.968c.005.507-.009.938-.023,1.355s-.027.833-.021,1.31a4.173,4.173,0,0,0,1.071,3,3.1,3.1,0,0,0,2.336.781c1.076-.033,1.857-.479,3.215-1.838l.37.37c-1.451,1.451-2.351,1.953-3.568,1.99a3.6,3.6,0,0,1-2.714-.926,4.719,4.719,0,0,1-1.232-3.371c-.006-.489.008-.919.021-1.334s.027-.836.022-1.332a3.635,3.635,0,0,0-2.206-3.489c-1.942-.793-4.407.038-6.592,2.223s-3.018,4.644-2.228,6.579a3.645,3.645,0,0,0,3.494,2.219c.5,0,.922-.009,1.333-.022s.844-.027,1.333-.021a4.719,4.719,0,0,1,3.371,1.232,3.608,3.608,0,0,1,.926,2.714c-.038,1.217-.539,2.118-1.991,3.569"
        transform="translate(-996.092 -253.467)"
        fill="#364a59"
      />
      <path
        id="Path_101677"
        data-name="Path 101677"
        d="M923.144,127.326a4.353,4.353,0,0,1-2.055-.493,4.24,4.24,0,0,1-2.191-3.808c-.005-.509.009-.939.022-1.355s.027-.832.021-1.309a4.174,4.174,0,0,0-1.071-3,3.108,3.108,0,0,0-2.336-.781c-1.077.033-1.857.479-3.215,1.838l-.37-.37c1.45-1.451,2.351-1.953,3.568-1.991a3.609,3.609,0,0,1,2.714.926,4.716,4.716,0,0,1,1.232,3.371c.007.489-.008.918-.021,1.333s-.027.838-.022,1.333a3.725,3.725,0,0,0,1.912,3.351c1.483.784,3.445.493,5.384-.8a12.769,12.769,0,0,0,2.793-2.8c1.287-1.929,1.577-3.892.793-5.375a3.725,3.725,0,0,0-3.351-1.912c-.5-.005-.922.009-1.333.022s-.843.028-1.333.021a4.724,4.724,0,0,1-3.371-1.232,3.606,3.606,0,0,1-.926-2.714c.038-1.217.539-2.118,1.99-3.569l.37.37c-1.358,1.358-1.8,2.139-1.838,3.215a3.105,3.105,0,0,0,.781,2.336,4.174,4.174,0,0,0,3,1.071c.477.006.881-.007,1.309-.021s.848-.027,1.355-.022a4.24,4.24,0,0,1,3.808,2.191c.877,1.658.576,3.815-.826,5.919A13.277,13.277,0,0,1,927.015,126a7.1,7.1,0,0,1-3.873,1.326"
        transform="translate(-832.073 -89.175)"
        fill="#364a59"
      />
      <path
        id="Path_101700"
        data-name="Path 101700"
        d="M455.618,65.769a.1.1,0,0,0-.025.044.3.3,0,0,1,.03-.027l0-.018"
        transform="translate(-455.333 -54.297)"
        fill="#364a59"
      />
      <path
        id="Path_101703"
        data-name="Path 101703"
        d="M455.784,65.785a.112.112,0,0,0-.022.015.056.056,0,0,0,.016.033c0-.025,0-.039.006-.048"
        transform="translate(-455.471 -54.31)"
        fill="#364a59"
      />
      <path
        id="Path_101704"
        data-name="Path 101704"
        d="M454.171,104.514l-.074.077a.36.36,0,0,0,.074-.077"
        transform="translate(-454.097 -86.281)"
        fill="#364a59"
      />
      <path
        id="Path_101709"
        data-name="Path 101709"
        d="M465.763,67.11l-.044-.071a.331.331,0,0,0,.044.071"
        transform="translate(-463.691 -55.344)"
        fill="#364a59"
      />
      <path
        id="Path_101710"
        data-name="Path 101710"
        d="M463.907,66.091a.2.2,0,0,0-.023.036l.023-.036"
        transform="translate(-462.177 -54.562)"
        fill="#364a59"
      />
      <path
        id="Path_101711"
        data-name="Path 101711"
        d="M460.77,95.4a.035.035,0,0,0-.006.013c-.007.037,0,.015.006-.013"
        transform="translate(-459.599 -78.758)"
        fill="#364a59"
      />
      <path
        id="Path_101712"
        data-name="Path 101712"
        d="M466.39,87.14a.322.322,0,0,1,0,.073c.026-.017.044-.034,0-.073"
        transform="translate(-464.241 -71.939)"
        fill="#364a59"
      />
      <path
        id="Path_101713"
        data-name="Path 101713"
        d="M454.529,104.466l-.008.008Z"
        transform="translate(-454.447 -86.242)"
        fill="#364a59"
      />
      <path
        id="Path_101714"
        data-name="Path 101714"
        d="M466.06,87.613a.071.071,0,0,0,.056-.058c-.022.014-.05.03-.056.058"
        transform="translate(-463.973 -72.282)"
        fill="#364a59"
      />
      <path
        id="Path_101718"
        data-name="Path 101718"
        d="M456.955,65.2c-.041,0-.12-.055-.172-.016l0,.03c.013-.063.145.054.17-.013"
        transform="translate(-456.315 -53.799)"
        fill="#364a59"
      />
      <path
        id="Path_101719"
        data-name="Path 101719"
        d="M469.8,83.749l.078-.014-.052-.068Z"
        transform="translate(-467.056 -69.071)"
        fill="#364a59"
      />
      <path
        id="Path_101720"
        data-name="Path 101720"
        d="M564.936,808.972c.028.011.033.044.044.063a.289.289,0,0,0-.09-.257c.02.122-.018.093.019.229a.063.063,0,0,1-.1-.018c.008.033-.069.027-.037.1.1.03.15-.014.167-.114"
        transform="translate(-545.455 -667.684)"
        fill="#364a59"
      />
      <path
        id="Path_101721"
        data-name="Path 101721"
        d="M568.155,807.225a.087.087,0,0,0-.03.042.2.2,0,0,1,.033-.024.1.1,0,0,0,0-.018"
        transform="translate(-548.232 -666.401)"
        fill="#364a59"
      />
      <path
        id="Path_101722"
        data-name="Path 101722"
        d="M559.033,813.175a.15.15,0,0,0,.012.013c0-.011-.006-.017-.012-.013"
        transform="translate(-540.726 -671.312)"
        fill="#364a59"
      />
      <path
        id="Path_101723"
        data-name="Path 101723"
        d="M568.461,807.19a.264.264,0,0,1,.038-.013.075.075,0,0,0-.038.013"
        transform="translate(-548.509 -666.361)"
        fill="#364a59"
      />
      <path
        id="Path_101724"
        data-name="Path 101724"
        d="M549.54,820.751a.346.346,0,0,1,.076.054c-.048-.051.023-.131-.076-.054"
        transform="translate(-532.889 -677.541)"
        fill="#364a59"
      />
      <path
        id="Path_101725"
        data-name="Path 101725"
        d="M568.34,807.25l-.025.013a.055.055,0,0,0,.015.034c0-.025,0-.039.01-.048"
        transform="translate(-548.389 -666.422)"
        fill="#364a59"
      />
      <path
        id="Path_101726"
        data-name="Path 101726"
        d="M559.165,845.421l-.108.048a.436.436,0,0,0,.108-.048"
        transform="translate(-540.746 -697.933)"
        fill="#364a59"
      />
      <path
        id="Path_101727"
        data-name="Path 101727"
        d="M523.207,834.764l.016-.03a.03.03,0,0,0-.016.03"
        transform="translate(-511.151 -689.111)"
        fill="#364a59"
      />
      <path
        id="Path_101728"
        data-name="Path 101728"
        d="M556.961,846.675a.19.19,0,0,0-.09-.026.091.091,0,0,0,0,.03Z"
        transform="translate(-538.94 -698.947)"
        fill="#364a59"
      />
      <path
        id="Path_101730"
        data-name="Path 101730"
        d="M521.327,836.46a.253.253,0,0,0,0-.041.3.3,0,0,1,0,.041"
        transform="translate(-509.598 -690.502)"
        fill="#364a59"
      />
      <path
        id="Path_101731"
        data-name="Path 101731"
        d="M524.311,833.607l.015.02.006-.03Z"
        transform="translate(-512.062 -688.171)"
        fill="#364a59"
      />
      <path
        id="Path_101732"
        data-name="Path 101732"
        d="M581.208,811.759a.048.048,0,0,0,.009-.03.064.064,0,0,0-.009.03"
        transform="translate(-559.032 -670.119)"
        fill="#364a59"
      />
      <path
        id="Path_101733"
        data-name="Path 101733"
        d="M578.791,809.24a.86.86,0,0,0-.044-.076.539.539,0,0,0,.044.076"
        transform="translate(-557.001 -668.001)"
        fill="#364a59"
      />
      <path
        id="Path_101734"
        data-name="Path 101734"
        d="M576.892,808.045a.2.2,0,0,0-.026.034l.026-.034"
        transform="translate(-555.448 -667.078)"
        fill="#364a59"
      />
      <path
        id="Path_101735"
        data-name="Path 101735"
        d="M569.259,838a.039.039,0,0,0-.01.013c-.017.037,0,.016.01-.013"
        transform="translate(-549.154 -691.807)"
        fill="#364a59"
      />
      <path
        id="Path_101736"
        data-name="Path 101736"
        d="M576.714,830.126a.334.334,0,0,1-.013.075c.03-.017.05-.033.013-.075"
        transform="translate(-555.312 -685.307)"
        fill="#364a59"
      />
      <path
        id="Path_101737"
        data-name="Path 101737"
        d="M559.687,845.391s-.012.005-.012.005Z"
        transform="translate(-541.256 -697.908)"
        fill="#364a59"
      />
      <path
        id="Path_101738"
        data-name="Path 101738"
        d="M576.324,830.61a.082.082,0,0,0,.066-.057c-.025.014-.055.028-.066.057"
        transform="translate(-555.001 -685.659)"
        fill="#364a59"
      />
      <path
        id="Path_101739"
        data-name="Path 101739"
        d="M566.027,808.182a.035.035,0,0,1,.006.015l.015-.022Z"
        transform="translate(-546.5 -667.184)"
        fill="#364a59"
      />
      <path
        id="Path_101740"
        data-name="Path 101740"
        d="M565.99,808.3l-.015.022c.01-.006.018-.011.015-.022"
        transform="translate(-546.457 -667.289)"
        fill="#364a59"
      />
      <path
        id="Path_101741"
        data-name="Path 101741"
        d="M565.8,808.477a.388.388,0,0,1,.03-.048c-.016.008-.035.018-.03.048"
        transform="translate(-546.316 -667.395)"
        fill="#364a59"
      />
      <path
        id="Path_101742"
        data-name="Path 101742"
        d="M569.619,806.7c-.043,0-.122-.055-.18-.021v.03c.019-.057.149.058.18-.008"
        transform="translate(-549.316 -665.944)"
        fill="#364a59"
      />
      <path
        id="Path_101743"
        data-name="Path 101743"
        d="M580.525,826.948a.671.671,0,0,0,.078,0l-.039-.078Z"
        transform="translate(-558.469 -682.619)"
        fill="#364a59"
      />
      <path
        id="Path_101791"
        data-name="Path 101791"
        d="M674.814,68.717a19.312,19.312,0,0,0-.829-2.973,41.956,41.956,0,0,0-9.952-8.105,35.828,35.828,0,0,1-5.127-3.723c-2.544-2.135-3.6-3.019-7.648-1.479-3.186,1.21-6.5,2.191-9.712,3.139a104.44,104.44,0,0,0-12.378,4.231l-.081.036L629,59.822c-.9-.217-1.861-.4-2.884-.6-1.386-.264-2.82-.539-4.067-.9l.146-.5c1.223.356,2.645.627,4.019.889.953.182,1.937.369,2.823.581A105.763,105.763,0,0,1,641.4,55.074c3.2-.945,6.507-1.923,9.675-3.127,4.339-1.649,5.62-.574,8.17,1.567a35.4,35.4,0,0,0,5.057,3.673,42.483,42.483,0,0,1,10.1,8.236l.035.037.018.048a19.765,19.765,0,0,1,.871,3.1Z"
        transform="translate(-592.75 -42.224)"
        fill="#364a59"
      />
      <path
        id="Path_101792"
        data-name="Path 101792"
        d="M881.205,227.877a1.574,1.574,0,0,1-1.109-.529c-1.628-1.627-.977-3.447-.288-5.373l.125-.351.493.175-.126.353c-.686,1.919-1.229,3.435.165,4.827a1.036,1.036,0,0,0,.778.375c.372-.019.79-.321,1.277-.922a59.8,59.8,0,0,0,4.141-5.78l.443.279a60.325,60.325,0,0,1-4.177,5.831,2.452,2.452,0,0,1-1.656,1.115l-.066,0"
        transform="translate(-804.937 -182.157)"
        fill="#364a59"
      />
      <path
        id="Path_101793"
        data-name="Path 101793"
        d="M638.855,147.433a27.087,27.087,0,0,1-15.336-4.444,20.522,20.522,0,0,0-3.19-1.654c-.411-.15-.855-.271-1.283-.389a6.612,6.612,0,0,1-3.052-1.427l.367-.373a6.144,6.144,0,0,0,2.824,1.3c.439.12.893.244,1.324.4a20.887,20.887,0,0,1,3.276,1.694c4.1,2.41,9.709,5.71,21.931,3.8a30.941,30.941,0,0,1,11.667.036l.585.09c8.207,1.238,12.4-.464,13.148-1.481a1.435,1.435,0,0,0,.015-1.633c-1.167-2.158-6.722-4.956-13.12-4.61l-.034,0-.034-.007a21.549,21.549,0,0,1-7.328-3.338,44.5,44.5,0,0,0-6.8-3.624l.2-.484a44.936,44.936,0,0,1,6.878,3.662,21.2,21.2,0,0,0,7.126,3.264c6.267-.329,12.178,2.307,13.575,4.885a1.942,1.942,0,0,1-.053,2.192c-.958,1.3-5.509,2.917-13.648,1.69l-.585-.09a30.521,30.521,0,0,0-11.508-.036,44.924,44.924,0,0,1-6.942.58"
        transform="translate(-587.75 -108.383)"
        fill="#364a59"
      />
      <path
        id="Path_101794"
        data-name="Path 101794"
        d="M895.237,199.112l-.515-.094c.1-.558.183-1.028.252-1.42.22-1.26.277-1.563.406-1.713.378-.439,2.573-.676,4.463.163l-.212.479a5.4,5.4,0,0,0-3.848-.3,13.252,13.252,0,0,0-.292,1.466c-.068.393-.15.864-.253,1.424"
        transform="translate(-817.852 -161.385)"
        fill="#364a59"
      />
      <path
        id="Path_101795"
        data-name="Path 101795"
        d="M821.708,130.9a16.842,16.842,0,0,1-3.86-1.67,6.837,6.837,0,0,0-3.087-1.135l.015-.523a7.318,7.318,0,0,1,3.325,1.2,16.385,16.385,0,0,0,3.747,1.623Z"
        transform="translate(-751.841 -105.314)"
        fill="#364a59"
      />
      <path
        id="Path_101796"
        data-name="Path 101796"
        d="M792.665,124.22l-.165-.5a14.709,14.709,0,0,0,3.687-1.936,3.637,3.637,0,0,0,.368-1.327l.522.035a4.077,4.077,0,0,1-.459,1.6l-.026.046-.042.032a14.992,14.992,0,0,1-3.884,2.049"
        transform="translate(-733.463 -99.446)"
        fill="#364a59"
      />
      <path
        id="Path_101797"
        data-name="Path 101797"
        d="M857.231,144.085a17.466,17.466,0,0,1-2.839-2.55c-1.065-1.206-.973-1.417-.934-1.506l.183-.1-.018-.052a4.342,4.342,0,0,0,1.391-.823l.364.376a4.557,4.557,0,0,1-1.316.838,15.076,15.076,0,0,0,3.478,3.39Z"
        transform="translate(-783.769 -114.799)"
        fill="#364a59"
      />
      <path
        id="Path_101798"
        data-name="Path 101798"
        d="M853.176,234.908a1.671,1.671,0,0,1-.948-.288,2.6,2.6,0,0,1-1.01-1.975c-.064-2.105,1.883-3.406,3.172-4.267l.291.435c-1.515,1.012-2.99,2.141-2.94,3.815a2.078,2.078,0,0,0,.78,1.556,1.29,1.29,0,0,0,1.2.1l2.489-1.578.281.442-2.564,1.618a2.191,2.191,0,0,1-.754.143"
        transform="translate(-781.936 -188.538)"
        fill="#364a59"
      />
      <path
        id="Path_101799"
        data-name="Path 101799"
        d="M847.82,227.219a4.108,4.108,0,0,1,1.32-3.66l.345.393a3.572,3.572,0,0,0-1.148,3.183Z"
        transform="translate(-779.094 -184.559)"
        fill="#364a59"
      />
      <path
        id="Path_101800"
        data-name="Path 101800"
        d="M926.9,166.442l-.523-.024a27.779,27.779,0,0,0-.214-4.773l.519-.068a28.282,28.282,0,0,1,.218,4.864"
        transform="translate(-843.809 -133.391)"
        fill="#364a59"
      />
    </svg>
  );
};

export default UnderMaintenanceIcon;
