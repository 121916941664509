import { OPEN_SPECIFIC_MESSAGE_EDITOR } from '../../constants/massComunications'

const intialState = false;


export default function emailPopUpReducer(state = intialState, action) {

    switch (action.type) {
        case OPEN_SPECIFIC_MESSAGE_EDITOR:
            return action.payload
        default:
            return state
    }
}  