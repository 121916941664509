import { Contrato } from "../../../../../utils/knackKeys";

const idDocuments = [
  {
    name: "Cartão de Cidadão",
    document: Contrato.fields.NUMERO_CARTAO_CIDADAO,
    expiry: Contrato.fields.VALIDADE_CARTAO_CIDADAO,
  },
  {
    name: "Passaporte",
    document: Contrato.fields.NUMERO_PASSAPORTE,
    expiry: Contrato.fields.VALIDADE_PASSAPORTE,
  },
  {
    name: "Autorização de Residência",
    document: Contrato.fields.NUMERO_AUTORIZACAO_RESIDENCIA,
    expiry: Contrato.fields.VALIDADE_AUTORIZACAO_RESIDENCIA,
  },
];

const idExpiryFields = idDocuments.map((el) => el.expiry);
const idNumberFields = idDocuments.map((el) => el.document);

const validateExpiryDates = ({ fieldKey, value, getValues }) => {
  if (!idExpiryFields.includes(fieldKey)) return true;

  const relatedIdNumberField = idDocuments.find((el) => el.expiry === fieldKey);

  if (!!getValues(relatedIdNumberField.document) && !value)
    return "Este campo é obrigatório";

  return true;
};

const checkDocuments = ({
  fieldKey,
  getValues,
  trigger,
  getFieldState,
  formState,
}) => {
  if (!idNumberFields.includes(fieldKey)) return true;

  const isAtLeastOneDocumentFilled = idNumberFields.some(
    (el) => !!getValues(el)
  );

  if (!isAtLeastOneDocumentFilled)
    return "Por favor preencha pelo menos um Documento de Identificação e a sua respetiva validade";

  if (isAtLeastOneDocumentFilled) {
    const errorFields = idNumberFields?.filter(
      (el) => getFieldState(el, formState)?.error?.type === "idDocuments"
    );
    if (!errorFields?.length) return true;
    if (errorFields[0] === fieldKey && !!errorFields[1])
      trigger(errorFields[1]);
    else trigger(errorFields[0]);
  }
  return true;
};

export default checkDocuments;
export { idDocuments, idExpiryFields, idNumberFields, validateExpiryDates };
