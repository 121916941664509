import React from "react";
import TalentoButton from "../../TalentoButton";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

const RenderEditButton = ({
  classes,
  disableEditButton,
  params,
  id,
  parentId,
}) => {
  const generateLink = () => {
    const slug = params.objectLabelPlural.split(" ").join("-").toLowerCase();
    const singleSlug = params.objectLabelSingular
      .split(" ")
      .join("-")
      .toLowerCase();

    const parentSlug = params.parentListUrl
      ? `${params.parentListUrl}/${parentId}`
      : "";
    return `${parentSlug}/${slug}/editar-${singleSlug}/${id}`;
  };

  if (disableEditButton) return null;
  return (
    <Paper className={classes.paperButtons}>
      <TalentoButton
        component={Link}
        to={generateLink()}
        variant="contained"
        color="secondary"
        className={classes.editButton}
      >
        Editar {params.objectLabelSingular}
      </TalentoButton>
    </Paper>
  );
};

export default RenderEditButton;
