import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import { requiredErrorMessage } from "../../../utils/errors";

const useStyle = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
}));

const NameField = (props) => {
  const classes = useStyle();
  const { field: fieldProps, recordValues, control, disabled } = props;

  const requiredRule = {
    required: { value: fieldProps.required, message: requiredErrorMessage },
  };
  const sharedTextProps = {
    variant: "outlined",
    size: "small",
    className: classes.inputField,
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          <Controller
            control={control}
            name={`${fieldProps.key}_firstName`}
            defaultValue={recordValues.first || ""}
            rules={requiredRule}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                {...sharedTextProps}
                id={field.name}
                label="Primeiro Nome"
                required={fieldProps.required}
                error={!!error}
                helperText={error?.message || ""}
                disabled={disabled}
              />
            )}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          <Controller
            control={control}
            name={`${fieldProps.key}_middleName`}
            defaultValue={recordValues.middle || ""}
            render={({ field }) => (
              <TextField
                {...field}
                {...sharedTextProps}
                id={field.name}
                label="Nomes do meio"
                disabled={disabled}
              />
            )}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          <Controller
            control={control}
            name={`${fieldProps.key}_lastName`}
            defaultValue={recordValues.last || ""}
            rules={requiredRule}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                {...sharedTextProps}
                id={field.name}
                label="Último Nome"
                required={fieldProps.required}
                disabled={disabled}
                error={!!error}
                helperText={error?.message || ""}
              />
            )}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default NameField;
