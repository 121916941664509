import { v4 as uuidv4 } from "uuid";
import { getAuthCache } from "../redux/actions/knackconfig";
import { backendUrl } from "../utils/config";
import { apiCall, dbApiCall } from "../redux/actions/api";
import { METHODS, UPDATE_ONE } from "../redux/actions/knack/types";
import { Contrato } from "./knackKeys";

export const uploadFileToS3 = async (file, scene, view, id) => {
  const extension = file.extension;
  const fileName = `${uuidv4()}.${extension}`;

  try {
    const result = await apiCall({
      action: METHODS.POST.value,
      url: `${backendUrl}/knack/signUrl`,
      data: { type: "put", key: fileName },
      token: getAuthCache().token,
      shouldRetry: false,
    });
    const {
      data: { signUrl },
    } = result;
    await apiCall({
      action: METHODS.PUT.value,
      url: signUrl,
      data: file.blob,
      shouldRetry: false,
    });
    const fileUrl = fileName;
    await dbApiCall({
      action: METHODS.PUT.value,
      type: UPDATE_ONE,
      scene,
      view,
      id,
      formData: { [Contrato.fields.DIPLOMA]: fileUrl },
    });
  } catch (err) {
    console.log("error in uploadFileToS3=>", err);
    return err;
  }
};
