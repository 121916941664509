import { createNotification } from "../notifications";
import { store } from "../../storeConfig";
import { CREATE_CONTRACT_VALIDATION } from "../../constants/customValidation";
import validatePaymentFields from "./validatePaymentFields/index.js";
import { Contrato } from "../../../utils/knackKeys.js";

export const invalidateForm = ({ message, dispatch }) => {
  store.dispatch(createNotification()).error({ message });
  dispatch({
    type: CREATE_CONTRACT_VALIDATION,
    payload: false,
  });
  return false;
};

export const customValidation =
  ({ object, formData, action }) =>
  async (dispatch) => {
    if (!["create", "update"].includes(action))
      return invalidateForm({
        dispatch,
        message:
          "Invalid param 'action' while calling customValidation function.",
      });
    switch (object) {
      case Contrato.object: {
        const arePaymentFieldsValid = validatePaymentFields({
          dispatch,
          formData,
        });
        if (!arePaymentFieldsValid) return false;
        return true;
      }
      default:
        return true;
    }
  };
