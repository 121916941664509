import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_29"
const objectLabel = "Grupo-Parceiro"
const objectLabelSingular = "Grupo-Parceiro"
const objectLabelPlural = "Grupos-Parceiros"
const listUrl = "/grupos-parceiros"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }




const FormAddPartnersGroup = (props) => {
   
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_204"}
            view={"view_282"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditPartnersGroup = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_203"}
            view={"view_280"}
            userPermissions={userPermissions}
        />
    )

}

const ListPartnersGroups = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_201"}
            view={"view_279"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {
    FormAddPartnersGroup,
    FormEditPartnersGroup,
    ListPartnersGroups  
}