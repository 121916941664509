import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_25"
const objectLabel = "Funcao-Colaborador"
const objectLabelSingular = "Funcao-Colaborador"
const objectLabelPlural = "Funcoes-Colaborador"
const listUrl = "/funcoes-colaborador"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }





const FormAddEmployeeJob = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_183"}
            view={"view_246"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditEmployeeJob = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_182"}
            view={"view_244"}
            userPermissions={userPermissions}
        />
    )

}

const ListEmployeeJobs = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_180"}
            view={"view_243"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {
    FormAddEmployeeJob,
    FormEditEmployeeJob,
    ListEmployeeJobs  
}