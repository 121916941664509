/* eslint-disable eqeqeq */
import sleep from "../../../../utils/sleep";

const ERROR_CODES = [408, 429, 503];
const RETRY_AFTER = 1500

const checkForRateLimit = async ({ error, retryFunction }) => {
  console.log("ERROR inside checkForRateLimit");
  console.dir(error);

  if (ERROR_CODES.includes(error?.response?.status)) {
    try {
      console.log("RETRY AFTER", RETRY_AFTER);
      await sleep(RETRY_AFTER);
      const result = await retryFunction();
      console.log("RESULT", result);
      return result;
    } catch (error) {
      console.log("ERROR STILL HAPPENNING");
      throw error;
    }
  } else {
    console.log("ANOTHER ERROR", error);
    console.error("ANOTHER ERROR console.error, below console.dir", error);
    console.dir(error);
    return error;
  }
};

export default checkForRateLimit;
