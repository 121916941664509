import { NOTIFICATION } from "../constants/notifications";

export const SUCCESS = "success";
export const INFO = "info";
export const WARNING = "warning";
export const ERROR = "error";
export const LEVELS = [SUCCESS, INFO, WARNING, ERROR];

export const validateLevel = (value) => LEVELS.includes(value);

const notification =
  ({ isOpen = true, type = "success", message = "", options = null }) =>
  (dispatch) => {
    dispatch({
      type: NOTIFICATION,
      payload: {
        isOpen,
        type,
        message,
        options,
      },
    });
  };

export const createNotification = () => (dispatch) => ({
  success: ({ message, options }) =>
    notification({ type: SUCCESS, message, options })(dispatch),
  warning: ({ message, options }) =>
    notification({ type: WARNING, message, options })(dispatch),
  error: ({ message, options }) =>
    notification({ type: ERROR, message, options })(dispatch),
  info: ({ message, options }) =>
    notification({ type: INFO, message, options })(dispatch),
});

export const closeNotification = () =>
  notification({
    isOpen: false,
    type: undefined,
    message: "",
    options: null,
  });
