const historyBackFallback = (fallbackUrl) => {
  fallbackUrl = fallbackUrl || "/";
  var prevPage = window.location.href;

  window.history.go(-1);

  setTimeout(() => {
    if (window.location.href === prevPage) {
      window.location.href = fallbackUrl;
    }
  }, 500);
};

export default historyBackFallback;
