import { createExcel, getTotalPages, prepareDataForExport } from "./helper";

const exportData = async ({
  props,
  fetchFunction,
  gridConfig,
  profileKeys,
}) => {
  try {
    props.createNotification().warning({
      message: "Processo de exportação iniciado, por favor aguarde...",
    });

    // check total records and pages
    const response = await fetchFunction({ page: 1 });
    if (response === "error") return;

    const totalPages = getTotalPages(response);
    const dataForExcel = await Promise.all(
      [...Array(totalPages)].map(async (_, page) => {
        const result = await fetchFunction({ page: page + 1 });
        return result?.records;
      })
    );
    const dataReady = prepareDataForExport(
      dataForExcel.flat(),
      gridConfig,
      profileKeys
    );

    createExcel(dataReady);
    props.createNotification().success({
      message: "Processo de exportação terminado",
    });
  } catch (error) {
    console.error("Erro na exportação", error);
    props.createNotification().error({
      message: `Ocorreu um erro no processo de exportação, tente novamente. Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

export default exportData;
