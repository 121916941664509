export const checkIfIsAdmin = (profile_keys) => {
  let isAdmin = false;
  if (typeof profile_keys !== "undefined") {
    profile_keys.forEach((profile_key) => {
      if (profile_key === "profile_27") {
        isAdmin = true;
      }
    });
  }
  return isAdmin;
};

export const addTableFilters = (filters, tableFilters) => {
  if (tableFilters?.rules?.length > 0) {
    filters.rules.push(...tableFilters.rules);
  }
};

export const filterAccordingToUserPermissions = ({
  filters,
  isAdmin,
  userPermissionsField,
  userId
}) => {
  if (!isAdmin) {
    filters.rules.push({
      field: userPermissionsField,
      operator: "is",
      value: userId,
    });
  }
};

export const addSearchFilter = (tableFilters) => {
  if (tableFilters && tableFilters.searchFilter) {
    return `&search=${encodeURIComponent(tableFilters.searchFilter)}`;
  }
  return "";
};
