import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";
import { Templates } from "../../../utils/knackKeys";

const { list } = Templates;

// SHARED CONSTANTS

const object = "object_44";
const objectLabel = "Templates";
const objectLabelSingular = "Template";
const objectLabelPlural = "Templates";
const listUrl = "/templates";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [],
};

const DetailTemplate = (props) => {
  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      id={props.match.params.id}
      scene={"scene_307"}
      view={"view_412"}
      sceneEdit={"scene_306"}
      viewEdit={"view_411"}
      userPermissions={userPermissions}
      tabs={[]}
    />
  );
};

const FormAddTemplate = (props) => {
  return (
    <AddForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      scene={"scene_302"}
      view={"view_410"}
      userPermissions={userPermissions}
    />
  );
};

const FormEditTemplate = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_306"}
      view={"view_411"}
      userPermissions={userPermissions}
    />
  );
};

const ListTemplates = (props) => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={list.scene}
      view={list.view}
      userPermissions={userPermissions}
      extraConfigs={{}}
    />
  );
};

export { DetailTemplate, FormAddTemplate, FormEditTemplate, ListTemplates };
