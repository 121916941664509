import {
  FETCH_RECORDS_ERROR,
  CLEAR_ERRORS,
  PERMISSIONS_DENIED_ERROR,
} from "../constants/errors";

export default function errorsReducer(state = null, action) {
  switch (action.type) {
    case CLEAR_ERRORS:
      return null;
    case "ERROR_LOGIN":
      return action.payload;
    case FETCH_RECORDS_ERROR:
      return action.payload || "Erro ao carregar os dados, por favor tente de novo";
    case PERMISSIONS_DENIED_ERROR:
      return "Sem permissões de acesso";
    default:
      return state;
  }
}
