import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import MassEmailTemplate from "./MassEmailTemplate";
import MassEmailRichText from "./MassEmailRichText";
import CloseIcon from "@material-ui/icons/Close";
import { setCustomPopUpOpen } from "../../../../../redux/actions/popUps";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { openSpecificMessageEditor } from "../../../../../redux/actions/massComunications";
import { Stack } from "@mui/material";
import { Box } from "@material-ui/core";
import TalentoButton from "../../../TalentoButton";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
    padding: theme.spacing(5),
    marginTop: "10px",
  },
  dialogTitle: {
    margin: theme.spacing(2),
    display: "flex",
  },
  textEditor: {
    marginBottom: theme.spacing(2),
    alignSelf: "start",
    justifySelf: "start",
    display: "flex",
  },
  htmlEditor: {
    marginBottom: theme.spacing(2),
    alignSelf: "end",
    justifySelf: "end",
    display: "flex",
  },
}));

const PopUpForMassEmail = (props) => {
  const { isCustomPopUpOpen, gridApi } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isCustomPopUpOpen}
      maxWidth="xs"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width="54px" height="24px" mr="auto" />
              <Box
                mb={0}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  color="inherit"
                  style={{ marginBottom: 0 }}
                >
                  Enviar Email
                </Typography>
              </Box>
              <TalentoButton
                size="small"
                onClick={() => props.setCustomPopUpOpen(false)}
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </TalentoButton>
            </Stack>
          </Box>
          <Box sx={{ width: "100%" }} mt={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <TalentoButton
                variant="contained"
                color="primary"
                onClick={() =>
                  props.openSpecificMessageEditor(true, "richText")
                }
              >
                Escrever Email
              </TalentoButton>
              <TalentoButton
                variant="contained"
                color="primary"
                onClick={() =>
                  props.openSpecificMessageEditor(true, "emailTemplateOptions")
                }
              >
                Selecionar Template
              </TalentoButton>
            </Stack>
          </Box>
        </Stack>
        <MassEmailTemplate gridApi={gridApi} />
        <MassEmailRichText gridApi={gridApi} />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  setCustomPopUpOpen,
  openSpecificMessageEditor,
})(PopUpForMassEmail);
