import {
  FETCH_RECORD,
  CREATE_RECORD,
  EDIT_RECORD,
  ADD_CONNECTED_RECORD,
  CLEAN_RECORD,
  CLEAN_RECORDS,
  DETAIL_HEADER_FIELDS,
} from "../../constants/dataApiCalls";
import { PERMISSIONS_DENIED_ERROR } from "../../constants/errors";
import { dbApiCall } from ".";
import {
  CREATE,
  GET_MULTIPLE,
  GET_MULTIPLE_FILTERING_CONNECTED,
  GET_ONE,
  METHODS,
  UPDATE_ONE,
} from "../knack/types";
import notify from "../helper";
import { ERROR, WARNING } from "../notifications";
import checkInvalidToken from "./apiHelpers/checkInvalidToken";

const DEFAULT_ERROR_MESSAGE = "Erro de processamento, por favor tente de novo";

export const deleteRecord =
  ({ scene, view, recordId, auth }) =>
  async (dispatch) => {
    try {
      await dbApiCall({
        action: METHODS.DELETE.value,
        type: GET_ONE,
        scene,
        view,
        id: recordId,
        auth,
      });
    } catch (error) {
      console.log("error in deleteRecord", error);
      errorHandlers({
        dispatch,
        error,
        errorMessage: "Não foi possível apagar este registo, tente de novo",
      });
      return "error";
    }
  };

export const fetchRecords =
  ({
    scene,
    view,
    auth,
    userPermissionsField,
    userId,
    profile_keys,
    page,
    paramsForSort = undefined,
    tableFilters = undefined,
    itemsPerPage = undefined,
  }) =>
  async (dispatch) => {
    try {
      let gridData = [];
      let totalRecords = "";

      const response = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_MULTIPLE,
        scene,
        view,
        auth,
        urlParams: {
          userPermissionsField,
          userId,
          profileKeys: profile_keys,
          params: {
            page,
            paramsForSort,
            tableFilters,
            itemsPerPage,
          },
        },
      });
      gridData.push(...response.data.records);
      totalRecords = response.data.total_records;
      return {
        records: gridData,
        totalRecords: totalRecords,
      };
    } catch (error) {
      console.log("error in fetchRecords", error);
      errorHandlers({
        dispatch,
        error,
        errorMessage: DEFAULT_ERROR_MESSAGE,
      });
      return "error";
    }
  };

export const cleanRecords = () => (dispatch) =>
  dispatch({ type: CLEAN_RECORDS });

export const fetchFilteredRecordsByConnection =
  ({
    scene,
    view,
    id,
    connectedField,
    auth,
    userPermissionsField,
    userId,
    profileKeys,
    currentPage,
    paramsForSort,
    filters,
  }) =>
  async (dispatch) => {
    try {
      const response = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_MULTIPLE_FILTERING_CONNECTED,
        scene,
        view,
        id,
        auth,
        urlParams: {
          connectedField,
          userPermissionsField,
          userId,
          profileKeys,
          params: {
            page: currentPage,
            paramsForSort,
            tableFilters: filters,
          },
        },
      });

      let gridData = response.data.records;
      let totalRecords = response.data.total_records;

      return { records: gridData, totalRecords: totalRecords };
    } catch (error) {
      console.log("error in fetchFilteredRecordsByConnection", error);
      errorHandlers({
        dispatch,
        error,
        errorMessage: DEFAULT_ERROR_MESSAGE,
      });
      return "error";
    }
  };

export const fetchRecord = (id, scene, view, auth) => async (dispatch) => {
  try {
    const response = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene,
      view,
      id,
      auth,
    });
    dispatch({ type: FETCH_RECORD, payload: response.data });
    return response.data;
  } catch (error) {
    console.log("error in fetchRecord", error);
    errorHandlers({ error, dispatch });
  }
};

export const fetchHeaderFields =
  (id, scene, view, auth) => async (dispatch) => {
    try {
      const response = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_ONE,
        scene,
        view,
        id,
        auth,
      });
      dispatch({ type: DETAIL_HEADER_FIELDS, payload: response.data });
    } catch (error) {
      console.log("error in fetchHeaderFields", error);
      errorHandlers({ dispatch, error });
    }
  };

export const cleanRecord = () => (dispatch) => dispatch({ type: CLEAN_RECORD });

export const createRecord =
  (formData, scene, view, auth) => async (dispatch) => {
    let result = "";
    try {
      const response = await dbApiCall({
        action: METHODS.POST.value,
        type: CREATE,
        scene,
        view,
        formData,
        auth,
      });

      dispatch({ type: CREATE_RECORD, payload: response.data });
      displayNotification({ scene, view, dispatch });

      result = response;
    } catch (error) {
      const errors =
        error?.response?.data?.data?.errors ||
        error?.response?.data?.errors ||
        error?.response?.data;
      if (!errors?.length) {
        console.log("error in create", {
          error,
          errorMessage: error?.response?.data,
        });
        result = "error";
        return;
      }
      errors.forEach((error) => {
        notify({ dispatch, type: ERROR, message: error.message });
      });
      result = "error";
    }
    return result;
  };

export const editRecord =
  (formData, id, scene, view, auth) => async (dispatch) => {
    try {
      const response = await dbApiCall({
        action: METHODS.PUT.value,
        type: UPDATE_ONE,
        scene,
        view,
        id,
        formData,
        auth,
      });
      dispatch({ type: EDIT_RECORD, payload: response?.data });
      displayNotification({ scene, view, dispatch });
      return "success";
    } catch (error) {
      console.log("error in editRecord", {
        error,
        errorMessage: error?.response?.data,
      });
      if (!!error?.response?.data?.message) {
        notify({
          dispatch,
          type: ERROR,
          message: error?.response?.data?.message,
        });
        return "error";
      }
      if (error?.response?.data) {
        const errors =
          error?.response?.data?.data?.errors || error?.response?.data?.errors;
        errors?.forEach((eachError) => {
          notify({ dispatch, type: ERROR, message: eachError?.message });
        });
      }
      return "error";
    }
  };

export const passIdToState = (id) => (dispatch) =>
  dispatch({ type: ADD_CONNECTED_RECORD, payload: id });

//HELLPERS

export const errorHandlers = ({ dispatch, error, errorMessage = "" }) => {
  if (error?.response?.status === 403) {
    const isTokenExpired = checkInvalidToken({ error });
    if (isTokenExpired) return true;
    else {
      dispatch({ type: PERMISSIONS_DENIED_ERROR });
      notify({ dispatch, type: WARNING, message: "Sem permissões" });
      return true;
    }
  } else {
    notify({
      dispatch,
      type: ERROR,
      message: `Ocorreu um erro! Detalhes: ${
        errorMessage ||
        error?.response?.data?.message ||
        error?.response?.data ||
        error?.message ||
        DEFAULT_ERROR_MESSAGE
      }`,
    });
    return false;
  }
};

const displayNotification = ({ view, scene, dispatch }) => {
  if (
    (scene === "scene_45" && view === "view_56") ||
    (scene === "scene_46" && view === "view_57")
  ) {
    notify({
      dispatch,
      type: WARNING,
      message:
        "Guardando turma. Se alterou o número da edição para um que já existia, o número será substituído e gerado automaticamente.",
    });
  }
};
