import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper_header: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  paper_form: {
    padding: 10,
  },
  submitButton: {
    margin: theme.spacing(2),
  },
  formRecord: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default useStyles;
