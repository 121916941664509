import React from "react";
import EditForm from "../layout/forms/EditForm";

// SHARED CONSTANTS

const object = "object_50";
const objectLabel = "Acompanhamento Pedagógico";
const listUrl = "/turmas";

const FormEditPedagogicSupervision = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      parentId={props.match.params.classId}
      scene={process.env.NODE_ENV === "development" ? "scene_371" : "scene_372"}
      view={process.env.NODE_ENV === "development" ? "view_479" : "view_482"}
      userPermissions={{
        userPermissionsField: "",
        viewRolesPermissions: [],
      }}
    />
  );
};

export { FormEditPedagogicSupervision };
