import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_10"
const objectLabel = "Area-Formacao"
const objectLabelSingular = "Area-Formacao"
const objectLabelPlural = "Areas-formacao"
const listUrl = "/areas-formacao"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }




const FormAddTeachingArea = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_174"}
            view={"view_234"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditTeachingArea = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_173"}
            view={"view_232"}
            userPermissions={userPermissions}
        />
    )

}

const ListTeachingAreas = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_171"}
            view={"view_231"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {
    FormAddTeachingArea,
    FormEditTeachingArea,
    ListTeachingAreas  
}