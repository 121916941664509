import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { editRecord } from "../../../../redux/actions/api/dataApiCalls";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import { v4 as uuidv4 } from "uuid";
import { knackRoles } from "../../../../utils/KnackAndFrontEndRel";
import { updateProfilePicture } from "../../../../redux/actions/auth";
import { backendUrl } from "../../../../utils/config";
import { apiCall } from "../../../../redux/actions/api";
import { METHODS } from "../../../../redux/actions/knack/types";
import UploadedFile from "./UploadedFile";
import { createNotification } from "../../../../redux/actions/notifications";
import { File, Image } from "./Files";

const signedUrlEndpoint = `${backendUrl}/knack/signUrl`;

const UploadFile = (props) => {
  const {
    field,
    fieldConfig,
    url: urlFromProps,
    uploadToFolder = "",
    recordId,
    scene,
    view,
    auth,
    value
  } = props;
  const { profile_keys } = props.auth;

  const [state, setState] = useState({
    isUploading: false,
    isUploaded: false,
    error: false,
    fieldFile: field,
    originalFileUrl: urlFromProps,
    signedFileUrl: '',
    fileType: '',
    isLoading: false,
    mounted: false,
  });

  useEffect(() => {
    if (value) {
      setState((prevState) => ({
        ...prevState,
        originalFileUrl: value.url,
        fieldFile: value.field_key
      }));
    }
  }, [value]);

  useEffect(() => {
    if (state.originalFileUrl) {
      getFileType(state.originalFileUrl);
    } else {
      setState((prevState) => ({ ...prevState, mounted: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.originalFileUrl]);

  const uploadFile = async (event) => {
    event.persist();

    setState((prevState) => ({
      ...prevState,
      isUploaded: false,
      isUploading: true
    }));
    const file = event.target.files[0];
    const extension = file.name.split(".").pop();
    const fileName = `${uploadToFolder ? `${uploadToFolder}/` : ""}${uuidv4()}.${extension}`;

    try {
      const response = await apiCall({
        action: METHODS.POST.value,
        url: signedUrlEndpoint,
        data: { type: "put", key: fileName },
        token: auth.token,
        shouldRetry: false,
      });
      await apiCall({
        action: METHODS.PUT.value,
        url: response.data.signUrl,
        data: event.target.files[0],
        shouldRetry: false,
      });

      const fileUrl = fileName;
      await props.editRecord({ [state.fieldFile]: fileUrl }, recordId, scene, view, auth);
      setState((prevState) => ({
        ...prevState,
        isUploaded: true,
        isUploading: false,
        originalFileUrl: fileUrl
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isUploaded: false,
          isUploading: false,
        }));
      }, 1000);
      const isProfilePicture = recordId === auth.profileId;
      const isProfilePictureField = knackRoles.find(
        (role) => role.userPictureField === state.fieldFile
      );
      if (isProfilePicture && isProfilePictureField)
        await props.updateProfilePicture(fileUrl);
    } catch (err) {
      console.log("error while uploadFile", err);
      props.createNotification().error({ message: 'Ocorreu um erro ao fazer o upload! Se o documento não estiver visível tente de novo', options: { persist: true } });
      setState((prevState) => ({
        ...prevState,
        error: true,
        isUploading: false
      }));
    }
  };

  const getFileType = async (url) => {
    try {
      if (!url) {
        setState((prevState) => ({ ...prevState, mounted: true }));
        return null;
      }
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const response = await apiCall({
        action: METHODS.POST.value,
        url: signedUrlEndpoint,
        data: { type: "get", key: url },
        token: auth.token,
        shouldRetry: false,
      });

      const {
        data: { fileName, signUrl },
      } = response;

      const getFileExtension = (fileExtension) => {
        switch (fileExtension.toLowerCase()) {
          case "jpg":
          case "png":
          case "jpeg":
            return "image";
          default:
            return "file";
        }
      };

      const fileExtension = fileName.split(".").pop();
      const fileType = getFileExtension(fileExtension);

      setState((prevState) => ({
        ...prevState,
        signedFileUrl: signUrl,
        fileType,
        isLoading: false,
        mounted: true
      }));
    } catch (error) {
      console.log("error while fetching file type ", error);
      props.createNotification().error({ message: 'Ocorreu um erro ao recuperar o ficheiro! Tente novamente ou contacte o suporte' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        mounted: true
      }));
    }
  };

  const openFile = async () => {
    try {
      const response = await apiCall({
        action: METHODS.POST.value,
        url: signedUrlEndpoint,
        data: { type: "get", key: state.originalFileUrl },
        token: auth.token,
        shouldRetry: false,
      });
      window.open(`${response.data.signUrl}`);
    } catch (error) {
      console.log("error in openFile", error);
      props.createNotification().error({ message: 'Ocorreu um erro ao abrir o ficheiro. Tente novamente ou contacte o suporte' });
    }
  };

  return (
    <Grid style={{ display: "flex", alignItems: "center" }}>
      <File
        fileUrl={state.signedFileUrl}
        openFile={openFile}
        fileType={state.fileType}
      />
      <Image
        field={field}
        fieldConfig={fieldConfig}
        fileUrl={state.signedFileUrl}
        isLoading={state.isLoading}
        fileType={state.fileType}
        openFile={openFile}
      />
      <UploadedFile
        fieldConfig={fieldConfig}
        field={state.fieldFile}
        isUploading={state.isUploading}
        isUploaded={state.isUploaded}
        mounted={state.mounted}
        uploadFile={uploadFile}
        profile_keys={profile_keys}
        recordId={recordId}
      />
      {state.isUploading && (
        <CircularProgress color="primary" size={30} style={{ marginLeft: 5 }} />
      )}
      {state.isUploaded && (
        <CheckIcon
          color="primary"
          fontSize="large"
          style={{ marginLeft: 10 }}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  formSubmission: state.formSubmission,
  auth: state.auth,
});

export default connect(mapStateToProps, { editRecord, updateProfilePicture, createNotification })(
  UploadFile
);
