import { getAuthCache } from "../../../../redux/actions/knackconfig";
import { backendUrl } from "../../../../utils/config";
import { apiCall } from "../../../../redux/actions/api";
import { METHODS } from "../../../../redux/actions/knack/types";
import { Contrato } from "../../../../utils/knackKeys";
import { SameStudentEmailErrorMessage } from "../../../../utils/errors";

export const validateUniqueEmailInField = async ({ email, key, setHelper }) => {
  if (key !== Contrato.fields.EMAIL_ALUNO) return true;
  try {
    const account = await apiCall({
      action: METHODS.POST.value,
      url: `${backendUrl}/knack/find-by-email`,
      token: getAuthCache().token,
      data: { email },
    });

    if (!!account.data?.profile_keys) {
      const {
        data: { profile_keys, id, name },
      } = account;
      if (!profile_keys.includes("Aluno"))
        return `Este email está associado a uma conta de ${profile_keys}. Use outro email.`;
      setHelper(<SameStudentEmailErrorMessage id={id} name={name} />);
      return true;
    }

    return true;
  } catch (error) {
    return `Ocorreu um erro ao validar o email, tente gravar novamente. Detalhes: ${
      error?.response?.data?.message || error?.response?.data || error.message
    }`;
  }
};
