import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";
import { Instalacoes } from "../../../utils/knackKeys";

// SHARED CONSTANTS

const object = "object_8";
const objectLabel = "Instalacao";
const objectLabelSingular = "Instalacao";
const objectLabelPlural = "Instalacoes";
const listUrl = "/instalacoes";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [],
};

const DetailInstallation = (props) => (
  <DetailView
    object={object}
    objectLabelSingular={objectLabelSingular}
    objectLabelPlural={objectLabelPlural}
    id={props.match.params.id}
    scene={Instalacoes.details.scene}
    view={Instalacoes.details.view.details}
    sceneEdit={Instalacoes.edit.scene}
    viewEdit={Instalacoes.edit.view}
    userPermissions={userPermissions}
    deletable={true}
    tabs={[]}
  />
);

const FormAddInstallation = (props) => (
  <AddForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    scene={Instalacoes.create.scene}
    view={Instalacoes.create.view}
    userPermissions={userPermissions}
  />
);

const FormEditInstallation = (props) => (
  <EditForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    id={props.match.params.id}
    scene={Instalacoes.edit.scene}
    view={Instalacoes.edit.view}
    userPermissions={userPermissions}
  />
);

const ListInstallations = (props) => (
  <ListView
    object={object}
    objectLabelPlural={objectLabelPlural}
    objectLabelSingular={objectLabelSingular}
    scene={"scene_34"}
    view={"view_41"}
    userPermissions={userPermissions}
    extraConfigs={{}}
  />
);

export {
  DetailInstallation,
  FormAddInstallation,
  FormEditInstallation,
  ListInstallations,
};
