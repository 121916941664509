import {
  CLEAR_STATE,
  CHANGE_TAB,
  CLEAR_TAB,
  GET_TABS,
} from "../constants/layout";

export const clearState = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};

export const getTabs = ({ tabs, record = {} }) => {
  const finalTabs = tabs.filter((el) => {
    if (!el?.conditionalDisplay?.isActive) return el;
    const { field, value } = el?.conditionalDisplay;
    if (record?.[field] !== value) return false;
    return el;
  });

  const reorderedTabs = finalTabs.map((el, index) => ({
    ...el,
    position: index + 1,
  }));

  return {
    type: GET_TABS,
    payload: reorderedTabs,
  };
};

export const changeTab = (newValue) => {
  return {
    type: CHANGE_TAB,
    payload: newValue,
  };
};

export const clearTab = (newValue) => {
  return {
    type: CLEAR_TAB,
    payload: newValue,
  };
};
