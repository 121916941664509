import React from "react";
import DetailView from "../layout/Detail/DetailView";
import AddForm from "../layout/forms/AddForm";
import EditForm from "../layout/forms/EditForm";
import ListView from "../layout/ListView";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import { GET_CLASSES_FOR_STUDENT } from "../../utils/customApiCallsTypes";
import {
  Aluno,
  Contrato,
  FormacaoModularContrato,
} from "../../utils/knackKeys";
import { modularCertificateAlert } from "../../constants/strings";

// SHARED CONSTANTS

const object = "object_4";
const objectLabel = "Aluno";
const objectLabelSingular = "Aluno";
const objectLabelPlural = "Alunos";
const identifierNameField = "field_12";
const listUrl = "/alunos";
const icon = (
  <LocalLibraryIcon
    fontSize="large"
    style={{ marginRight: 16, color: "#148FF6" }}
  />
);
const userPermissions = {
  userPermissionsField: "field_433",
  viewRolesPermissions: [],
};

const DetailStudent = (props) => {
  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      icon={icon}
      id={props.match.params.id}
      scene={"scene_29"}
      view={"view_31"}
      sceneEdit={"scene_33"}
      viewEdit={"view_40"}
      deletable={true}
      userPermissions={{
        userPermissionsField: "field_433",
        editRolesPermissions: [],
      }}
      tabs={[
        {
          title: "Contratos",
          position: 1,
          params: {
            object: "object_6",
            objectLabelPlural: "Contratos",
            objectLabelSingular: "Contrato",
            slug: "contratos",
            scene: Contrato.list.scene,
            view: Contrato.list.view,
            userPermissions: {
              userPermissionsField: "field_432",
              viewRolesPermissions: ["profile_4"],
              tabRolesPermissions: ["profile_4"],
            },
            extraConfigs: {
              showCreateButton: "yes",
            },
            filterByConnectedField: {
              connected_field: "field_50",
              id: props.match.params.id,
              identifierNameField: identifierNameField,
            },
          },
        },
        {
          title: "Turmas",
          position: 2,
          params: {
            object: "object_7",
            objectLabelPlural: "Turmas",
            objectLabelSingular: "Turma",
            slug: "turmas",
            scene: "scene_43",
            view: "view_53",
            pinned: ["Turma", "Abrir"],
            userPermissions: {
              userPermissionsField: "field_431",
              viewRolesPermissions: ["profile_4"],
              tabRolesPermissions: ["profile_4"],
              clickTableRolesPermissions: ["profile_4"],
            },
            extraConfigs: {
              showCreateButton: "no",
            },
            filterByConnectedField: {
              connected_field: "field_50",
              id: props.match.params.id,
            },
            customApiCall: {
              name: GET_CLASSES_FOR_STUDENT,
              profiles: ["profile_4"],
            },
          },
        },
        {
          title: "Presenças",
          position: 3,
          conditionalDisplay: {
            isActive: true,
            object: Aluno.object,
            field: Aluno.fields.TEM_SO_FORMACAO_MODULAR,
            value: false,
          },
          params: {
            object: "object_39",
            objectLabelPlural: "Presenças",
            objectLabelSingular: "Presença",
            slug: "presencas",
            scene: "scene_248",
            view: "view_341",
            userPermissions: {
              userPermissionsField: "field_436",
              viewRolesPermissions: ["profile_4"],
              tabRolesPermissions: ["profile_4"],
            },
            extraConfigs: {
              showCreateButton: "yes",
            },
            filterByConnectedField: {
              connected_field: "field_424",
              id: props.match.params.id,
              identifierNameField: identifierNameField,
            },
          },
        },
        {
          title: "Estágios",
          position: 4,
          conditionalDisplay: {
            isActive: true,
            object: Aluno.object,
            field: Aluno.fields.TEM_SO_FORMACAO_MODULAR,
            value: false,
          },
          params: {
            object: "object_18",
            objectLabelPlural: "Estagios",
            objectLabelSingular: "Estagio",
            slug: "estagios",
            scene: "scene_111",
            view: "view_152",
            userPermissions: {
              userPermissionsField: "field_434",
              viewRolesPermissions: ["profile_4"],
              tabRolesPermissions: ["profile_4"],
            },
            extraConfigs: {
              showCreateButton: "no",
            },
            filterByConnectedField: {
              connected_field: "field_371",
              id: props.match.params.id,
            },
          },
        },
        {
          title: "Formação Modular",
          position: 5,
          conditionalDisplay: {
            isActive: true,
            object: Aluno.object,
            field: Aluno.fields.TEM_FORMACAO_MODULAR,
            value: true,
          },
          params: {
            object: FormacaoModularContrato.object,
            objectLabelPlural: "Formacao Modular",
            objectLabelSingular: "Formacao Modular",
            slug: "formacao-modular",
            scene: FormacaoModularContrato.list.scene,
            view: FormacaoModularContrato.list.view,
            userPermissions: {
              userPermissionsField: "",
              viewRolesPermissions: ["profile_4"],
              tabRolesPermissions: ["profile_4"],
            },
            extraConfigs: {
              showCreateButton: "no",
            },
            filterByConnectedField: {
              connected_field: FormacaoModularContrato.fields.ALUNO,
              id: props.match.params.id,
              identifierNameField,
              parentsConnectedField: Aluno.fields.NAME,
            },
            messages: [
              {
                id: 0,
                content: modularCertificateAlert,
                visibleToProfiles: ["profile_4"],
                display: true,
              },
            ],
          },
        },
      ]}
    />
  );
};

const FormAddStudent = (props) => {
  return (
    <AddForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      scene={"scene_42"}
      view={"view_52"}
      userPermissions={userPermissions}
    />
  );
};

const FormEditStudent = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_33"}
      view={"view_40"}
      userPermissions={userPermissions}
    />
  );
};

const ListStudents = (props) => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={"scene_27"}
      view={"view_29"}
      icon={icon}
      pinned={["Nome", "Abrir"]}
      userPermissions={userPermissions}
      extraConfigs={{}}
    />
  );
};

export { DetailStudent, FormAddStudent, FormEditStudent, ListStudents };
