import {FETCH_RECORDS, FETCH_FILTERED_RECORDS_BY_CONNECTION, CLEAN_RECORDS } from '../constants/dataApiCalls'

const initialState = {
    records: [],
    totalRecords: null
}



export default function recordsReducer(state = initialState, action) {

    switch (action.type) {
        case FETCH_RECORDS:
            return action.payload
        case FETCH_FILTERED_RECORDS_BY_CONNECTION:
            return action.payload
        case CLEAN_RECORDS:
            return []
        default:
            return state;
    }
}