import {
  SET_ERROR_MESSAGE,
  RESET_ERROR_MESSAGE,
} from "../constants/errorMessage";

const initialState = {
  message: "",
  link: "",
};

export default function errorMessageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return { message: action.payload.message, link: action.payload.link };
    case RESET_ERROR_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
