import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";

// SHARED CONSTANTS

const object = "object_24";
const objectLabel = "Colaborador";
const objectLabelSingular = "Colaborador";
const objectLabelPlural = "Colaboradores";
const listUrl = "/colaboradores";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [],
};

const DetailEmployee = (props) => {
  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      id={props.match.params.id}
      scene={"scene_198"}
      view={"view_274"}
      sceneEdit={"scene_200"}
      viewEdit={"view_277"}
      userPermissions={userPermissions}
      tabs={[]}
    />
  );
};

const FormAddEmployee = (props) => {
  return (
    <AddForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      scene={"scene_199"}
      view={"view_276"}
      userPermissions={userPermissions}
    />
  );
};

const FormEditEmployee = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_200"}
      view={"view_277"}
      userPermissions={userPermissions}
    />
  );
};

const ListEmployees = (props) => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={"scene_196"}
      view={"view_273"}
      pinned={["Nome", "Abrir"]}
      userPermissions={userPermissions}
      extraConfigs={{}}
    />
  );
};

export { DetailEmployee, FormAddEmployee, FormEditEmployee, ListEmployees };
