import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TalentoButton from "./TalentoButton";
import { deleteRecord } from "../../redux/actions/api/dataApiCalls";
import { createNotification } from "../../redux/actions/notifications";

const useStyles = makeStyles({
  root: {
    background: "#f44336",
    color: "white",
    "&:hover": {
      background: "#d32f2f",
    },
  },
  modal: {
    paddingBottom: "30px",
    paddingRight: "30px",
    padding: "15px",
  },
  actions: {
    paddingTop: "10px",
  },
});

const DeleteButton = ({
  recordId,
  objectLabelPlural,
  disableDeleteButton,
  objectLabelSingular,
  scene,
  view,
  auth,
  deleteRecord,
  createNotification,
}) => {
  const classes = useStyles();
  let history = useHistory();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteItem = async () => {
    try {
      await deleteRecord({ scene, view, recordId, auth });
    } catch (error) {
      createNotification().error({
        message: "Erro ao apagar o registo, tente novamente",
      });
    }
    history.push(`/${objectLabelPlural.toLowerCase()}`);
    setOpen(false);
  };

  return (
    <div>
      <TalentoButton
        startIcon={<DeleteForeverIcon />}
        disabled={disableDeleteButton}
        onClick={handleOpen}
        variant="contained"
        classes={{
          root: classes.root,
        }}
      >
        {objectLabelSingular}
      </TalentoButton>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.modal }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Pretende mesmo apagar este registo?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta ação é IRREVERSÍVEL
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TalentoButton
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            Cancelar
          </TalentoButton>
          <TalentoButton
            onClick={deleteItem}
            color="secondary"
            variant="contained"
          >
            Confirmar
          </TalentoButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, { deleteRecord, createNotification })(
  DeleteButton
);
