import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Toolbar,
  Paper,
  Grid,
  Card,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  CircularProgress,
  Typography,
  CssBaseline,
  Box,
  Tooltip,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { connect } from "react-redux";
import {
  fetchFormConfig,
  clearFormConfig,
} from "../../../../redux/actions/knackconfig";
import { resetInputs } from "../../../../redux/actions/formFields";
import { createRecord } from "../../../../redux/actions/api/dataApiCalls";
import { clearState } from "../../../../redux/actions/layout";
import { setAddPopUpOpen } from "../../../../redux/actions/popUps";
import {
  userHasPermissionsToAdd,
  userCanSeeField,
} from "../../../../utils/userPermissions";
import { checkForErrors } from "../../../../utils/errors";
import { clearErrors } from "../../../../redux/actions/errors";
import { v4 as uuidv4 } from "uuid";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { createRecordCustomFlows } from "../../../../redux/actions/createRecordCustomFlows";
import { customValidation } from "../../../../redux/actions/customValidation";
import { setErrorMessage } from "../../../../redux/actions/errorMessage";
import { backendUrl } from "../../../../utils/config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import checkUrl from "./helper";
import { isObjectEmpty } from "../../../../utils/textUtils";
import TalentoButton from "../../TalentoButton";
import useFormValidation, {
  FormTypes,
} from "../../../../customHooks/useFormValidation";
import RenderField from "./RenderField";
import useStyles from "./styles";
import { FormProvider } from "react-hook-form";
import { METHODS } from "../../../../redux/actions/knack/types";
import { apiCall } from "../../../../redux/actions/api";

const AddRecord = (props) => {
  const {
    object,
    objectLabel,
    listUrl,
    scene,
    view,
    isAddPopUpOpen,
    connectedField,
    auth,
    showUploadButtonOnForm,
    helperText,
    //From redux
    userPermissions,
    clearErrors: storeClearErrors,
    fetchFormConfig,
    formSubmission,
    resetInputs,
    clearState,
    setAddPopUpOpen,
    clearFormConfig,
    customValidation,
    createRecord,
    createRecordCustomFlows,
    formConfig,
    errors: storeErrors,
    setErrorMessage,
  } = props;
  const { profile_keys, profileId, userId } = auth;
  const { viewRolesPermissions } = userPermissions;
  const [isLoaded, setIsLoaded] = useState(false);
  const [file, setFile] = useState(false);
  const [isButtonSaving, setIsButtonSaving] = useState(false);
  let history = useHistory();
  const classes = useStyles();

  const methods = useFormValidation({
    type: FormTypes.CREATE,
    object,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = methods;

  const [recordValues, setRecordValues] = useState([]);
  const location = useLocation();

  useEffect(() => {
    storeClearErrors();

    (async () => {
      try {
        const recordValuesFromUrl = await checkUrl({ location, object });
        setRecordValues(recordValuesFromUrl);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.errorMessage || error.message;
        const link = error?.response?.data?.link;
        setErrorMessage({
          message: errorMessage || "",
          link: link || "",
        });
        history.replace("/erro");
        return;
      }

      const getConfigAndData = async () => {
        await fetchFormConfig(object);
        setIsLoaded(true);
        if (formSubmission.length !== 0) {
          resetInputs();
          clearState();
          if (isAddPopUpOpen === false) {
            history.push(listUrl);
          } else {
            setAddPopUpOpen(false);
          }
        }
      };
      getConfigAndData();
    })();

    return () => {
      clearFormConfig();
      resetInputs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmission]);

  // needs to be fixed to handle multiple files
  const uploadFile = (e) => {
    setFile({
      field: e.target.name,
      file: e.target.files[0],
      fileName: e.target.files[0].name,
    });
  };

  const onSubmit = async (formData) => {
    setIsButtonSaving(true);
    // needs to be fixed to handle multiple files
    if (file) {
      const extension = file.fileName.split(".").pop();
      const fileName = `${uuidv4()}.${extension}`;
      const getSignUrl = await apiCall({
        action: METHODS.POST.value,
        url: `${backendUrl}/knack/signUrl`,
        data: { type: "put", key: fileName },
        token: auth.token,
        shouldRetry: false,
      });
      await apiCall({
        action: METHODS.PUT.value,
        url: getSignUrl.data.signUrl,
        data: file.file,
        shouldRetry: false,
      });
      formData[file.field] = fileName;
    }

    const validation = await customValidation({
      object,
      formData,
      action: "create",
    });
    if (!validation) return setIsButtonSaving(false);

    const result = await createRecord(formData, scene, view, auth);
    if (!result || result === "error") return setIsButtonSaving(false);
    const dataForCustomFlows = {
      recordId: result?.data?.record?.id,
      record: result?.data?.record,
      object,
      objectLabel,
      listUrl,
      profile_keys,
      profileId,
      userId,
    };
    if (dataForCustomFlows) await createRecordCustomFlows(dataForCustomFlows);
    if (result === "error") setIsButtonSaving(false);
  };

  const cancelForm = (isAddPopUpOpen) => {
    if (!isAddPopUpOpen) history.push(listUrl);
    else setAddPopUpOpen(false);
  };

  if (!formConfig) return null;

  if (!userHasPermissionsToAdd(viewRolesPermissions, profile_keys)) {
    return (
      <div style={{ marginLeft: 10, marginTop: 100 }}>
        <h1>Não tem permissões para ver este registo</h1>
      </div>
    );
  }

  const getRecordValue = (field) =>
    recordValues.find((el) => el.field === field.key)?.value;

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!isAddPopUpOpen ? (
        <>
          <Toolbar />
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12}>
              <Paper elevation={3} className={classes.paper_header}>
                <Typography>Adicionar {objectLabel}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Grid container style={{ padding: 10 }}>
        {checkForErrors({
          errors: storeErrors,
          record: null,
          isLoaded,
          isCreate: true,
        }) || (
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <form
                noValidate
                onSubmit={async (event) => {
                  setIsButtonSaving(true);
                  await handleSubmit(onSubmit)(event);
                  setIsButtonSaving(false);
                }}
                className={classes.formRecord}
              >
                <Grid container spacing={2}>
                  {formConfig.map((group) => {
                    return (
                      <Grid key={group.id} item xs={12} sm={4}>
                        <Card key={group.id} variant="outlined">
                          <TableContainer style={{ overflowY: "hidden" }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    {group.field_326}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <ErrorBoundary>
                                  {group.fields.map((field) => {
                                    if (
                                      userCanSeeField(
                                        field.canView,
                                        profile_keys
                                      ) &&
                                      !field.hideInCreate
                                    )
                                      return (
                                        <RenderField
                                          key={field.key}
                                          field={field}
                                          control={control}
                                          errors={errors}
                                          profile_keys={profile_keys}
                                          showUploadButtonOnForm={
                                            showUploadButtonOnForm
                                          }
                                          object={object}
                                          getValues={getValues}
                                          getRecordValue={getRecordValue}
                                          connectedField={connectedField}
                                          uploadFile={uploadFile}
                                          file={file}
                                        />
                                      );
                                    return null;
                                  })}
                                </ErrorBoundary>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
                {helperText && (
                  <Box
                    mt={3}
                    flexDirection={"row"}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <WarningIcon style={{ marginRight: 8, fontSize: 20 }} />
                    <Typography>{helperText}</Typography>
                  </Box>
                )}
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TalentoButton
                    onClick={() => cancelForm(isAddPopUpOpen)}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    style={{ marginLeft: "auto" }}
                  >
                    Cancelar
                  </TalentoButton>
                  <Tooltip
                    arrow
                    title="Há erros no formulário"
                    open={!!isObjectEmpty(errors)}
                  >
                    <span>
                      <TalentoButton
                        disabled={isButtonSaving || !!isObjectEmpty(errors)}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.submitButton}
                        formNoValidate
                        debounceTime={1000}
                      >
                        Guardar {objectLabel}
                      </TalentoButton>
                    </span>
                  </Tooltip>
                  <div style={{ marginLeft: 5, width: 30 }}>
                    {isButtonSaving && (
                      <CircularProgress color="secondary" size={30} />
                    )}
                  </div>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    formConfig: state.formConfig,
    record: state.record,
    formSubmission: state.formSubmission,
    isAddPopUpOpen: state.addPopUp,
    auth: state.auth,
    errors: state.errors,
    createRecordCustomFlow: state.createRecordCustomFlow,
    customValidation: state.customValidation,
  };
};

export default connect(mapStateToProps, {
  fetchFormConfig,
  resetInputs,
  createRecord,
  clearState,
  setAddPopUpOpen,
  clearErrors,
  clearFormConfig,
  createRecordCustomFlows,
  customValidation,
  setErrorMessage,
})(AddRecord);
