import callWebhook from "../../../../redux/actions/api/callWebhook";
import { Contrato } from "../../../../utils/knackKeys";
import { validateCrmIdWebhook } from "../../../../utils/webhooks";

const checkUrl = async ({ location, object }) => {
  if (object !== Contrato.object) return [];
  const { search } = location;
  if (!search) throw new Error("Link inválido para a criação de contrato.");
  const [, crmIdInUrl] = search.split("?crmid=");
  if (crmIdInUrl === process.env.REACT_APP_CREATE_CONTRACT_TOKEN) return [];
  if (!crmIdInUrl) throw new Error("Link inválido para a criação de contrato");
  if (crmIdInUrl.length) {
    await callWebhook({
      webhook: validateCrmIdWebhook,
      body: { id: crmIdInUrl },
    });
    return [{ field: Contrato.fields.CRM_ID, value: crmIdInUrl }];
  }
  return [];
};

export default checkUrl;
