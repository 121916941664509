import { useForm } from "react-hook-form";
// import { Contrato } from "../utils/knackKeys";
// import { useEffect } from "react";
// import validate from "../components/layout/forms/SelectConnectedField/validate";

export const FormTypes = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

// const specialValidation = [
//   {
//     types: [FormTypes.CREATE, FormTypes.UPDATE],
//     object: Contrato.object,
//     validations: [
//       {
//         fields: [Contrato.fields.TURMA],
//         action: ({ formValues }) => {
//           if (!formValues[Contrato.fields.HABILITACOES_LITERARIAS]?.id)
//             return false;
//           return true;
//         },
//       },
//     ],
//   },
// ];

const useFormValidation = ({ type = FormTypes.UPDATE, object }) => {
  const methods = useForm({
    mode: "onTouched",
  });
  //   const {
  // handleSubmit,
  // control,
  //{ errors },
  // formState: getValues,
  // watch,
  // setFocus,
  // trigger,
  // setValue,
  // setError,
  //   } = methods;
  //   watch();

  //   useEffect(() => {
  //     const subscription = watch(async (value, { name, type }) => {
  //       if (!type) return;

  //   switch (name) {
  //     case Contrato.fields.HABILITACOES_LITERARIAS: {
  //       if (!value[Contrato.fields.HABILITACOES_LITERARIAS]?.id) {
  //         trigger(Contrato.fields.TURMA);
  //         return;
  //       }
  //       const currentClassValue = value[Contrato.fields.TURMA];
  //       if (!currentClassValue) return;
  //       trigger(Contrato.fields.TURMA, { shouldFocus: true });
  //       break;
  //     }
  //     default:
  //       return;
  //   }
  // });

  //     return () => subscription.unsubscribe();
  //   }, [getValues, setFocus, watch]);

  //   watch((data, { name, type }) => {
  //     console.log("hello?");
  //     specialValidation.forEach(
  //       ({ types, validations, object: validationObject }) => {
  //         console.log("0 - here?");
  //         if (!types.includes(type) && object !== validationObject) return;
  //         validations.forEach(({ fields, action }) => {
  //           if (!fields.includes(name)) return;
  //           console.log("1 - here?");
  //           if (action({ formValues: data })) return;
  //           console.log("here?");
  //           setError(name, {
  //             type: "validate",
  //             message:
  //               "Por favor preencher o campo Habilitações Literárias primeiro",
  //           });
  //         });
  //       }
  //     );
  //   });
  return methods;
};

export default useFormValidation;
