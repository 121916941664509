import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {
  isFiltersOpen,
  setFilters,
} from "../../../../../redux/actions/filters";
import SearchFilter from "../../../filterComponent/SearchFilter";
import { userCanSeeFilters } from "../../../../../utils/userPermissions";
import useStyles from "../../styles";
import TalentoButton from "../../../TalentoButton";

const RenderFilters = ({ auth, filters: filtersFromProps, isFiltersOpen, setFilters }) => {
  const classes = useStyles();
  const { profile_keys } = auth;
  const openFilters = () => isFiltersOpen(true);
  const resetFilters = () => {
    setFilters({
      isActive: null,
      rules: [],
    });
  };

  return (
    <Grid item className={classes.header_item}>
      {userCanSeeFilters(profile_keys) ? (
        <TalentoButton variant="contained" color="primary" onClick={openFilters}>
          Filtros
        </TalentoButton>
      ) : null}

      <SearchFilter />
      {filtersFromProps?.isActive ? (
        <TalentoButton size="small" onClick={resetFilters}>
          Limpar Filtros
        </TalentoButton>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  filters: state.filters,
});

export default connect(mapStateToProps, {
  isFiltersOpen,
  setFilters,
})(RenderFilters);
