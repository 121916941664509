import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddForm from "./forms/AddForm";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "scroll",
  },
  dialogContentRoot: {
    overflowY: "scroll",
  },
});

const AddPopUp = (props) => {
  const {
    popUpTitle,
    isAddPopUpOpen,
    object,
    scene,
    view,
    userPermissions,
    connectedField,
    showUploadButtonOnForm,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isAddPopUpOpen}
      maxWidth="xl"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <DialogTitle component="h5">Adicionar {popUpTitle}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }} dividers>
        <AddForm
          typeOfForm="popUp"
          object={object}
          scene={scene}
          view={view}
          connectedField={connectedField}
          userPermissions={userPermissions}
          showUploadButtonOnForm={showUploadButtonOnForm}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    isAddPopUpOpen: state.addPopUp,
    record: state.record,
  };
};

export default connect(mapStateToProps, null)(AddPopUp);
