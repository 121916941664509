import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import Logo from '../../assets/images/centrostalento logo mais dgert.png'
import OpenSansRegular from '../../assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../assets/fonts/OpenSans-Bold.ttf'
import OpenSansItalic from '../../assets/fonts/OpenSans-Italic.ttf'
import Signature from '../../assets/images/diploma_signature.png'
import moment from 'moment';

export const intershipCurricularDeclaration = (dynamicData = "") => {

    const {
        studentName,
        studentNif,
        internshipHoursDuration,
        partnerName,
        courseName,
        internshipGrade
    } = dynamicData

    return (
        <Document size="A4">
            <Page style={styles.body2}>
                <Image src={Logo} style={styles.image} />
                <Text style={styles.title}>
                    {"\n"}
                    {"\n"}
                    Declaração de estágio curricular
                    {"\n"}
                    {"\n"}
                </Text>
                <Text style={styles.text}>
                    Porto, <Text>{moment().format('DD MMMM, YYYY')}</Text>
                    {"\n"}
                    {"\n"}
                    Para os devidos efeitos declara-se que <Text>{studentName}</Text>, com o Número de Identificação Fiscal <Text>{studentNif}</Text>, fez o seu estágio curricular de <Text>{internshipHoursDuration}</Text> horas, no/a <Text>{partnerName}</Text>, no âmbito do curso de <Text>{courseName}</Text>, terminado com uma nota final de <Text>{internshipGrade}</Text> valores (escala de 1 a 5).
                </Text>
                <Image src={Signature} style={styles.signature} />
                <View style={styles.signatureText}>
                    <Text>
                        ______________________________________
                        {"\n"}
                        Cristiana Teixeira
                        {"\n"}
                        Coordenadora de Formação
                    </Text>
                </View>
                <View style={styles.footerLine}/>
                <View style={styles.footer}>
                    <Text>
                        Talentodisseia - Unipessoal, Lda. | NIF 509777970 | info@centrostalento.pt | www.centrostalento.pt
                        {"\n"}
                        Rua de Sá da Bandeira, 481, 2E - 4000-436 Porto | +351 220 437 946
                    </Text>
                </View>

            </Page>
        </Document>
    )
}


// Register font => to refactory later
Font.register({ family: 'OpenSansRegular', src: OpenSansRegular });
Font.register({ family: 'OpenSansBold', src: OpenSansBold });
Font.register({ family: 'OpenSansItalic', src: OpenSansItalic });




const styles = StyleSheet.create({

    title: {
        fontSize: 18,
        textAlign: 'center',
        fontFamily: 'OpenSansBold',
        position: 'absolute',
        top: 150,
        width: '100%'
    },
    text: {
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'OpenSansRegular',
        position: 'absolute',
        top: 270,
        left: 120,
        width: 350,
    },
    background: {
        position: 'absolute',
        top: 400,
        right: 0,
        width: '47%',
        height: '47%'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 50,
        width: 500,
        position: 'absolute',
        top: 10
    },
    signature: {
        width: 200,
        position: 'absolute',
        top: 530,
        left: 175,
        textAlign: 'center'
    },
    signatureText: {
        fontSize: 12,
        margin: 12,
        fontFamily: 'OpenSansRegular',
        textAlign: 'center',
        position: 'absolute',
        top: 550,
        width: '100%'
    },
    footerLine: {
        position: 'absolute',
        width: 500,
        left: 42,
        bottom: 40,
        textAlign: 'center',
        borderTop: 0.5,
        borderColor: '#148FF6'
    }, 
    footer: {
        position: 'absolute',
        width: 600,
        bottom: 10,
        fontFamily: 'OpenSansRegular',
        textAlign: 'center',
        fontSize: 8,

    }

});