import { CLEAR_ERRORS, FETCH_RECORDS_ERROR } from "../constants/errors";
import notify from "./helper";
import { ERROR } from "./notifications";

export const clearErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

export const fetchRecordError =
  ({ message = "" } = {}) =>
  (dispatch) => {
    dispatch({
      type: FETCH_RECORDS_ERROR,
      payload: message,
    });
    notify({ dispatch, type: ERROR, message });
  };
