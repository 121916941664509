import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";

// SHARED CONSTANTS

const object = "object_22";
const objectLabel = "Centro";
const objectLabelSingular = "Centro";
const objectLabelPlural = "Centros";
const listUrl = "/centros";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [],
};

const DetailCenter = (props) => (
  <DetailView
    object={object}
    objectLabelSingular={objectLabelSingular}
    objectLabelPlural={objectLabelPlural}
    id={props.match.params.id}
    scene={"scene_132"}
    view={"view_182"}
    sceneEdit={"scene_228"}
    viewEdit={"view_314"}
    userPermissions={userPermissions}
    deletable={true}
    tabs={[
      {
        title: "Instalações",
        position: 1,
        params: {
          object: "object_8",
          objectLabelPlural: "Instalacoes",
          objectLabelSingular: "Instalacao",
          slug: "instalacoes",
          scene: "scene_34",
          view: "view_41",
          userPermissions: userPermissions,
          extraConfigs: {
            showCreateButton: "no",
          },
          filterByConnectedField: {
            connected_field: "field_373",
            id: props.match.params.id,
          },
        },
      },
      {
        title: "Orientadores Pedagógicos",
        position: 2,
        params: {
          object: "object_20",
          objectLabelPlural: "Orientadores-Pedagogicos",
          objectLabelSingular: "Orientador-Pedagogico",
          slug: "orientadores-pedagogicos",
          scene: "scene_115",
          view: "view_174",
          userPermissions: userPermissions,
          extraConfigs: {
            showCreateButton: "no",
          },
          filterByConnectedField: {
            connected_field: "field_283",
            id: props.match.params.id,
          },
        },
      },
      {
        title: "Formadores",
        position: 3,
        params: {
          object: "object_5",
          objectLabelPlural: "Formadores",
          objectLabelSingular: "Formador",
          slug: "formadores",
          scene: "scene_47",
          view: "view_58",
          userPermissions: userPermissions,
          extraConfigs: {
            showCreateButton: "no",
          },
          filterByConnectedField: {
            connected_field: "field_398",
            id: props.match.params.id,
          },
        },
      },
    ]}
  />
);

const FormAddCenter = (props) => (
  <AddForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    scene={"scene_133"}
    view={"view_184"}
    userPermissions={userPermissions}
  />
);

const FormEditCenter = (props) => (
  <EditForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    id={props.match.params.id}
    scene={"scene_228"}
    view={"view_314"}
    userPermissions={userPermissions}
  />
);

const ListCenters = (props) => (
  <ListView
    object={object}
    objectLabelPlural={objectLabelPlural}
    objectLabelSingular={objectLabelSingular}
    scene={"scene_130"}
    view={"view_181"}
    extraConfigs={{}}
    userPermissions={userPermissions}
  />
);

export { DetailCenter, FormAddCenter, FormEditCenter, ListCenters };
