import { OPEN_SPECIFIC_MESSAGE_EDITOR } from '../constants/massComunications'


export const openSpecificMessageEditor = (isOpen, type) => (dispatch) => {

    dispatch({
        type: OPEN_SPECIFIC_MESSAGE_EDITOR,
        payload: {
            isOpen: isOpen,
            type: type
        }
    })
}