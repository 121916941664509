import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_15"
const objectLabel = "Funcao-Pessoa"
const objectLabelSingular = "Funcao-Pessoa"
const objectLabelPlural = "Funcoes-Pessoa"
const listUrl = "/funcoes-pessoa"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }




const FormAddPeopleJob = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_179"}
            view={"view_241"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditPeopleJob = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_178"}
            view={"view_239"}
            userPermissions={userPermissions}
        />
    )

}

const ListPeopleJobs = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_176"}
            view={"view_238"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {
    FormAddPeopleJob,
    FormEditPeopleJob,
    ListPeopleJobs  
}