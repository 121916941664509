import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import useStyles from "../styles";
import { userCanSeeActionsSection } from "../../../../utils/userPermissions";
import RenderFilters from "./filters/RenderFilters";
import { setAddPopUpOpen } from "../../../../redux/actions/popUps";
import RenderQuickFiltersAndActions from "./filters/RenderQuickFiltersAndActions";
import RenderCreateButton from "./RenderCreateButton";
import RenderExportButton from "./RenderExportButton";

const ActionsSection = ({
  object,
  gridApi,
  filtersFromExtraConfigs,
  actionsMenu,
  showCreateButton,
  showCreateButtonToProfiles,
  objectLabelSingular,
  scene,
  view,
  parentProps,
  connected_field,
  userPermissionsField,
  gridConfig,
  // PROPS COMING FROM STORE
  auth,
  filters: filtersFromProps,
  setAddPopUpOpen,
}) => {
  const { profile_keys, userId } = auth;
  const classes = useStyles();

  if (!userCanSeeActionsSection(profile_keys)) return null;
  return (
    <Grid
      container
      spacing={2}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: '5px 20px 0px',
      }}
    >
      <RenderFilters
        profile_keys={profile_keys}
        filtersFromProps={filtersFromProps}
      />
      <RenderQuickFiltersAndActions
        gridApi={gridApi}
        actionsMenu={actionsMenu}
        object={object}
        filtersFromExtraConfigs={filtersFromExtraConfigs}
      />
      <Grid item className={classes.header_item}>
        <RenderCreateButton
          showCreateButton={showCreateButton}
          auth={auth}
          showCreateButtonToProfiles={showCreateButtonToProfiles}
          objectLabelSingular={objectLabelSingular}
          setAddPopUpOpen={setAddPopUpOpen}
        />
        <RenderExportButton
          auth={auth}
          scene={scene}
          view={view}
          connected_field={connected_field}
          userPermissionsField={userPermissionsField}
          gridConfig={gridConfig}
          userId={userId}
          filtersFromProps={filtersFromProps}
          parentProps={parentProps}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  filters: state.filters,
});

export default connect(mapStateToProps, { setAddPopUpOpen })(ActionsSection);
