import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import MassSmsTemplate from "./MassSmsTemplate";
import MassSMSText from "./MassSMSText";
import CloseIcon from "@material-ui/icons/Close";
import { setCustomPopUpOpen } from "../../../../../redux/actions/popUps";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { openSpecificMessageEditor } from "../../../../../redux/actions/massComunications";
import TalentoButton from "../../../TalentoButton";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  dialogTitle: {
    margin: theme.spacing(2),
    display: "flex",
  },
  editorButtons: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(2),
  },
}));

const PopUpForMassSMS = (props) => {
  const { isCustomPopUpOpen, gridApi } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isCustomPopUpOpen}
      maxWidth="sm"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.dialogTitle}>
            <Typography variant="h4" color="inherit">
              Enviar SMS
            </Typography>
            <TalentoButton
              size="small"
              onClick={() => props.setCustomPopUpOpen(false)}
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </TalentoButton>
          </Grid>
          <Grid item xs={6} className={classes.editorButtons}>
            <TalentoButton
              variant="contained"
              color="primary"
              onClick={() =>
                props.openSpecificMessageEditor(true, "textEditor")
              }
            >
              Escrever SMS
            </TalentoButton>
          </Grid>
          <Grid item xs={6} className={classes.editorButtons}>
            <TalentoButton
              variant="contained"
              color="primary"
              onClick={() =>
                props.openSpecificMessageEditor(true, "smsTemplateOptions")
              }
            >
              Usar template
            </TalentoButton>
          </Grid>
        </Grid>
        <MassSmsTemplate gridApi={gridApi} />
        <MassSMSText gridApi={gridApi} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    object: state.actions.object,
  };
};

export default connect(mapStateToProps, {
  setCustomPopUpOpen,
  openSpecificMessageEditor,
})(PopUpForMassSMS);
