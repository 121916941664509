import { validateExpiryDates } from "../InputTextField/validate/validateDocuments";
import dayjs from "dayjs";

const MAX_DATE = dayjs("2100-12-31");
const MIN_DATE = dayjs("1900-01-01");

const validateDate = (value) => {
  if (!value) return true;

  const isValid = dayjs(value).isValid();
  if (!isValid) return false;

  const isBeforemaxDate = dayjs(value).isBefore(MAX_DATE);
  const isAfterMinDate = dayjs(value).isAfter(MIN_DATE);

  return isBeforemaxDate && isAfterMinDate;
};

const validate = ({ fieldKey, getValues }) => ({
  // valueAsDate: true,
  date: (value) => validateDate(value) || "Data Inválida",
  documentsExpiryDates: (value) =>
    validateExpiryDates({ value, fieldKey, getValues }),
});

export default validate;
