import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { openSpecificMessageEditor } from "../../../../../redux/actions/massComunications";
import { customActionsPopUps } from "../../../../../redux/actions/customActionsPopUps";
import { setCustomPopUpOpen } from "../../../../../redux/actions/popUps";
import "react-quill/dist/quill.snow.css";
import TalentoButton from "../../../TalentoButton";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
    minHeight: 400,
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  helperAlert: {
    color: theme.palette.orange.main,
    fontWeight: 800,
  },
}));

const MassEmailRichText = (props) => {
  const {
    gridApi,
    emailPopUpEditor: { isOpen = false, ...emailPopUpEditor },
    object,
  } = props;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const classes = useStyles();

  const onSubmit = (formData) => {
    let emailInfo = {
      channel: "email",
      type: "richText",
      subject: formData.subject,
      content: formData.emailContent,
      campaignId: 27,
      object: object,
    };
    props.customActionsPopUps("sendMassEmail", gridApi, emailInfo);
    handleClose();
  };

  const handleClose = () => {
    props.setCustomPopUpOpen(false);
    props.openSpecificMessageEditor(false, undefined);
  };

  return (
    <Dialog
      open={isOpen ? emailPopUpEditor.type === "richText" : false}
      maxWidth="sm"
      fullWidth={true}
      className={classes.paperFullWidth}
    >
      <DialogContent style={{ padding: 50 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="inherit"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              Escreva o seu e-mail...
            </Typography>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 0, marginBottom: 10 }}
              >
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    rules={{ required: "Este campo é obrigatório" }}
                    name="subject"
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message || ""}
                          label="Assunto"
                          required
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={"emailContent"}
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...rest } }) => {
                      return (
                        <ReactQuill
                          {...rest}
                          theme="snow"
                          placeholder={"Adicione texto..."}
                        />
                      );
                    }}
                  />
                  <div style={{ marginTop: 30 }}>
                    <Typography className={classes.helperAlert}>
                      Atenção!
                    </Typography>
                    <p>
                      Tome em atenção que este email estará inserido na
                      estrutura comum aos emails da Talento, composta pelo
                      logótipo da Talento no topo e de um footer com vários
                      links no final. Não inclui assinatura pessoal.
                    </p>
                  </div>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: 10 }}
                >
                  <TalentoButton
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Enviar Email
                  </TalentoButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    emailPopUpEditor: state.emailPopUpEditor,
    object: state.actions.object,
  };
};

export default connect(mapStateToProps, {
  customActionsPopUps,
  openSpecificMessageEditor,
  setCustomPopUpOpen,
})(MassEmailRichText);
