import React, { useRef } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import useStyles from "../../styles";
import ActionsMenu from "../../../ActionsMenu";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const DisplayQuickFilters = ({
  classes,
  gridItems,
  externalFilterChanged,
  filtersFromExtraConfigs,
}) => {
  if (typeof filtersFromExtraConfigs === "undefined") return null;
  return (
    <FormControl variant="outlined" size="small" className={classes.select}>
      <InputLabel>Filtros Rápidos</InputLabel>
      <Select
        label="Filtros Rápidos"
        value={gridItems.current || ""}
        onChange={externalFilterChanged}
      >
        {filtersFromExtraConfigs?.dropDown?.map((filter, index) => {
          return (
            <MenuItem key={index} value={filter.value}>
              {filter.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const DisplayActionsMenu = ({ actionsMenu, id, object, gridApi }) => {
  if (!actionsMenu) return null;
  return (
    <ActionsMenu
      actions={actionsMenu}
      detailId={id}
      object={object}
      gridApi={gridApi}
    />
  );
};

const RenderQuickFiltersAndActions = ({
  filtersFromExtraConfigs,
  gridApi,
  actionsMenu,
  object,
}) => {
  const classes = useStyles();
  let gridItems = useRef("");
  const { id } = useParams();

  const externalFilterChanged = (e) => {
    const { value } = e.target;
    gridItems.current = value;
    gridApi.onFilterChanged();
  };

  return (
    <Grid item className={classes.header_item}>
      <DisplayQuickFilters
        classes={classes}
        gridItems={gridItems}
        externalFilterChanged={externalFilterChanged}
        filtersFromExtraConfigs={filtersFromExtraConfigs}
      />
      <DisplayActionsMenu
        actionsMenu={actionsMenu}
        id={id}
        object={object}
        gridApi={gridApi}
      />
    </Grid>
  );
};

export default RenderQuickFiltersAndActions;
