import TextField from "@material-ui/core/TextField";
import SelectConnectedField from "../forms/SelectConnectedField";
import DatePickerField from "../forms/DatePickerField";
import BooleanField from "../forms/BooleanField";
import { TableCell } from "@material-ui/core";
import { Controller } from "react-hook-form";
import SelectBoxField from "../forms/SelectBoxField";

const InputOfType = ({
  fieldSelected,
  object,
  index,
  item,
  control,
  getValues,
}) => {
  const typeOfBox = fieldSelected[index]?.fieldObject?.type;

  switch (typeOfBox) {
    case "connection":
      return (
        <SelectConnectedField
          control={control}
          field={fieldSelected[index]?.fieldObject}
          filterName={`values[${index}].value`}
          filterDefaultValue={item.value}
          object={object}
          getValues={getValues}
          fromSearch
        />
      );
    case "multiple_choice":
      return (
        <SelectBoxField
          control={control}
          field={fieldSelected[index]?.fieldObject}
          filterName={`values[${index}].value`}
          fromSearch
        />
      );
    case "date_time":
      return (
        <DatePickerField
          filterName={`values[${index}].value`}
          control={control}
          field={fieldSelected[index]?.fieldObject}
          recordValues=""
          fromSearch
          disableEnforceFocus
        />
      );
    case "boolean":
      return (
        <BooleanField
          filterName={`values[${index}].value`}
          control={control}
          field={fieldSelected[index]?.fieldObject}
          recordValues=""
          fromSearch
        />
      );
    default:
      return (
        <TableCell>
          <Controller
            control={control}
            name={`values[${index}].value`}
            defaultValue={item.value || ""}
            render={({ field }) => (
              <TextField {...field} variant="outlined" size="small" />
            )}
          />
        </TableCell>
      );
  }
};

export default InputOfType;
