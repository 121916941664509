import React from "react";
import { nifValidator, ibanValidator } from "../../../../../utils/textUtils";
import {
  nifErrorMessage,
  ibanErrorMessage,
  NotPortugueseNifMessage,
} from "../../../../../utils/errors";
import _ from "lodash";
import checkDocuments from "./validateDocuments";

const validate = ({
  fieldProps,
  setHelper,
  helper,
  trigger,
  getValues,
  getFieldState,
  formState,
}) => ({
  nif: (value) => {
    if (!fieldProps.name.includes("NIF")) return true;
    if (!value || !value?.toString().trim()) return true;
    if (value?.toString().length <= 9)
      return nifValidator(value) || nifErrorMessage;

    const isSameNifStudentErrorMessageHidden = _.isEmpty(helper?.props);
    // _.isEmpty(helper?.props) -> before were getting this value from helper.type.name, but in prod the name of the component changes 😮

    if (isSameNifStudentErrorMessageHidden)
      setHelper(<NotPortugueseNifMessage />);
    return true;
  },
  iban: (value) => {
    if (!fieldProps.name.includes("IBAN")) return true;
    if (!value || !value?.toString().trim()) return true;
    return ibanValidator(value) || ibanErrorMessage;
  },
  custom: (value) => {
    const fieldWithValidation = fieldProps.validation?.length;
    if (!fieldWithValidation) return true;

    let isValid = true;
    let message = "";

    fieldProps.validation.forEach((val) => {
      if (!isValid) return;

      const errorMessage = val.message;
      val.criteria.forEach((criteria) => {
        if (!isValid) return;
        const isRegex = criteria.operator === "matches regular expression";
        const isSameField = criteria.field === fieldProps.key;

        var parts = /\/(.*)\/(.*)/.exec(criteria.value);
        var restoredRegex = new RegExp(parts[1], parts[2]);

        if (!(isRegex && isSameField)) return;

        const matches = value?.match(restoredRegex);

        if (!matches?.length) return;

        isValid = matches?.length ? false : true;
        message = errorMessage;
      });
    });

    return isValid || message;
  },
  idDocuments: () =>
    checkDocuments({
      fieldKey: fieldProps.key,
      getValues,
      trigger,
      getFieldState,
      formState,
    }),
});

export default validate;
