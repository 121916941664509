import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Typography, TextField } from "@material-ui/core";
import { setNewPassword } from "../../redux/actions/auth";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createNotification } from "../../redux/actions/notifications";
import { useHistory } from "react-router-dom";
import { requiredErrorMessage } from "../../utils/errors";
import TalentoButton from "../layout/TalentoButton";

const SetNewPasswordForm = (props) => {
  const { setFormState, classes, queryParams } = props;
  const methods = useForm({ mode: "onTouched" });
  const { control, handleSubmit } = methods;

  const [isValidToken, setIsValidToken] = useState(true);
  const [token, setToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const token = queryParams.get("token");
    setToken(token);
  }, [queryParams]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const response = await props.setNewPassword({
      password: formData.password,
      token: token,
    });
    if (response.error) {
      setIsValidToken(false);
      setIsLoading(false);
      return;
    }

    setFormState("login");
    history.replace({ search: "" });
    props.createNotification().success({
      message:
        "Password alterada com sucesso. Já pode fazer login com a sua nova password",
    });
  };

  return (
    <div>
      {!isValidToken ? (
        <div style={{ textAlign: "center" }}>
          <Typography component="h1" variant="h5" className={classes.title}>
            Token inválido ou expirado
          </Typography>
          <Typography
            onClick={() => setFormState("recoverPassword")}
            className={classes.resetPassword}
          >
            Voltar a pedir novo token
          </Typography>
        </div>
      ) : (
        <>
          <Typography component="h1" variant="h5" className={classes.title}>
            Introduza a sua nova password
          </Typography>
          <FormProvider {...methods}>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="password"
                defaultValue={""}
                rules={{ required: requiredErrorMessage }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                )}
              />
              {props.errors && (
                <Typography color="error">{props.errors}</Typography>
              )}
              <div className={classes.formOptions}>
                <Typography
                  onClick={() => setFormState("login")}
                  className={classes.resetPassword}
                >
                  Voltar a login
                </Typography>
              </div>
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="primary" size={30} />
                </div>
              ) : (
                <TalentoButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Alterar Password
                </TalentoButton>
              )}
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { setNewPassword, createNotification })(
  SetNewPasswordForm
);
