import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { disableFieldForUser } from "../../../../utils/userPermissions";
import TalentoButton from "../../TalentoButton";
import { Typography } from "@mui/material";

const UploadedFile = ({ fieldConfig, field, isUploading, isUploaded, mounted, uploadFile, profile_keys, recordId }) => {
    const isDisabled = disableFieldForUser(
        fieldConfig?.canEdit || field?.canEdit,
        profile_keys
    );
    const notUploadingNorUploaded = !isUploading && !isUploaded;
    const isFileUploaded = isDisabled ? false : notUploadingNorUploaded;
    // const isUploadDisabled = fieldConfig?.isUploadDisabled
    const isUploadDisabled = fieldConfig?.format?.count_field === "Connection"
    const alternativeInformation = fieldConfig.alternativeInformation

    if (!isFileUploaded || !mounted || !recordId) return null;
    if (isUploadDisabled) return <Typography color="secondary" fontStyle="italic" fontSize={12} marginLeft={3}>{alternativeInformation || 'O Upload deste ficheiro não é aqui'}</Typography>

    return (
        <TalentoButton
            variant="contained"
            component="label"
            color="primary"
            disabled={isUploading}
            style={{ marginLeft: 10 }}
            size="small"
        >
            <input type="file" name="picture" hidden onChange={uploadFile} />
            <CloudUploadIcon color="inherit" fontSize="small" />
        </TalentoButton>
    );
};

export default UploadedFile;