import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { openSpecificMessageEditor } from "../../../../../redux/actions/massComunications";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../../redux/actions/customActionsPopUps";
import { setCustomPopUpOpen } from "../../../../../redux/actions/popUps";
import { FormHelperText } from "@material-ui/core";
import { fetchRecords } from "../../../../../redux/actions/api/dataApiCalls";
import TalentoButton from "../../../TalentoButton";
import { Templates } from "../../../../../utils/knackKeys";

const {
  list: { scene, view },
  fields: {
    TIPO,
    ACTIVE,
    NOME_TEMPLATE,
    ID,
    ID_ZEPTOMAIL,
    ASSUNTO,
    ENVIAR_DESDE,
  },
} = Templates;

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
    minHeight: 300,
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
}));

const MassEmailTemplate = (props) => {
  const {
    gridApi,
    emailPopUpEditor: { isOpen = false, ...emailPopUpEditor },
    auth,
    object,
  } = props;
  const { userId, profile_keys } = props.auth;
  const methods = useForm({
    mode: "onTouched",
  });
  const { handleSubmit, control, setValue, errors } = methods;

  const [subject, setSubject] = useState();
  const [massEmailTemplates, setMassEmailTemplates] = useState([]);
  const classes = useStyles();
  const userPermissionsField = "";

  const onSubmit = (formData) => {
    let templateToUse = {};
    massEmailTemplates.forEach((emailTemplate) => {
      if (emailTemplate.templateId === formData.templateId) {
        templateToUse = {
          channel: "email",
          type: "emailTemplateOptions",
          templateId: emailTemplate.templateId,
          zeptomailTemplateId: emailTemplate.zeptomailTemplateId,
          campaignId: 27,
          subject: subject || formData.subject,
          object: object,
        };
      }
    });
    props.customActionsPopUps("sendMassEmail", gridApi, templateToUse);
    handleClose();
  };

  const preFillSubject = (templateId) => {
    massEmailTemplates.forEach((emailTemplate) => {
      // eslint-disable-next-line eqeqeq
      if (emailTemplate.templateId == templateId) {
        setSubject(emailTemplate.subject);
        setValue("subject", emailTemplate.subject);
      }
    });
  };

  const getTemplates = async () => {
    let massEmailTemplates = [];
    const response = await props.fetchRecords({
      scene,
      view,
      auth,
      userPermissionsField,
      userId,
      profile_keys,
      page: 1,
    });
    const templates = response?.records;

    templates?.forEach((template) => {
      const validObjects =
        template[ENVIAR_DESDE] && template[ENVIAR_DESDE].split(",");
      const isValidObject =
        validObjects?.includes(object) || !validObjects?.length;
      const hideTemplate =
        !isValidObject || template[TIPO] !== "Email" || !template[ACTIVE];
      if (hideTemplate) return;

      massEmailTemplates.push({
        templateName: template[NOME_TEMPLATE],
        templateId: template[ID],
        zeptomailTemplateId: template[ID_ZEPTOMAIL],
        subject: template[ASSUNTO],
      });
    });
    setMassEmailTemplates(massEmailTemplates);
  };

  useEffect(() => {
    if (
      isOpen &&
      emailPopUpEditor.type === "emailTemplateOptions" &&
      !massEmailTemplates?.length
    )
      getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, emailPopUpEditor]);

  const handleClose = () => {
    props.setCustomPopUpOpen(false);
    props.openSpecificMessageEditor(false, undefined);
  };

  return (
    <Dialog
      open={isOpen ? emailPopUpEditor.type === "emailTemplateOptions" : false}
      maxWidth="sm"
      fullWidth={true}
      className={classes.paperFullWidth}
    >
      <DialogContent style={{ padding: 50 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="inherit"
              style={{ marginTop: 10, marginBottom: 20, fontSize: "30px" }}
            >
              Enviar email através de template
            </Typography>
          </Grid>
          <Grid container style={{ margin: "0 auto 10px auto" }}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" size="small">
                    <InputLabel>Selecione um template</InputLabel>
                    <Controller
                      control={control}
                      name="templateId"
                      defaultValue={""}
                      rules={{ required: "Este campo é obrigatório" }}
                      render={({
                        field: { onChange, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Select
                            {...rest}
                            required
                            label="Selecione um template"
                            style={{ minWidth: 500 }}
                            onChange={(e) => {
                              preFillSubject(e.target.value);
                              onChange(e.target.value);
                            }}
                          >
                            {massEmailTemplates.map((emailTemplate, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={emailTemplate.templateId}
                                >
                                  {emailTemplate.templateName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {error?.message && (
                            <FormHelperText error>
                              {error?.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                  {errors?.templateId && (
                    <FormHelperText error>
                      {errors.templateId?.message}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: 10 }}
                >
                  <TalentoButton
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Enviar Email
                  </TalentoButton>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    emailPopUpEditor: state.emailPopUpEditor,
    auth: state.auth,
    records: state.records,
    object: state.actions.object,
  };
};

export default connect(mapStateToProps, {
  customActionsPopUps,
  openSpecificMessageEditor,
  fetchRecords,
  setCustomPopUpOpen,
})(MassEmailTemplate);
