
export const documentStyles = {
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        lineHeight: 1.6

    },
    body2: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 70,
        lineHeight: 1.6
    },
    title: {
        fontFamily: 'OpenSansRegular',
        fontSize: 18,
        textAlign: 'center',
        color: '#366091',
        marginBottom: 10
    },
    text: {
        fontFamily: 'OpenSansRegular',
        fontSize: 11,
        textAlign: 'justify',
        color: '#111212',
        margin: 8
    },
    textCertificateLetter: {
        fontFamily: 'OpenSansRegular',
        fontSize: 11,
        textAlign: 'justify',
        color: '#111212',
        margin: 8,
        textIndent: 40
    },
    boldText: {
        fontFamilly: 'OpenSansBold',
        fontSize: 11,
        color: '#111212',
        margin: 8

    },
    contractItems: {
        fontFamilly: 'OpenSansRegular',
        fontSize: 11,
        textAlign: 'center',
        color: '#366091',
        margin: 8,

    },
    contracItemsDescription: {
        fontFamily: 'OpenSansRegular',
        fontSize: 11,
        textAlign: 'justify',
        color: '#111212',
        textIndent: 50,
        margin: 8,
    },
    image: {
        marginVertical: 15,
        marginRight: 15,
        marginLeft: "auto",
        width: 150
    },
    pagination: {
        fontSize: 10,
        position: "absolute",
        bottom: 20,
        right: 35

    },
    footer: {
        textAlign: 'center',
        fontFamilly: 'OpenSansBold',
        fontSize: 8,
        position: 'absolute',
        bottom: 10,
        left: 75
    },
    signature: {
        textAlign: 'center',
        fontFamilly: 'OpenSansRegular',
        fontSize: 11,
        position: 'absolute',
        bottom: 50,
        left: 75
    },
    signatureCertificateLetter: {
        fontFamilly: 'OpenSansBold',
        fontSize: 11,
        color: '#111212',
        margin: 8,
        textAlign: 'center'

    },
    headerLetter: {
        textAlign: 'left',
        maxWidth: 220,
        fontFamily: 'OpenSansRegular',
        fontSize: 12,
        color: '#111212',

        marginLeft: 'auto'

    }

}