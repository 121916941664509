import React from "react";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import { removeLastComma } from "./helper";

const renderBooleanField = (params) => {
  if (params.data) {
    let value = params.data[params.colDef.field];
    if (value === true) {
      return "Sim";
    } else {
      return "Não";
    }
  }
};

const renderFullName = (params) => {
  if (params.data) {
    const firstName =
      typeof params.data[params.colDef.field] !== "undefined"
        ? params.data[params.colDef.field].first
        : "";
    const middleName =
      typeof params.data[params.colDef.field] !== "undefined"
        ? params.data[params.colDef.field].middle
        : "";
    const lastName =
      typeof params.data[params.colDef.field] !== "undefined"
        ? params.data[params.colDef.field].last
        : "";

    const fullName = `${firstName} ${middleName} ${lastName}`;
    return fullName;
  }
};

const renderFullAddress = (params) => {
  if (params.data) {
    const street = params?.data[params?.colDef?.field]?.street;
    const city = params?.data[params?.colDef?.field]?.city;
    const zip = params?.data[params?.colDef?.field]?.zip;
    const country = params?.data[params?.colDef?.field]?.country;

    let fullAddress = `${street ? street + "," : ""} ${zip ? zip + "," : ""} ${
      city ? city + "," : ""
    } ${country ? country : ""}`;
    fullAddress = removeLastComma(fullAddress);

    return fullAddress;
  }
};

const renderRichText = (params) => {
  if (params.value) {
    const cellContent = params.value
      .replace(/(<([^>]+)>)/gi, "")
      .substring(0, 200);
    if (cellContent.length < 200) {
      return cellContent;
    } else {
      return `${cellContent}...`;
    }
  }
};

const renderMultipleChoice = (params) => {
  if (params.value) {
    if (params.fieldType === "multiple_choice") {
      return Array.isArray(params.value) ? params.value.join() : params.value;
    } else if (params.fieldType === "connection") {
      let fieldValues = [];
      params.value.map((value) => {
        return fieldValues.push(value.identifier);
      });
      return fieldValues.join();
    }
  }
  return null;
};

const renderOpenInNewTab = (params) => {
  if (params.data) {
    let baseUrl = params.baseUrl;
    let id = params.data.id;

    if (params.openDifferentDetail && params.node) {
      const {
        node: { data },
        openDifferentDetail: { connectedField },
      } = params;
      id = data[connectedField]?.length
        ? data[connectedField][0].id
        : params.data.id;
    }

    return (
      <IconButton href={`/${baseUrl}/${id}`} target="_blank">
        <LaunchIcon />
      </IconButton>
    );
  }
  return null;
};

export {
  renderBooleanField,
  renderFullName,
  renderFullAddress,
  renderRichText,
  renderMultipleChoice,
  renderOpenInNewTab,
};
