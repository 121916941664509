import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_30"
const objectLabel = "Tipo-Parceria"
const objectLabelSingular = "Tipo-Parceria"
const objectLabelPlural = "Tipos-Parceria"
const listUrl = "/tipos-parceria"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }




const FormAddTypesOfPartnership = (props) => {
   
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_208"}
            view={"view_287"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditTypesOfPartnership = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_207"}
            view={"view_285"}
            userPermissions={userPermissions}
        />
    )

}

const ListTypesOfPartnerships = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_205"}
            view={"view_284"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {
    FormAddTypesOfPartnership,
    FormEditTypesOfPartnership,
    ListTypesOfPartnerships  
}