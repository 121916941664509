import { invalidateForm } from "..";
import { Contrato } from "../../../../utils/knackKeys";

const {
  DIA_DEBITO_PRESTACOES,
  MEIO_PAGAMENTO_PRESTACOES,
  VALOR_PRESTACAO,
  NUMERO_PRESTACOES,
  DATA_INICIO_PAGAMENTO_PRESTACOES,
  IBAN,
} = Contrato.fields;

export const mandatoryInstallmentFields = [
  {
    field: MEIO_PAGAMENTO_PRESTACOES,
    name: "Meio de pagamento prestações",
  },
  {
    field: VALOR_PRESTACAO,
    name: "Valor prestação",
  },
  {
    field: NUMERO_PRESTACOES,
    name: "Número prestações",
  },
  {
    field: DATA_INICIO_PAGAMENTO_PRESTACOES,
    name: "Data de Início de Pagamento Prestações",
  },
];

export const mandatoryDebitFields = [
  {
    field: DIA_DEBITO_PRESTACOES,
    name: "Dia débito prestações (se for débito direto)",
  },
  {
    field: IBAN,
    name: "IBAN",
  },
];

export const notifyMissingFields = ({ fields, formData, dispatch }) => {
  const missingFields = fields.reduce((acc, curr) => {
    if (!!formData[curr.field]) return acc;
    let string = `${acc}, ${curr.name}`;
    if (!acc.length) string = curr.name;
    return string;
  }, "");

  if (!missingFields.length) return true;

  invalidateForm({
    dispatch,
    message: `Deve preencher o(s) campo(s) ${missingFields}`,
  });
  return false;
};
