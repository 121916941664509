import { IS_ADD_POPUP_OPEN, IS_CUSTOM_POPUP_OPEN, IS_EDIT_POPUP_OPEN, IS_RICH_POPUP_OPEN } from '../constants/popUps';

export const setCustomPopUpOpen = (isPopUpOpen) => (dispatch) => {
    dispatch({
        type: IS_CUSTOM_POPUP_OPEN,
        payload: isPopUpOpen
    })
}

export const setEditPopUpOpen = (isEditPopUpOpen) => (dispatch) => {
    dispatch({
        type: IS_EDIT_POPUP_OPEN,
        payload: isEditPopUpOpen
    })
}

export const setAddPopUpOpen = (isAddPopUpOpen) => (dispatch) => {
    dispatch({
        type: IS_ADD_POPUP_OPEN,
        payload: isAddPopUpOpen
    })
}

export const setRichPopUpOpen = (isRichPopUpOpen) => (dispatch) => {
    dispatch({
        type: IS_RICH_POPUP_OPEN,
        payload: isRichPopUpOpen
    })
}