import {FETCH_RECORD, CLEAN_RECORD} from '../constants/dataApiCalls'


export default function recordReducer(state = {}, action) {
    
    switch (action.type) {
        case FETCH_RECORD:
            return  action.payload
        case CLEAN_RECORD:
            return {}
        default:
            return state;
    }
}