import { Grid, makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  userHasPermissions,
  userHasPermissionsToCreateDigitalDocument,
  userHasPermissionsToCreateDocument,
} from "../../../../utils/userPermissions";
import { connect } from "react-redux";
import DigitalDocumentsArea from "./DigitalDocuments";
import CreateDocumentsArea from "./CreateDocuments";

/**
 * BOOLEAN VALUES FROM THIS COMPONENT ARE ALL WRONG (they are working but it's unintelligible)
 */

const DocumentCreationArea = ({
  userPermissions,
  documents,
  documentsToSign,
  // COMING FROM REDUX
  auth,
  record,
  document,
}) => {
  const { userPermissionsField, viewRolesPermissions } = userPermissions;
  const { userId, profile_keys, profileId } = auth;

  const classes = useStyles();
  const [documentLoading, setDocumentLoading] = useState(null);
  const { pathname } = useLocation();
  const [, , id] = pathname.split("/");

  const canUserCreateDocument = userHasPermissionsToCreateDocument(
    userPermissions.documentsRolesPermissions,
    profile_keys
  );

  const canUserCreateDigitalDocument =
    userHasPermissionsToCreateDigitalDocument(
      userPermissions.digitalDocumentsRolesPermissions,
      profile_keys
    );

  const canUserSeeRecord = userHasPermissions({
    userPermissionsField: record?.[userPermissionsField],
    viewRolesPermissions,
    currentUserId: userId,
    userProfileKeys: profile_keys,
    currentProfileId: profileId,
    objectId: id,
  });

  const displayDigitalDocumentSection =
    !canUserCreateDigitalDocument &&
    canUserSeeRecord &&
    documentsToSign?.length;

  const displayCreateDocumentsArea =
    !canUserCreateDocument && documents?.length;

  const createDocument = async (newDocument) => {
    setDocumentLoading(newDocument.type);
    if (!document || document?.type !== newDocument.type)
      await newDocument.documentFunction(id, userId);
  };

  const displayDocumentLink = useMemo(() => {
    if (document) {
      setDocumentLoading(null);
      return document.document;
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <Grid container justifyContent="space-between">
      <CreateDocumentsArea
        displayCreateDocumentsArea={displayCreateDocumentsArea}
        documents={documents}
        createDocument={createDocument}
        documentLoading={documentLoading}
        displayDocumentLink={displayDocumentLink}
        userPermissions={userPermissions}
        auth={auth}
        classes={classes}
        document={document}
      />
      <DigitalDocumentsArea
        documentsToSign={documentsToSign}
        displayDigitalDocumentSection={displayDigitalDocumentSection}
        createDocument={createDocument}
        canUserCreateDigitalDocument={canUserCreateDigitalDocument}
        classes={classes}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  documentActions: {
    width: "100%",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0.8rem",
  },
}));

const mapStateToProps = (state) => ({
  auth: state.auth,
  record: state.record,
  document: state.documents,
});

export default connect(mapStateToProps, null)(DocumentCreationArea);
