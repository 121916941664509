import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import { requiredErrorMessage, emailErrorMessage } from "../../../../utils/errors";
import { validateUniqueEmailInField } from "./validateUniqueEmail";

const useStyle = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
}));

const EmailField = (props) => {
  const classes = useStyle();
  const { field: fieldProps, recordValues, control, disabled } = props;
  const [helper, setHelper] = useState("");

  const validate = {
    uniqueEmail: async (value) =>
      validateUniqueEmailInField({ email: value, key: fieldProps.key, setHelper }),
  };

  const getHelperText = (error) => error?.message || helper

  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{ width: "100%" }}>
        <Controller
          control={control}
          defaultValue={recordValues || ""}
          name={fieldProps.key}
          rules={{
            required: {
              value: fieldProps.required,
              message: requiredErrorMessage,
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: emailErrorMessage,
            },
            validate,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={getHelperText(error)}
              className={classes.inputField}
              required={fieldProps.required}
              label={fieldProps.name}
              variant="outlined"
              size="small"
              id={field.name}
              disabled={disabled}
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
};

export default EmailField;
