import React from 'react';
import DetailView from '../layout/Detail/DetailView'
import AddForm from '../layout/forms/AddForm';
import EditForm from '../layout/forms/EditForm';
import ListView from '../layout/ListView'
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";


// SHARED CONSTANTS

const object = "object_5"
const objectLabel = "Formador"
const objectLabelSingular = "Formador"
const objectLabelPlural = "Formadores"
const listUrl = "/formadores"
const icon = <RecordVoiceOverIcon fontSize="large" style={{ marginRight: 16, color: "#148FF6" }} />
const userPermissions = {
    userPermissionsField: 'field_444',
    viewRolesPermissions: []
}


const DetailInstructor = (props) => {
    return (
        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            icon={icon}
            id={props.match.params.id}
            scene={"scene_48"}
            view={"view_59"}
            sceneEdit={"scene_50"}
            viewEdit={"view_62"}
            userPermissions={{
                userPermissionsField: 'field_444',
                editRolesPermissions: ['profile_5']
            }}
            tabs={[
                {
                    title: 'Turmas',
                    position: 1,
                    params: {
                        object: "object_7",
                        objectLabelPlural: "Turmas",
                        objectLabelSingular: "Turma",
                        slug:'turmas',
                        scene: "scene_43",
                        view: "view_53",
                        userPermissions: userPermissions,
                        extraConfigs: {
                            showCreateButton: 'no',
                        },
                        filterByConnectedField: {
                            connected_field: "field_73",
                            id: props.match.params.id
                        }
                    }
                }
            ]}
        />
    )
}


const FormAddInstructor = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_49"}
            view={"view_61"}
            userPermissions={userPermissions}
        />
    )
}

const FormEditInstructor = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_50"}
            view={"view_62"}
            userPermissions={userPermissions}
        />
    )
}

const ListInstructors = (props) => {
    return (
        <ListView
            object={object}
            pinned={["Nome", "Abrir"]}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_47"}
            view={"view_58"}
            icon={icon}
            extraConfigs={{
                actionsMenu: [
                    {
                        name: "Enviar SMS em Massa",
                        customActionId: "sendMassSMS"
                    },
                    {
                        name: "Enviar Email em Massa",
                        customActionId: "sendMassEmail"
                    }
                ]
            }}
            userPermissions={userPermissions}
        />
    )
}


export {
    DetailInstructor,
    FormAddInstructor,
    FormEditInstructor,
    ListInstructors
}
