import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import {
  minCharErrorMessage,
  requiredErrorMessage,
} from "../../../utils/errors";

const useStyle = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
}));

const PhoneField = (props) => {
  const classes = useStyle();
  const { field: fieldProps, recordValues, control, disabled } = props;

  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{ width: "100%" }}>
        <Controller
          control={control}
          defaultValue={recordValues}
          name={fieldProps.key}
          rules={{
            required: {
              value: fieldProps.required,
              message: requiredErrorMessage,
            },
            minLength: { value: 9, message: minCharErrorMessage(9) },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              className={classes.inputField}
              required={fieldProps.required}
              label={fieldProps.name}
              type="number"
              variant="outlined"
              size="small"
              id={field.name}
              disabled={disabled}
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
};

export default PhoneField;
