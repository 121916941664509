import moment from "moment";

const formatName = (form) => {
  let nameValues = {};
  let fieldKey = "";
  for (const property in form) {
    if (property.endsWith("_firstName")) {
      nameValues.first = form[property];
    }
    if (property.endsWith("_middleName")) {
      nameValues.middle = form[property];
    }
    if (property.endsWith("_lastName")) {
      nameValues.last = form[property];
    }

    if (property.endsWith("_firstName")) {
      fieldKey = property.replace("_firstName", "");
      form[fieldKey] = nameValues;
    }
  }

  const nameObject = {
    [fieldKey]: nameValues,
  };

  return nameObject;
};

const checkIsAdress = (record) => {
  for (const property in record) {
    if (record[property] instanceof Array) {
      if (record[property][0]) {
        const { city, country, street, zip } = record[property][0];
        if (city || country || street || zip)
          record[property] = { ...record[property][0] };
      }
    }
  }
};

export const prepareData = (record) => {
  Object.entries(record).forEach(([k, v]) => {
    if (v instanceof Date) {
      record[k] = moment(v).format("DD/MM/YYYY");
    }
  });

  for (const property in record) {
    // this is to handle connected field that are not multiple
    if (record[property] !== null && typeof record[property] !== "undefined") {
      if (record[property].id) {
        record[property] = record[property].id;
      }
    }
  }

  formatName(record);
  checkIsAdress(record);

  return record;
}
