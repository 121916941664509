import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { connect } from "react-redux";
import {
  createNotification,
  closeNotification,
  validateLevel,
  WARNING,
} from "../../redux/actions/notifications";
import { io } from "socket.io-client";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { backendUrl } from "../../utils/config";
import TalentoButton from "./TalentoButton";
import { store } from "../../redux/storeConfig";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles({
  root: {
    color: "white",
    borderColor: "white",
  },
});

const Notifications = (props) => {
  const { notifications, auth } = props;
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const socket = io(backendUrl, {
      transports: ["websocket"],
      auth: { token: auth.token },
    });

    socket.on("connect", () => {
      const userId = props.auth.userId;
      socket.emit("join-room", userId);
    });

    socket.on("event", (data) => {
      const { type, message, options } = data;
      const level = validateLevel(type) ? type : WARNING;
      store.dispatch(createNotification())[level]({ message, options });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.closeNotification();
  };

  const handleClick = ({ url, target = "link" }) => {
    if (target === "link") history.push(url);
    else window.open(url, "_blank");
    props.closeNotification();
  };

  const urlType = notifications?.options?.outsideUrl
    ? "_blank"
    : notifications?.options?.url
    ? "link"
    : "link";
  const url = notifications?.options?.outsideUrl || notifications?.options?.url;

  return (
    <div style={{ marginTop: 100 }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={notifications.isOpen}
        autoHideDuration={notifications?.options?.persist ? null : 10000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={notifications.type}
          style={{ textAlign: "right" }}
        >
          <span dangerouslySetInnerHTML={{ __html: notifications?.message }} />
          {notifications?.options?.withLink ? (
            <TalentoButton
              style={{ marginLeft: 10 }}
              onClick={() => handleClick({ url, target: urlType })}
              variant="outlined"
              classes={{
                root: classes.root,
              }}
            >
              {notifications.options.buttonText}
            </TalentoButton>
          ) : null}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  closeNotification,
})(Notifications);
