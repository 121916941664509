const romanMatrix = [
  [1000, "M"],
  [900, "CM"],
  [500, "D"],
  [400, "CD"],
  [100, "C"],
  [90, "XC"],
  [50, "L"],
  [40, "XL"],
  [10, "X"],
  [9, "IX"],
  [5, "V"],
  [4, "IV"],
  [1, "I"],
];

const convertToRomanNumber = (number) => {
  if (Number.isNaN(number)) return;

  if (number === 0) {
    return "";
  }
  for (var i = 0; i < romanMatrix.length; i++) {
    if (number >= romanMatrix[i][0]) {
      return (
        romanMatrix[i][1] + convertToRomanNumber(number - romanMatrix[i][0])
      );
    }
  }
};

export default convertToRomanNumber;
