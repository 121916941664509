import React, { useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { KnackAndFrontEndRelMainMenu } from "../../utils/KnackAndFrontEndRel";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const menuItemsList = KnackAndFrontEndRelMainMenu;

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: "15px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.lighter,
    },
  },
  icon: {
    color: theme.palette.dark.main,
  },
}));

const MenuItems = ({ auth }) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [/*menuAccessReady*/, setMenuAccessReady] = useState(false);
  const [menuItems, setMenuItems] = useState(menuItemsList);

  const checkAccess = () => {
    const finalMenu = []
    auth.profile_keys?.forEach((userProfile_key) =>
      menuItems.forEach((menuItem) =>
        menuItem.profilesWithAccess.forEach((profileWithAccess) => {
          if (userProfile_key === profileWithAccess) return finalMenu.push({...menuItem, showMenu: true})
          return finalMenu.push({...menuItem, showMenu: false})
        })
      )
    );
    setMenuItems(finalMenu)
    setMenuAccessReady(true);
  };

  useEffect(() => {
    checkAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.userId]);

  return (
    <div key={auth.userId}>
      {menuItems.map((menuItem) =>
        menuItem.showMenu ? (
          <ListItem
            key={menuItem.object}
            button
            component={Link}
            to={menuItem.url}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText primary={menuItem.text} />
          </ListItem>
        ) : null
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(MenuItems);
