import { Profiles } from "./knackKeys";

export const userHasPermissions = ({
  userPermissionsField,
  viewRolesPermissions,
  currentUserId,
  userProfileKeys,
  currentProfileId = "",
  objectId = "",
  ignoreUserPermissionsField = false,
}) => {
  let hasAccess = false;
  if (currentProfileId === objectId) return true; // check if user is accessing their own profile

  userProfileKeys.forEach((profileKey) => {
    if (profileKey === Profiles.ADMIN) return (hasAccess = true);
    if (ignoreUserPermissionsField) {
      const matchingRole = viewRolesPermissions.find(viewRole => viewRole === profileKey)
      if (matchingRole?.length) hasAccess = true;
    }
    if (!userPermissionsField) return;

    userPermissionsField.forEach((userField) => {
      if (userField?.id !== currentUserId) return;
      viewRolesPermissions.forEach((viewRole) => {
        if (viewRole !== profileKey) return;
        hasAccess = true;
      });
    });
  });
  return hasAccess;
};

// export const userHasPermissions = (
//   userPermissionsField,
//   viewRolesPermissions,
//   currentUserId,
//   userProfileKeys,
//   currentProfileId,
//   objectId
// ) => {
//   let hasAccess = false;

//   if (currentProfileId === objectId) {
//     hasAccess = true; // check if user is accessing is own profile
//   } else {
//     userProfileKeys.map((profile_key) => {
//       if (profile_key === "profile_27") {
//         hasAccess = true; // check if user is Admin
//       } else if (
//         userPermissionsField !== "" &&
//         typeof userPermissionsField !== "undefined"
//       ) {
//         userPermissionsField.map((userField) => {
//           if (userField?.id === currentUserId) {
//             viewRolesPermissions.map((viewRole) => {
//               if (viewRole === profile_key) {
//                 hasAccess = true; // check if user has permissions to the views
//               }
//               return null;
//             });
//           }
//           return null;
//         });
//       }
//       return null;
//     });
//   }

//   return hasAccess;
// }

export const userHasPermissionsToAdd = (
  viewRolesPermissions,
  user_profile_keys
) => {
  let hasAccess = false;
  user_profile_keys.some((profile_key) => {
    if (profile_key === "profile_27") {
      return (hasAccess = true);
    } else {
      viewRolesPermissions.map((viewRole) => {
        if (viewRole === profile_key) {
          hasAccess = true;
        }
        return (hasAccess = true);
      });
    }
    return null;
  });
  return hasAccess;
};

// FIELDS PERMISSIONS
export const disableFieldForUser = (field_profile_keys, user_profile_keys) => {
  return shouldDisableField(field_profile_keys, user_profile_keys);
};

export const userCanSeeField = (
  field_profile_keys,
  user_profile_keys,
  viewType
) => {
  return !shouldDisableField(field_profile_keys, user_profile_keys, viewType);
};

export const userCanClickInConnectedFields = (
  field_profile_keys,
  user_profile_keys
) => {
  return shouldDisableField(field_profile_keys, user_profile_keys);
};

// ROLES PERMISSIONS
export const userHasPermissionsToDelete = (
  deleleteRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(deleleteRolesPermissions, user_profile_keys);
};

export const userHasPermissionsToEdit = (
  editRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(editRolesPermissions, user_profile_keys);
};

export const userHasPermissionsToViewTab = (
  tabRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(tabRolesPermissions, user_profile_keys);
};

export const userHasPermissionsToCreateDocument = (
  documentsRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(documentsRolesPermissions, user_profile_keys);
};

export const userHasPermissionsToCreateDigitalDocument = (
  digitalDocumentsRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(
    digitalDocumentsRolesPermissions,
    user_profile_keys
  );
};

export const userHasAccessToAction = (
  actionRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(actionRolesPermissions, user_profile_keys);
};

export const userCanClickInTable = (
  clickTableRolesPermissions,
  user_profile_keys
) => {
  return shouldDisableButton(clickTableRolesPermissions, user_profile_keys);
};

export const userCanSeeActionsSection = (user_profile_keys) => {
  let hasAccess = false;
  user_profile_keys.map((profile_key) => {
    if (profile_key !== "profile_4") {
      hasAccess = true;
    }
    return null;
  });

  return hasAccess;
};

export const userCanSeeFilters = (user_profile_keys) => {
  let hasAccess = false;
  user_profile_keys.map((profile_key) => {
    if (profile_key !== "profile_5" && "profile_4") {
      hasAccess = true;
    }
    return null;
  });
  return hasAccess;
};

export const userCanSeeCreateButton = (user_profile_keys, showToProfiles) => {
  let hasAccess = false;
  user_profile_keys.map((profile_key) => {
    if (showToProfiles?.length) {
      if (showToProfiles.includes(profile_key)) hasAccess = true;
    } else if (profile_key !== "profile_4" && profile_key !== "profile_21") {
      hasAccess = true;
    }
    return null;
  });
  return hasAccess;
};

export const userCanExport = (user_profile_keys) => {
  let hasAccess = false;
  user_profile_keys.map((profile_key) => {
    if (profile_key !== "profile_5" && "profile_4") {
      hasAccess = true;
    }
    return null;
  });
  return hasAccess;
};

function shouldDisableField(field_profile_keys, user_profile_keys, viewType) {
  let disableField = true;
  user_profile_keys.map((profile_key) => {
    if (profile_key === "profile_27") {
      disableField = false;
      field_profile_keys = "";
    } else if (profile_key !== "profile_27" && field_profile_keys?.length > 0) {
      field_profile_keys.map((field_profile_key) => {
        if (field_profile_key === profile_key) {
          disableField = false;
        }
        return null;
      });
    }
    return null;
  });
  if (viewType === "grid") disableField = !disableField;
  return disableField;
}

const shouldDisableButton = (typeOfRole, user_profile_keys) => {
  let hasAccess = false;
  let disableButton = true;

  user_profile_keys.map((profile_key) => {
    if (profile_key === "profile_27") hasAccess = true;
    else {
      typeOfRole &&
        typeOfRole.map((viewRole) => {
          if (viewRole === profile_key) hasAccess = true;
          return null;
        });
    }
    return null;
  });

  if (hasAccess) disableButton = false;
  return disableButton;
};
