import React, { useEffect, useState, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../redux/actions/customActionsPopUps";
import { setCustomPopUpOpen } from "../../../../redux/actions/popUps";

import InputTextField from "../../forms/InputTextField";
import SelectConnectedField from "../../forms/SelectConnectedField";

import { Aulas, Contrato } from "../../../../utils/knackKeys";
import { fetchGridConfig } from "../../../../redux/actions/knackconfig";
import TalentoButton from "../../TalentoButton";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  submitButton: {
    margin: theme.spacing(2),
  },
}));

const PopUpForCreatingPresencesManually = (props) => {
  const {
    gridConfig,
    gridApi,
    isCustomPopUpOpen,
    students,
    selectedClassFields,
    classData,
    fetchGridConfig,
    customActionsPopUps,
    setCustomPopUpOpen,
  } = props;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control, errors } = methods;

  const classes = useStyles();
  const [studentsField, setStudentsField] = useState();
  const [fields, setFields] = useState([]);

  const getFields = useCallback(() => {
    const fields = [];
    selectedClassFields.forEach((fieldwithValue) =>
      gridConfig.forEach(
        (gridElement) =>
          gridElement.key === fieldwithValue.key &&
          fields.push({
            ...gridElement,
            defaultValue: fieldwithValue[gridElement.key],
          })
      )
    );
    return fields;
  }, [gridConfig, selectedClassFields]);

  useEffect(() => {
    (async () => {
      const studentsGridConfig = await fetchGridConfig(Contrato.object);
      const field = studentsGridConfig.find(
        (element) => element.key === Contrato.fields.ALUNO
      );
      setFields(getFields());
      setStudentsField(field);
    })();
  }, [getFields, fetchGridConfig]);

  const onSubmit = (formData) => {
    if (formData[studentsField.key]?.length) {
      customActionsPopUps("createPresencesManually", gridApi, {
        students: formData[studentsField.key],
        classData,
      });
      setCustomPopUpOpen(false);
    }
  };

  const PopUpHeader = () => (
    <DialogTitle>
      <Typography align="center">
        Introduza os dados para <br /> criar presenças para a aula selecionada:
      </Typography>
    </DialogTitle>
  );

  const ActionsMenu = () => (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      <TalentoButton
        onClick={() => setCustomPopUpOpen(false)}
        variant="contained"
        color="primary"
        style={{ marginRight: 10 }}
      >
        Cancelar
      </TalentoButton>
      <TalentoButton variant="contained" color="secondary" type="submit">
        Executar
      </TalentoButton>
    </Grid>
  );

  return (
    <Dialog
      open={isCustomPopUpOpen}
      maxWidth="xs"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <Grid
        container
        style={{ padding: 10, display: "flex", justifyContent: "center" }}
      >
        <PopUpHeader />
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <TableContainer>
                  <Table aria-label="simple tabel">
                    <TableBody>
                      {fields?.length
                        ? fields.map((field) => (
                            <InputTextField
                              key={field.key}
                              recordValues={field.defaultValue}
                              errors={errors}
                              control={control}
                              field={field}
                              object={Aulas.object}
                              disabled={true}
                            />
                          ))
                        : null}
                      {studentsField ? (
                        <SelectConnectedField
                          customOptions={{
                            list: students,
                            hasMultiple: true,
                            truncate: true,
                          }}
                          errors={errors}
                          control={control}
                          field={studentsField}
                          object={Contrato.object}
                          required
                        />
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <ActionsMenu />
              </form>
            </FormProvider>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    students: state.actions.students,
    selectedClassFields: state.actions.selectedClassFields,
    classData: state.actions.classData,
  };
};

export default connect(mapStateToProps, {
  customActionsPopUps,
  setCustomPopUpOpen,
  fetchGridConfig,
})(PopUpForCreatingPresencesManually);
