import { invalidateForm } from "..";
import { Contrato } from "../../../../utils/knackKeys";
import {
  mandatoryDebitFields,
  mandatoryInstallmentFields,
  notifyMissingFields,
} from "./helper";

const {
  VALOR_NUMERARIO,
  VALOR_CHEQUE,
  VALOR_MULTIBANCO_IFTHENPAY,
  VALOR_TRANSFERENCIA,
  VALOR_DEBITO_DIRETO,
  DATA_DEBITO_DIRETO_PAGAMENTO_INICIAL,
  TIPO_PAGAMENTO,
  MEIO_PAGAMENTO_PRESTACOES,
} = Contrato.fields;

const validatePaymentFields = ({ dispatch, formData }) => {
  if (
    !formData[VALOR_NUMERARIO] &&
    !formData[VALOR_CHEQUE] &&
    !formData[VALOR_MULTIBANCO_IFTHENPAY] &&
    !formData[VALOR_TRANSFERENCIA] &&
    !formData[VALOR_DEBITO_DIRETO]
  ) {
    invalidateForm({
      dispatch,
      message:
        "Deve preencher pelo menos um destes campos: Valor em Numerário, Valor em Cheque, Valor por Multibanco ou Ifthenpay, Valor por Transferência, Valor por Débito Direto",
    });
    return false;
  }

  if (formData[VALOR_DEBITO_DIRETO])
    if (!formData[DATA_DEBITO_DIRETO_PAGAMENTO_INICIAL]) {
      invalidateForm({
        dispatch,
        message:
          "Deve preencher o campo Data do débito direto pagamento da inscrição.",
      });
      return false;
    }

  if (formData[TIPO_PAGAMENTO] !== "Prestações") return true;

  const isCorrect = notifyMissingFields({
    fields: mandatoryInstallmentFields,
    formData,
    dispatch,
  });
  if (!isCorrect) return false;

  if (formData[MEIO_PAGAMENTO_PRESTACOES] === "Débito Direto") {
    const isCorrect = notifyMissingFields({
      fields: mandatoryDebitFields,
      formData,
      dispatch,
    });
    if (!isCorrect) return false;
  }
  return true;
};

export default validatePaymentFields;
