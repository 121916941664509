import { SET_GRID_FILTER, SET_GRID_SEARCH_FILTER } from "../constants/filters";
import { IS_FILTERS_OPEN } from "../constants/popUps";

export const setFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_GRID_FILTER,
    payload: filters,
  });
};

export const setSearchFilter = (searchFilter) => (dispatch) => {
  dispatch({
    type: SET_GRID_SEARCH_FILTER,
    payload: searchFilter,
  });
};

export const isFiltersOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: IS_FILTERS_OPEN,
    payload: isOpen,
  });
};
