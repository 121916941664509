import React, { useEffect } from "react";
import { useStyles } from "./styles";
import Logo from "../../../assets/images/logo_centrostalento_no_subtitle.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import TalentoButton from "../TalentoButton";
import ErrorIcon from "./ErrorIcon";
import LaunchIcon from "@material-ui/icons/Launch";
import { resetErrorMessage } from "../../../redux/actions/errorMessage";

const RelatedLink = ({ link, classes }) => {
  if (!link) return null;
  return (
    <Link to={link} target="_blank">
      <TalentoButton
        type="submit"
        variant="outlined"
        color="primary"
        style={{ fontSize: 14 }}
      >
        Abrir
        <LaunchIcon fontSize="small" className={classes.buttonIcon} />
      </TalentoButton>
    </Link>
  );
};

const ErrorDetails = ({ errorMessage, link, classes }) => {
  if (!errorMessage)
    return (
      <div className={classes.message}>
        Esta página não existe, não tem permissões ou encontrou um erro.
      </div>
    );
  return (
    <>
      <div className={classes.subtitle}>Detalhes: </div>
      <div className={classes.message}>{errorMessage}</div>
      <RelatedLink link={link} classes={classes} />
    </>
  );
};

const ErrorPage = ({ errorMessage, link }) => {
  const classes = useStyles();

  useEffect(() => {
    return () => resetErrorMessage();
  }, []);

  return (
    <div className={classes.box}>
      <div className={classes.innerBox}>
        <div className={classes.title}>Uh oh, Aconteceu um erro!</div>
        <ErrorIcon className={classes.image} />
        <ErrorDetails
          errorMessage={errorMessage}
          link={link}
          classes={classes}
        />
        <img className={classes.logo} alt="logo" src={Logo} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage.message,
  link: state.errorMessage.link,
});

const mapDispatchToProps = { resetErrorMessage };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
