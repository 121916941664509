import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const FieldOption = ({ props, option, inputValue, classes }) => {
    if (!option || !option.identifier) return <div />

    const matches = match(option.identifier, inputValue);
    const parts = parse(option.identifier, matches);

    return (
        <div {...props} className={classes.option}>
            {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                </span>
            ))}
        </div>
    );
}

export default FieldOption;