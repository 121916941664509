import React from "react";
import { disableFieldForUser } from "../../../../utils/userPermissions";
import InputTextField from "../InputTextField";
import NameField from "../NameField";
import PhoneField from "../PhoneField";
import BooleanField from "../BooleanField";
import EmailField from "../EmailField";
import DatePickerField from "../DatePickerField";
import SelectBoxField from "../SelectBoxField";
import AddressField from "../AddressField";
import SelectConnectedField from "../SelectConnectedField";
import UploadFileInForm from "../UploadFileInForm";

const RenderField = ({
  field,
  control,
  errors,
  profile_keys,
  showUploadButtonOnForm,
  object,
  getValues,
  connectedField,
  uploadFile,
  file,
  getRecordValue,
}) => {
  const getFieldForAddPopUp = (field, connectedField) => {
    let fieldForAddPopUp = {};
    if (field.key === (connectedField && connectedField[0].key)) {
      fieldForAddPopUp = connectedField;
      return fieldForAddPopUp;
    }
  };

  switch (field.type) {
    case "name":
      return (
        <NameField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "short_text":
    case "number":
    case "currency":
    case "paragraph_text":
    case "link":
      return (
        <InputTextField
          key={field.key}
          control={control}
          field={field}
          recordValues={getRecordValue(field)}
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    //commented so it doesnt show in create forms, password should be generated automaticly from some integromat logic
    // case "password":
    //     return (<PasswordField key={field.key} field={field} disabled={disableFieldForUser(field.canEdit, profile_keys)} />)
    case "boolean":
      return (
        <BooleanField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "phone":
      return (
        <PhoneField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "email":
      return (
        <EmailField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "date_time":
      return (
        <DatePickerField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "multiple_choice":
      return (
        <SelectBoxField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "address":
      return (
        <AddressField
          key={field.key}
          control={control}
          field={field}
          recordValues=""
          errors={errors}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      );
    case "connection":
      return (
        <SelectConnectedField
          key={field.key}
          control={control}
          field={field}
          recordValues={getFieldForAddPopUp(field, connectedField)}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
          object={object}
          getValues={getValues}
        />
      );
    case "file":
      return showUploadButtonOnForm ? (
        <UploadFileInForm
          key={field.key}
          control={control}
          field={field}
          uploadFile={uploadFile}
          file={file}
          disabled={disableFieldForUser(field.canEdit, profile_keys)}
        />
      ) : null;
    default:
      return null;
  }
};
export default RenderField;
