import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { recoverPassword } from "../../redux/actions/auth";
import { createNotification } from "../../redux/actions/notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import TalentoButton from "../layout/TalentoButton";

const RecoverPasswordForm = (props) => {
  const { setFormState, classes } = props;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const response = await props.recoverPassword(formData);
    if (response.error) {
      props.createNotification().error({ message: response.error.message });
      setIsLoading(false);
      return;
    }
    setIsEmailSent(true);
  };

  return (
    <div>
      {isEmailSent ? (
        <>
          <Typography component="h1" variant="h5" className={classes.title}>
            Reset de password efetuado com sucesso.
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            className={classes.title}
          >
            Por favor consulte o seu email.
          </Typography>
        </>
      ) : (
        <>
          <Typography component="h1" variant="h5" className={classes.title}>
            Introduza o seu email
          </Typography>
          <FormProvider {...methods}>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="email"
                rules={{ required: "Este campo é obrigatório" }}
                defaultValue={""}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                  />
                )}
              />
              {props.errors && (
                <Typography color="error">{props.errors}</Typography>
              )}
              <div className={classes.formOptions}>
                <Typography
                  onClick={() => setFormState("login")}
                  className={classes.resetPassword}
                >
                  Voltar a login
                </Typography>
              </div>
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="primary" size={30} />
                </div>
              ) : (
                <TalentoButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Recuperar password
                </TalentoButton>
              )}
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, {
  recoverPassword,
  createNotification,
})(RecoverPasswordForm);
