import React, { useEffect, useState } from "react";
import {
  fetchFormConfig,
  clearFormConfig,
} from "../../../redux/actions/knackconfig";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { clearErrors } from "../../../redux/actions/errors";
import {
  fetchRecord,
  cleanRecord,
} from "../../../redux/actions/api/dataApiCalls";
import { userHasPermissions } from "../../../utils/userPermissions";
import { checkForErrors } from "../../../utils/errors";
import moment from "moment";
import "moment/locale/pt";
import { setRichPopUpOpen } from "../../../redux/actions/popUps";
import RenderDetailField from "./RenderDetailField";
import useStyles from "./style";

moment.locale("pt");

const GroupsView = ({
  id,
  record,
  object,
  scene,
  view,
  sceneEdit,
  viewEdit,
  errors,
  auth,
  userPermissions,
  uploadToFolderField,
  // FROM REDUX
  clearFormConfig,
  clearErrors,
  cleanRecord,
  fetchFormConfig,
  fetchRecord,
  formSubmission,
  formConfig,
}) => {
  const classes = useStyles();
  const { userPermissionsField, viewRolesPermissions, ignoreUserPermissionsField } = userPermissions;
  const { userId, profile_keys, profileId } = auth;

  const [isLoaded, setIsLoaded] = useState(false);
  const [hasPermission, setHasPermission] = useState(false)

  useEffect(() => {
    let isMounted = true;
    (async () => {
      setIsLoaded(false);
      clearFormConfig();
      clearErrors();
      cleanRecord();
      await fetchFormConfig(object);
      await fetchRecord(id, scene, view, auth);
      if (isMounted) setIsLoaded(true);
      return () => {
        // cleanRecord();
        clearFormConfig();
        clearErrors();
        isMounted = false;
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmission, id]);

  useEffect(() => {
    const doesUserHavePermission = userHasPermissions({
      userPermissionsField: record?.[userPermissionsField],
      viewRolesPermissions,
      currentUserId: userId,
      userProfileKeys: profile_keys,
      currentProfileId: profileId,
      objectId: id,
      ignoreUserPermissionsField,
    });
    setHasPermission(doesUserHavePermission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, userPermissions, id])

  if (!formConfig) return null;
  if (record?.length || record?.id) {
    if (!hasPermission && isLoaded) {
      return (
        <div style={{ marginLeft: 10, marginTop: 100 }}>
          <h1>Não tem permissões para ver este registo</h1>
        </div>
      );
    }
  }

  return (
    <Paper className={classes.backGroundPaper} elevation={3}>
      {checkForErrors({ errors, record, isLoaded }) || (
        <Grid container spacing={2}>
          {formConfig.map((group) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={group.id}>
                <Card variant="outlined">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>{group.field_326}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <RenderDetailField
                          key={group.id}
                          object={object}
                          group={group}
                          record={record}
                          sceneEdit={sceneEdit}
                          viewEdit={viewEdit}
                          uploadToFolderField={uploadToFolderField}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  formConfig: state.formConfig,
  errors: state.errors,
  record: state.record,
  auth: state.auth,
  isRichPopUpOpen: state.richPopUp,
  formSubmission: state.formSubmission,
});

export default connect(mapStateToProps, {
  fetchFormConfig,
  clearFormConfig,
  checkForErrors,
  clearErrors,
  fetchRecord,
  setRichPopUpOpen,
  cleanRecord,
})(GroupsView);
