import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  titleCell: {
    fontWeight: "bold",
  },
  backGroundPaper: {
    display: "flex",
    width: "100%",
    margin: theme.spacing(1),
    minHeight: "74vh",
    padding: theme.spacing(2),
  },
}));

export default useStyles;
