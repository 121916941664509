import React from 'react';
import DetailView from '../../layout/Detail/DetailView'
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'
import { Contrato } from '../../../utils/knackKeys';


// SHARED CONSTANTS

const object = "object_20"
const objectLabel = "Orientador Pedagogico"
const objectLabelSingular = "Orientador-Pedagogico"
const objectLabelPlural = "Orientadores-Pedagogicos"
const listUrl = "/orientadores-pedagogicos"
const userPermissions = {
    userPermissionsField: 'field_552'
}

const DetailPedagogicOrientator = (props) => {
    return (

        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            scene={"scene_127"}
            view={"view_177"}
            sceneEdit={"scene_128"}
            viewEdit={"view_178"}
            userPermissions={{
                userPermissionsField: userPermissions.userPermissionsField,
                viewRolesPermissions: [
                    'profile_21',
                ],
                editRolesPermissions: [
                    'profile_20'
                ],
                deleleteRolesPermissions: []
            }}
            tabs={[
                {
                    title: 'Contratos',
                    position: 1,
                    params: {
                        object: "object_6",
                        objectLabelPlural: "Contratos",
                        objectLabelSingular: "Contrato",
                        slug:'contratos',
                        scene: Contrato.list.scene,
                        view: Contrato.list.view,
                        pinned: ["Número do contrato", "Aluno", "Abrir", "Estado do contrato"],
                        userPermissions: {
                            userPermissionsField: userPermissions.userPermissionsField,
                            viewRolesPermissions: [
                                'profile_21'
                            ],
                            tabRolesPermissions: [
                                'profile_21'
                            ],
                            clickTableRolesPermissions: [
                                'profile_21'
                            ]
                        },
                        extraConfigs: {
                            showCreateButton: 'no',
                        },
                        filterByConnectedField: {
                            connected_field: Contrato.fields.ORIENTADOR_PEDAGOGICO,
                            id: props.match.params.id
                        }
                    }
                }
            ]}
        />
    )
}


const FormAddPedagogicOrientator = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_118"}
            view={"view_173"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditPedagogicOrientator = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_128"}
            view={"view_178"}
            userPermissions={userPermissions}
        />
    )

}

const ListPedagogicOrientators = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_115"}
            view={"view_174"}
            userPermissions={{
                userPermissionsField: userPermissions.userPermissionsField,
                viewRolesPermissions: [
                    'profile_5'
                ]
            }}
            extraConfigs={{}}

        />
    )
}

export {
    DetailPedagogicOrientator,
    FormAddPedagogicOrientator,
    FormEditPedagogicOrientator,
    ListPedagogicOrientators
}