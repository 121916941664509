import exportData from "./exportAction";

const exportDataFromGrid = async (
  scene,
  view,
  auth,
  userPermissionsField,
  userId,
  profile_keys,
  filters,
  props,
  gridConfig
) => {
  const fetchFunction = async ({ page }) =>
    props.fetchRecords({
      scene,
      view,
      auth,
      userPermissionsField,
      userId,
      profile_keys,
      page,
      tableFilters: filters,
      itemsPerPage: 30,
    });

  return await exportData({
    props,
    fetchFunction,
    gridConfig,
    profileKeys: profile_keys,
  });
};

const exportDataFromGridInsideDetail = async (
  scene,
  view,
  id,
  connected_field,
  auth,
  userPermissionsField,
  userId,
  profile_keys,
  filters,
  props,
  gridConfig
) => {
  const fetchFunction = async ({ page }) =>
    props.fetchFilteredRecordsByConnection({
      scene,
      view,
      id,
      connectedField: connected_field,
      auth: props.auth,
      userPermissionsField,
      userId,
      profileKeys: profile_keys,
      currentPage: page,
      paramsForSort: "",
      filters,
    });

  return await exportData({
    props,
    fetchFunction,
    gridConfig,
    profileKeys: profile_keys,
  });
};

export { exportDataFromGrid, exportDataFromGridInsideDetail };
