import { frequencyDeclaration } from "../../../components/documents/frequencyDeclaration";
import { signupDeclaration } from "../../../components/documents/signupDeclaration";
import { Contrato, Curso, Turma } from "../../../utils/knackKeys";
import { generateDebitFileWebhook } from "../../../utils/webhooks";
import { store } from "../../storeConfig";
import { dbApiCall } from "../api";
import callWebhook from "../api/callWebhook";
import notify from "../helper";
import { GET_ONE, METHODS } from "../knack/types";
import { getAuthCache } from "../knackconfig";
import { ERROR, WARNING, createNotification } from "../notifications";

const {
  fields: {
    CURSO,
    TURMA_ATUAL,
    TURMA,
    NUMERO_CARTAO_CIDADAO,
    NUMERO_PASSAPORTE,
    NUMERO_AUTORIZACAO_RESIDENCIA,
    ALUNO,
    NIF,
    SEXO,
    TURMA_INICIO_AUTOMATICO,
    INICIO_TURMA_CURSO,
  },
} = Contrato;

const {
  fields: { NOME, AREAS_FORMACAO, CARGA_HORARIA },
} = Curso;

const {
  fields: {
    DATA_INICIO,
    DIA_SEMANA,
    HORA_INICIO_SESSOES,
    HORA_TERMINO_SESSOES,
  },
} = Turma;

const getDataForDeclarations = async (contractId) => {
  try {
    const contractData = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: Contrato.details.scene,
      view: Contrato.details.view,
      id: contractId,
    });

    const { data: contract } = contractData;

    const courseId = contract[CURSO][0].id;

    const courseData = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: Curso.details.scene,
      view: Curso.details.view,
      id: courseId,
    });

    const { data: course } = courseData;

    const currentClass = contract[TURMA_ATUAL].length
      ? contract[TURMA_ATUAL]
      : contract[TURMA];

    const classData = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: Turma.details.scene,
      view: Turma.details.view.details,
      id: currentClass[0].id,
    });

    const { data: classInfo } = classData;

    const isAutoStartClass = contract[TURMA_INICIO_AUTOMATICO] === "Sim";
    const classStartDate = isAutoStartClass
      ? contract[INICIO_TURMA_CURSO]?.iso_timestamp
      : classInfo[DATA_INICIO]?.iso_timestamp;

    const getIdentificationNumber = () =>
      contract[NUMERO_CARTAO_CIDADAO] ||
      contract[NUMERO_PASSAPORTE] ||
      contract[NUMERO_AUTORIZACAO_RESIDENCIA];

    return {
      studentName: contract[ALUNO]?.[0]?.identifier?.toUpperCase(),
      studentNif: contract[NIF]?.toString(),
      gender: contract[SEXO],
      identificationNumber: getIdentificationNumber(),
      courseName: course[NOME],
      areaNumber: course[AREAS_FORMACAO]?.[0]?.identifier,
      totalCourseHours: course[CARGA_HORARIA]?.toString(),
      courseStartDate: classStartDate,
      startHour: classInfo[HORA_INICIO_SESSOES],
      finishHour: classInfo[HORA_TERMINO_SESSOES],
      weekDay: classInfo[DIA_SEMANA],
    };
  } catch (error) {
    store.dispatch(createNotification()).error({
      message: `Erro ao recuperar os dados para gerar declaração. Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

const createSignupDeclaration = (contractId) => async (dispatch) => {
  const data = await getDataForDeclarations(contractId);

  const document = signupDeclaration(data);

  dispatch({
    type: "CREATE_SIGNUP_DECLARATION",
    payload: document,
  });
};

const createFrequencyDeclaration = (contractId) => async (dispatch) => {
  const data = await getDataForDeclarations(contractId);

  const document = frequencyDeclaration(data);

  dispatch({
    type: "CREATE_FREQUENCY_DECLARATION",
    payload: document,
  });
};

const generateDebitsFile = async ({ gridApi, dispatch }) => {
  try {
    const selectedContracts = gridApi.getSelectedNodes().map((el) => el.data);
    const { userId } = getAuthCache();
    const selectedContractsIds = selectedContracts.map((el) => el.id);

    if (!selectedContractsIds.length)
      return notify({
        dispatch,
        type: ERROR,
        message: "Selecione pelo menos um Contrato",
      });

    if (selectedContracts.some((el) => !el[Contrato.fields.CURSO]?.[0]?.id))
      return notify({
        dispatch,
        type: ERROR,
        message: "Selecione contratos com o campo Curso preenchido.",
      });
    notify({
      dispatch,
      type: WARNING,
      message:
        "Receberá uma notficação quando o ficheiro tiver sido gerado! ⏰",
    });
    await callWebhook({
      webhook: generateDebitFileWebhook,
      body: { contract_ids: selectedContractsIds, user_id: userId },
    });
  } catch (error) {
    console.log("error in generateDebitsFile", error);
    notify({
      dispatch,
      type: ERROR,
      message: `Ocorreu um erro ao gerar o ficheiro! Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

export {
  createSignupDeclaration,
  createFrequencyDeclaration,
  generateDebitsFile,
};
