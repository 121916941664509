import { Contrato } from "../../../../../utils/knackKeys";

export const billingCountries = [
    {
        name: "País Morada Faturação",
        field: Contrato.fields.PAIS_MORADA_FATURACAO,
        relatedName: "Morada de Faturação",
        related: Contrato.fields.MORADA_FATURACAO
    },
    {
        name: "País Morada Formando",
        field: Contrato.fields.PAIS_MORADA_FORMANDO,
        relatedName: "Morada do Formando",
        related: Contrato.fields.MORADA
    },
    {
        name: "País Morada Titular",
        field: Contrato.fields.PAIS_MORADA_TITULAR,
        relatedName: "Morada do Titular",
        related: Contrato.fields.ENDERECO
    },
]

export const getRequiredMessage = ({ name, relatedName }) => `O campo ${name} é obrigatório quando os campos da ${relatedName} estão preenchidos.`