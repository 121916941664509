import {SET_INPUT, RESET_INPUT} from '../constants/formFields'

export const setInputValue = (payload) => (dispatch) => {
    dispatch({
        type: SET_INPUT,
        payload: payload
    })

}

export const resetInputs = () => (dispatch) => {
    dispatch({
        type: RESET_INPUT,
        payload: {}
    })
}