export default function documentsReducer(state = null, action) {

    switch (action.type) {

        case 'CREATE_INTERNSHIP_CONTRACT_PARTNER':
            return {
                type: 'internship_contract',
                document: action.payload
            }
        case 'CREATE_INTERNSHIP_CURRICULAR_DECLARATION':
            return {
                type: 'internship_declaration',
                document: action.payload
            }
        case 'CREATE_PARTNERSHIP_PROTOCOL':
            return {
                type: 'partnership_protocol',
                document: action.payload
            }
        case 'CREATE_PARTNERSHIP_PROTOCOL_WITH_DISCOUNT':
            return {
                type: 'partnership_protocol_with_discount',
                document: action.payload
            }
        case 'CREATE_INTERNSHIP_BADGE':
            return {
                type: 'internship_badge',
                document: action.payload
            }
        case 'CREATE_CERTIFICATE_LETTER':
            return {
                type: 'certificate_letter',
                document: action.payload
            }
        case 'CREATE_DIPLOMA':
            return {
                type: 'diploma',
                document: action.payload
            }
        case 'CREATE_SIGNUP_DECLARATION':
            return {
                type: 'signup_declaration',
                fileName: 'Declaracao_Inscricao',
                document: action.payload
            }
        case 'CREATE_FREQUENCY_DECLARATION':
            return {
                type: 'frequency_declaration',
                fileName: 'Declaracao_Frequencia',
                document: action.payload
            }
        case 'CLEAR_DOCUMENT':
            return null

        default:
            return state;
    }
}