import React from 'react';
import DetailView from '../../layout/Detail/DetailView'
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_21"
const objectLabel = "Agente"
const objectLabelSingular = "Agente"
const objectLabelPlural = "Agentes"
const listUrl = "/agentes"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
}

const DetailAgent = (props) => {
    return (

        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            scene={"scene_190"}
            view={"view_251"}
            sceneEdit={"scene_191"}
            viewEdit={"view_252"}
            userPermissions={{
                editRolesPermissions: [
                    'profile_21'
                ],
            }}
            tabs={[
                {
                    title: 'Orientadores Pedagógicos',
                    position: 1,
                    params: {
                        object: "object_20",
                        objectLabelPlural: "Orientadores-Pedagogicos",
                        objectLabelSingular: "Orientador-Pedagogico",
                        slug:'orientadores-pedagogicos',
                        scene: "scene_115",
                        view: "view_174",
                        userPermissions: {
                            userPermissionsField: userPermissions.field,
                            viewRolesPermissions: [
                                'profile_21'
                            ],
                            tabRolesPermissions: [
                                'profile_21'
                            ],
                            clickTableRolesPermissions: [
                                'profile_21'
                            ]
                        },
                        extraConfigs: {
                            showCreateButton: 'no',
                        },
                        filterByConnectedField: {
                            connected_field: "field_374",
                            id: props.match.params.id
                        }
                    }
                },
            ]}
        />
    )
}


const FormAddAgent = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_138"}
            view={"view_190"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditAgent = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_191"}
            view={"view_252"}
            userPermissions={userPermissions}
        />
    )

}

const ListAgents = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_135"}
            view={"view_187"}
            userPermissions={userPermissions}
            extraConfigs={{}}

        />
    )
}

export {
    DetailAgent,
    FormAddAgent,
    FormEditAgent,
    ListAgents
}