import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    box: {
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    innerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginRight: "0.5rem",
      marginLeft: "0.5rem",
    },
    image: {
      width: "250px",
      height: "250px",
      [theme.breakpoints.up("md")]: {
        width: "300px",
        height: "300px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "350px",
        height: "350px",
      },
    },
    title: {
      fontSize: "30px",
      fontWeight: 300,
      marginTop: "6rem",
      marginBottom: "1.5rem",
    },
    subtitle: {
      fontSize: "16px",
      fontWeight: 600,
      marginTop: "2rem",
    },
    message: {
      fontSize: "20px",
      fontWeight: 600,
      marginTop: "1rem",
      marginBottom: "1.5rem",
      maxWidth: "30rem",
      color: theme.palette.error.main,
    },
    buttonIcon: {
      marginLeft: "0.5rem",
    },
    logo: {
      marginTop: "3rem",
      width: "200px",
      marginLeft: "0.75rem",
      marginBottom: "4.25rem",
    },
  };
});
