import React, { useState } from "react";
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
} from "react-hook-form";
import { typeOfFilters, optionsTranslation } from "./typeOfFilters";
import { connect } from "react-redux";
import { setFilters, isFiltersOpen } from "../../../redux/actions/filters";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import moment from "moment";
import { requiredErrorMessage } from "../../../utils/errors";
import InputOfType from "./InputOfType";
import { FormHelperText } from "@material-ui/core";
import TalentoButton from "../TalentoButton";
import cloneDeep from "../../../utils/cloneDeep";

const GridFilter = (props) => {
  const { gridConfig, isFilterPopUpOpen, object } = props;
  const [values] = useState([
    {
      field: "",
      operator: "",
      value: "",
    },
  ]);

  const methods = useForm({
    defaultValues: { values },
    mode: "onTouched",
  });
  const { control, handleSubmit, reset, /*setValue,*/ getValues, trigger } =
    methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "values",
    mode: "onTouched",
  });

  const [fieldSelected, setFieldSelected] = useState([{}]);

  const onSubmit = (formData) => {
    let filters = {
      isActive: false,
      match: formData.filterRule,
      rules: [],
    };

    const prepareValuesForFilter = (filterValues) => {
      const [fieldKey, fieldType] = filterValues.field.split(",");
      const operator = filterValues.operator;
      const value = filterValues.value;

      let fieldsForFilter = {
        field: fieldKey,
        operator,
        value,
      };

      switch (fieldType) {
        case "equation":
          if (fieldKey.includes(".date_formatted")) {
            fieldsForFilter.field = fieldKey.split(".")[0];
            fieldsForFilter.value = value;
            break;
          }
          break;
        case "date_time":
          fieldsForFilter.field = fieldKey.split(".")[0];
          fieldsForFilter.value = moment(value).format("DD/MM/YYYY");
          break;
        case "connection":
          fieldsForFilter.value = Array.isArray(value)
            ? value[0]?.id
            : value?.id;
          break;
        default:
      }
      return fieldsForFilter;
    };

    formData.values.forEach((value) =>
      filters.rules.push(prepareValuesForFilter(value))
    );
    filters.isActive = true;
    props.setFilters(filters);
    props.isFiltersOpen(false);
    reset({
      values: [
        {
          field: "",
          operator: "",
          value: "",
        },
      ],
    });
  };

  gridConfig.forEach((field) => {
    field.options = [];
    typeOfFilters.forEach((filter) => {
      if (filter.type === field.type) field.options = filter.options;
    });
  });

  const handleChange = (event, filterIndex) => {
    const valuesOption = event.target.value.split(","); // I need to create an array from the option value because it passes two values, type and the actuall value
    const [fieldKey, fieldType, rawIndex] = valuesOption;
    const index = parseInt(rawIndex);
    let options = [];
    let fieldObject = {};

    if (fieldType)
      gridConfig.forEach((field) => {
        if (field.key === fieldKey) {
          options = field.options;
          fieldObject = field;
        }
      });

    const name = `values[${index}].operator`;
    const newFields = !!fieldSelected[filterIndex]
      ? fieldSelected.map((element, elementIndex) =>
          elementIndex === filterIndex
            ? { name, options, fieldObject, valuesOption }
            : element
        )
      : [{ name, options, fieldObject, valuesOption }];
    setFieldSelected(newFields);
    update(filterIndex, { field: fieldKey, operator: options[0], value: "" });
    trigger();
  };

  const closeFilters = () => props.isFiltersOpen(false);

  // const displayInputOfType = (selectedOperator) => selectedOperator !== 'is blank' && selectedOperator !== 'is not blank'

  const getFilters = () => {
    const clonedFilters = cloneDeep(gridConfig);
    return clonedFilters.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <Dialog open={isFilterPopUpOpen} maxWidth="lg">
      <DialogTitle component="h5">Selecione os filtros</DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <h2>Regra</h2>
                    <select name="filterRule" ref={register()}>
                        <option value="and">And</option>
                        <option value="or">Or</option>
                    </select> */}
            <TableContainer>
              <Table style={{ marginTop: 10, marginBottom: 10 }}>
                <TableBody>
                  <ErrorBoundary>
                    {fields.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Controller
                            control={control}
                            defaultValue={item.field || ""}
                            name={`values[${index}].field`}
                            rules={{
                              required: {
                                value: true,
                                message: requiredErrorMessage,
                              },
                            }}
                            render={({
                              field: { onChange, ...rest },
                              fieldState: { error },
                            }) => {
                              return (
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  required={true}
                                  error={!!error}
                                >
                                  <InputLabel>Selecione um campo</InputLabel>
                                  <Select
                                    {...rest}
                                    error={!!error}
                                    label={"Selecione um campo"}
                                    defaultValue={
                                      !!rest.value ? rest.value : ""
                                    }
                                    value={!!rest.value ? rest.value : ""}
                                    onChange={(event) => {
                                      handleChange(event, index);
                                      onChange(event.target.value);
                                    }}
                                    style={{ marginRight: 10, minWidth: 250 }}
                                  >
                                    {getFilters().map(
                                      (field, index) => (
                                        <MenuItem
                                          key={index}
                                          value={[
                                            field.key,
                                            field.type,
                                            index,
                                          ].join()}
                                        >
                                          {field.name}
                                        </MenuItem>
                                      ) // This info needs to be joined because autocomplete requires a string to be passed as value
                                    )}
                                  </Select>
                                  {error && (
                                    <FormHelperText error>
                                      {error?.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl variant="outlined" size="small">
                            <InputLabel>Selecione um operador</InputLabel>
                            <Controller
                              name={`values[${index}].operator`}
                              control={control}
                              defaultValue={
                                item.operator ||
                                (!!fieldSelected[index]?.options?.length
                                  ? optionsTranslation(
                                      fieldSelected[index]?.options[0]
                                    )
                                  : "")
                              }
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    disabled={
                                      !!fieldSelected[index] ? false : true
                                    }
                                    value={
                                      !!field.value
                                        ? field.value
                                        : !!fieldSelected[index]?.options
                                            ?.length
                                        ? fieldSelected[index]?.options[0]
                                        : ""
                                    }
                                    label={"Selecione um operador"}
                                    style={{ minWidth: 250, marginRight: 10 }}
                                  >
                                    {fieldSelected[index]?.options?.map(
                                      (option, index) => (
                                        <MenuItem key={index} value={option}>
                                          {optionsTranslation(option)}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                );
                              }}
                            />
                          </FormControl>
                        </TableCell>
                        <InputOfType
                          key={item.id}
                          fieldSelected={fieldSelected}
                          object={object}
                          index={index}
                          item={item}
                          control={control}
                          getValues={getValues}
                        />
                        <TableCell>
                          <TalentoButton
                            type="button"
                            onClick={() => {
                              remove(index);
                              const newFieldSelected = fieldSelected?.filter(
                                (_, elIndex) => elIndex !== index
                              );
                              setFieldSelected(newFieldSelected);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                          >
                            Remover
                          </TalentoButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ErrorBoundary>
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ marginLeft: 16 }}>
              <TalentoButton
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 20 }}
                onClick={() => {
                  append({});
                  setFieldSelected([
                    ...fieldSelected,
                    {
                      fieldObject: {},
                      name: `values[${fieldSelected.length}].operator`,
                      options: [],
                      valuesOption: [],
                    },
                  ]);
                }}
              >
                Adicionar Filtro
              </TalentoButton>
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <TalentoButton
                value="submit"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: "auto" }}
                onClick={() => closeFilters()}
              >
                Cancelar
              </TalentoButton>
              <TalentoButton
                type="submit"
                value="submit"
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginLeft: 20 }}
              >
                Filtrar
              </TalentoButton>
            </div>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  isFilterPopUpOpen: state.filterPopUp,
});

export default connect(mapStateToProps, { setFilters, isFiltersOpen })(
  GridFilter
);
