import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  paper_header: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
  },
  header_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paper_grid: {
    width: "100%",
    height: "72vh",
  },
  quickSearch: {
    minWidth: 250,
  },
  select: {
    marginLeft: theme.spacing(2),
    minWidth: 250,
  },
  emptyGridText: {
    marginTop: "2rem",
    fontWeight: 200,
  },
  emptyBody: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "1rem",
    height: "57vh",
  },
  backGroundPaper: {
    display: "flex",
    width: "100%",
    margin: theme.spacing(1),
    minHeight: "74vh",
    padding: theme.spacing(2),
  },
  paperButtons: {
    display: "flex",
    width: "100%",
    margin: theme.spacing(1),
    marginBottom: 0,
    padding: theme.spacing(2),
  },
  editButton: {
    marginLeft: "auto",
  }
}));

export default useStyles;
