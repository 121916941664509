import React from "react";
import EditForm from "../layout/forms/EditForm";
import { FormacaoModularTurma } from "../../utils/knackKeys";

// SHARED CONSTANTS
const object = FormacaoModularTurma.object;
const objectLabel = "Formação Modular";
const listUrl = "/turmas";

const FormEditFormacaoModularTurma = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      parentId={props.match.params.classId}
      scene={FormacaoModularTurma.edit.scene}
      view={FormacaoModularTurma.edit.view}
      userPermissions={{
        userPermissionsField: "",
        viewRolesPermissions: [],
      }}
    />
  );
};

export { FormEditFormacaoModularTurma };
