export const CREATE_SESSION_CLASSES = 'CREATE_SESSION_CLASSES'
export const EXPORT_AND_DELETE_SESSION_CLASSES = 'EXPORT_AND_DELETE_SESSION_CLASSES'
export const MARK_STUDENT_PRESENCES = 'MARK_STUDENT_PRESENCES'
export const REMOVE_STUDENT_PRESENCES = 'REMOVE_STUDENT_PRESENCES'
export const CLEAR_TABLE_STATE = 'CLEAR_TABLE_STATE'
export const OPEN_EVALUATIONS_CREATION_POPUP = 'OPEN_EVALUATIONS_CREATION_POPUP';
export const PUSH_CLASSES_ONE_WEEK_FORWARD = 'PUSH_CLASSES_ONE_WEEK_FORWARD';
export const ASSOCIATE_CAREER_MANAGERS = 'ASSOCIATE_CAREER_MANAGERS';
export const OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP = 'OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP';
export const OPEN_SEND_SMS_POP_UP = 'OPEN_SEND_SMS_POP_UP';
export const SEND_MASS_SMS = 'SEND_MASS_SMS';
export const OPEN_SEND_EMAIL_POP_UP = 'OPEN_SEND_EMAIL_POP_UP';
export const SEND_MASS_EMAIL = 'SEND_MASS_EMAIL';
export const PROCESSING_AUTOMATION = 'PROCESSING_AUTOMATION';
export const CHANGE_STUDENTS_CONTRACT_STATUS = 'CHANGE_STUDENTS_CONTRACT_STATUS';
export const CREATE_PRESENCES_MANUALLY = 'CREATE_PRESENCES_MANUALLY';
export const DOWNLOAD_QUALIFICA_DOCUMENTS = 'DOWNLOAD_QUALIFICA_DOCUMENTS';