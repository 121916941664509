import React from "react";
import UnderMaintenanceIcon from "./UnderMaintenanceIcon";
import { useStyles } from "./styles";
import Logo from '../../../assets/images/logo_centrostalento_no_subtitle.png'


const UnderMaintenance = () => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <UnderMaintenanceIcon className={classes.image} />
      <div className={classes.title}>O puzzle encontra-se em manutenção.</div>
      <div className={classes.subtitle}>Voltamos já!</div>
      <img className={classes.logo} alt="logo" src={Logo}/>
    </div>
  );
};

export default UnderMaintenance;
