import React from "react";
import DetailView from "../layout/Detail/DetailView";
import AddForm from "../layout/forms/AddForm";
import EditForm from "../layout/forms/EditForm";
import ListView from "../layout/ListView";
import { connect } from "react-redux";
import BusinessIcon from "@material-ui/icons/Business";
import {
  createInternshipContractPartner,
  createIntershipCurricularDeclaration,
  createInternshipBadge,
} from "../../redux/actions/documents/internships";

// SHARED CONSTANTS

const object = "object_18";
const objectLabel = "Estagio";
const objectLabelSingular = "Estagio";
const objectLabelPlural = "Estagios";
const listUrl = "/estagios";
const icon = (
  <BusinessIcon
    fontSize="large"
    style={{ marginRight: 16, color: "#148FF6" }}
  />
);
const userPermissions = {
  userPermissionsField: "field_434",
  viewRolesPermissions: [],
};

let DetailInternship = (props) => {
  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      icon={icon}
      id={props.match.params.id}
      scene={"scene_113"}
      view={"view_153"}
      sceneEdit={"scene_123"}
      viewEdit={"view_168"}
      deletable={true}
      userPermissions={{
        userPermissionsField: "field_434",
        viewRolesPermissions: [],
        documentsRolesPermissions: [],
      }}
      tabs={[]}
      documents={[
        {
          type: "internship_contract",
          title: "Criar Contrato Estágio",
          fileName: "Contrato_Estagio.pdf",
          documentFunction: props.createInternshipContractPartner,
        },
        {
          type: "internship_declaration",
          title: "Criar Declaração de estágio",
          fileName: "declaracao_curricular.pdf",
          documentFunction: props.createIntershipCurricularDeclaration,
        },
        {
          type: "internship_badge",
          title: "Criar crachá de estágio",
          fileName: "cracha_estagio.pdf",
          documentFunction: props.createInternshipBadge,
        },
      ]}
    />
  );
};

const mapDispatchToProps = {
  createInternshipContractPartner,
  createIntershipCurricularDeclaration,
  createInternshipBadge,
};
DetailInternship = connect(null, mapDispatchToProps)(DetailInternship);

const FormAddInternship = (props) => {
  return (
    <AddForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      scene={"scene_114"}
      view={"view_155"}
      userPermissions={userPermissions}
    />
  );
};

const FormEditInternship = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_123"}
      view={"view_168"}
      userPermissions={userPermissions}
    />
  );
};

const ListInternships = (props) => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={"scene_111"}
      view={"view_152"}
      sceneEdit={"scene_123"}
      viewEdit={"view_168"}
      icon={icon}
      pinned={["Nome do Aluno", "Abrir"]}
      userPermissions={userPermissions}
      extraConfigs={{
        actionsMenu: [
          {
            name: "Associar gestores de carreira",
            customActionId: "associateCareerManagers",
          },
          {
            name: "Enviar SMS em Massa",
            customActionId: "sendMassSMS",
          },
          {
            name: "Enviar Email em Massa",
            customActionId: "sendMassEmail",
          },
        ],
      }}
    />
  );
};

export {
  DetailInternship,
  FormAddInternship,
  FormEditInternship,
  ListInternships,
};
