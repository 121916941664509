import React from 'react';
import DetailView from '../../layout/Detail/DetailView'
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_36"
const objectLabel = "Modulo"
const objectLabelSingular = "Modulo"
const objectLabelPlural = "Modulos"
const identifierNameField = 'field_381';
const listUrl = "/modulos"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
}

const DetailModule = (props) => {
    return (

        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            scene={"scene_236"}
            view={"view_323"}
            sceneEdit={"scene_235"}
            viewEdit={"view_322"}
            userPermissions={userPermissions.userPermissionsField}
            tabs={[
                {
                    title: 'Sessões',
                    position: 1,
                    params: {
                        object: "object_37",
                        objectLabelPlural: "Sessoes",
                        objectLabelSingular: "Sessoes",
                        slug:'sessoes',
                        scene: "scene_238",
                        view: "view_328",
                        userPermissions: userPermissions,
                        extraConfigs: {
                            showCreateButton: 'yes',
                        },
                        filterByConnectedField: {
                            connected_field: "field_388",
                            id: props.match.params.id,
                            identifierNameField: identifierNameField

                        }
                    }
                }
            ]}
        />
    )
}


const FormAddModule = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_237"}
            view={"view_326"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditModule = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_235"}
            view={"view_322"}
            userPermissions={userPermissions}
        />
    )

}

const ListModules = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_233"}
            view={"view_321"}
            userPermissions={userPermissions}
            extraConfigs={{}}

        />
    )
}

export {
    DetailModule,
    FormAddModule,
    FormEditModule,
    ListModules
}