import React from 'react'
import { AgGridColumn } from "ag-grid-react";
import { renderBooleanField, renderFullName, renderRichText, renderMultipleChoice, renderFullAddress } from '../../utils/Grid/renderFields';
import { userCanSeeField } from '../../utils/userPermissions';

export const GridColumns = (gridConfig, profile_keys, baseUrl, pinned = [], openDifferentDetail = undefined) => {
    return (
        gridConfig?.map(column => {
            const differentWidthFields = (column.key === 'field_48' || column.key === 'field_754') ? { width: 160, minWidth: 150 } : column.key === 'field_408' ? { width: 140, minWidth: 110 } : ((column.type === "id" || column.type === "field_976") && pinned.includes(column.name)) ? { width: 100, minWidth: 100 } : {};
            
            switch (column.type) {
                case "name":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer={renderFullName} minWidth={300} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} checkboxSelection={column.checkBox} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                case "address":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer={renderFullAddress} minWidth={700} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                case "boolean":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer={renderBooleanField} minWidth={100} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} pinned={pinned.includes(column.name) && "left"} {...differentWidthFields}></AgGridColumn>)
                case "rich_text":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer={renderRichText} minWidth={400} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                case "file":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer='uploadFileComponent' cellRendererParams={{ fieldConfig: column }} minWidth={200} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                case "id":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer='newTabComponent' cellRendererParams={{ baseUrl: !!openDifferentDetail ? openDifferentDetail.customUrl : baseUrl, openDifferentDetail }} minWidth={100} {...differentWidthFields} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                case "number":
                case "currency":
                case "sum":
                case "average":
                case "equation":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} minWidth={200} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} checkboxSelection={column.checkBox} pinned={pinned.includes(column.name) && "left"} {...differentWidthFields}></AgGridColumn>)
                case "date_time":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} minWidth={200} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                case "multiple_choice":
                case "connection":
                    return (<AgGridColumn key={column.key} field={column.key} headerName={column.name} cellRenderer={renderMultipleChoice} cellRendererParams={{ fieldType: column.type }} minWidth={200} flex={1} resizable={true} hide={userCanSeeField(column.canView, profile_keys, 'grid')} suppressMovable={true} checkboxSelection={column.checkBox} pinned={pinned.includes(column.name) && "left"}></AgGridColumn>)
                default:
                    return (
                        <AgGridColumn
                            key={column.key}
                            flex={1}
                            minWidth={column.key === 'field_68' ? 350 : 200}
                            {...differentWidthFields}
                            field={column.key}
                            headerName={column.name}
                            pinned={pinned.includes(column.name) && "left"}
                            checkboxSelection={column.checkBox}
                            headerCheckboxSelectionFilteredOnly={true}
                            resizable={true}
                            hide={userCanSeeField(column.canView, profile_keys, 'grid')}
                            suppressMovable={true}
                        />
                    )
            }
        })
    )
}


