import {CREATE_RECORD, EDIT_RECORD } from '../constants/dataApiCalls'
import {CLEAR_STATE} from '../constants/layout';


export default function formSubmissionReducer(state = [], action) {
    switch (action.type) {
        case CREATE_RECORD:
            return action.payload
        case EDIT_RECORD:
            return action.payload
        case CLEAR_STATE:
            return []
        default:
            return state;
    }
}