import React from 'react';
import AddForm from '../layout/forms/AddForm';
import EditForm from '../layout/forms/EditForm';
import ListView from '../layout/ListView'


// SHARED CONSTANTS

const object = "object_41"
const objectLabel = "Avaliacao"
const objectLabelSingular = "Avaliacao"
const objectLabelPlural = "Avaliacoes"
const listUrl = "/avaliacoes"
const userPermissionsField = 'field_455'



const FormAddEvaluation = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_280"}
            view={"view_379"}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: []
            }}
        />
    )

}

const FormEditEvaluation = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_279"}
            view={"view_377"}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: [
                    'profile_5'
                ]
            }}
        />
    )

}

const ListEvaluations = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_277"}
            view={"view_376"}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: [
                    'profile_5'
                ]
            }}
            extraConfigs={{}}

        />
    )
}

export {
    FormAddEvaluation,
    FormEditEvaluation,
    ListEvaluations
}