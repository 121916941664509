import { invalidTokenResponse } from "../../../../utils/errors";
import { LOG_OUT } from "../../../constants/auth";
import { store } from "../../../storeConfig";
import notify from "../../helper";
import { WARNING } from "../../notifications";

const dispatch = store.dispatch;
const invalidTokenMessage =
  "Autenticação expirada, por favor faça login novamente";

const checkInvalidToken = ({ error }) => {
  if (error?.response?.status !== 403) return false;
  if (
    ![error?.response?.data, error?.response?.data?.data].includes(
      invalidTokenResponse
    )
  )
    return false;

  dispatch({ type: LOG_OUT });
  notify({ dispatch, type: WARNING, message: invalidTokenMessage });
  return true;
};

export default checkInvalidToken;
