import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { requiredErrorMessage } from "../../../utils/errors";
import { FormHelperText } from "@material-ui/core";

const useStyle = (fromSearch) =>
  makeStyles((theme) => ({
    select: {
      width: fromSearch ? "300px" : "100%",
    },
  }))();

const SelectBoxField = (props) => {
  const {
    field: fieldProps,
    recordValues,
    control,
    disabled,
    filterName = undefined,
    fromSearch,
  } = props;
  const classes = useStyle(fromSearch);

  const Wrapper = ({ children }) =>
    fromSearch ? children : <TableRow>{children}</TableRow>;
  const required = !!filterName ? false : fieldProps.required;

  return (
    <Wrapper>
      <TableCell>
        <Controller
          control={control}
          defaultValue={
            recordValues?.toString() || fieldProps.format?.default || ""
          }
          name={filterName || fieldProps.key}
          rules={{
            validate: (value) =>
              required ? value !== "" || requiredErrorMessage : true,
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              variant="outlined"
              className={classes.select}
              size="small"
              required={required}
              error={!!error}
            >
              <InputLabel
                error={!!error}
                component="span"
              >
                {fieldProps.name}
              </InputLabel>
              <Select
                {...field}
                label={fieldProps.name}
                required={required}
                disabled={disabled}
              >
                <MenuItem key={0} value=""></MenuItem>
                <MenuItem key={1} value={"true"}>
                  Sim
                </MenuItem>
                <MenuItem key={2} value={"false"}>
                  Não
                </MenuItem>
              </Select>
              {error && (
                <FormHelperText error={!!error}>{error.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </TableCell>
    </Wrapper>
  );
};

export default SelectBoxField;
