import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Typography } from "@material-ui/core";
import { doLogOut } from "../../redux/actions/auth";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import { setMenuOpen } from "../../redux/actions/menu";
import TalentoButton from "./TalentoButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    width: 150,
    marginLeft: "10px",
  },
  logoMobile: {
    marginTop: "5px",
    width: 150,
  },
  logOut: {
    display: "flex",
    marginLeft: "auto",
  },
  logOutIccon: {
    marginLeft: "10px",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (
    props.location.pathname === "/login" ||
    props.location.pathname === "/" ||
    !props.auth.isLoggedIn
  ) {
    return null;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        {mobile ? (
          <Toolbar>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid item xs>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => props.setMenuOpen(true)}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <img
                  src="https://talento-documentos-gerais-publicos.s3.eu-west-1.amazonaws.com/logos-and-favicon/logo_white.png"
                  alt="logo"
                  className={classes.logoMobile}
                />
              </Grid>
              <Grid item xs>
                <TalentoButton
                  color="inherit"
                  className={classes.logOut}
                  onClick={props.doLogOut}
                >
                  <ExitToAppIcon className={classes.logOutIccon} />
                </TalentoButton>
              </Grid>
            </Grid>
          </Toolbar>
        ) : (
          <Toolbar>
            <img
              src="https://talento-documentos-gerais-publicos.s3.eu-west-1.amazonaws.com/logos-and-favicon/logo_white.png"
              alt="logo"
              className={classes.logo}
            />
            <TalentoButton
              color="inherit"
              className={classes.logOut}
              onClick={props.doLogOut}
            >
              <Typography fontWeight="200">Sair</Typography>
              <ExitToAppIcon fontSize="small" className={classes.logOutIccon} />
            </TalentoButton>
          </Toolbar>
        )}
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { doLogOut, setMenuOpen })(Header);
