import { Avatar } from "@mui/material";
import TalentoButton from "../../TalentoButton";

const Image = ({ field, fieldConfig, fileUrl, isLoading, fileType, openFile }) => {
    if (!fileUrl || fileType !== "image") return null;
    return (
        <Avatar
            slotProps={{ img: { loading: "lazy", id: field } }}
            onClick={openFile}
            src={fileUrl}
        />
    );
};

const File = ({ fileUrl, openFile, fileType }) => {
    if (fileType !== 'file') return null;
    if (!fileUrl) return (
        <TalentoButton disabled={true} variant="contained" size="small">
            Sem Fic.
        </TalentoButton>
    );
    return (
        <TalentoButton
            onClick={openFile}
            color="primary"
            variant="contained"
            size="small"
        >
            Ver Dc.
        </TalentoButton>
    );
};

export { File, Image }