import { createNotification } from "./notifications";
import { CREATE_CONTRACT_FLOW } from "../constants/createRecordCustomFlows";
import { createContractFlowWebhook } from "../../utils/webhooks";
import callWebhook from "./api/callWebhook";
import { Contrato } from "../../utils/knackKeys";

export const createRecordCustomFlows = (data) => async (dispatch) => {
  switch (data.object) {
    case Contrato.object:
      const result = await createContractFlow(data, dispatch);
      dispatch({
        type: CREATE_CONTRACT_FLOW,
        payload: result,
      });
      break;
    default:
      return null;
  }
};

const createContractFlow = async (data, dispatch) => {
  if (data.recordId) {
    dispatch(createNotification()).warning({
      message:
        "Contrato a ser criado, pode utilizar o Puzzle à vontade, será notificado assim que o processo terminar...",
    });

    const body = {
      id: data.recordId,
      object: data.object,
      profileId: data.profileId,
      profile_keys: data.profile_keys,
      userId: data.userId,
    };
    await callWebhook({ webhook: createContractFlowWebhook, body });
    return data;
  }
};
