import { NOTIFICATION } from "../constants/notifications";

const initialState = {
  isOpen: false,
  type: "",
  message: "",
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        type: action.payload.type,
        message: action.payload.message,
        options: action.payload.options,
      };
    default:
      return state;
  }
}
