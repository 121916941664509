import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ListViewTab from "../../ListViewTab";
import { connect } from "react-redux";
import GroupsView from "../../GroupsView/GroupsView";
import useStyles from "../../ListViewTab/styles";
import { EmptyGrid } from "../../ListViewTab/RenderGrid";
import { userHasPermissionsToEdit } from "../../../../utils/userPermissions";
import { dbApiCall } from "../../../../redux/actions/api";
import { GET_ONE, METHODS } from "../../../../redux/actions/knack/types";
import RenderEditButton from "./RenderEditButton";

const RenderTab = ({
  object,
  scene,
  view,
  sceneEdit,
  viewEdit,
  userPermissions,
  uploadToFolderField,
  // COMING FROM REDUX
  tabs,
  auth,
  tab,
}) => {
  const classes = useStyles();
  const { pathname, search } = useLocation();
  const { editRolesPermissions } = userPermissions;
  const { profile_keys } = auth;
  const [, , id] = pathname.split("/");
  const [tabsWithIds, setTabsWithIds] = useState(tabs);
  const [disableEditButton, setDisableEditButton] = useState(false);

  const getNewTabs = async () => {
    return await Promise.all(
      tabs?.map(async (el) => {
        if (!el.params?.isDetail) return el;
        const currentRecord = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_ONE,
          scene,
          view,
          id,
          auth,
        });
        const parentsConnectedField =
          currentRecord.data[
            el.params.filterByConnectedField.parentsConnectedField
          ];
        if (currentRecord.data.id && parentsConnectedField) {
          const elId = parentsConnectedField?.[0]?.id;
          return { ...el, id: elId };
        }
        return el;
      })
    );
  };

  const currentTab = search.split("?active_tab=")?.[1];

  useEffect(() => {
    setDisableEditButton(
      userHasPermissionsToEdit(editRolesPermissions, profile_keys)
    );
    (async () => {
      const newTabs = await getNewTabs();
      setTabsWithIds(newTabs);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, scene, tabs, view]);

  const isFirstTab = tab === 0 && !currentTab;

  return (
    <Grid item container spacing={2} className={classes.cards}>
      {isFirstTab ? (
        <GroupsView
          object={object}
          scene={scene}
          view={view}
          sceneEdit={sceneEdit}
          viewEdit={viewEdit}
          id={id}
          userPermissions={userPermissions}
          uploadToFolderField={uploadToFolderField}
        />
      ) : (
        tabsWithIds?.map(({ position, params, pinned, id: tabId }) => {
          if (tab !== position) return null;
          if (params?.isDetail) {
            if (tabId)
              return (
                <React.Fragment key={position}>
                  <RenderEditButton
                    classes={classes}
                    disableEditButton={disableEditButton}
                    params={params}
                    id={tabId}
                    parentId={id}
                  />
                  <GroupsView
                    object={params.object}
                    scene={params.scene}
                    view={params.view}
                    sceneEdit={params.sceneEdit}
                    viewEdit={params.viewEdit}
                    id={tabId}
                    userPermissions={params.userPermissions}
                    uploadToFolderField={params.uploadToFolderField}
                    secondary={true}
                  />
                </React.Fragment>
              );
            else
              return (
                <div key={position} className={classes.emptyBody}>
                  <EmptyGrid classes={classes} />
                </div>
              );
          } else
            return (
              <ListViewTab
                key={position}
                objectParams={params}
                pinned={pinned}
              />
            );
        })
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  tab: state.layout.tab,
  tabs: state.layout.tabs,
});

export default connect(mapStateToProps)(RenderTab);
