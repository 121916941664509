import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { CssBaseline, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchRecords,
  cleanRecords,
} from "../../redux/actions/api/dataApiCalls";
import { fetchGridConfig } from "../../redux/actions/knackconfig";
import { createNotification } from "../../redux/actions/notifications";
import { clearErrors } from "../../redux/actions/errors";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { editRecord } from "../../redux/actions/api/dataApiCalls";
import UploadFile from "./forms/UploadFile";
import ActionsMenu from "./ActionsMenu";
import { setCustomPopUpOpen } from "../../redux/actions/popUps";
import * as CustomPopUps from "./customComponents/index";
import { GridColumns } from "./GridColumns";
import GridFilter from "./filterComponent/GridFilter";
import { isFiltersOpen, setFilters } from "../../redux/actions/filters";
import SearchFilter from "./filterComponent/SearchFilter";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  userCanSeeActionsSection,
  userCanSeeFilters,
  userCanExport,
  userCanSeeCreateButton,
} from "../../utils/userPermissions";
import { renderOpenInNewTab } from "../../utils/Grid/renderFields";
import { exportDataFromGrid } from "../../utils/Grid/export";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TalentoButton from "./TalentoButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  paper_header: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    margin: 4,
    width: "100%",
  },
  header_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  list: {
    fontStyle: "italic",
    fontSize: "18px",
    marginRight: "0.5rem",
  },
  paper_grid: {
    width: "100%",
    height: "82vh",
    marginLeft: theme.spacing(0.5),
    marginRigth: theme.spacing(0.5),
  },

  select: {
    marginLeft: theme.spacing(2),
    minWidth: 150,
  },
}));

const ListRecords = (props) => {
  const {
    object,
    objectLabelPlural,
    objectLabelSingular,
    scene,
    view,
    sort,
    extraConfigs,
    auth,
    icon,
    sceneEdit,
    viewEdit,
    isCustomPopUpOpen,
    customActions,
    filters,
    pinned,
  } = props;
  const { userId, profile_keys } = props.auth;
  const { userPermissionsField } = props.userPermissions;
  const classes = useStyles();
  const [gridApi, setGridApi] = useState(null);
  const [gridConfig, setGridConfig] = useState([]);
  const [currentPath, setCurrentPath] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  const onGridReady = async (params) => {
    const getGridConfig = await props.fetchGridConfig(object);
    setGridConfig(getGridConfig);
    setGridApi(params.api);
  };

  history.listen((a, b) => {
    resetFilters();
  });

  const updateDataSource = () => {
    let currentPage = 0;

    const dataSource = {
      getRows: async (params) => {
        currentPage = params.endRow / 30;

        let sortModel = params.sortModel[0];
        let paramsForSort = prepareSortModel(sortModel);

        const data = await props.fetchRecords({
          scene,
          view,
          auth,
          userPermissionsField,
          userId,
          profile_keys,
          page: currentPage,
          paramsForSort,
          tableFilters: filters,
        });

        if (data === "error") return params.failCallback();
        let totalRows = data.totalRecords;
        params.successCallback(data.records, totalRows);
      },
    };

    const prepareSortModel = (sortModel) => {
      let paramsForSort = "";
      if (!!sort) {
        let sortColumn = sort.field;
        if (sort.field.includes("date_formatted")) {
          sortColumn = sort.field.replace(".date_formatted", "");
        }
        paramsForSort = `&sort_field=${sortColumn}&sort_order=${sort.order}`;
      }
      if (sortModel) {
        let sortColumn = sortModel.colId;
        if (sortModel.colId.includes("date_formatted")) {
          sortColumn = sortModel.colId.replace(".date_formatted", "");
        }
        paramsForSort = `&sort_field=${sortColumn}&sort_order=${sortModel.sort}`;
      }
      return paramsForSort;
    };

    return dataSource;
  };

  useEffect(() => {
    if (gridApi && filters) {
      gridApi.setDatasource(updateDataSource());
    }

    return () => {
      props.cleanRecords();
      props.clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  let location = useLocation();

  if (!currentPath) setCurrentPath(location.pathname);
  else if (currentPath !== location.pathname)
    props.setFilters({ isActive: false, rules: [] });

  const baseUrl = objectLabelPlural.toLowerCase();
  const openDetail = (row) => {
    if (!row) return;
    if (
      ["uploadFileComponent", "newTabComponent"].includes(
        row.colDef.cellRenderer
      )
    )
      return null;
    else if (extraConfigs.hasDetail !== "no") {
      history.push(`/${baseUrl}/${row.data.id}`);
    }
  };

  const renderUploadFileButton = (params) => {
    if (params.data) {
      if (params.data[params.colDef.field]) {
        return (
          <UploadFile
            url={
              typeof params.data[params.colDef.field] !== "undefined"
                ? params.data[params.colDef.field]
                : ""
            }
            field={params.colDef.field}
            fieldConfig={params.fieldConfig}
            scene={sceneEdit}
            view={viewEdit}
            object={object}
            recordId={params?.data?.id}
          />
        );
      }
    }

    return null;
  };

  let gridItems = useRef("");

  const quickFilterChanged = (e) => {
    const { value } = e.target;
    const filterKey = value.split(",")[1];
    const filterValue = value.split(",")[0];
    props.setFilters({
      isActive: true,
      rules: [
        {
          field: filterKey,
          operator: "is",
          value: filterValue,
        },
      ],
    });
  };

  // Render dynamicly popups acording to configurations
  const showCustomPopUp = (customActions, gridConfig, gridApi) => {
    if (!customActions) return;
    const actionType = customActions?.type;
    const componentName = customActions?.componentName;
    const webhook = customActions?.webhook;

    if (!actionType || !componentName) return;
    const Component = CustomPopUps[componentName];
    return (
      <Component
        gridConfig={gridConfig}
        gridApi={gridApi}
        isCustomPopUpOpen={isCustomPopUpOpen}
        webhook={webhook}
      />
    );
  };

  const openFilters = () => {
    props.isFiltersOpen(true);
  };

  const resetFilters = () => {
    props.setFilters({
      isActive: null,
      rules: [],
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar />
      <Grid container spacing={1} style={{ padding: 10 }}>
        {userCanSeeActionsSection(profile_keys) ? (
          <Paper elevation={3} className={classes.paper_header}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item className={classes.header_item}>
                {icon}
                <Typography className={classes.list}>
                  Lista de {objectLabelPlural}
                </Typography>
              </Grid>
              <Grid item className={classes.header_item}>
                {userCanSeeFilters(profile_keys) ? (
                  <TalentoButton
                    variant="contained"
                    color="primary"
                    onClick={() => openFilters()}
                  >
                    Filtros
                  </TalentoButton>
                ) : null}
                {extraConfigs.filters ? (
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.select}
                  >
                    <InputLabel>Filtros Rápidos</InputLabel>
                    <Select
                      label="Filtros Rápidos"
                      value={gridItems.current}
                      onChange={quickFilterChanged}
                    >
                      {extraConfigs.filters.dropDown.map((filter) => {
                        return (
                          <MenuItem key={filter.value} value={filter.value}>
                            {filter.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  ""
                )}
                <SearchFilter />
                {filters?.isActive ? (
                  <TalentoButton size="small" onClick={resetFilters}>
                    Limpar Filtros
                  </TalentoButton>
                ) : null}
              </Grid>
              <Grid item className={classes.header_item}>
                {extraConfigs.actionsMenu ? (
                  <ActionsMenu
                    actions={extraConfigs.actionsMenu}
                    object={object}
                    gridApi={gridApi}
                  ></ActionsMenu>
                ) : (
                  ""
                )}

                {showCustomPopUp(customActions, gridConfig, gridApi)}

                {userCanExport(profile_keys) ? (
                  <TalentoButton
                    onClick={() =>
                      exportDataFromGrid(
                        scene,
                        view,
                        auth,
                        userPermissionsField,
                        userId,
                        profile_keys,
                        filters,
                        props,
                        gridConfig
                      )
                    }
                  >
                    <CloudDownloadIcon
                      fontSize="large"
                      style={{ color: "lightgray" }}
                    />
                  </TalentoButton>
                ) : null}
              </Grid>
              <Grid
                item
                className={classes.header_item}
                style={{ marginLeft: !mobile ? "auto" : "" }}
              >
                {extraConfigs.showCreateButton !== "no" &&
                userCanSeeCreateButton(profile_keys) ? (
                  <>
                    <TalentoButton
                      component={Link}
                      to={`/${objectLabelPlural.toLowerCase()}/adicionar-${objectLabelSingular.toLowerCase()}`}
                      variant="contained"
                      color="secondary"
                    >
                      Adicionar {objectLabelSingular}
                    </TalentoButton>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Paper>
        ) : null}

        <Grid container>
          <Paper elevation={3} className={classes.paper_grid}>
            {gridConfig?.length > 0 ? (
              <GridFilter gridConfig={gridConfig} object={object} />
            ) : null}
            <div
              className="ag-theme-material"
              style={{ height: "100%", width: "100%" }}
            >
              {/* {checkForErrors(errors, props.records, isLoaded) === false ? */}
              <AgGridReact
                reactNext={true}
                onGridReady={onGridReady}
                onCellClicked={openDetail}
                datasource={updateDataSource()}
                immutableData // to work with redux better
                getRowNodeId={(data) => data.id}
                defaultColDef={{
                  sortable: true,
                  filter: false,
                }}
                rowSelection="multiple"
                frameworkComponents={{
                  uploadFileComponent: renderUploadFileButton,
                  newTabComponent: renderOpenInNewTab,
                }}
                rowModelType={"infinite"}
                cacheBlockSize={30}
                rowBuffer={0}
                paginationPageSize={30}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={1}
                maxBlocksInCache={30}
                infiniteInitialRowCount={30}
                pagination={true}
                paginationAutoPageSize={false}
                enableCellTextSelection={true}
              >
                {GridColumns(gridConfig, profile_keys, baseUrl, pinned)}
              </AgGridReact>
              {/* : <div style={{ marginTop: 30 }}>{checkForErrors(errors, props.records, isLoaded)}</div> */}
              {/* }  */}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    records: state.records,
    massUpdate: state.formSubmission,
    errors: state.errors,
    auth: state.auth,
    isCustomPopUpOpen: state.customPopUp,
    customActions: state.actions,
    filters: state.filters,
    tableConfig: state.gridConfig,
  };
};

export default connect(mapStateToProps, {
  fetchRecords,
  fetchGridConfig,
  editRecord,
  clearErrors,
  cleanRecords,
  setCustomPopUpOpen,
  isFiltersOpen,
  setFilters,
  // This is needed for the custom functions
  createNotification,
})(ListRecords);
