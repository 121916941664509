import moment from "moment";
import { getDynamicData, getPartnerData } from ".";
import { internshipContractPartner } from "../../../components/documents/internshipContractPartner";
import { intershipCurricularDeclaration } from "../../../components/documents/intershipCurricularDeclaration";
import { Contrato, Curso, Estagio } from "../../../utils/knackKeys";
import { dbApiCall } from "../api";
import { GET_ONE, METHODS } from "../knack/types";
import { internshipBadge } from "../../../components/documents/internshipBadge";
import {
  INTERNSHIP_BADGE,
  INTERNSHIP_CONTRACT_PARTNER,
  INTERNSHIP_CURRICULAR_DECLARATION,
} from "./constants";
import notify from "../helper";
import { ERROR } from "../notifications";

moment.locale("pt");

const {
  fields: { CURSO },
} = Contrato;

const {
  fields: { PARCEIRO_EMPRESA, CONTRATO, NOME_ALUNO },
} = Estagio;

const getDataForInternshipDocuments = async (id) => {
  const fetchedInternship = await dbApiCall({
    action: METHODS.GET.value,
    type: GET_ONE,
    scene: "scene_113",
    view: "view_153",
    id,
  });

  const internship = fetchedInternship.data;
  const requiredFieldsExist =
    internship[PARCEIRO_EMPRESA]?.length && internship[CONTRATO]?.length;

  if (!requiredFieldsExist)
    throw new Error("Preencher o campo Contrato e Parceiro/Empresa");

  const partnerId = internship[PARCEIRO_EMPRESA]?.[0]?.id || null;
  const contractId = internship[CONTRATO]?.[0]?.id || null;
  const studentId = internship[NOME_ALUNO]?.[0]?.id || null;

  const partner = await getPartnerData({ partnerId });

  const contract = await dbApiCall({
    action: METHODS.GET.value,
    type: GET_ONE,
    scene: Contrato.details.scene,
    view: Contrato.details.view,
    id: contractId,
  });
  const student = await dbApiCall({
    action: METHODS.GET.value,
    type: GET_ONE,
    scene: "scene_29",
    view: "view_31",
    id: studentId,
  });

  const courseId = contract.data[CURSO][0].id;

  const course = await dbApiCall({
    action: METHODS.GET.value,
    type: GET_ONE,
    scene: Curso.details.scene,
    view: Curso.details.view,
    id: courseId,
  });

  return {
    internship,
    partner: partner,
    contract: contract.data,
    student: student.data,
    course: course.data,
  };
};

const createInternshipContractPartner = (id) => async (dispatch) => {
  try {
    const entityData = await getDataForInternshipDocuments(id);

    const contractPartnerInfo = getDynamicData({
      data: entityData,
      type: INTERNSHIP_CONTRACT_PARTNER,
    });

    const document = internshipContractPartner(contractPartnerInfo);

    dispatch({
      type: "CREATE_INTERNSHIP_CONTRACT_PARTNER",
      payload: document,
    });
  } catch (error) {
    notify({
      dispatch,
      type: ERROR,
      message: `Por favor verifique se tem o campo de parceiro e/ou contrato preenchido. Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

const createIntershipCurricularDeclaration = (id) => async (dispatch) => {
  try {
    const entityData = await getDataForInternshipDocuments(id);

    const internshipCurricularDeclarationInfo = getDynamicData({
      data: entityData,
      type: INTERNSHIP_CURRICULAR_DECLARATION,
    });

    const document = intershipCurricularDeclaration(
      internshipCurricularDeclarationInfo
    );

    dispatch({
      type: "CREATE_INTERNSHIP_CURRICULAR_DECLARATION",
      payload: document,
    });
  } catch (error) {
    notify({
      dispatch,
      type: ERROR,
      message: `Por favor verifique se tem o campo de parceiro e/ou contrato preenchido. Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

const createInternshipBadge = (id) => async (dispatch) => {
  try {
    const entitiesData = await getDataForInternshipDocuments(id);

    const internshipBadgeInfo = getDynamicData({
      data: entitiesData,
      type: INTERNSHIP_BADGE,
    });

    const document = internshipBadge(internshipBadgeInfo);

    dispatch({
      type: "CREATE_INTERNSHIP_BADGE",
      payload: document,
    });
  } catch (error) {
    notify({
      dispatch,
      type: ERROR,
      message: `Por favor verifique se tem o campo de parceiro e/ou contrato preenchido. Detalhes: ${
        error?.response?.data?.message || error?.response?.data || error.message
      }`,
    });
  }
};

export {
  createInternshipContractPartner,
  createIntershipCurricularDeclaration,
  createInternshipBadge,
};
