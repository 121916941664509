import {FILE_UPLOADED, FILE_UPLOADED_WITH_FORM} from '../constants/dataApiCalls';

export default function fileUploadReducer(state = null, action) {
    switch(action.type){
        case FILE_UPLOADED :
           return action.payload
        case FILE_UPLOADED_WITH_FORM:
            return action.payload
        default:
            return state;
    }
}