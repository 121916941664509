import { SET_GRID_FILTER, SET_GRID_SEARCH_FILTER } from '../constants/filters';


export default function filtersReducer(state = null, action) {
    switch (action.type) {
        case SET_GRID_FILTER:
            return action.payload
        case SET_GRID_SEARCH_FILTER:
            return action.payload
        default:
            return state;
    }
}