import React from 'react';
import DetailView from '../layout/Detail/DetailView'
import AddForm from '../layout/forms/AddForm';
import EditForm from '../layout/forms/EditForm';
import ListView from '../layout/ListView'
import CastForEducationIcon from "@material-ui/icons/CastForEducation";


// SHARED CONSTANTS

const object = "object_38"
const objectLabel = "Aula"
const objectLabelSingular = "Aula"
const objectLabelPlural = "Aulas"
const listUrl = "/aulas"
const icon = <CastForEducationIcon fontSize="large" style={{ marginRight: 16, color: "#148FF6" }} />
const userPermissionsField = 'field_435'

const DetailClassSession = (props) => {
    return (
        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            icon={icon}
            scene={"scene_246"}
            view={"view_336"}
            sceneEdit={"scene_245"}
            viewEdit={"view_335"}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: [
                    'profile_5'
                ],
                editRolesPermissions: [
                    'profile_5'
                ]
            }}
            tabs={[
                {
                    title: 'Presenças',
                    position: 1,
                    params: {
                        object: "object_39",
                        objectLabelPlural: "Presenças",
                        objectLabelSingular: "Presença",
                        slug:'presencas',
                        scene: "scene_248",
                        view: "view_341",
                        userPermissions: {
                            userPermissionsField: userPermissionsField,
                            viewRolesPermissions: [
                                'profile_5'
                            ],
                            tabRolesPermissions: [
                                'profile_5'
                            ],
                            clickTableRolesPermissions: [
                                'profile_5'
                            ]
                        },
                        extraConfigs: {
                            showCreateButton: 'no',
                            hasDetail: 'no',
                            actionsMenu: [
                                {
                                    name: "Marcar Presenças",
                                    customActionId: "markStudentPresence",
                                    actionRolesPermissions: [
                                        'profile_5'
                                    ]
                                },
                                {
                                    name: "Marcar Falta",
                                    customActionId: "removeStudentPresence",
                                    actionRolesPermissions: [
                                        'profile_5'
                                    ]
                                }
                            ]
                        },
                        filterByConnectedField: {
                            connected_field: "field_423",
                            id: props.match.params.id
                        }
                    }
                }
            ]}
        />
    )
}


const FormAddClassSession = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={"/aulas"}
            scene={"scene_247"}
            view={"view_339"}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: []
            }}
        />
    )

}

const FormEditClassSession = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_245"}
            view={"view_335"}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: [
                    'profile_5'
                ]
            }}
        />
    )

}

const ListClassSessions = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_243"}
            view={"view_334"}
            icon={icon}
            pinned={["Sessão Nº", "Sessões", "Abrir"]}
            userPermissions={{
                userPermissionsField: userPermissionsField,
                viewRolesPermissions: [
                    'profile_5'
                ]
            }}
            extraConfigs={{}}

        />
    )
}

export {
    DetailClassSession,
    FormAddClassSession,
    FormEditClassSession,
    ListClassSessions
}