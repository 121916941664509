import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedRoute = ({ component: Component, onlyAdmin, ...rest }) => {
  const isUserAdmin = rest.auth?.profile_keys?.includes("profile_27");
  const isLogged = rest.auth?.isLoggedIn;

  const canAccess = onlyAdmin ? isLogged && isUserAdmin : isLogged;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (canAccess) return <Component {...props} />;
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(ProtectedRoute);
