import moment from "moment";
import { getAuthCache } from "./knackconfig";

export const KnackHeadersViewBased = (databaseToken) => {
  const headers = {
    "X-Knack-Application-Id": process.env.REACT_APP_KNACK_APP_ID,
    Authorization: `Bearer ${databaseToken}`,
    "content-type": "application/json",
  };
  return headers;
}

export const KnackHeadersObjectBased = (databaseToken) => {
  const headers = {
    "X-Knack-Application-Id": process.env.REACT_APP_KNACK_APP_ID,
    "X-Knack-REST-API-KEY": process.env.REACT_APP_KNACK_API_KEY,
    Authorization: `Bearer ${databaseToken}`,
    "content-type": "application/json",
  };
  return headers;
}

// How to use knack API, types of request combined with Axios request:

/*
- Get Multiple records => getMultiple
- Get One record => getOne
- Create one record => createOne
*/

const checkIfIsAdmin = (profile_keys) => {
  let isAdmin = false;
  if (typeof profile_keys !== "undefined") {
    profile_keys.forEach((profile_key) => {
      if (profile_key === "profile_27") {
        isAdmin = true;
      }
    });
  }
  return isAdmin;
};

const filterAccordingToUserPermissions = (
  filters,
  isAdmin,
  userPermissionsField,
  userId
) => {
  if (!isAdmin) {
    filters.rules.push({
      field: userPermissionsField,
      operator: "is",
      value: userId,
    });
  }
};

const addTableFilters = (filters, tableFilters) => {
  if (tableFilters?.rules?.length > 0) {
    filters.rules.push(...tableFilters.rules);
  }
};

const addSearchFilter = (tableFilters) => {
  if (tableFilters && tableFilters.searchFilter) {
    return `&search=${tableFilters.searchFilter}`;
  }
  return "";
};

export const KnackUrl = (
  type,
  element,
  view,
  id,
  connected_field,
  userPermissionsField,
  userId,
  profile_keys,
  page = "",
  paramsForSort = "",
  tableFilters = "",
  itemsPerPage = 30,
  filterOperator = "is",
  filterByParent = undefined
) => {
  let filters = "";
  let encodedFilters = "";
  let searchFilter = "";
  let baseUrl = "";
  let isBaseUrlReady = false;
  let isAdmin = checkIfIsAdmin(profile_keys);

  switch (type) {
    case "getMultiple-ViewBased":
      filters = {
        match: "and",
        rules: [],
      };

      filterAccordingToUserPermissions(
        filters,
        isAdmin,
        userPermissionsField,
        userId
      );
      addTableFilters(filters, tableFilters);
      searchFilter = addSearchFilter(tableFilters);
      encodedFilters = encodeURIComponent(JSON.stringify(filters));
      baseUrl = `https://api.knack.com/v1/pages/${element}/views/${view}/records?format=raw&filters=${encodedFilters}&rows_per_page=${itemsPerPage}&page=${page}${paramsForSort}${searchFilter}`;
      isBaseUrlReady = true;

      break;

    case "getOne-ViewBased":
      baseUrl = `https://api.knack.com/v1/pages/${element}/views/${view}/records/${id}?format=raw`;
      isBaseUrlReady = true;
      break;
    case "createOne-ViewBased":
      baseUrl = `https://api.knack.com/v1/pages/${element}/views/${view}/records?format=raw`;
      isBaseUrlReady = true;
      break;
    case "getMultiple-ObjectBased":
      baseUrl = `https://api.knack.com/v1/objects/${element}/records?rows_per_page=500`;
      isBaseUrlReady = true;
      break;
    case "updateOne-ViewBased":
      baseUrl = `https://api.knack.com/v1/pages/${element}/views/${view}/records/${id}?format=raw`;
      isBaseUrlReady = true;
      break;
    case "getOne-ObjectBased":
      baseUrl = `https://api.knack.com/v1/objects/${element}/records/${id}?format=raw`;
      isBaseUrlReady = true;
      break;

    case "getMultiple-ViewBased-Filtered-Connected":
      const rules = [
        {
          field: connected_field,
          operator: filterOperator,
          value: id,
        },
      ];

      filterByParent &&
        rules.push({
          field: filterByParent.field,
          operator: "is",
          value: filterByParent.value,
        });

      filters = {
        match: "and",
        rules,
      };

      filterAccordingToUserPermissions(
        filters,
        isAdmin,
        userPermissionsField,
        userId
      );
      addTableFilters(filters, tableFilters);
      searchFilter = addSearchFilter(tableFilters);
      encodedFilters = encodeURIComponent(JSON.stringify(filters));
      baseUrl = `https://api.knack.com/v1/pages/${element}/views/${view}/records?format=raw&filters=${encodedFilters}&rows_per_page=${itemsPerPage}&page=${page}${paramsForSort}${searchFilter}`;
      isBaseUrlReady = true;
      break;
    default:
      return null;
  }
  if (isBaseUrlReady) {
    return baseUrl;
  }
}

const KnackSearchObjectBased = ({ object, fieldKey, valueToFilter }) => {
  let baseUrl = "";
  const filters = {
    match: "and",
    rules: [
      {
        field: fieldKey,
        operator: "contains",
        value: valueToFilter,
      },
    ],
  };
  let encodedFilters = encodeURIComponent(JSON.stringify(filters));
  baseUrl = `https://api.knack.com/v1/objects/${object}/records?format=raw&filters=${encodedFilters}&rows_per_page=500`;

  return baseUrl;
}

export { KnackSearchObjectBased as getSearchUrl }

export const SearchObjectBased = ({ object, fieldKey, value }) => (
  {
    method: 'get',
    url: KnackSearchObjectBased({ object, fieldKey, valueToFilter: value }),
    headers: KnackHeadersObjectBased(getAuthCache().databaseToken)
  }
)

const extraFilter = (field, operator, value) => {
  return {
    field: field,
    operator: operator,
    value: value,
  };
};

export const KnackConfigs = (
  type,
  element,
  filterValue,
  extraFilterValues = []
) => {
  let baseUrl = "";
  let filters = "";
  let encodedFilters = "";

  switch (type) {
    case "entityDefinitions-ObjectBased":
      baseUrl = `https://api.knack.com/v1/objects/${element}?format=raw/`;
      break;

    case "groupConfig-ObjectBased":
      filters = {
        match: "and",
        rules: [
          {
            field: "field_327",
            operator: "is",
            value: filterValue,
          },
        ],
      };

      encodedFilters = encodeURIComponent(JSON.stringify(filters));
      baseUrl = `https://api.knack.com/v1/objects/${element}/records?format=raw&filters=${encodedFilters}&rows_per_page=500`;
      break;

    case "fieldConfig-ObjectBased":
      filters = {
        match: "and",
        rules: [
          {
            field: "field_349",
            operator: "is",
            value: filterValue,
          },
          extraFilter(...extraFilterValues),
        ],
      };

      encodedFilters = encodeURIComponent(JSON.stringify(filters));
      baseUrl = `https://api.knack.com/v1/objects/${element}/records?format=raw&filters=${encodedFilters}&rows_per_page=500`;
      break;

    case "fieldsConnectedConfig-ObjectBased":
      filters = {
        match: "and",
        rules: [
          {
            field: "field_347",
            operator: "is",
            value: filterValue,
          },
        ],
      };

      encodedFilters = encodeURIComponent(JSON.stringify(filters));
      baseUrl = `https://api.knack.com/v1/objects/${element}/records?format=raw&filters=${encodedFilters}&rows_per_page=500`;
      break;

    case "gridFieldsConfig-ObjectBased":
      filters = {
        match: "and",
        rules: [
          {
            field: "field_349",
            operator: "is",
            value: filterValue,
          },
          {
            field: "field_348",
            operator: "is",
            value: true,
          },
        ],
      };

      encodedFilters = encodeURIComponent(JSON.stringify(filters));
      baseUrl = `https://api.knack.com/v1/objects/${element}/records?format=raw&filters=${encodedFilters}&rows_per_page=500`;
      break;

    default:
      return null;
  }

  return baseUrl;
}

// Utility consts = 

export const getResponsiblePerson = (partnerId) => {
  let baseUrl = "";
  let filters = "";
  let encodedFilters = "";

  filters = {
    match: "and",
    rules: [
      {
        field: "field_156",
        operator: "is",
        value: partnerId,
      },
      {
        field: "field_155",
        operator: "is",
        value: true,
      },
    ],
  };

  encodedFilters = encodeURIComponent(JSON.stringify(filters));
  baseUrl = `https://api.knack.com/v1/objects/object_14/records?format=raw&filters=${encodedFilters}&rows_per_page=500`;
  return baseUrl;
}

const formatName = (form) => {
  let nameValues = {};
  let fieldKey = "";
  for (const property in form) {
    if (property.endsWith("_firstName")) {
      nameValues.first = form[property];
    }
    if (property.endsWith("_middleName")) {
      nameValues.middle = form[property];
    }
    if (property.endsWith("_lastName")) {
      nameValues.last = form[property];
    }

    if (property.endsWith("_firstName")) {
      fieldKey = property.replace("_firstName", "");
      form[fieldKey] = nameValues;
    }
  }

  const nameObject = {
    [fieldKey]: nameValues,
  };

  return nameObject;
};

const checkIsAdress = (record) => {
  for (const property in record) {
    if (Array.isArray(record[property])) {
      if (
        record[property][0] &&
        (record[property][0].city ||
          record[property][0].country ||
          record[property][0].street ||
          record[property][0].zip)
      ) {
        record[property] = { ...record[property][0] };
      }
    }
  }
};

export const prepareData = (record) => {
  Object.entries(record).forEach(([k, v]) => {
    if (v instanceof Date) {
      record[k] = moment(v).format("DD/MM/YYYY");
    }
  });

  for (const property in record) {
    // this is to handle connected field that are not multiple
    if (record[property] !== null && typeof record[property] !== "undefined") {
      if (record[property].id) {
        record[property] = record[property].id;
      }
    }
  }

  formatName(record);
  checkIsAdress(record);

  return record;
}
