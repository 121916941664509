import { sendContractToSignWebhook, sendContractToZohoSignWebhook } from "../../utils/webhooks";
import callWebhook from "./api/callWebhook";
import { Contrato } from "../../utils/knackKeys";
import { dbApiCall } from "./api";
import { GET_ONE, METHODS } from "./knack/types";
import notify from "./helper";
import { ERROR, SUCCESS, WARNING } from "./notifications";

export const createContractToSign = (id, userId) => async (dispatch) => {
  const useZohoSign = process.env.REACT_APP_CONTRACT_PROVIDER === 'ZOHO_SIGN';

  const confirm = window.confirm(useZohoSign ? "Tem a certeza que pretende começar um novo ciclo de assinatura?" :
    "Tem a certeza que pretende gerar um novo contrato? Se houver já um contrato gerado, este será apagado."
  );
  if (!confirm) return;

  if (!useZohoSign) {
    const url = `${sendContractToSignWebhook}?action=create_contract&id=${id}&user_id=${userId}`;
    window.open(url, "_blank").focus();
    return;
  }

  try {
    notify({
      dispatch, type: WARNING, message: 'A gerar contrato...'
    })
    const response = await callWebhook({
      webhook: sendContractToZohoSignWebhook,
      body: { id, user_id: userId }
    });
    if (response.data === "Accepted") throw new Error('O processo falhou a meio da execução')
    notify({
      dispatch, type: SUCCESS,
      message: 'O Contrato foi enviado para o seu email. Depois da sua aprovação será enviado ao Formando'
    })
  } catch (error) {
    return notify({
      dispatch,
      type: ERROR,
      message: `Ocorreu um erro: ${error.data || error?.response?.data}`,
    });
  }
};

export const sendContractToSign = (id, userId) => async (dispatch) => {
  const confirm = window.confirm(
    "Tem a certeza que pretende enviar o contrato para ser assinado? Se o contrato já estiver em processo de assinatura, o registo do estado da assinatura será eliminado."
  );
  if (!confirm) return;
  try {
    const { data } = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: Contrato.details.scene,
      view: Contrato.details.view,
      id,
    });

    if (!data[Contrato.fields.ORIENTADOR_PEDAGOGICO][0]?.id) {
      return notify({
        dispatch,
        type: ERROR,
        message: "O contrato não tem Orientador Pedagógico",
      });
    }

    notify({
      dispatch,
      type: WARNING,
      message: "A enviar contrato...",
    });

    const body = {
      id: id,
      action: "send_contract_to_sign",
      user_id: userId,
    };
    const response = await callWebhook({
      webhook: sendContractToSignWebhook,
      body,
    });

    if (response.data === "success") {
      notify({
        dispatch,
        type: SUCCESS,
        message: "Contrato enviado com sucesso ao Orientador",
      });
    } else {
      notify({
        dispatch,
        type: ERROR,
        message: `Erro ao enviar contrato, detalhes: ${response.data}`,
      });
    }
  } catch (error) {
    notify({
      dispatch,
      type: ERROR,
      message: `Erro ao enviar contrato, detalhes: ${error?.response?.data?.message || error?.response?.data || error.message
        }`,
    });
  }
};
