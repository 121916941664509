import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { openSpecificMessageEditor } from "../../../../../redux/actions/massComunications";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../../redux/actions/customActionsPopUps";
import { requiredErrorMessage } from "../../../../../utils/errors";
import TalentoButton from "../../../TalentoButton";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
    minHeight: 400,
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
}));

const MassSMSText = (props) => {
  const { gridApi, emailPopUpEditor, object } = props;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const classes = useStyles();

  const onSubmit = (formData) => {
    let smsInfo = {
      channel: "sms",
      type: "textEditor",
      content: formData.smsContent,
      campaignId: 28,
      object: object,
    };

    props.customActionsPopUps("sendMassSMS", gridApi, smsInfo);
  };

  return (
    <Dialog
      open={
        emailPopUpEditor.isOpen ? emailPopUpEditor.type === "textEditor" : false
      }
      maxWidth="lg"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" color="inherit">
              Escreva a sua SMS...
            </Typography>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                style={{ marginLeft: 5, marginTop: 20 }}
              >
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="smsContent"
                    defaultValue={""}
                    rules={{ required: requiredErrorMessage }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        multiline={true}
                        placeholder={"Escreva aqui..."}
                        variant="outlined"
                        size="medium"
                        minRows={3}
                        style={{ minWidth: 500 }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <TalentoButton
                    onClick={() => props.openSpecificMessageEditor(false)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Enviar SMS
                  </TalentoButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    emailPopUpEditor: state.emailPopUpEditor,
    object: state.actions.object,
  };
};

export default connect(mapStateToProps, {
  customActionsPopUps,
  openSpecificMessageEditor,
})(MassSMSText);
