import { CHANGE_TAB, CLEAR_TAB, GET_TABS } from "../constants/layout";

export default function layoutReducer(state = { tab: 0, tabs: [] }, action) {
  switch (action.type) {
    case GET_TABS:
      const currentTabs = action.payload;
      return { ...state, tabs: currentTabs };
    case CHANGE_TAB:
      return { ...state, tab: action.payload };
    case CLEAR_TAB:
      return { ...state, tab: 0 };
    default:
      return state;
  }
}
