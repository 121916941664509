import axios from "axios";
import { getAuthCache } from "../knackconfig";
import { backendUrl } from "../../../utils/config";

const callWebhook = async ({ webhook, body, proxyOptions = {} }) => {
  const { token, userId } = getAuthCache();

  const options = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    ...proxyOptions,
  };

  const request = { webhook, params: body, userId, proxyOptions };

  const url = `${backendUrl}/v1/webhook`;

  return axios.post(url, request, options);
};

export default callWebhook;
