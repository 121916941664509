import React from "react";
import { TextField } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const FieldInput = ({
  params,
  disabled,
  requiredValue,
  error,
  labelForInput,
  classes,
  loading,
  helper,
  inputRef,
}) => {
  return (
    <TextField
      {...params}
      disabled={disabled}
      required={requiredValue}
      error={!!error}
      label={labelForInput}
      helperText={helper}
      inputRef={inputRef}
      FormHelperTextProps={{ component: "div" }}
      InputLabelProps={{
        ...params.InputLabelProps,
        className: classes.label,
      }}
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );
};

export default FieldInput;
