import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

const MessageContainer = ({ messages, children }) => {
  if (!messages?.length) return null;
  return children;
};

const Message = ({ messages, auth }) => {
  const { search, pathname } = useLocation();
  const tab = search
    .split("?")?.[1]
    ?.split("active_tab=")?.[1]
    ?.split("&")?.[0];
  const slug = pathname.split("/")?.[1];

  const storedMessages = !!sessionStorage.getItem(`${slug}-${tab}-messages`)
    ? sessionStorage.getItem(`${slug}-${tab}-messages`)
    : null;

  const [filteredMessages, setFilteredMessages] = useState(
    JSON.parse(storedMessages || null) || []
  );

  useEffect(() => {
    const userRoles = auth?.profile_keys;
    if (!messages?.length) return;
    if (filteredMessages?.length) return;
    const messagesToDisplay = messages?.filter((message) =>
      message?.visibleToProfiles?.find((profile) =>
        userRoles?.includes(profile)
      )
    );
    sessionStorage.setItem(
      `${slug}-${tab}-messages`,
      JSON.stringify(messagesToDisplay)
    );
    setFilteredMessages(messagesToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleClose = ({ id }) => {
    const hideMessage = filteredMessages?.map((message) => {
      if (message.id === id) return { ...message, display: false };
      return message;
    });
    sessionStorage.setItem(
      `${slug}-${tab}-messages`,
      JSON.stringify(hideMessage)
    );
    setFilteredMessages(hideMessage);
  };

  return (
    <MessageContainer messages={filteredMessages}>
      {filteredMessages?.map(
        ({ id, content, display }, index) =>
          display && (
            <Alert
              key={index.toString()}
              severity="warning"
              onClose={() => handleClose({ id })}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Alert>
          )
      )}
    </MessageContainer>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, null)(Message);
