import React from 'react';
import DetailView from '../../layout/Detail/DetailView'
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_37"
const objectLabel = "Sessao"
const objectLabelSingular = "Sessao"
const objectLabelPlural = "Sessoes"
const listUrl = "/sessoes"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
}

const DetailSession = (props) => {
    return (

        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            scene={"scene_241"}
            view={"view_330"}
            sceneEdit={"scene_240"}
            viewEdit={"view_329"}
            userPermissions={{
                deleleteRolesPermissions: [],
                editRolesPermissions: []
            }}
            tabs={[]}
        />
    )
}


const FormAddSession = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_242"}
            view={"view_332"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditSession = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_240"}
            view={"view_329"}
            userPermissions={userPermissions}
        />
    )

}

const ListSessions = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_238"}
            view={"view_328"}
            userPermissions={userPermissions}
            extraConfigs={{}}

        />
    )
}

export {
    DetailSession,
    FormAddSession,
    FormEditSession,
    ListSessions
}