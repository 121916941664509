import React from "react";
import { SameNifStudentErrorMessage } from "../../../../utils/errors";
import { Aluno, Contrato } from "../../../../utils/knackKeys";
import { CircularProgress } from "@material-ui/core";
import { backendUrl } from "../../../../utils/config";
import { idDocuments, idNumberFields } from "./validate/validateDocuments";
import { apiCall } from "../../../../redux/actions/api";
import { METHODS } from "../../../../redux/actions/knack/types";

const {
  fields: { NAME },
} = Aluno;

const handleOnBlur = async ({
  value,
  onBlur,
  setHelper,
  fieldProps,
  trigger,
  getFieldState,
  formState,
}) => {
  const isContractNif = fieldProps.key === Contrato.fields.NIF;
  if (isContractNif)
    return await findStudentWithSameNif({
      value,
      onBlur,
      setHelper,
      fieldProps,
    });

  Promise.resolve();
  const isIdDocument = idNumberFields.includes(fieldProps.key);
  if (isIdDocument) {
    return checkDocuments({
      fieldKey: fieldProps.key,
      value,
      trigger,
      getFieldState,
      formState,
      onBlur,
    });
  }
  return onBlur();
};

const checkDocuments = ({
  fieldKey,
  value,
  trigger,
  getFieldState,
  formState,
  onBlur,
}) => {
  if (!idNumberFields.includes(fieldKey)) return onBlur();

  const { isTouched } = getFieldState(fieldKey, formState);

  const related = idDocuments.find((el) => el.document === fieldKey);
  if (value || isTouched) trigger(related.expiry);
  return onBlur();
};

const findStudentWithSameNif = async ({
  value,
  onBlur,
  setHelper,
  fieldProps,
}) => {
  const isContractNif = fieldProps.key === Contrato.fields.NIF;
  if (!isContractNif) return onBlur();

  try {
    setHelper(
      <CircularProgress
        color="secondary"
        size={30}
        style={{ marginTop: "1.4rem" }}
      />
    );

    const {
      data: { data },
    } = await apiCall({
      action: METHODS.GET.value,
      url: `${backendUrl}/users/check-nif?nif=${value}`,
    });

    if (!data?.length) {
      setHelper("");
      return onBlur();
    }
    const {
      [NAME]: { first, last },
      id,
    } = data?.[0];
    setHelper(
      <SameNifStudentErrorMessage
        studentId={id}
        studentName={`${first} ${last}`}
      />
    );
    onBlur();
  } catch (error) {
    setHelper("");
    return onBlur();
  }
};

export default handleOnBlur;
