import { Contrato } from "./knackKeys";

export const checkForSpecialCases = (field, fieldOptions, auth) => {
  let filteredOptions = [];
  auth.profile_keys?.forEach((profile) => {
    if (profile.profile_key !== "profile_27")
      if (field === Contrato.fields.ORIENTADOR_PEDAGOGICO) {
        // to show only to Own "Orientadores" when add or editing contract
        filteredOptions = fieldOptions?.filter(
          (option) => option.field_196.email === auth.userEmail
        );
      } else return fieldOptions;
  });
  if (filteredOptions.length > 0) return filteredOptions;
  return fieldOptions;
};
