import IBAN from "iban";

export const truncate = (string, num) => {
  if (string.length <= num) {
    return string;
  }
  return string.slice(0, num) + "...";
};

export const nifValidator = (value) => {
  value = value + "";

  // has 9 digits?
  if (!/^[0-9]{9}$/.test(value)) return false;

  // is from a person?
  if (!/^[123]|45|5|7/.test(value)) return false;

  // digit check
  let tot =
    value[0] * 9 +
    value[1] * 8 +
    value[2] * 7 +
    value[3] * 6 +
    value[4] * 5 +
    value[5] * 4 +
    value[6] * 3 +
    value[7] * 2;
  let div = tot / 11;
  let mod = tot - parseInt(div) * 11;
  // eslint-disable-next-line eqeqeq
  let tst = mod == 1 || mod == 0 ? 0 : 11 - mod;
  // eslint-disable-next-line eqeqeq
  return value[8] == tst;
};

export const isObjectEmpty = (object) => {
  if (!object) return false;
  if (object?.constructor === Object) return !!Object.keys(object).length;
  throw new Error("Not an object");
};

export const ibanValidator = (iban) => IBAN.isValid(iban);
