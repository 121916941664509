import axios from "axios";
import { noAuthHeaders } from "./headers";

class BaseRequest {
  headers;
  useProxy;

  constructor({ headers, useProxy = false, isRetry = false }) {
    this.headers = headers;
    this.useProxy = useProxy;
    this.isRetry = isRetry;
  }

  async get({ url }) {
    return this.defaultRequest({
      method: "GET",
      headers: this.headers,
      url,
    });
  }

  async post({ url, data }) {
    return this.defaultRequest({
      method: "POST",
      url,
      data,
    });
  }

  async put({ url, data }) {
    return this.defaultRequest({
      method: "PUT",
      url,
      data,
    });
  }

  async patch({ url, data }) {
    return this.defaultRequest({
      method: "PATCH",
      url,
      data,
    });
  }

  async delete({ url, data }) {
    return this.defaultRequest({
      method: "DELETE",
      url,
      data,
    });
  }

  async defaultRequest({ method, url, data }) {
    return request({
      method,
      headers: this.headers,
      url,
      data,
      useProxy: this.useProxy,
      isRetry: this.isRetry,
    });
  }
}

const request = async ({
  method = "GET",
  headers = noAuthHeaders,
  url = "",
  data = null,
  useProxy = false,
  isRetry = false,
}) => {
  if (!useProxy) {
    const options = { method, headers, url, data };
    if (isRetry) {
      if (!data) options.data = {};
      options.data.isRetry = isRetry;
    }
    return await axios(options);
  }

  const proxyUrl = `${
    process.env.REACT_APP_BACKEND_URL
  }/v1/proxy/${method.toLowerCase()}`;
  const body = { url, data };
  if (isRetry) body.isRetry = isRetry;
  return await axios.post(proxyUrl, body, { headers });
};

export default BaseRequest;
