
import React from 'react';
import { Page, Text, Image, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo_centrostalento.png'
import OpenSansRegular from '../../assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../assets/fonts/OpenSans-Bold.ttf'
import OpenSansItalic from '../../assets/fonts/OpenSans-Italic.ttf'



export const internshipBadge = (dynamicData = "") => {

    const { studentName, courseName } = dynamicData

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.frame}>
                    <Image style={styles.image} src={Logo} />
                    <Text style={styles.title}>{studentName}</Text>
                    <Text style={styles.subtitle}>{courseName}</Text>
                    <Text style={styles.role}>Em estágio</Text>
                </View>
            </Page>
        </Document>
    )
}

// Register font => to refactory later
Font.register({ family: 'OpenSansRegular', src: OpenSansRegular });
Font.register({ family: 'OpenSansBold', src: OpenSansBold });
Font.register({ family: 'OpenSansItalic', src: OpenSansItalic });


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        display: 'flex',
        alignItems: 'center'
    },
    frame: {
        borderWidth: 2,
        borderColor: '#148FF6',
        width: 360,
        height: 280,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'OpenSansRegular',
    },
    title: {
        fontSize: 24,
      	marginBottom: 15,
        color: '#366091',
      	marginHorizontal: 'auto',
    },
    subtitle: {
        fontSize: 18,
        marginVertical: 12,
        color: '#366091',
      	marginHorizontal: 'auto',
    },
    role: {
        fontSize: 14,
        color: '#366091',
    },
    image: {
        width: 150,
        marginHorizontal: 'auto',
      	marginBottom: 30
    },
});


