import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_12"
const objectLabel = "Profissao"
const objectLabelSingular = "Profissao"
const objectLabelPlural = "Profissoes"
const listUrl = "/profissoes"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }


const FormAddProfession = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_175"}
            view={"view_236"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditProfession = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_170"}
            view={"view_229"}
            userPermissions={userPermissions}
        />
    )

}

const ListProfessions = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_168"}
            view={"view_228"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {

    FormAddProfession,
    FormEditProfession,
    ListProfessions  
}