import React from "react";
import { userCanSeeCreateButton } from "../../../../utils/userPermissions";
import TalentoButton from "../../TalentoButton";

const RenderCreateButton = ({
  showCreateButton,
  auth,
  showCreateButtonToProfiles,
  objectLabelSingular,
  setAddPopUpOpen
}) => {
  const canUserCreate =
    showCreateButton !== "no" &&
    userCanSeeCreateButton(auth.profile_keys, showCreateButtonToProfiles);

  if (!canUserCreate) return null;
  return (
    <TalentoButton
      variant="contained"
      color="secondary"
      onClick={() => setAddPopUpOpen(true)}
      style={{ marginLeft: "auto", marginRight: 5 }}
    >
      Adicionar {objectLabelSingular}
    </TalentoButton>
  );
};

export default RenderCreateButton;
