import React, { useEffect, useState } from "react";
import GridFilter from "../filterComponent/GridFilter";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { GridColumns } from "../GridColumns";
import { renderOpenInNewTab } from "../../../utils/Grid/renderFields";
import UploadFile from "../forms/UploadFile";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAuthCache } from "../../../redux/actions/knackconfig";
import { Contrato, Profiles } from "../../../utils/knackKeys";
import { Link } from "react-router-dom/cjs/react-router-dom";


const GridFilters = ({ gridConfig, object }) => {
  if (!gridConfig?.length) return null;
  return <GridFilter gridConfig={gridConfig} object={object} />;
};

export const EmptyGrid = ({ classes }) => {
  return (
    <Typography className={classes.emptyGridText} variant="h4" align="center">
      Sem registos
    </Typography>
  );
};

const RenderSpecialCase = ({ id }) => {
  return <Link to={`/alunos/${id}?active_tab=formacao-modular`}>
    Abrir
  </Link>
}

const specialCases = [
  {
    field: Contrato.fields.DIPLOMA,
    object: Contrato.object,
    where: 'grid',
    conditions: [
      {
        field: Contrato.fields.FORMACAO_MODULAR,
        operator: 'is',
        value: 'Sim'
      },
      {
        field: Contrato.fields.HAS_DIPLOMA_IN_AVALIACOES,
        operator: 'exists',
      },
    ],
    profiles: [Profiles.ALUNO],
    Render: ({ id }) => <RenderSpecialCase id={id} />
  },
  {
    field: Contrato.fields.CERTIFICADO,
    object: Contrato.object,
    where: 'grid',
    conditions: [
      {
        field: Contrato.fields.FORMACAO_MODULAR,
        operator: 'is',
        value: 'Sim'
      },
      {
        field: Contrato.fields.HAS_CERTIFICATE_IN_AVALIACOES,
        operator: 'exists',
      },
    ],
    profiles: [Profiles.ALUNO],
    Render: ({ id }) => <RenderSpecialCase id={id} />
  }
]

const SpecialCase = ({ field, record, object }) => {
  const auth = getAuthCache();
  const specialCase = specialCases.find(el => el.field === field)
  if (!specialCase) return null;
  const { conditions, profiles, Render } = specialCase;
  if (object !== specialCase.object) return null;

  const profileMatch = profiles.some(el => {
    const filtered = auth?.profile_keys?.filter(profile => profile === el)
    return !!filtered?.length
  })
  if (!profileMatch) return null;

  const matchedConditions = conditions.every(({ field, operator, value = undefined }) => {
    const fieldForCondition = record?.[field];
    switch (operator) {
      case 'is': return fieldForCondition === value;
      case 'exists': return !!fieldForCondition
      default: return fieldForCondition === value;
    }
  })
  if (!matchedConditions) return null;
  return ({ id }) => <Render id={id} />
}

const renderUploadFileButton = ({ params, sceneEdit, viewEdit, object }) => {
  const auth = getAuthCache();

  const field = params.colDef.field
  const fieldValue = params?.data?.[field]
  const forceShowUpload = params?.fieldConfig?.forceAllowUpload || false

  const url = !!fieldValue ? fieldValue : "";

  const Special = SpecialCase({ field, record: params.data, object })
  if (!!Special) return <Special id={auth.profileId} />

  if (!field && !forceShowUpload) return null;
  return (
    <UploadFile
      url={url}
      field={params.colDef.field}
      fieldConfig={params.fieldConfig}
      scene={sceneEdit}
      view={viewEdit}
      object={object}
      recordId={params?.data?.id}
    />
  );
};

const RenderGrid = ({
  onGridReady,
  openDetail,
  updateDataSource,
  gridConfig,
  object,
  isGridEmpty,
  sceneEdit,
  viewEdit,
  showListPopUpEdit,
  pinned,
  baseUrl,
  openDifferentDetail,
  auth,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return (
    <>
      <GridFilters gridConfig={gridConfig} object={object} />
      {isGridEmpty ? (
        <EmptyGrid classes={classes} />
      ) : (
        <div
          className="ag-theme-material"
          style={{ height: "85%", width: "100%" }}
        >
          <AgGridReact
            reactNext={true}
            onGridReady={onGridReady}
            onCellClicked={openDetail}
            datasource={mounted && updateDataSource()}
            immutableData // to work with redux better
            pagination={true}
            getRowNodeId={(data) => data.id}
            defaultColDef={{
              sortable: true,
              filter: false,
            }}
            rowSelection="multiple"
            enableCellChangeFlash={true}
            frameworkComponents={{
              uploadFileComponent: (params) =>
                renderUploadFileButton({ params, sceneEdit, viewEdit, object }),
              newTabComponent: renderOpenInNewTab,
            }}
            rowModelType={"infinite"}
            cacheBlockSize={30}
            rowBuffer={0}
            paginationPageSize={30}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={30}
            infiniteInitialRowCount={30}
            paginationAutoPageSize={false}
            enableCellTextSelection={true}
            rowClassRules={{
              transferred: (params) => {
                if (params.data) {
                  let isTransfered = false;
                  if (params.data.field_556) {
                    const actualClass = params.data.field_556;
                    actualClass.map((element) => {
                      return element.id !== id ? (isTransfered = true) : false;
                    });
                  }
                  return isTransfered;
                }
              }
            }}
          >
            {GridColumns(
              gridConfig,
              auth.profile_keys,
              baseUrl,
              pinned,
              openDifferentDetail
            )}

            <AgGridColumn
              hide={showListPopUpEdit === "yes" ? false : true}
              minWidth={200}
              flex={1}
              headerName={"Editar"}
              cellRenderer={() => "editar"}
            />
          </AgGridReact>
        </div>
      )}
    </>
  );
};

export default RenderGrid;
