import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import EditForm from '../../forms/EditForm';
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux';
import { cleanRecord } from '../../../../redux/actions/api/dataApiCalls'



const PopUpEdit = (props) => {
    const { popUpTitle, isEditPopUpOpen, object, scene, view, id, helperText } = props;
    const { userPermissions } = props;

    useEffect(() => {
        props.cleanRecord()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog
            open={isEditPopUpOpen}
            maxWidth="xl"
            fullWidth={true}
        >
            <DialogTitle>
                <Typography >
                    Editar {popUpTitle}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <EditForm
                    typeOfForm='popUp'
                    object={object}
                    scene={scene}
                    view={view}
                    userPermissions={userPermissions}
                    id={id}
                    helperText={helperText}
                />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        isEditPopUpOpen: state.editPopUp,
        record: state.record
    }
}

export default connect(mapStateToProps, { cleanRecord })(PopUpEdit)
