import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      backgroundColor: "white",
    },
    image: {
      backgroundImage: "url(https://centrostalento.pt/wp-content/uploads/2024/03/headway-5QgIuuBxKwM-unsplash.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    box: {
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "none",
      backgroundColor: "white",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    formContainer: {
      width: "80%",
    },
    title: {
      textAlign: "center",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formOptions: {
      display: "flex",
      alignItems: "center",
    },
    resetPassword: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      marginLeft: "auto",
      cursor: "pointer",
    },
  }));