import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    box: {
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    image: {
      width: "300px",
      height: "300px",
      [theme.breakpoints.up("md")]: {
        width: "350px",
        height: "350px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "400px",
        height: "400px",
      },
    },
    title: {
      fontSize: "16px",
      fontWeight: 600,
      marginTop: "3rem",
    },
    subtitle: {
      fontSize: "30px",
      fontWeight: 300,
      marginTop: "2rem",
    },
    logo: {
      marginTop: "3rem",
      width: "200px",
      marginLeft: '0.75rem'
    },
  };
});
