import React from 'react';
import DetailView from '../../layout/Detail/DetailView'
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_14"
const objectLabel = "Pessoa"
const objectLabelSingular = "Pessoa"
const objectLabelPlural = "Pessoas"
const listUrl = "/pessoas"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }

const DetailPeople = (props) => {
    return (

        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            scene={"scene_232"}
            view={"view_319"}
            sceneEdit={"scene_231"}
            viewEdit={"view_318"}
            userPermissions={userPermissions}
            tabs={[]}
        />
    )
}


const FormAddPeople = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_230"}
            view={"view_317"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditPeople = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_231"}
            view={"view_318"}
            userPermissions={userPermissions}
        />
    )

}

const ListPeoples = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_226"}
            view={"view_313"}
            userPermissions={userPermissions}
            extraConfigs={{}}

        />
    )
}

export {
    DetailPeople,
    FormAddPeople,
    FormEditPeople,
    ListPeoples  
}