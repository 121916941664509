import {
  SET_ERROR_MESSAGE,
  RESET_ERROR_MESSAGE,
} from "../constants/errorMessage";

export const setErrorMessage =
  ({ message, link }) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: { message, link },
    });
  };

export const resetErrorMessage = () => (dispatch) => {
  dispatch({
    type: RESET_ERROR_MESSAGE,
  });
};
