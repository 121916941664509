import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import DgertLogo from "../../assets/images/carta_certificado_centrostalento_logo_dgert.png";
import OpenSansRegular from "../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansItalic from "../../assets/fonts/OpenSans-Italic.ttf";
import { documentStyles } from "./documentStyles";
import moment from "moment";

export const certificateLetter = (dynamicData) => (
  <Document size="A4">
    {dynamicData.map((data, index) => {
      return (
        <Page style={styles.body2} key={index.toString()}>
          <View>
            <Image src={DgertLogo} style={{ marginBottom: 40 }} />
          </View>
          <View>
            <Text style={styles.headerLetter}>
              Caro/a Formando/a
              {"\n"}
              <Text>
                {data.studentName ? data.studentName : "{NOME DO ALUNO}"}
              </Text>
              {"\n"}
              <Text>
                {data.studentStreet ? data.studentStreet : "{RUA DO ALUNO}"}
              </Text>
              {"\n"}
              <Text>
                {data.studentZip
                  ? `${data.studentZip}, `
                  : "{CPOSTAL DO ALUNO}"}
              </Text>
              <Text>
                {data.studentCity ? data.studentCity : "{CIDADE DO ALUNO}"}
              </Text>
              {"\n"}
            </Text>
          </View>
          <View>
            <Text style={styles.text}>
              Porto,{" "}
              <Text style={styles.text}>
                {moment().format("DD MMMM, YYYY")}
              </Text>
            </Text>
            <Text style={styles.text}>
              Caro/a Formando/a do Curso de{" "}
              <Text>
                {data.courseName ? data.courseName : "{CURSO DO ALUNO}"}
              </Text>
            </Text>
          </View>

          <Text style={styles.textCertificateLetter}>
            Chegou ao fim a sua formação na área que escolheu. Agora espera-lhe
            uma jornada de aprendizagem prática pela qual tem lutado: a inserção
            no mercado de trabalho na área que pretende.
            {"\n"}
            Temos a certeza que os conhecimentos que adquiriu no nosso Curso
            ajudar-lhe-ão a atingir os seus objetivos e a sentir-se realizado/a
            pessoalmente por estar a investir na sua formação.
            {"\n"}
            Juntamente com esta carta enviamos-lhe o certificado emitido pela
            Plataforma Sigo.
            {"\n"}
            Por fim, despedimo-nos com o desejo de termos cumprido, em si, o
            slogan que nos move:{" "}
            <Text style={{ fontFamily: "OpenSansBold" }}>
              “FORMAMOS PROFISSIONAIS”.
            </Text>
            {"\n"}
            Dica: Para valorizar o seu currículo não se esqueça de escrever no
            mesmo: “Curso de Formação Profissional em{" "}
            <Text>
              {data.courseName ? data.courseName : "{CURSO DO ALUNO}"}
            </Text>
            ”. Aconselhamos-lhe a colocar os módulos do Curso para que o seu
            futuro empregador fique a conhecer as diferentes competências que
            adquiriu.
          </Text>

          <Text style={styles.textCertificateLetter}>
            Muito obrigado pela sua dedicação e entusiasmo!
            {"\n"}
            Com os melhores cumprimentos,
            {"\n"}
            {"\n"}
          </Text>
          <Text style={styles.signatureCertificateLetter}>
            Departamento Didático
            {"\n"}
            {"\n"}
          </Text>

          <View style={styles.footer}>
            <Text>
              Talentodisseia - Unipessoal, Lda. | NIF 509777970 |
              info@centrostalento.pt | www.centrostalento.pt
              {"\n"}
              Rua de Sá da Bandeira, 481, 2E - 4000-436 Porto | +351 220 437 946
            </Text>
          </View>
        </Page>
      );
    })}
  </Document>
);

// Register font => to refactory later
Font.register({ family: "OpenSansRegular", src: OpenSansRegular });
Font.register({ family: "OpenSansBold", src: OpenSansBold });
Font.register({ family: "OpenSansItalic", src: OpenSansItalic });

const styles = StyleSheet.create(documentStyles);
