import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { connect } from "react-redux";
import { login } from "../../redux/actions/auth";
import TalentoButton from "../layout/TalentoButton";

const LoginForm = (props) => {
  const { classes, setFormState } = props;

  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const onSubmit = async (formData) => await props.login(formData);

  return (
    <div>
      <Typography component="h1" variant="h5" className={classes.title}>
        Introduza as suas credenciais
      </Typography>
      <FormProvider {...methods}>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            defaultValue=""
            name="email"
            rules={{ required: "Este campo é obrigatório" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message || ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{ required: "Este campo é obrigatório" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message || ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            )}
          />
          <div className={classes.formOptions}>
            <Controller
              control={control}
              defaultValue={false}
              name="remember"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} color="primary" defaultValue={false} />
                  }
                  label="Lembrar-me"
                />
              )}
            />
            <Typography
              onClick={() => setFormState("recoverPassword")}
              className={classes.resetPassword}
            >
              Esqueceu-se da password? Clique aqui.
            </Typography>
          </div>
          {props.errors && (
            <Typography color="error">{props.errors}</Typography>
          )}
          <TalentoButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </TalentoButton>
        </form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { login })(LoginForm);
