import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { setCustomPopUpOpen } from "../../../../redux/actions/popUps";
import { createNotification } from "../../../../redux/actions/notifications";
import TalentoButton from "../../TalentoButton";
import { apiCall } from "../../../../redux/actions/api";
import { METHODS } from "../../../../redux/actions/knack/types";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  inputField: {
    minWidth: "300px",
    margin: theme.spacing(1.5),
  },
  submitButton: {
    margin: theme.spacing(2),
  },
}));

const PopUpForEvaluations = (props) => {
  const { isPopUpOpen, id } = props;
  const { userId } = props.auth;
  const classes = useStyles();
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const [isTotalValuesCorrect, setIsTotalValuesCorrect] = useState(null);

  const formFields = [
    {
      name: "pesoAssiduidade",
      label: "Peso da Assiduidade",
    },
    {
      name: "pesoAvaliacaoContinua",
      label: "Peso da Avaliação Continua",
    },
    {
      name: "pesoAvaliacaoEscrita",
      label: "Peso da Avaliação Escrita",
    },
    {
      name: "pesoProjetoFinal",
      label: "Peso do Projeto Final",
    },
    {
      name: "pesoAvaliacaoPratica",
      label: "Peso da Avaliação Prática",
    },
    {
      name: "pesoTrabalhosDesenvolvidos",
      label: "Peso dos Trabalhos Desenvolvidos",
    },
  ];

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e, onChange) => {
    const { value } = e.target;
    setInputValue({ [e.target.name]: value });
  };

  const onSubmit = async (formData) => {
    let values = {
      totalValues: 0,
    };
    for (const property in formData) {
      if (formData[property] !== "") {
        values.totalValues =
          values.totalValues + parseFloat(formData[property]) / 100;
        values[property] = parseFloat(formData[property]) / 100;
      }
    }

    if (values.totalValues !== 1) {
      return setIsTotalValuesCorrect(false);
    } else if (values.totalValues === 1) {
      try {
        await apiCall({
          action: METHODS.POST.value,
          url: "https://hook.integromat.com/av2rx2kqnrt6nt6lxpz6nsczlpxwvmd6",
          data: {
            classId: id,
            customAction: "createEvaluations",
            values,
            userId,
          },
          shouldRetry: false,
        });
        props.setCustomPopUpOpen(false);
        props.createNotification().warning({
          message:
            "A criar a avaliações, o sistema irá notificá-lo assim que terminar",
        });
      } catch (error) {
        console.log("error in onSubmit in PopUpForEvaluations", error);
      }
    }
  };

  return (
    <Dialog
      open={isPopUpOpen}
      maxWidth="xl"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <DialogTitle>
        <Typography>Criar Avaliações</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }} dividers>
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                  {formFields.map((formField) => {
                    return (
                      <Controller
                        control={control}
                        name={formField.name}
                        defaultValue={inputValue.value}
                        render={({ field: { onChange, ...rest } }) => (
                          <TextField
                            {...rest}
                            onChange={(event) => handleChange(event, onChange)}
                            className={classes.inputField}
                            name={formField.name}
                            label={formField.label}
                            value={inputValue.value}
                            variant="outlined"
                            size="small"
                            id={rest.name}
                          />
                        )}
                      />
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  {isTotalValuesCorrect === false ? (
                    <Typography color="error" style={{ marginTop: 20 }}>
                      O valor total dos pesos tem de ser 100, por favor insira
                      novamente
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <TalentoButton
                    onClick={() => props.setCustomPopUpOpen(false)}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "auto" }}
                    className={classes.submitButton}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={classes.submitButton}
                  >
                    Criar Avaliações
                  </TalentoButton>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    isPopUpOpen: state.customPopUp,
    auth: state.auth,
    record: state.record,
  };
};

export default connect(mapStateToProps, {
  setCustomPopUpOpen,
  createNotification,
})(PopUpForEvaluations);
