import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Paper, CssBaseline } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../assets/style/gridStyles.css";
import {
  fetchFilteredRecordsByConnection,
  passIdToState,
  cleanRecords,
  cleanRecord,
} from "../../../redux/actions/api/dataApiCalls";
import { fetchGridConfig } from "../../../redux/actions/knackconfig";
import { clearTableState } from "../../../redux/actions/customActions";
import { clearTab } from "../../../redux/actions/layout";
import { clearErrors } from "../../../redux/actions/errors";
import {
  setCustomPopUpOpen,
  setEditPopUpOpen,
  setAddPopUpOpen,
} from "../../../redux/actions/popUps";
import { editRecord } from "../../../redux/actions/api/dataApiCalls";
import { isFiltersOpen, setFilters } from "../../../redux/actions/filters";
import { getClassesForStudent } from "../../../redux/actions/api/customDataApiCalls";
import { userCanClickInTable } from "../../../utils/userPermissions";
import { permissionsExceptions } from "../../../utils/permissionsExceptions";
import { getcustomApiCall } from "../../../utils/customApiCalls";
import AddPopUp from "../AddPopUp";
import PopUpEdit from "../customComponents/PopUps/PopUpEdit";
import useStyles from "./styles";
import ActionsSection from "./ActionsSection";
import CustomPopUp from "../customComponents/PopUps/CustomPopUp";
import RenderGrid from "./RenderGrid";
import { createNotification } from "../../../redux/actions/notifications";
import Message from "./Message";

const ListViewTab = (props) => {
  const {
    objectParams,
    auth: authFromProps,
    detailHeaderFields,
    fetchGridConfig,
    fetchFilteredRecordsByConnection,
    filters: filtersFromProps,
    clearErrors,
    customActions,
    cleanRecords,
    formSubmission,
    setEditPopUpOpen,
    setFilters,
    editPopUp,
    clearTab,
    isAddPopUpOpen,
  } = props;
  const {
    object,
    objectLabelPlural,
    objectLabelSingular,
    scene,
    view,
    extraConfigs,
    sort,
    filterByConnectedField,
    customApiCall,
    sceneEdit,
    viewEdit,
    showListPopUpEdit,
    pinned,
    userPermissions,
    messages
  } = objectParams;
  const {
    openDifferentDetail,
    hasDetail,
    filters,
    actionsMenu,
    showCreateButton,
    showCreateButtonToProfiles,
    showUploadButtonOnForm,
    helperText,
  } = extraConfigs;
  const { userId, profile_keys } = authFromProps;
  const { connected_field, id, identifierNameField } = filterByConnectedField;
  const { userPermissionsField, clickTableRolesPermissions } = userPermissions;

  const classes = useStyles();
  const history = useHistory();

  const [gridApi, setGridApi] = useState(null);
  const [idForEditPopUp, setIdForEditPopUp] = useState(null);
  const [gridConfig, setGridConfig] = useState([]);
  const [isGridEmpty, setIsGridEmpty] = useState(false);
  const [mounted, setMounted] = useState(false);

  const connectedField = [
    {
      id,
      identifier:
        detailHeaderFields?.[identifierNameField]?.formatted_value ||
        detailHeaderFields?.[identifierNameField]?.full ||
        detailHeaderFields?.[identifierNameField],
      key: connected_field,
    },
  ];

  useEffect(() => {
    setMounted(true);
    clearErrors();
    if (
      gridApi &&
      (customActions?.isTableUpdated || filtersFromProps) &&
      mounted
    )
      gridApi.setDatasource(updateDataSource());

    return () => {
      cleanRecords();
      if (mounted) setMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customActions, formSubmission, filtersFromProps]);

  const onGridReady = async (params) => {
    const getGridConfig = await fetchGridConfig(object);
    setGridConfig(getGridConfig);
    setGridApi(params.api);
  };

  history.listen((a, b) => {
    setFilters({
      isActive: null,
      rules: [],
    });
  });

  const prepareSortModel = (sortModel) => {
    let paramsForSort = "";
    if (!!sort) {
      let sortColumn = sort.field;
      if (sort.field.includes("date_formatted")) {
        sortColumn = sort.field.replace(".date_formatted", "");
      }
      paramsForSort = `&sort_field=${sortColumn}&sort_order=${sort.order}`;
    }
    if (sortModel) {
      let sortColumn = sortModel.colId;
      if (sortModel.colId.includes("date_formatted")) {
        sortColumn = sortModel.colId.replace(".date_formatted", "");
      }
      paramsForSort = `&sort_field=${sortColumn}&sort_order=${sortModel.sort}`;
    }
    return paramsForSort;
  };

  const updateDataSource = () => {
    isGridEmpty && setIsGridEmpty(false);
    let currentPage = 0;

    const dataSource = {
      getRows: async (params) => {
        try {
          let results;
          if (customApiCall) {
            results = await getcustomApiCall({
              customApiCall,
              callArguments: {
                auth: authFromProps,
                profiles: customApiCall.profiles,
              },
              props,
            });
          }
          if (!results) {
            currentPage = params.endRow / 30;
            let sortModel = params.sortModel[0];
            let paramsForSort = prepareSortModel(sortModel);

            const userPermissionsFieldWithExceptions = permissionsExceptions(
              profile_keys,
              object,
              userPermissionsField
            );
            results = await fetchFilteredRecordsByConnection({
              scene,
              view,
              id,
              connectedField: connected_field,
              auth: authFromProps,
              userPermissionsField: userPermissionsFieldWithExceptions,
              userId,
              profileKeys: profile_keys,
              currentPage,
              paramsForSort,
              filters: filtersFromProps,
            });
          }
          if (results === "error") {
            params.failCallback();
          } else {
            let totalRows = !!results.totalRecords
              ? results.totalRecords
              : results.length;
            if (!totalRows && mounted) return setIsGridEmpty(true);
            const records = !!results.records ? results.records : results;
            params.successCallback(records, totalRows);
          }
        } catch (error) {
          params.failCallback();
        }
      },
    };
    return dataSource;
  };

  const baseUrl = objectLabelPlural.toLowerCase();
  const openDetail = (row) => {
    const disableClick = userCanClickInTable(
      clickTableRolesPermissions,
      profile_keys
    );
    if (disableClick) return;
    if (!row) return;
    if (row.colDef?.headerName === "Editar") {
      setIdForEditPopUp(row.data?.id);
      setEditPopUpOpen(true);
      return;
    }
    if (
      ["uploadFileComponent", "newTabComponent"].includes(
        row.colDef?.cellRenderer
      )
    )
      return null;
    if (hasDetail !== "no") {
      clearTab();
      history.push({
        pathname: `/${baseUrl}/${row.data?.id}`,
        search: "",
      });
    }
  };

  if (gridApi !== null) gridApi.sizeColumnsToFit();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={1} style={{ padding: 10 }}>
        <Paper elevation={3} className={classes.paper_grid}>
          <Message messages={messages}/>
          <ActionsSection
            gridApi={gridApi}
            filtersFromExtraConfigs={filters}
            actionsMenu={actionsMenu}
            object={object}
            showCreateButton={showCreateButton}
            showCreateButtonToProfiles={showCreateButtonToProfiles}
            objectLabelSingular={objectLabelSingular}
            scene={scene}
            view={view}
            parentProps={props}
            connected_field={connected_field}
            userPermissionsField={userPermissionsField}
            gridConfig={gridConfig}
          />
          <RenderGrid
            onGridReady={onGridReady}
            openDetail={openDetail}
            updateDataSource={updateDataSource}
            gridConfig={gridConfig}
            object={object}
            isGridEmpty={isGridEmpty}
            sceneEdit={sceneEdit}
            viewEdit={viewEdit}
            showListPopUpEdit={showListPopUpEdit}
            pinned={pinned}
            baseUrl={baseUrl}
            openDifferentDetail={openDifferentDetail}
            auth={authFromProps}
          />
        </Paper>
      </Grid>

      <AddPopUp
        isOpenPopUp={isAddPopUpOpen}
        popUpTitle={objectLabelSingular}
        object={object}
        scene={scene}
        view={view}
        connectedField={connectedField}
        userPermissions={userPermissions}
        showUploadButtonOnForm={showUploadButtonOnForm}
      />
      <PopUpEdit
        isOpenPopUp={editPopUp}
        popUpTitle={objectLabelSingular}
        object={object}
        scene={sceneEdit}
        view={viewEdit}
        id={idForEditPopUp}
        userPermissions={userPermissions}
        helperText={helperText}
      />
      <CustomPopUp
        id={id}
        gridApi={gridApi}
        gridConfig={gridConfig}
        customActions={customActions}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    records: state.records,
    isAddPopUpOpen: state.addPopUp,
    editPopUp: state.editPopUp,
    customActions: state.actions,
    auth: state.auth,
    detailHeaderFields: state.detailHeaderFields,
    formSubmission: state.formSubmission,
    filters: state.filters,
  };
};

export default connect(mapStateToProps, {
  fetchFilteredRecordsByConnection,
  getClassesForStudent,
  fetchGridConfig,
  editRecord,
  clearErrors,
  passIdToState,
  setCustomPopUpOpen,
  setEditPopUpOpen,
  setAddPopUpOpen,
  clearTableState,
  cleanRecords,
  cleanRecord,
  isFiltersOpen,
  setFilters,
  clearTab,
  createNotification,
})(ListViewTab);
