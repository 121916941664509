import React from "react";
import EditForm from "../layout/forms/EditForm";
import { FormacaoModularContrato } from "../../utils/knackKeys";

// SHARED CONSTANTS
const object = FormacaoModularContrato.object;
const objectLabel = "Formação Modular";
const listUrl = "/contratos";

const FormEditFormacaoModularContrato = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      parentId={props.match.params.contractId}
      scene={FormacaoModularContrato.edit.scene}
      view={FormacaoModularContrato.edit.view}
      userPermissions={{
        userPermissionsField: "",
        viewRolesPermissions: [],
      }}
    />
  );
};

export { FormEditFormacaoModularContrato };
