import { useEffect } from "react";
import {
  KnackAndFrontEndRelMainMenu,
  KnackAndFrontEndRelConfigMenu,
} from "./KnackAndFrontEndRel";
import { getAuthCache } from "../redux/actions/knackconfig";
import { backendUrl } from "../utils/config";
import { apiCall } from "../redux/actions/api";
import { METHODS } from "../redux/actions/knack/types";

export const useClearStorageAfterChanges = () => {
  useEffect(() => {
    getDeployLastUpdate();
    getFieldsLastUpdate();
  }, []);
};

const getDeployLastUpdate = () => {
  const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;

  if (
    typeof localStorage.APP_VERSION === "undefined" ||
    localStorage.APP_VERSION === null
  ) {
    localStorage.setItem("APP_VERSION", APP_VERSION);
  }

  if (localStorage.APP_VERSION !== APP_VERSION) {
    getObjectsAndClearStorage();

    localStorage.setItem("APP_VERSION", APP_VERSION);
  }
};

const getFieldsLastUpdate = async () => {
  try {
    const response = await apiCall({
      action: METHODS.GET.value,
      url: `${backendUrl}/knack/last-update`,
      token: getAuthCache().token,
      shouldRetry: false,
    });

    const FIELDS_VERSION = response?.data?.last_update_id;

    if (!localStorage.FIELDS_VERSION)
      localStorage.setItem("FIELDS_VERSION", FIELDS_VERSION);

    if (localStorage.FIELDS_VERSION !== FIELDS_VERSION) {
      getObjectsAndClearStorage();
      localStorage.setItem("FIELDS_VERSION", FIELDS_VERSION);
    }
  } catch (error) {
    console.log("error in getFieldsLastUpdate", error);
  }
};

const getObjectsAndClearStorage = () => {
  KnackAndFrontEndRelMainMenu.forEach((entity) => {
    clearObjectStorage(entity);
  });

  KnackAndFrontEndRelConfigMenu.management.forEach((entity) => {
    clearObjectStorage(entity);
  });

  KnackAndFrontEndRelConfigMenu.configurations.forEach((entity) => {
    clearObjectStorage(entity);
  });
};

const clearObjectStorage = (entity) => {
  localStorage.removeItem(`detail_header_config_${entity.object}`);
  localStorage.removeItem(`form_config_${entity.object}`);
  localStorage.removeItem(`grid_config_${entity.object}`);
};
