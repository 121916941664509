import React, { useState, useEffect, useCallback, useMemo } from "react";
import { configApi } from "../../../../redux/actions/knackconfig";
import { KnackConfigs } from "../../../../redux/actions/knackApiTemplates";
import { checkForSpecialCases } from "../../../../utils/filterSelectConnectedField";
import {
  KnackAndFrontEndRelMainMenu,
  KnackAndFrontEndRelConfigMenu,
} from "../../../../utils/KnackAndFrontEndRel";
import { ConfigCampos } from "../../../../utils/knackKeys";
import { truncate } from "../../../../utils/textUtils";
import _ from "lodash";
import { dbApiCall } from "../../../../redux/actions/api";
import {
  GET_MULTIPLE_FILTERING_CONNECTED,
  METHODS,
} from "../../../../redux/actions/knack/types";
import removeHtmlTags from "../../../../utils/removeHTMLTags";
import validate from "./validate";
import { CircularProgress } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

const MAX_RESULTS = 500;

const useSelectConnectedField = ({
  fieldProps,
  recordValues,
  auth,
  object,
  filterName,
  customOptions,
  required,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [customHelper, setCustomHelper] = useState(null);
  const [isHelperLoading, setIsHelperLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState(null);

  const { getValues, trigger } = useFormContext();

  const {
    OBJECT,
    PARENT_FILTER_IN_FORM,
    PARENT_FILTER_IN_ENTITY,
    CONNECTION_FIELD_IDENTIFIER,
    FIELD_ID,
    TYPE_IDENTIFIER,
  } = ConfigCampos.fields;

  const loading = open && options.length === 0;

  const getFilters = useCallback(
    (fieldToGetLabel) => {
      if (!fieldToGetLabel) return;
      /** If you have to filter by any other input present in the form. Ex: filter Modulos by selected Curso */
      let filterByParam = undefined;

      /** The connected field in the form to add to the filter. Ex: field of Curso in the current form */
      const filterByFieldInForm = fieldToGetLabel[PARENT_FILTER_IN_FORM];

      /** Field in the entity of the current input to add to the filter. Ex: field of Curso in the object Modulo */
      const filterByFieldInEntity = fieldToGetLabel[PARENT_FILTER_IN_ENTITY];

      const fieldValueInForm = getValues(filterByFieldInForm);

      if (!!filterByFieldInForm) {
        if (!!fieldValueInForm)
          filterByParam = {
            field: filterByFieldInEntity,
            value: [fieldValueInForm.id],
          };
      }
      return filterByParam;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldProps.name]
  );

  const getRelatedView = (object) => {
    let entity = KnackAndFrontEndRelMainMenu.find(
      (item) => item.object === object
    );
    if (entity) return entity;
    const { management, configurations } = KnackAndFrontEndRelConfigMenu;
    entity =
      management.find((item) => item.object === object) ||
      configurations.find((item) => item.object === object);
    return entity;
  };

  const getParamsForApiCall = (config) => {
    let fieldToGetLabel = "";
    let filterByParam = "";
    const relatedView = getRelatedView(fieldProps?.relationship?.object);
    try {
      fieldToGetLabel =
        config?.data?.records?.find(
          (connectedField) => connectedField[OBJECT] === object
        ) || "";
      filterByParam = getFilters(fieldToGetLabel);

      return { fieldToGetLabel, relatedView, filterByParam };
    } catch (error) {
      console.log("error in getParamsForApiCall", error);
      return { fieldToGetLabel, relatedView, filterByParam };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const parseResults = ({ connectedFields, fieldToGetLabel }) => {
    let connectedFieldsOptions = connectedFields.data.records;
    const connectedFieldsOptionsConfig = [];

    let confirmConnectedFieldOptions = checkForSpecialCases(
      fieldToGetLabel[FIELD_ID] /* field_id in Knack s*/,
      connectedFieldsOptions,
      auth
    );

    if (!confirmConnectedFieldOptions.length)
      return connectedFieldsOptionsConfig;
    confirmConnectedFieldOptions.forEach((fieldOption) => {
      let identifierKey = "";

      /** Connection field identifier in Knack -> it's a name */
      const nameField =
        fieldOption[fieldToGetLabel[CONNECTION_FIELD_IDENTIFIER]];

      if (fieldToGetLabel[TYPE_IDENTIFIER] === "name") {
        identifierKey = `${nameField.first} ${
          nameField.middle ? nameField.middle : ""
        } ${nameField.last}`;
      } else identifierKey = nameField;

      connectedFieldsOptionsConfig.push({
        id: fieldOption.id,
        identifier: identifierKey,
      });
    });
    return connectedFieldsOptionsConfig;
  };

  const fetchConfig = useMemo(async () => {
    const url = KnackConfigs(
      "fieldsConnectedConfig-ObjectBased",
      ConfigCampos.object,
      fieldProps?.relationship?.object
    );
    return await configApi({ url });
  }, [fieldProps?.relationship?.object]);

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;
    if (!!customOptions && customOptions?.list?.length)
      return setOptions(customOptions.list);

    (async () => {
      try {
        setFetchingError(null);
        const config = await fetchConfig;
        const {
          fieldToGetLabel,
          relatedView: { scene, view },
          filterByParam,
        } = getParamsForApiCall(config);

        const connectedFields = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_MULTIPLE_FILTERING_CONNECTED,
          scene,
          view,
          id: searchValue,
          auth,
          urlParams: {
            connectedField: fieldToGetLabel[CONNECTION_FIELD_IDENTIFIER],
            params: {
              itemsPerPage: MAX_RESULTS,
              filterOperator: "contains",
              filterByParent: filterByParam,
            },
          },
        });

        let options = parseResults({ connectedFields, fieldToGetLabel });
        if (!options.length)
          options = [{ id: "123456789", identifier: "Sem resultados" }];

        if (active) {
          setOptions(options);
          setOpen(true);
        }
      } catch (error) {
        setOpen(false);
        setFetchingError({ message: "Ups! A chamada falhou. Tente novamente" });
        console.log("error in fetch record in db", error);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CONNECTION_FIELD_IDENTIFIER,
    FIELD_ID,
    OBJECT,
    TYPE_IDENTIFIER,
    auth,
    customOptions,
    fieldProps.relationship.object,
    getFilters,
    loading,
    object,
    searchValue,
    options.length,
  ]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  useEffect(() => {
    if (!isHelperLoading) return setCustomHelper(null);
    return setCustomHelper(
      <CircularProgress
        color="secondary"
        size={30}
        style={{ marginTop: "1.4rem" }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHelperLoading]);

  const getDefaultValue = () => {
    if (isMultiple) return recordValues;
    return {
      id: recordValues?.length ? recordValues[0]?.id : "",
      identifier: recordValues?.length ? recordValues[0]?.identifier : "",
    };
  };

  const isMultiple =
    fieldProps?.relationship?.has === "many" ||
    (!!customOptions && customOptions?.hasMultiple);

  const handleOnInputChange = _.debounce((_, value, reason) => {
    setInputValue(value);
    if (value.length >= 3) {
      if (reason !== 'reset') {
        setOpen(false)
        setOpen(true)
      }
      return setSearchValue(value);
    } else {
      setSearchValue("");
      return setOpen(false);
    }
  }, 1000);

  const getValue = (value) => {
    return !!value?.identifier
      ? value.identifier
      : isMultiple && !value?.length
      ? []
      : isMultiple
      ? value
      : "";
  };

  const getOptionSelected = (option, value) => {
    if (isMultiple) return option.identifier === value.identifier;
    const stringValue = value.toString();
    return !stringValue.trim()
      ? options[0].id === option.id
      : option.identifier === stringValue;
  };

  const getOptionLabel = (option, value) => {
    if (!option || option === null) return "";
    const label =
      option.identifier ||
      (!!value && typeof value === "object"
        ? value.identifier?.toString()
        : !!value
        ? value.toString()
        : "") ||
      "";
    return customOptions?.truncate ? truncate(label, 40) : label;
  };

  const labelForInput = !!filterName
    ? `Selecione ${fieldProps.name}`
    : fieldProps.name;

  const getHelper = (error) =>
    customHelper ||
    error?.message ||
    removeHtmlTags(fieldProps.meta?.description);

  const requiredValue =
    required || (!!filterName ? false : fieldProps.required);

  const rules = {
    validate: validate({
      fieldKey: fieldProps.key,
      getValues,
      setLoading: setIsHelperLoading,
      trigger,
      requiredValue,
      isMultiple,
    }),
  };

  return {
    open,
    setOpen,
    options,
    loading,
    isMultiple,
    getDefaultValue,
    requiredValue,
    getValue,
    getOptionSelected,
    getOptionLabel,
    labelForInput,
    handleOnInputChange,
    inputValue,
    getHelper,
    rules,
    isHelperLoading,
    fetchingError,
  };
};

export default useSelectConnectedField;
