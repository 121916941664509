import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

import { setFilters } from "../../../redux/actions/filters";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import TalentoButton from "../TalentoButton";

const SearchFilter = ({ setFilters }) => {
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const onSubmit = (formData) => {
    setFilters({
      isActive: true,
      searchFilter: formData.searchFilter,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
        <Controller
          name={"searchFilter"}
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              size="small"
              id={field.name}
              label="Pesquise..."
            />
          )}
        />
        <TalentoButton
          type="submit"
          style={{ display: "none" }}
        ></TalentoButton>
      </form>
    </FormProvider>
  );
};

export default connect(null, { setFilters })(SearchFilter);
