import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect } from "react";
import { userHasPermissionsToViewTab } from "../../../utils/userPermissions";
import { fetchRecord } from "../../../redux/actions/api/dataApiCalls";
import { changeTab, clearTab } from "../../../redux/actions/layout";
import { connect } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import useStyles from "../GroupsView/style";

const TabsSelection = ({
  isTabDisabled,
  id,
  scene,
  view,
  // COMING FROM REDUX
  tabs,
  tab: tabFromRedux,
  auth,
  changeTab,
  fetchRecord,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const location = useLocation();
  const currentTab = location.search.split("?active_tab=")?.[1];

  useEffect(() => {
    if (currentTab) {
      const currentSelectedTab = tabs.find(
        (el) => el.params.slug === currentTab
      );
      if (currentSelectedTab?.position !== tabFromRedux) {
        changeTab(Number(currentSelectedTab?.position || 0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, tabs]);

  const canUserSeeTab = (tab) =>
    !userHasPermissionsToViewTab(
      tab?.params?.userPermissions?.tabRolesPermissions,
      auth.profile_keys
    );

  const handleChange = async (_, newValue) => {
    changeTab(Number(newValue));
    if (newValue === 0) {
      await fetchRecord(id, scene, view, auth);
      history.replace({
        pathname,
        search: "",
      });
    } else {
      const tab = tabs.find((el) => el.position === newValue);
      if (!location.search.includes(tab?.params?.slug))
        history.replace({
          pathname,
          search: `?active_tab=${tab?.params?.slug}`,
        });
    }
  };

  return (
    <Grid item xs={12} style={{ marginTop: 5 }}>
      <Paper elevation={3} className={classes.paper_header}>
        <Tabs
          value={tabFromRedux || 0}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Detalhes" />
          {tabs?.map((tab) =>
            canUserSeeTab(tab) && !isTabDisabled ? (
              <Tab
                key={tab.position}
                label={tab.title}
                disabled={isTabDisabled}
              />
            ) : (
              <Tab key={tab.position} label="" style={{ display: "none" }} />
            )
          )}
        </Tabs>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  tab: state.layout.tab,
  tabs: state.layout.tabs,
  auth: state.auth,
  record: state.record,
});

export default connect(mapStateToProps, {
  fetchRecord,
  changeTab,
  clearTab,
})(TabsSelection);
