import { prepareData } from "../knack/beforeSubmit";
import { METHODS } from "../knack/types";
import { KnackUrl } from "../knack/url";
import { getAuthCache } from "../knackconfig";
import { request } from "./apiHelpers";
import checkForRateLimit from "./apiHelpers/checkForRateLimit";
import checkInvalidToken from "./apiHelpers/checkInvalidToken";
import {
  validateAction,
  validateActionAndType,
} from "./apiHelpers/validateAction";

export const apiCall = async ({
  action,
  useProxy = false,
  url,
  data,
  token,
  shouldRetry = true,
}) => {
  if (!validateAction(action)) throw new Error("MALFORMED API CALL");
  const isRetryDefault = !shouldRetry || false; // If we don't want to repeat the call, isRetry should be true 

  const callback = async ({ isRetry = isRetryDefault }) =>
    await request({
      useProxy,
      token,
    })({ token, isRetry })[action]({ url, data });

  try {
    const response = await callback({});
    return response;
  } catch (error) {
    const isExpiredToken = checkInvalidToken({ error });
    if (isExpiredToken || !shouldRetry) throw error;
    const response = await checkForRateLimit({
      error,
      retryFunction: () => callback({ isRetry: true }),
    });
    if (response?.status < 400) return response;
    throw error;
  }
};

export const dbApiCall = async ({
  action,
  type,
  auth = getAuthCache(),
  scene,
  view,
  formData,
  id,
  urlParams,
  useProxy = true,
  forceRetry = false,
}) => {
  if (!validateActionAndType({ action, type }))
    throw new Error("MALFORMED URL");

  const url = KnackUrl({ type, scene, view, id, ...urlParams });
  const data = !!formData && prepareData(formData);
  const token = auth.databaseToken;
  const shouldRetry = action === METHODS.GET.value || forceRetry;

  return await apiCall({
    action,
    auth,
    useProxy,
    url,
    data,
    token,
    shouldRetry,
  });
};
