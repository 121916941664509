import React from "react";
import ReactDOM from "react-dom";
import "./assets/style/index.css";
import * as serviceWorker from "./serviceWorker";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  ThemeProvider as NewThemeProvider,
  createTheme as newCreateTheme,
} from "@mui/material";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import pt from "date-fns/locale/pt";

import App from "./App";
import { store, persistor } from "./redux/storeConfig";

const newTheme = newCreateTheme({
  palette: {
    primary: {
      main: "#148FF6",
    },
    secondary: {
      main: "#F2AC57",
      lighter: "#ffe9ce",
      contrastText: "#fff",
    },
    success: {
      main: "#14A48B",
      contrastText: "#fff",
    },
    orange: {
      main: "#FF694D",
      lighter: "#fcdad2",
      lightest: "",
    },
    warning: {
      main: "#F2AC57",
    },
    error: {
      main: "#FF694D",
    },
    dark: {
      main: "#23303A",
    },
    text: {
      primary: "#23303A",
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: [
      "Archivo",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#148FF6",
    },
    secondary: {
      main: "#F2AC57",
      lighter: "#ffe9ce",
      contrastText: "#fff",
    },
    success: {
      main: "#14A48B",
      contrastText: "#fff",
    },
    orange: {
      main: "#FF694D",
      lighter: "#fcdad2",
      lightest: "",
    },
    warning: {
      main: "#F2AC57",
    },
    error: {
      main: "#FF694D",
    },
    dark: {
      main: "#23303A",
    },
    text: {
      primary: "#23303A",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <NewThemeProvider theme={newTheme}>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}> */}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      {/* </LocalizationProvider> */}
    </NewThemeProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
