import React /*, { useState }*/ from "react";
import { connect } from "react-redux";

const ErrorBoundary = ({ error, children }) => {
  if (!error) return children;
  return <div style={{ color: "red" }}>Aconteceu um erro: {error?.message ||error}</div>;
};

// class ErrorBoundary extends React.Component {
//   state = { error: null };

//   static getDerivedStateFromError(error) {
//     return { error };
//   }

//   render() {
//     const { error } = this.state;
//     if (error) {
//       return (
//         <div style={{ color: "red" }}>Aconteceu um erro: {error.message}</div>
//       );
//     }
//     return this.props.children;
//   }
// }

const mapStateToProps = (state) => ({
  error: state.errors,
});

export default connect(mapStateToProps, {})(ErrorBoundary);
