import { METHODS } from "../../knack/types";

export const validateAction = (action) =>
  Object.values(METHODS)
    .map((el) => el.value)
    .includes(action);

export const validateActionAndType = ({ action, type }) => {
  const entry = Object.entries(METHODS).find(
    ([, value]) => value.value === action
  );
  if (!entry) return false;
  const [, value] = entry;
  return value.options.includes(type);
};
