import { Contrato, Profiles } from "./knackKeys";

const EXCEPTION_PROFILES = [
  Profiles.ORIENTADOR_PEDAGOGICO,
  Profiles.AGENTE,
  Profiles.FORMADOR,
];

/* eslint-disable no-mixed-operators */
export const permissionsExceptions = (
  profile_keys,
  object,
  userPermissionsField
) => {
  const matchesExceptionProfile = profile_keys.find((userProfile) =>
    EXCEPTION_PROFILES.find((profile) => userProfile === profile)
  );
  if (matchesExceptionProfile && object === Contrato.object) return "";
  return userPermissionsField;
};
