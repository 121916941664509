import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";
import { connect } from "react-redux";
import {
  createPartnershipProtocol,
  createPartnershipProtocolWithDiscount,
} from "../../../redux/actions/documents/partnerships";

// SHARED CONSTANTS

const object = "object_13";
const objectLabel = "Empresa";
const objectLabelSingular = "Empresa";
const objectLabelPlural = "Empresas";
const listUrl = "/empresas";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [],
};

let DetailPartner = (props) => {
  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      id={props.match.params.id}
      scene={"scene_106"}
      view={"view_143"}
      sceneEdit={"scene_108"}
      viewEdit={"view_146"}
      deletable={true}
      userPermissions={userPermissions}
      tabs={[
        {
          title: "Pessoas de contacto",
          position: 1,
          params: {
            object: "object_14",
            objectLabelPlural: "Pessoas",
            objectLabelSingular: "Pessoa",
            slug: "pessoas",
            scene: "scene_226",
            view: "view_313",
            userPermissions: userPermissions,
            extraConfigs: {
              showCreateButton: "yes",
            },
            filterByConnectedField: {
              connected_field: "field_156",
              id: props.match.params.id,
              identifierNameField: "field_117",
            },
          },
        },
        {
          title: "Estágios realizados",
          position: 2,
          params: {
            object: "object_18",
            objectLabelPlural: "Estagios",
            objectLabelSingular: "Estagio",
            slug: "estagios",
            scene: "scene_111",
            view: "view_152",
            pinned: ["Nome do Aluno", "Abrir"],
            userPermissions: userPermissions,
            extraConfigs: {
              showCreateButton: "no",
            },
            filterByConnectedField: {
              connected_field: "field_168",
              id: props.match.params.id,
              identifierNameField: "field_117",
            },
          },
        },
      ]}
      documents={[
        {
          type: "partnership_protocol",
          title: "Criar Protocolo Parceria",
          fileName: "Protocolo_Parceria.pdf",
          documentFunction: props.createPartnershipProtocol,
        },
        {
          type: "partnership_protocol_with_discount",
          title: "Criar Protocolo Parceria com Desconto",
          fileName: "Protocolo_Parceria.pdf",
          documentFunction: props.createPartnershipProtocolWithDiscount,
        },
      ]}
    />
  );
};

const mapDispatchToProps = {
  createPartnershipProtocol,
  createPartnershipProtocolWithDiscount,
};
DetailPartner = connect(null, mapDispatchToProps)(DetailPartner);

const FormAddPartner = (props) => {
  return (
    <AddForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      scene={"scene_106"}
      view={"view_143"}
      userPermissions={userPermissions}
    />
  );
};

const FormEditPartner = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_108"}
      view={"view_146"}
      userPermissions={userPermissions}
    />
  );
};

const ListPartners = (props) => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={"scene_104"}
      view={"view_142"}
      userPermissions={userPermissions}
      pinned={["Designação da empresa", "Abrir"]}
      extraConfigs={{
        filters: {
          dropDown: [
            { label: "Concluída", value: "Concluída,field_124" },
            { label: "Sem interesse", value: "Sem interesse,field_124" },
            { label: "Interesse futuro", value: "Interesse futuro,field_124" },
          ],
        },
      }}
    />
  );
};

export { DetailPartner, FormAddPartner, FormEditPartner, ListPartners };
