import { getClassesForStudentsWebhook } from "../../../utils/webhooks";
import { errorHandlers } from "./dataApiCalls";
import callWebhook from "./callWebhook";
import { dbApiCall } from ".";
import { GET_ONE, METHODS } from "../knack/types";
import notify from "../helper";
import { ERROR } from "../notifications";

export const getClassesForStudent =
  ({ auth, profiles }) =>
  async (dispatch) => {
    const isUserProfileMatch = profiles.find((callProfile) =>
      auth.profile_keys.find((userProfiles) => callProfile === userProfiles)
    );
    if (isUserProfileMatch) {
      try {
        const { data } = await callWebhook({
          webhook: getClassesForStudentsWebhook,
          body: { studentId: auth.userId },
        });
        if (data === "Accepted")
          throw new Error("Erro ao recuperar as turmas, tente de novo");
        return await Promise.all(
          data?.map(
            async (element) =>
              await getRecord({
                id: element.id,
                scene: "scene_43",
                view: "view_53",
                auth,
                dispatch,
              })
          )
        );
      } catch (error) {
        console.log("error in getClassesForStudent", error);
        notify({
          dispatch,
          type: ERROR,
          message: `Erro ao recuperar as turmas, tente novamente. Detalhes: ${
            error?.response?.data?.message ||
            error?.response?.data ||
            error.message
          }`,
        });
      }
    } else return;
  };

const getRecord = async ({ auth, id, scene, view, dispatch }) => {
  try {
    const response = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene,
      view,
      id,
      auth,
    });
    return response.data;
  } catch (error) {
    console.log("error in getRecord", error);
    errorHandlers({ dispatch, error });
  }
};
