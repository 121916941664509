import {FORM_CONFIG, CLEAR_FORM_CONFIG } from '../constants/knackConfig';


export default function formConfigReducer(state = null, action) {
    switch (action.type) {
        case CLEAR_FORM_CONFIG:
            return null
        case FORM_CONFIG:
            return action.payload;
        default:
            return state;
    }
}