import { combineReducers } from "redux";
import recordsReducer from "./recordsReducer";
import layoutReducer from "./layoutReducer";
import formConfigReducer from "./formConfigReducer";
import formsReducer from "./formsReducer";
import formSubmissionReducer from "./formSubmissionReducer";
import recordReducer from "./recordReducer";
import gridReducer from "./gridReducer";
import connectedFieldsReducer from "./connectedFieldsReducer";
import authReducer from "./authReducer";
import errorsReducer from "./errorsReducer";
import documentsReducer from "./documentsReducer";
import customPopUpReducer from "./popUps/customPopUpReducer";
import editPopUpReducer from "./popUps/editPopUpReducer";
import addPopUpReducer from "./popUps/addPopUpReducer";
import richPopUpReducer from "./popUps/richPopUpReducer";
import customActionsReducer from "./customActionsReducer";
import detailHeaderConfigReducer from "./detailHeaderConfigReducer";
import detailHeaderFieldsReducer from "./detailHeaderFieldsReducer";
import notificationsReducer from "./notificationsReducer";
import fileUploadReducer from "./fileUploadReducer";
import filtersReducer from "./filtersReducer";
import filterPopUpReducer from "./popUps/filterPopUpReducer";
import emailPopUpReducer from "./popUps/emailPopUpReducer";
import menuReducer from "./menuReducer";
import createRecordCustomFlowsReducer from "./createRecordCustomFlowsReducer";
import customValidationReducer from "./customValidation";
import errorMessageReducer from "./errorMessageReducer";

export default combineReducers({
  records: recordsReducer,
  record: recordReducer,
  layout: layoutReducer,
  formConfig: formConfigReducer,
  gridConfig: gridReducer,
  detailHeaderConfig: detailHeaderConfigReducer,
  detailHeaderFields: detailHeaderFieldsReducer,
  forms: formsReducer,
  formSubmission: formSubmissionReducer,
  connectedFields: connectedFieldsReducer,
  auth: authReducer,
  errors: errorsReducer,
  errorMessage: errorMessageReducer,
  documents: documentsReducer,
  customPopUp: customPopUpReducer,
  editPopUp: editPopUpReducer,
  addPopUp: addPopUpReducer,
  richPopUp: richPopUpReducer,
  filterPopUp: filterPopUpReducer,
  actions: customActionsReducer,
  notifications: notificationsReducer,
  fileUpload: fileUploadReducer,
  filters: filtersReducer,
  emailPopUpEditor: emailPopUpReducer,
  menu: menuReducer,
  createRecordCustomFlow: createRecordCustomFlowsReducer,
  customValidation: customValidationReducer,
});
