import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";

import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { editRecord } from "../../../redux/actions/api/dataApiCalls";
import TalentoButton from "../TalentoButton";

const useStyle = makeStyles((theme) => ({
  inputField: {
    minWidth: "320px",
    width: "100%",
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
}));

const PasswordField = (props) => {
  const classes = useStyle();
  const { recordId, field, errors, disabled, scene, view, auth } = props;

  let inputError = false;
  let errorMessage = false;

  const [inputValue, setInputValue] = useState({
    name: field,
    value: "",
  });
  const [isButtonSaving, setIsButtonSaving] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = !!anchorEl;
  const popOverId = open ? "simple-popover" : undefined;

  for (const property in errors) {
    if (property === field.formName) {
      inputError = true;
      switch (field.required) {
        case true:
          errorMessage = "Este campo é obrigatório";
          break;
        default:
          errorMessage = null;
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ name, value });
  };

  const editPassword = async () => {
    setIsButtonSaving(true);
    const formData = {
      [inputValue.name]: inputValue.value,
    };

    const result = await props.editRecord(
      formData,
      recordId,
      scene,
      view,
      auth
    );
    if (result === "error") {
      setIsButtonSaving(false);
    }
  };

  return (
    <div>
      <TalentoButton
        aria-describedby={popOverId}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Alterar Password
      </TalentoButton>
      <Popover
        style={{ marginTop: "5px" }}
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.inputField}>
          <TextField
            error={inputError}
            helperText={errorMessage}
            required={field.required}
            name={field}
            label={field.name}
            value={inputValue.value}
            variant="outlined"
            size="small"
            id={field.name}
            type="password"
            onChange={handleChange}
            disabled={disabled}
          />

          <div>
            {isButtonSaving ? (
              <CircularProgress
                color="secondary"
                size={30}
                style={{ marginLeft: "25px" }}
              />
            ) : (
              <TalentoButton
                onClick={editPassword}
                disabled={isButtonSaving}
                variant="contained"
                color="primary"
                style={{ marginLeft: "5px" }}
              >
                Alterar
              </TalentoButton>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { editRecord })(PasswordField);
