export const GET_MULTIPLE = "getMultiple-ViewBased";
export const GET_ONE = "getOne-ViewBased";
export const CREATE = "createOne-ViewBased";
export const GET_MULTIPLE_BY_OBJECT = "getMultiple-ObjectBased";
export const UPDATE_ONE = "updateOne-ViewBased";
export const GET_MULTIPLE_FILTERING_CONNECTED =
  "getMultiple-ViewBased-Filtered-Connected";

export const METHODS = {
  GET: {
    value: "get",
    options: [
      GET_MULTIPLE,
      GET_ONE,
      GET_MULTIPLE_BY_OBJECT,
      GET_MULTIPLE_FILTERING_CONNECTED,
    ],
  },
  POST: {
    value: "post",
    options: [CREATE],
  },
  PUT: {
    value: "put",
    options: [UPDATE_ONE],
  },
  DELETE: {
    value: "delete",
    options: [GET_ONE],
  },
};
