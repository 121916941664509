import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from "react-hook-form";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { requiredErrorMessage } from '../../../utils/errors';

const useStyle = makeStyles((theme) => ({
    select: {
        width: "100%"
    }
}))

const SelectBoxFieldUserRoles = (props) => {
    const { field: fieldProps, recordValues, control, disabled } = props;
    const classes = useStyle();

    return (
        <TableRow>
            <TableCell>
                <Controller
                    defaultValue={recordValues || ""}
                    name={fieldProps.key}
                    control={control}
                    rules={{ required: { value: fieldProps.required, message: requiredErrorMessage } }}
                    render={({ field, fieldState: { error } }) =>
                        <FormControl variant="outlined" className={classes.select} size="small" error={!!error} required={fieldProps.required}>
                            <InputLabel>{fieldProps.name}</InputLabel>
                            <Select
                                {...field}
                                error={!!error}
                                multiple={true}
                                label={fieldProps.name}
                                disabled={disabled}
                            >
                                <MenuItem value=""></MenuItem>
                                {fieldProps.format?.options?.map((option) =>
                                    <MenuItem key={option} value={option.knackRole}>{option.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }
                />
            </TableCell>
        </TableRow>
    )
}


export default SelectBoxFieldUserRoles;

