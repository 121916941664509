export const FETCH_RECORDS = 'FETCH_RECORDS';
export const FETCH_RECORD = 'FETCH_RECORD';
export const FETCH_FILTERED_RECORDS_BY_CONNECTION = 'FETCH_FILTERED_RECORDS_BY_CONNECTION';
export const CREATE_RECORD = 'CREATE_RECORD';
export const EDIT_RECORD = 'EDIT_RECORD';
export const ADD_CONNECTED_RECORD = 'ADD_CONNECTED_RECORD';
export const CLEAN_RECORD = 'CLEAN_RECORD';
export const CLEAN_RECORDS = 'CLEAN_RECORDS';
export const DETAIL_HEADER_FIELDS = 'DETAIL_HEADER_FIELDS';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_UPLOADED_WITH_FORM = 'FILE_UPLOADED_WITH_FORM';
