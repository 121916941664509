import {
  LOGIN_SUCCESS,
  LOG_OUT,
  UPDATE_PROFILE_PICTURE,
} from "../constants/auth";

const INITIAL_STATE = {
  isLoggedIn: null,
  profile_keys: null,
  userId: null,
  userName: "",
  userEmail: "",
  userPicture: "",
  profileId: "",
  token: null,
  databaseToken: null,
};

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        databaseToken: action.payload.databaseToken,
        profile_keys: action.payload.profile_keys,
        userId: action.payload.userId, // this is the userId corresponing to knack "contas" (top level of users)
        userName: action.payload.userName,
        userEmail: action.payload.userEmail,
        userPicture: action.payload.userPicture,
        profileId: action.payload.profileId, // this is the profileId corresponding to the specific profile, for example "formador",
        token: action.payload.token,
      };
    case UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        userPicture: action.payload,
      };
    case LOG_OUT:
      return {
        ...state,
        isLoggedIn: null,
        databaseToken: null,
        profile_keys: null,
        userId: null,
        userName: "",
        userEmail: "",
        userPicture: "",
        profileId: "",
        token: null,
      };
    default:
      return state;
  }
}
