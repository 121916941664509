import React from "react";

const ErrorIcon = ({ className }) => {
  return (
    <svg
      id="Component_41_1"
      data-name="Component 41 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="173.51"
      height="123.935"
      className={className}
      viewBox="0 0 173.51 123.935"
    >
      <path
        id="Path_104551"
        data-name="Path 104551"
        d="M87.469,99.124l-15.948,10.3-57.63,4.842,6.009,52.95,55.5-10.97Z"
        transform="translate(-8.402 -68.413)"
        fill="#f2ac57"
      />
      <path
        id="Path_102483"
        data-name="Path 102483"
        d="M346.25,103.145c3.784-6.439,11.742-8.764,13.63-13.168,1.122-2.7-.194-5.846-3.395-7.2a13.713,13.713,0,0,0-7.313-.875l-.09-4.153a16.127,16.127,0,0,1,9.837,1.263,11.7,11.7,0,0,1,5.422,4.628c1.893,3.09,1.3,7.278-1.566,10.33-3.294,3.5-9.06,5.618-14.391,10.074Zm-2.85,9.779a2.8,2.8,0,0,1-1.585-1.623,2.874,2.874,0,0,1,.034-2.253,2.991,2.991,0,0,1,1.628-1.622,2.8,2.8,0,0,1,2.284-.01,2.691,2.691,0,0,1,1.542,1.626,3,3,0,0,1-.066,2.282,2.937,2.937,0,0,1-1.6,1.593,2.749,2.749,0,0,1-2.242.007"
        transform="translate(-206.693 -55.25)"
        fill="#364a59"
      />
      <path
        id="Path_102484"
        data-name="Path 102484"
        d="M68.4,53.043c-1.037-4.976,1.931-9.753.867-12.84a3.82,3.82,0,0,0-5.025-2.114A7.918,7.918,0,0,0,62.349,39a7.791,7.791,0,0,0-2.221,1.954l-1.951-2.065a10.921,10.921,0,0,1,5.567-3.807,8.047,8.047,0,0,1,4.873-.1,5.884,5.884,0,0,1,3.947,5.945c-.063,3.265-2.015,6.945-2.673,11.616Zm3.043,6.247a1.929,1.929,0,0,1-1.548-.107,1.975,1.975,0,0,1-1.017-1.158,2.018,2.018,0,0,1,.081-1.559,1.892,1.892,0,0,1,1.154-1.038,1.856,1.856,0,0,1,1.528.127,2.062,2.062,0,0,1,1.013,1.187,1.98,1.98,0,0,1-.077,1.53,1.855,1.855,0,0,1-1.133,1.017"
        transform="translate(-35.202 -29.21)"
        fill="#364a59"
      />
      <path
        id="Path_102485"
        data-name="Path 102485"
        d="M174.684,112.314a53.484,53.484,0,0,1,3.751-14.169A88.572,88.572,0,0,1,184.759,85.5q3.445-5.628,6.8-10.909a72.475,72.475,0,0,0,5.583-10.52,24.892,24.892,0,0,0,2.224-9.654q-.087-7.619-4.841-12.035t-13.215-4.415a40.642,40.642,0,0,0-10.467,1.342,44.478,44.478,0,0,0-7.72,2.641q-2.662,1.3-6.062,3.377l-6.044.321,4.586-2.925-4.649-11.769a58.327,58.327,0,0,1,14.829-6.883,62.992,62.992,0,0,1,17.969-2.294,39.841,39.841,0,0,1,12.081,1.862,38.6,38.6,0,0,1,10.816,5.368,25.739,25.739,0,0,1,7.938,9.308A27.965,27.965,0,0,1,217.6,51.391a33.5,33.5,0,0,1-2.094,11.6,54.29,54.29,0,0,1-5.757,11.255q-3.664,5.541-7.72,10.823t-9.072,12.6a108.543,108.543,0,0,0-8.592,14.645Zm5.537,32.617a10.691,10.691,0,0,1-7.832-3.18,10.29,10.29,0,0,1-3.2-7.562,11.019,11.019,0,0,1,11.036-10.954,10.267,10.267,0,0,1,7.69,3.251,10.728,10.728,0,0,1,3.133,7.7,10.777,10.777,0,0,1-10.822,10.742"
        transform="translate(-91.341 -21.388)"
        fill="#364a59"
      />
      <path
        id="Path_102486"
        data-name="Path 102486"
        d="M164.209,114.819c3.067-24.1,23.94-41.151,23.94-56.747-.109-9.513-8.1-17.282-19.433-17.282A37.933,37.933,0,0,0,158.755,42c-7.242,1.921-8.831,2.825-13.092,5.428L140.055,35.09c9.144-5.868,17.811-8.673,31.119-8.673a38.393,38.393,0,0,1,22.205,7.011c9.65,6.868,13.27,20.171,8.586,32.956-5.375,14.673-19.942,28.32-30.2,48.434Zm4.025,32.931a9.2,9.2,0,0,1-6.837-2.859,9.39,9.39,0,0,1-2.8-6.8,9.679,9.679,0,0,1,2.8-6.926,9.1,9.1,0,0,1,6.837-2.923,8.838,8.838,0,0,1,6.713,2.923,9.8,9.8,0,0,1,2.735,6.926,9.5,9.5,0,0,1-2.735,6.8,8.929,8.929,0,0,1-6.713,2.859"
        transform="translate(-84.744 -24.207)"
        fill="#ff694d"
      />
      <path
        id="Path_102487"
        data-name="Path 102487"
        d="M164.422,144.715a11.12,11.12,0,0,1-8.111-3.3,10.71,10.71,0,0,1-3.32-7.839,11.414,11.414,0,0,1,11.431-11.346,10.694,10.694,0,0,1,7.975,3.372,11.15,11.15,0,0,1,3.242,7.974,11.17,11.17,0,0,1-11.217,11.134m0-21.7a10.626,10.626,0,0,0-10.641,10.562,9.955,9.955,0,0,0,3.089,7.285,10.352,10.352,0,0,0,7.552,3.066,10.384,10.384,0,0,0,10.427-10.35,10.394,10.394,0,0,0-3.023-7.432,9.928,9.928,0,0,0-7.4-3.13m4.146-11.237h-9.683a.4.4,0,0,1-.3-.133.389.389,0,0,1-.1-.308,55.244,55.244,0,0,1,3.777-13.952,89.412,89.412,0,0,1,6.353-12.7c2.285-3.733,4.575-7.405,6.807-10.915a72.3,72.3,0,0,0,5.553-10.463,24.616,24.616,0,0,0,2.193-9.5c-.056-4.94-1.643-8.894-4.715-11.749s-7.436-4.31-12.946-4.31a40.434,40.434,0,0,0-10.365,1.329A44.335,44.335,0,0,0,147.5,41.7c-1.751.854-3.779,1.984-6.03,3.359a.4.4,0,0,1-.57-.181L134.8,30.5a.39.39,0,0,1,.149-.481,58.979,58.979,0,0,1,14.93-6.929,63.673,63.673,0,0,1,18.083-2.311,40.424,40.424,0,0,1,12.2,1.88,39.184,39.184,0,0,1,10.927,5.423,26.25,26.25,0,0,1,8.058,9.447A28.48,28.48,0,0,1,202.2,50.783a34.042,34.042,0,0,1-2.117,11.736,54.925,54.925,0,0,1-5.8,11.337c-2.437,3.685-5.04,7.334-7.736,10.845s-5.737,7.735-9.059,12.58a129.44,129.44,0,0,0-8.566,14.286.4.4,0,0,1-.352.215M159.336,111h8.99a130.508,130.508,0,0,1,8.508-14.158c3.33-4.858,6.386-9.1,9.084-12.615,2.686-3.5,5.277-7.13,7.7-10.8a54.166,54.166,0,0,0,5.716-11.174,33.269,33.269,0,0,0,2.069-11.468,27.707,27.707,0,0,0-2.965-12.894,25.465,25.465,0,0,0-7.817-9.169,38.379,38.379,0,0,0-10.706-5.312,39.628,39.628,0,0,0-11.961-1.843A62.883,62.883,0,0,0,150.1,23.842a58.07,58.07,0,0,0-14.45,6.659l5.8,13.649c2.116-1.281,4.033-2.342,5.7-3.156a45.025,45.025,0,0,1,7.792-2.668,41.214,41.214,0,0,1,10.569-1.356c5.717,0,10.254,1.521,13.485,4.521s4.907,7.15,4.966,12.317a25.388,25.388,0,0,1-2.255,9.811A73.15,73.15,0,0,1,176.1,74.2c-2.229,3.506-4.517,7.175-6.8,10.9A88.6,88.6,0,0,0,163,97.684,54.711,54.711,0,0,0,159.336,111"
        transform="translate(-81.54 -20.78)"
        fill="#364a59"
      />
      <path
        id="Path_102488"
        data-name="Path 102488"
        d="M272.456,221.781c1.734-13,13.6-28.743,34.147-31.8,4.4-.655,10.708-.265,15.689,2.586,8.513,4.873,12.459,18.37,5.972,26.784-3.953,5.127-11.131,6.753-17.615,6.248-11.4-.888-20.6-6.891-38.194-3.82"
        transform="translate(-164.858 -123.49)"
        fill="#148ef6"
      />
      <path
        id="Path_102496"
        data-name="Path 102496"
        d="M308.2,222.921q-1.206,0-2.449-.1a73.391,73.391,0,0,1-11.927-2.235c-7.97-1.953-16.212-3.972-28.517-1.825a.4.4,0,0,1-.342-.1.389.389,0,0,1-.118-.334c1.937-14.525,15.124-30.9,36.58-34.089,4.616-.686,11.483-.313,16.91,2.793,4.756,2.722,8.321,7.952,9.537,13.99a18.278,18.278,0,0,1-3.081,15.019c-3.383,4.388-9.473,6.881-16.593,6.881m-32.742-5.846c7.314,0,13.185,1.438,18.555,2.754a72.767,72.767,0,0,0,11.8,2.215c7.891.618,14.752-1.808,18.353-6.479a17.513,17.513,0,0,0,2.934-14.389c-1.172-5.82-4.6-10.853-9.157-13.464-5.251-3.005-11.914-3.364-16.4-2.7C280.732,188.107,267.847,203.8,265.7,217.9a59.3,59.3,0,0,1,9.752-.827"
        transform="translate(-160.246 -119.985)"
        fill="#364a59"
      />
      <path
        id="Path_102497"
        data-name="Path 102497"
        d="M325.725,235.537a4.072,4.072,0,0,1-3.221-1.444c-.6-.753-1.723-2.889.853-6.449,2.4-3.322,6.193-4.73,10.475-5.305q.082-.757.109-1.521c.043-1.222-.039-5.307-2.889-6.551-3.748-1.635-10.254,6.818-12.444,10.214a.4.4,0,0,1-.488.149.391.391,0,0,1-.227-.455c.224-.892.41-2.515-.044-3.074-.44-.543-1.51.016-2.014.387a36.9,36.9,0,0,0-5.06,4.492l-.579-.534a37.691,37.691,0,0,1,5.168-4.588c.954-.7,2.337-1.186,3.1-.25a3.123,3.123,0,0,1,.448,2.032c2.33-3.279,8.314-10.9,12.458-9.09,3.3,1.443,3.408,5.949,3.361,7.3q-.024.7-.094,1.4a63.6,63.6,0,0,1,7.4-.268l-.007.784a61.355,61.355,0,0,0-7.485.285,21.954,21.954,0,0,1-2.795,8.083,8.881,8.881,0,0,1-4.081,4.023,5.106,5.106,0,0,1-1.939.389m8-12.391c-4.008.568-7.526,1.907-9.729,4.955-1.592,2.2-1.912,4.208-.876,5.5a3.69,3.69,0,0,0,4.24.818,8.149,8.149,0,0,0,3.7-3.692,21.182,21.182,0,0,0,2.665-7.586"
        transform="translate(-187.691 -137.817)"
        fill="#364a59"
      />
      <path
        id="Path_102501"
        data-name="Path 102501"
        d="M6.911,163.367a.4.4,0,0,1-.233-.076.391.391,0,0,1-.159-.271L0,107.706a.391.391,0,0,1,.087-.294.4.4,0,0,1,.274-.142l62.41-5.051L79.988,91.507a.4.4,0,0,1,.437.011.391.391,0,0,1,.158.4L67.491,151.6a.4.4,0,0,1-.312.3L6.986,163.36a.433.433,0,0,1-.075.007M.834,108.018l6.42,54.493,59.521-11.331L79.612,92.666l-16.5,10.268a.4.4,0,0,1-.178.059Z"
        transform="translate(0.001 -63.745)"
        fill="#364a59"
      />
      <path
        id="Path_102503"
        data-name="Path 102503"
        d="M46.574,195.855c1.125-2.55,2.824-5.157,5.4-6.454a6.553,6.553,0,0,1,4.2-.575,15.8,15.8,0,0,1,4.635,1.94c2.715,1.516,5.964,3.4,9.193,2.387,3-.946,4.136-4.2,6.639-5.836a8.932,8.932,0,0,1,4.847-1.456,14.682,14.682,0,0,1,5.634,1.532.425.425,0,0,0,.56-.146.407.407,0,0,0-.147-.555,18.024,18.024,0,0,0-5.192-1.6,9.6,9.6,0,0,0-4.6.731,9.983,9.983,0,0,0-3.7,2.761,15.425,15.425,0,0,1-3.131,3.261c-3,1.9-6.629-.056-9.324-1.573a19.8,19.8,0,0,0-4.634-2.1,7.549,7.549,0,0,0-4.589.18c-3.115,1.184-5.2,4.173-6.485,7.1-.209.474.5.888.707.41"
        transform="translate(-27.732 -120.654)"
        fill="#364a59"
      />
      <path
        id="Path_102504"
        data-name="Path 102504"
        d="M50.817,224.613a14.416,14.416,0,0,1,4.021-5.576,7.3,7.3,0,0,1,2.893-1.425,7.128,7.128,0,0,1,3.734.242c2.571.812,4.723,2.527,7.214,3.523,2.351.94,5.11,1.263,7.255-.337a17.083,17.083,0,0,0,2.718-2.889,9.919,9.919,0,0,1,3.366-2.711c.475-.225.06-.926-.413-.7A9.807,9.807,0,0,0,78.48,217.1c-.795.891-1.486,1.874-2.349,2.7a5.261,5.261,0,0,1-3.241,1.543A8.845,8.845,0,0,1,69,220.637c-2.538-.993-4.707-2.74-7.323-3.566a7.727,7.727,0,0,0-6.872.98,14.465,14.465,0,0,0-4.7,6.153c-.209.474.5.888.707.41"
        transform="translate(-30.295 -138.681)"
        fill="#364a59"
      />
      <path
        id="Path_102505"
        data-name="Path 102505"
        d="M424.533,169.187a5.411,5.411,0,0,1,.643-3.91,9.288,9.288,0,0,0,.673-1.876c.026-.13.6-3.189-2.284-3.873a3.22,3.22,0,0,0-3.527,1.147,3.878,3.878,0,0,0-.412,3.986l-.68.4a4.637,4.637,0,0,1,.457-4.849,4.018,4.018,0,0,1,4.345-1.444,3.929,3.929,0,0,1,2.875,4.787,9.992,9.992,0,0,1-.724,2.037,4.758,4.758,0,0,0-.613,3.365Z"
        transform="translate(-253.196 -104.591)"
        fill="#364a59"
      />
      <path
        id="Path_102507"
        data-name="Path 102507"
        d="M37.38,87l-.665-.423a5.5,5.5,0,0,1,3.423-2.036,9.427,9.427,0,0,0,1.878-.7c.117-.064,2.84-1.606,1.505-4.232a3.213,3.213,0,0,0-3.159-1.933,3.929,3.929,0,0,0-3.332,2.261l-.746-.26a4.7,4.7,0,0,1,4.027-2.784,4,4,0,0,1,3.916,2.362,3.921,3.921,0,0,1-1.827,5.27,10.155,10.155,0,0,1-2.035.766A4.833,4.833,0,0,0,37.38,87"
        transform="translate(-21.953 -54.89)"
        fill="#364a59"
      />
      <path
        id="Path_102508"
        data-name="Path 102508"
        d="M34.8,108.757a1.1,1.1,0,0,1-.431-.086,1.156,1.156,0,0,1-.643-1.4l.006-.021a1.148,1.148,0,0,1,.6-.652,1.117,1.117,0,0,1,1.531.563,1.136,1.136,0,0,1,.028.884,1.149,1.149,0,0,1-.634.621,1.2,1.2,0,0,1-.46.092m-.317-1.263a.383.383,0,0,0,.2.457.375.375,0,0,0,.277-.009.365.365,0,0,0,.2-.188.357.357,0,0,0-.017-.274.365.365,0,0,0-.194-.2.4.4,0,0,0-.466.212"
        transform="translate(-20.372 -72.886)"
        fill="#364a59"
      />
    </svg>
  );
};

export default ErrorIcon;
