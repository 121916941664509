import React from 'react';
import DetailView from '../../layout/Detail/DetailView'
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_47"
const objectLabel = "Documento"
const objectLabelSingular = "Documento"
const objectLabelPlural = "Documentos"
const listUrl = "/documentos"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
}

const DetailDocument = (props) => {
    return (

        <DetailView
            object={object}
            objectLabelSingular={objectLabelSingular}
            objectLabelPlural={objectLabelPlural}
            id={props.match.params.id}
            scene={"scene_351"}
            view={"view_459"}
            sceneEdit={"scene_350"}
            viewEdit={"view_458"}
            userPermissions={userPermissions}
            tabs={[]}
        />
    )
}


const FormAddDocument = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_348"}
            view={"view_460"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditDocumment = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_350"}
            view={"view_458"}
            userPermissions={userPermissions}
        />
    )

}

const ListDocument = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_348"}
            view={"view_457"}
            userPermissions={userPermissions}
            extraConfigs={{}}

        />
    )
}

export {
    DetailDocument,
    FormAddDocument,
    FormEditDocumment,
    ListDocument
}