import {
  LOGIN_SUCCESS,
  ERROR_LOGIN,
  LOG_OUT,
  UPDATE_PROFILE_PICTURE,
} from "../constants/auth";
import { CLEAR_ERRORS } from "../constants/errors";
import { knackRoles } from "../../utils/KnackAndFrontEndRel";
import { backendUrl } from "../../utils/config";
import { apiCall, dbApiCall } from "./api";
import { GET_ONE, METHODS } from "./knack/types";

export const getToken = async (userForToken) =>
  await apiCall({
    action: METHODS.POST.value,
    url: `${backendUrl}/auth/jwtToken`,
    data: userForToken,
    shouldRetry: false,
  });

export const login = (formData) => async (dispatch) => {
  try {
    let userData = {};
    const response = await apiCall({
      action: METHODS.POST.value,
      url: `https://api.knack.com/v1/applications/${process.env.REACT_APP_KNACK_APP_ID}/session`,
      data: {
        email: formData.email,
        password: formData.password,
        remember: formData.remember,
      },
    });

    const {
      data: {
        session: {
          user: {
            id: userId,
            profile_keys,
            profile_objects,
            token,
            values: { name, email },
          },
        },
      },
    } = response;

    userData = {
      isLoggedIn: true,
      profile_keys: profile_keys,
      profile_objects: profile_objects,
      userId,
      userName: name.first + " " + name.last,
      userEmail: email.email,
      userPicture: "",
      profileId: "",
      databaseToken: token,
      token: "",
    };

    let dataToFetchUserProfile = {
      scene: "",
      view: "",
      pictureField: "",
      profileId: "",
    };

    const buildProfileData = (knackRole) => {
      userData.profile_keys.forEach((profile_keys) => {
        if (knackRole.knackRole === profile_keys) {
          let profileId = "";
          userData.profile_objects.forEach((profile_object) => {
            if (profile_object.object === knackRole.knackObject) {
              profileId = profile_object.entry_id;
            }
          });
          dataToFetchUserProfile = {
            scene: knackRole.scene,
            view: knackRole.view,
            pictureField: knackRole.userPictureField,
            profileId: profileId,
          };
          return dataToFetchUserProfile;
        }
      });
    };

    knackRoles.forEach((knackRole) => {
      if (knackRole.main) buildProfileData(knackRole);
      else buildProfileData(knackRole);
    });

    // set token for serverless api calls
    const userForToken = {
      sub: userData.profileId,
      email: formData.email,
    };

    const jwtToken = await getToken(userForToken);

    userData.token = jwtToken.data.jwtToken;

    const { scene, view, profileId } = dataToFetchUserProfile;

    const record = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene,
      view,
      id: profileId,
      auth: userData,
    });

    userData.userPicture = record.data[dataToFetchUserProfile.pictureField];
    userData.profileId = dataToFetchUserProfile.profileId;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: userData,
    });
  } catch (err) {
    if (err?.response?.status === 423) {
      dispatch({
        type: ERROR_LOGIN,
        payload: "Demasiadas tentativas de acesso. Volte a tentar mais tarde.",
      });
    } else {
      dispatch({
        type: ERROR_LOGIN,
        payload: err?.response?.data?.errors?.[0]?.message,
      });
    }
    setTimeout(() => {
      dispatch({ type: CLEAR_ERRORS });
    }, 2000);
  }
};

export const recoverPassword = (formData) => async (dispatch) => {
  try {
    const response = await apiCall({
      action: METHODS.POST.value,
      url: `${backendUrl}/reset-password/request-password`,
      data: { userEmail: formData.email },
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const setNewPassword = (formData) => async (dispatch) => {
  try {
    const response = await apiCall({
      action: METHODS.POST.value,
      url: `${backendUrl}/reset-password/change-password`,
      data: {
        password: formData.password,
        token: formData.token,
      },
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateProfilePicture = (userPicture) => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE_PICTURE,
    payload: userPicture,
  });
};

export const doLogOut = () => (dispatch) => {
  localStorage.clear();
  sessionStorage.clear()
  dispatch({
    type: LOG_OUT,
  });
};
