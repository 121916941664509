import moment from "moment";
import "moment/locale/pt";
import { dbApiCall } from "../api";
import { GET_ONE, METHODS } from "../knack/types";
import {
  Aluno,
  Contrato,
  Curso,
  Estagio,
  Parceiro,
} from "../../../utils/knackKeys";
import {
  INTERNSHIP_BADGE,
  INTERNSHIP_CONTRACT_PARTNER,
  INTERNSHIP_CURRICULAR_DECLARATION,
  PARTNERSHIP_PROTOCOL,
  PARTNERSHIP_PROTOCOL_DISCOUNTED,
} from "./constants";

moment.locale("pt");

const {
  fields: {
    DESIGNACAO_EMPRESA,
    MORADA: MORADA_PARCEIRO,
    NIF: NIF_PARCEIRO,
    CURSOS,
    DESCONTO_PARCERIA,
    REPRESENTANTE_EMPRESA,
    DURACAO_ESTAGIO,
  },
} = Parceiro;

const {
  fields: { NAME, MORADA },
} = Aluno;

const {
  fields: { NIF },
} = Contrato;

const {
  fields: { NOME: NOME_CURSO },
} = Curso;

const {
  fields: { NOTA_ESTAGIO, DURACAO, DATA_INICIO },
} = Estagio;

const setListOfItems = (arrayOfItems) =>
  arrayOfItems.reduce((acc, curr) => `${acc} ${curr.identifier}, `, "");

const getPartnerData = async ({ partnerId }) => {
  try {
    const result = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: "scene_106",
      view: "view_143",
      id: partnerId,
    });
    return result?.data;
  } catch (error) {
    console.log(error);
  }
};

const getDynamicData = ({ data, type }) => {
  const { internship, partner, contract, student, course } = data;

  const currentDate = moment().format("DD-MM-YYYY").toString();

  let partnerName,
    partnerAddress,
    partnerNif,
    partnerResponsiblePerson,
    partnerCourses,
    partnerDiscount,
    partnerInternshipDuration,
    studentName,
    studentFullName,
    studentNif,
    studentAdress,
    courseName,
    internshipHoursDuration,
    internshipStartDate,
    internshipGrade,
    internshipCourses;

  if (partner) {
    partnerName = partner[DESIGNACAO_EMPRESA] || "{NOME DO PARCEIRO}";
    partnerAddress = partner[MORADA_PARCEIRO]
      ? `${partner[MORADA_PARCEIRO].street} ${partner[MORADA_PARCEIRO].zip} ${partner[MORADA_PARCEIRO].city}`
      : "{MORADA DO PARCEIRO}";
    partnerNif = partner[NIF_PARCEIRO]?.toString() || "{NIF DO PARCEIRO}";
    partnerResponsiblePerson =
      partner[REPRESENTANTE_EMPRESA] || "{PESSOA RESPONSÀVEL}";
    partnerCourses = partner[CURSOS]?.length
      ? setListOfItems(partner[CURSOS])
      : "{CURSOS PARCEIRO}";
    partnerDiscount = partner[DESCONTO_PARCERIA]
      ? partner[DESCONTO_PARCERIA].toString()
      : "{DESCONTO DA PARCERIA}";
    partnerInternshipDuration =
      partner[DURACAO_ESTAGIO]?.toString() || "{HORAS DE ESTÁGIO}";
  }

  if (student) {
    studentAdress = student[MORADA]
      ? `${student[MORADA].street} ${student[MORADA].zip} ${student[MORADA].city}`
      : "{MORADA DO ALUNO}";
    studentName = student[NAME]
      ? `${student[NAME].first} ${student[NAME].last}`
      : "{NOME DE ALUNO}";
    studentFullName = student[NAME]
      ? `${student[NAME].full || student[NAME].formatted_value}`
      : "{NOME DE ALUNO}";

    // studentNif = (contract || student)?.toString() || "{NIF DO ALUNO}";
    if (contract) {
      studentNif = contract[NIF]?.toString() || "{NIF DO ALUNO}";
    } else studentNif = student[NIF]?.toString() || "{NIF DO ALUNO}";
  }

  if (course) {
    courseName = course[NOME_CURSO] || "{NOME DO CURSO}";
  }

  if (internship) {
    internshipHoursDuration =
      internship[DURACAO]?.toString() || "{HORAS DE ESTÁGIO}";
    internshipStartDate =
      internship[DATA_INICIO]?.date_formatted || "{INICIO DE ESTÁGIO}";
    internshipGrade =
      internship[NOTA_ESTAGIO]?.toString() || "{NOTA DO ESTÁGIO}";
  }

  switch (type) {
    case INTERNSHIP_CONTRACT_PARTNER: {
      return {
        partnerName,
        partnerAddress,
        partnerNif,
        partnerResponsiblePerson,
        studentName,
        studentNif,
        studentAdress,
        courseName,
        internshipHoursDuration,
        internshipStartDate,
      };
    }
    case INTERNSHIP_CURRICULAR_DECLARATION: {
      return {
        currentDate,
        partnerName,
        studentName: studentFullName,
        studentNif,
        courseName,
        internshipHoursDuration,
        internshipGrade,
      };
    }
    case INTERNSHIP_BADGE: {
      return { studentName, courseName };
    }
    case PARTNERSHIP_PROTOCOL: {
      return {
        partnerName,
        partnerAddress,
        partnerNif,
        partnerResponsiblePerson,
        course: partnerCourses,
        internshipHoursDuration: partnerInternshipDuration,
      };
    }
    case PARTNERSHIP_PROTOCOL_DISCOUNTED: {
      return {
        partnerName,
        partnerAddress,
        partnerNif,
        partnerResponsiblePerson,
        course: partnerCourses,
        internshipHoursDuration: partnerInternshipDuration,
        discount: partnerDiscount,
      };
    }
    default:
      return {
        partnerName,
        partnerAddress,
        partnerNif,
        partnerResponsiblePerson,
        partnerCourses,
        partnerDiscount,
        studentName,
        studentFullName,
        studentNif,
        studentAdress,
        courseName,
        internshipHoursDuration,
        internshipStartDate,
        internshipGrade,
        internshipCourses,
      };
  }
};

const clearDocument = () => (dispatch) => {
  dispatch({
    type: "CLEAR_DOCUMENT",
  });
};

export { clearDocument, getDynamicData, getPartnerData };
