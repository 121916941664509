export const typeOfFilters = [
    { type: "short_text", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "boolean", options: ["is", "is not", "is blank", "is not blank"] },
    { type: "number", options: ["is", "is not", "higher than", "lower than", "is blank", "is not blank"] },
    { type: "count", options: ["is", "is not", "higher than", "lower than", "is blank", "is not blank"] },
    { type: "paragraph_text", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "multiple_choice", options: ["is", "is not", "contains", "does not contain", "is blank", "is not blank"] },
    { type: "date_time", options: ["is", "is not", "is before", "is after", "is blank", "is not blank"] },
    { type: "address", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "name", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "link", options: ["is", "is not", "is blank", "is not blank"] },
    { type: "email", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "phone", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "rich_text", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "currency", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "auto_increment", options: ["is", "is not", "higher than", "lower than", "is blank", "is not blank"] },
    { type: "rating", options: ["is", "is not", "higher than", "lower than", "is blank", "is not blank"] },
    { type: "text_formula", options: ["contains", "does not contain", "is", "is not", "starts with", "ends with", "is blank", "is not blank"] },
    { type: "signature", options: ["is blank", "is not blank"] },
    { type: "connection", options: ["is", "is not", "contains", "does not contain", "is blank", "is not blank"] },
    { type: "file", options: ["is blank", "is not blank"] }, 
    { type: "concatenation", options: ["is blank", "is not blank", "is", "is not", "starts with", "ends with", "contains", "does not contain"] },
    { type: "equation", options: ["is", "is not", "is before", "is after", "is blank", "is not blank"] },
]

export const optionsTranslation = (option) => {
    switch (option) {
        case 'contains':
            return 'Contém'
        case 'does not contain':
            return 'Não Contém'
        case 'is':
            return 'É igual a'
        case 'is not':
            return 'Não é igual a'
        case 'starts with':
            return 'Começa com'
        case 'ends with':
            return 'Termina com'
        case 'is blank':
            return 'Está em branco'
        case 'is not blank':
            return 'Não está em branco'
        case 'lower than':
            return 'Mais baixo que'
        case 'higher than':
            return 'Mais alto que'
        case 'is before':
            return 'É antes de'
        case 'is after':
            return 'É depois de'
        default:
    }
}