import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { openSpecificMessageEditor } from "../../../../../redux/actions/massComunications";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../../redux/actions/customActionsPopUps";
import { FormHelperText } from "@material-ui/core";
import { fetchRecords } from "../../../../../redux/actions/api/dataApiCalls";
import { requiredErrorMessage } from "../../../../../utils/errors";
import TalentoButton from "../../../TalentoButton";
import { Templates } from "../../../../../utils/knackKeys";

const {
  list: { scene, view },
  fields: { TIPO, NOME_TEMPLATE, ID, ACTIVE, ENVIAR_DESDE },
} = Templates;

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
    minHeight: 300,
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
}));

const MassSmsTemplate = (props) => {
  const { gridApi, emailPopUpEditor, auth, object } = props;
  const { userId, profile_keys } = props.auth;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control, errors } = methods;

  const [massSmsTemplates, setMassSmsTemplates] = useState([]);
  const classes = useStyles();
  const userPermissionsField = "";

  const onSubmit = async (formData) => {
    let templateToUse = {};
    massSmsTemplates.forEach((emailTemplate) => {
      if (emailTemplate.templateId === formData.templateId) {
        templateToUse = {
          channel: "sms",
          type: "massSmsTemplateOptions",
          templateId: emailTemplate.templateId,
          campaignId: 28,
          object,
        };
      }
    });
    await props.customActionsPopUps("sendMassSMS", gridApi, templateToUse);
  };

  const getTemplates = async () => {
    let massSmsTemplates = [];

    const templates = await props.fetchRecords({
      scene,
      view,
      auth,
      userPermissionsField,
      userId,
      profile_keys,
      page: 1,
    });

    templates.records?.forEach((template) => {
      const validObjects =
        template[ENVIAR_DESDE] && template[ENVIAR_DESDE].split(",");
      const isValidObject =
        validObjects?.includes(object) || !validObjects?.length;
      const hideTemplate =
        !isValidObject || template[TIPO] !== "SMS" || !template[ACTIVE];
      if (hideTemplate) return;

      const newTemplate = {
        templateName: template[NOME_TEMPLATE],
        templateId: template[ID],
        templateType: template[TIPO],
      };
      massSmsTemplates.push(newTemplate);
    });
    setMassSmsTemplates(massSmsTemplates);
  };

  useEffect(() => {
    if (emailPopUpEditor) {
      getTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPopUpEditor]);

  return (
    <Dialog
      open={
        emailPopUpEditor.isOpen
          ? emailPopUpEditor.type === "smsTemplateOptions"
          : false
      }
      maxWidth="lg"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" color="inherit">
              Enviar SMS através de template
            </Typography>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                style={{ marginLeft: 5, marginTop: 20 }}
              >
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="templateId"
                    defaultValue={""}
                    rules={{
                      required: { value: true, message: requiredErrorMessage },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={!!error}
                          required
                        >
                          <InputLabel>Selecione um template</InputLabel>
                          <Select
                            {...field}
                            error={!!error}
                            label="Selecione um template"
                            style={{ minWidth: 500 }}
                          >
                            {massSmsTemplates.map((emailTemplate, index) => (
                              <MenuItem
                                key={index}
                                value={emailTemplate.templateId}
                              >
                                {emailTemplate.templateName}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.templateId && (
                            <FormHelperText error>
                              {" "}
                              {errors.templateId.message}{" "}
                            </FormHelperText>
                          )}
                          {error && (
                            <FormHelperText error>
                              {" "}
                              {error.message}{" "}
                            </FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <TalentoButton
                    onClick={() => props.openSpecificMessageEditor(false)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Enviar SMS
                  </TalentoButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    emailPopUpEditor: state.emailPopUpEditor,
    auth: state.auth,
    records: state.records,
    object: state.actions.object,
  };
};

export default connect(mapStateToProps, {
  customActionsPopUps,
  openSpecificMessageEditor,
  fetchRecords,
})(MassSmsTemplate);
