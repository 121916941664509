import React from 'react';
import AddForm from '../../layout/forms/AddForm';
import EditForm from '../../layout/forms/EditForm';
import ListView from '../../layout/ListView'


// SHARED CONSTANTS

const object = "object_11"
const objectLabel = "Habilitacoes literarias"
const objectLabelSingular = "Habilitacoes-literarias"
const objectLabelPlural = "Habilitacoes-literarias"
const listUrl = "/habilitacoes-literarias"
const userPermissions = {
    userPermissionsField: '',
    viewRolesPermissions: []
  }


const FormAddEducationLevel = (props) => {
    return (
        <AddForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            scene={"scene_292"}
            view={"view_392"}
            userPermissions={userPermissions}
        />
    )

}

const FormEditEducationLevel = (props) => {
    return (
        <EditForm
            objectLabel={objectLabel}
            object={object}
            listUrl={listUrl}
            id={props.match.params.id}
            scene={"scene_290"}
            view={"view_389"}
            userPermissions={userPermissions}
        />
    )

}

const ListEducationLevels = (props) => {
    return (
        <ListView
            object={object}
            objectLabelPlural={objectLabelPlural}
            objectLabelSingular={objectLabelSingular}
            scene={"scene_288"}
            view={"view_388"}
            userPermissions={userPermissions}
            extraConfigs={
                {
                  showCreateButton : 'yes',
                  hasDetail : 'no',
                }
              }

        />
    )
}

export {

    FormAddEducationLevel,
    FormEditEducationLevel,
    ListEducationLevels  
}