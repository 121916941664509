import React from "react";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell } from "@material-ui/core";
import { Autocomplete, FormControl } from "@mui/material";
import useSelectConnectedField from "./useSelectConnectedField";
import FieldOption from "./FieldOption";
import FieldInput from "./FieldInput";

const useStyle = makeStyles((theme) => ({
  select: {
    width: "100%",
    minWidth: 300,
  },
  label: {
    top: "-5px",
  },
  option: {
    padding: "5px 15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const Wrapper = ({ children, fromSearch }) =>
  fromSearch ? children : <TableRow>{children}</TableRow>;

const SelectConnected = (props) => {
  const {
    field: fieldProps,
    recordValues,
    control,
    disabled,
    auth,
    filterName,
    object,
    customOptions,
    getValues,
    fromSearch,
    required = undefined,
  } = props;
  const classes = useStyle();

  const {
    open,
    setOpen,
    options,
    loading,
    isMultiple,
    getDefaultValue,
    requiredValue,
    getValue,
    getOptionSelected,
    getOptionLabel,
    labelForInput,
    handleOnInputChange,
    getHelper,
    rules,
    fetchingError,
  } = useSelectConnectedField({
    fieldProps,
    recordValues,
    auth,
    object,
    getValues,
    filterName,
    customOptions,
    required,
  });

  return (
    <Wrapper fromSearch={fromSearch}>
      <TableCell>
        <Controller
          name={filterName || fieldProps.key}
          control={control}
          defaultValue={getDefaultValue()}
          rules={rules}
          render={({
            field: { ref, value, onChange, ...rest },
            fieldState: { error },
          }) => {
            return (
              <FormControl
                variant="outlined"
                className={classes.select}
                size="small"
                error={!!error}
                required={requiredValue}
              >
                <Autocomplete
                  {...rest}
                  required={requiredValue}
                  disabled={disabled}
                  multiple={isMultiple}
                  className={classes.select}
                  onInputChange={handleOnInputChange}
                  value={getValue(value)}
                  freeSolo
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  open={open}
                  isOptionEqualToValue={getOptionSelected}
                  filterOptions={(value) => value}
                  loading={loading}
                  options={options}
                  getOptionLabel={(option) => getOptionLabel(option, value)}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => (
                    <FieldInput
                      params={params}
                      disabled={disabled}
                      requiredValue={requiredValue}
                      error={error || fetchingError}
                      labelForInput={labelForInput}
                      classes={classes}
                      loading={loading}
                      helper={getHelper(error || fetchingError)}
                      inputRef={ref}
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => (
                    <FieldOption
                      key={option?.identifier || option}
                      props={props}
                      option={option}
                      inputValue={inputValue}
                      classes={classes}
                    />
                  )}
                />
              </FormControl>
            );
          }}
        />
      </TableCell>
    </Wrapper>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {})(SelectConnected);
