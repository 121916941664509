import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";
import { Curso } from "../../../utils/knackKeys";

// SHARED CONSTANTS

const object = "object_9";
const objectLabel = "Curso";
const objectLabelSingular = "Curso";
const objectLabelPlural = "Cursos";
const identifierNameField = "field_75";
const listUrl = "/cursos";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [],
};

const DetailCourse = (props) => (
  <DetailView
    object={object}
    objectLabelSingular={objectLabelSingular}
    objectLabelPlural={objectLabelPlural}
    id={props.match.params.id}
    identifierNameField={identifierNameField}
    scene={Curso.details.scene}
    view={Curso.details.view}
    sceneEdit={"scene_41"}
    viewEdit={"view_50"}
    userPermissions={userPermissions}
    deletable={true}
    tabs={[
      {
        title: "Turmas",
        position: 1,
        params: {
          object: "object_7",
          objectLabelPlural: "Turmas",
          objectLabelSingular: "Turma",
          slug: "turmas",
          scene: "scene_43",
          view: "view_53",
          userPermissions: userPermissions,
          extraConfigs: {
            showCreateButton: "no",
          },
          filterByConnectedField: {
            connected_field: "field_81",
            id: props.match.params.id,
          },
        },
      },
      {
        title: "Módulos",
        position: 2,
        params: {
          object: "object_36",
          objectLabelPlural: "Modulos",
          objectLabelSingular: "Modulo",
          slug: "modulos",
          scene: "scene_233",
          view: "view_321",
          userPermissions: userPermissions,
          extraConfigs: {
            showCreateButton: "yes",
          },
          filterByConnectedField: {
            connected_field: "field_391",
            id: props.match.params.id,
            identifierNameField: identifierNameField,
          },
        },
      },
    ]}
  />
);

const FormAddCourse = (props) => (
  <AddForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    scene={"scene_40"}
    view={"view_49"}
    userPermissions={userPermissions}
  />
);

const FormEditCourse = (props) => (
  <EditForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    id={props.match.params.id}
    scene={"scene_41"}
    view={"view_50"}
    userPermissions={userPermissions}
  />
);

const ListCourses = (props) => (
  <ListView
    object={object}
    objectLabelPlural={objectLabelPlural}
    objectLabelSingular={objectLabelSingular}
    scene={"scene_38"}
    view={"view_46"}
    userPermissions={userPermissions}
    extraConfigs={{}}
  />
);

export { DetailCourse, FormAddCourse, FormEditCourse, ListCourses };
