import React, { useState, useMemo, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginForm from "../LoginForm";
import RecoverPasswordForm from "../RecoverPasswordForm";
import SetNewPasswordForm from "../SetNewPasswordForm";
import { useLocation } from "react-router-dom";
import { useStyles } from "./styles";

const useGetQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const LoginPage = ({ auth, location }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState("login");
  const queryParams = useGetQueryParams();

  useEffect(() => {
    if (queryParams.get("token")) setFormState("setNewPassword");
  }, [queryParams]);

  if (auth.isLoggedIn) {
    let pathname = "";
    auth.profile_keys?.forEach((profile_key) => {
      switch (profile_key) {
        case "profile_5":
          pathname = "/turmas";
          break;
        case "profile_21":
        case "profile_20":
          pathname = `/contratos/`;
          break;
        case "profile_4":
          pathname = `/alunos/${auth.profileId}`;
          break;
        default:
          pathname = "/alunos";
      }
    });

    return <Redirect to={{ pathname: pathname, state: { from: location } }} />;
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        square
        style={{ margin: "auto", boxShadow: "none", backgroundColor: "white" }}
      >
        <div className={classes.box}>
          <img
            src="https://talento-documentos-gerais-publicos.s3.eu-west-1.amazonaws.com/logos-and-favicon/logo_standard.png"
            alt="logo"
            style={{ width: "350px", marginBottom: "80px" }}
          />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <div className={classes.formContainer}>
            {showFormAccordingToState({
              formState,
              setFormState,
              classes,
              queryParams,
            })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const showFormAccordingToState = ({
  formState,
  setFormState,
  classes,
  queryParams,
}) => {
  switch (formState) {
    case "login":
      return <LoginForm setFormState={setFormState} classes={classes} />;
    case "recoverPassword":
      return (
        <RecoverPasswordForm setFormState={setFormState} classes={classes} />
      );
    case "setNewPassword":
      return (
        <SetNewPasswordForm
          setFormState={setFormState}
          classes={classes}
          queryParams={queryParams}
        />
      );
    default:
      return "login";
  }
};

const mapStateToProps = (state) => ({ auth: state.auth, errors: state.errors });

export default connect(mapStateToProps)(LoginPage);
