import BaseRequest from "./BaseRequest";
import { authHeaders, dbCallType, dbHeaders, noAuthHeaders } from "./headers";
import tryCatch from "./tryCatch";

const authenticatedRequest = ({ token, noDefaultUrl }) =>
  new BaseRequest({
    defaultUrl: noDefaultUrl ? "" : "/v1",
    headers: authHeaders({ token }),
  });

const unauthenticatedRequest = () =>
  new BaseRequest({
    defaultUrl: "",
    headers: noAuthHeaders,
  });

const request = ({ useProxy = true, token }) => {
  if (!token) return unauthenticatedRequest;
  if (useProxy) return dbProxyRequest;
  return dbRequest;
};

const dbRequest = ({ token, callType = dbCallType.VIEW, isRetry = true }) =>
  new BaseRequest({ headers: dbHeaders({ token, callType }), isRetry });

const dbProxyRequest = ({ token, isRetry = true }) =>
  new BaseRequest({ headers: authHeaders({ token }), useProxy: true, isRetry });

export {
  authenticatedRequest,
  unauthenticatedRequest,
  request,
  dbRequest,
  dbProxyRequest,
  BaseRequest,
  tryCatch,
};
