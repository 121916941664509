import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import TalentoButton from "../TalentoButton";


const UploadFileInForm = (props) => {
  // needs to be fixed to handle multiple files

  const { field, uploadFile, file } = props;
  const [showFileName, setShowFileName] = useState("");

  useEffect(() => {
    setShowFileName(file.fileName);
  }, [file]);

  return (
    <div
      style={{
        marginTop: 15,
        marginBottom: 10,
        marginLeft: 15,
        marginRight: 15,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography>{field.name}</Typography>
      <TalentoButton
        variant="contained"
        component="label"
        color="primary"
        // disabled={state && state.isUploading}
        style={{ flexGrow: 1 }}
        size="small"
      >
        <input type="file" name={field.key} hidden onChange={uploadFile} />
        <Typography>Clique para selecionar um ficheiro</Typography>
        <CloudUploadIcon
          color="inherit"
          fontSize="small"
          style={{ marginLeft: 10 }}
        />
      </TalentoButton>
      {<Typography>{showFileName} </Typography>}
    </div>
  );
};

export default UploadFileInForm;
