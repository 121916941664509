import {
    CREATE_SESSION_CLASSES,
    EXPORT_AND_DELETE_SESSION_CLASSES,
    MARK_STUDENT_PRESENCES,
    REMOVE_STUDENT_PRESENCES,
    CLEAR_TABLE_STATE,
    OPEN_EVALUATIONS_CREATION_POPUP,
    PUSH_CLASSES_ONE_WEEK_FORWARD,
    ASSOCIATE_CAREER_MANAGERS,
    OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP,
    OPEN_SEND_SMS_POP_UP,
    SEND_MASS_SMS,
    OPEN_SEND_EMAIL_POP_UP,
    SEND_MASS_EMAIL,
    PROCESSING_AUTOMATION,
    CHANGE_STUDENTS_CONTRACT_STATUS,
    CREATE_PRESENCES_MANUALLY,
    DOWNLOAD_QUALIFICA_DOCUMENTS
} from '../constants/customActions';

export default function customActionsReducer(state = null, action) {
    switch (action.type) {
        case CREATE_SESSION_CLASSES:
            return { ...state, isTableUpdated: action.payload.isTableUpdated };
        case EXPORT_AND_DELETE_SESSION_CLASSES:
            return { ...state, isTableUpdated: action.payload.isTableUpdated };
        case MARK_STUDENT_PRESENCES:
            return { ...state, isTableUpdated: action.payload.isTableUpdated };
        case REMOVE_STUDENT_PRESENCES:
            return { ...state, isTableUpdated: action.payload.isTableUpdated };
        case CLEAR_TABLE_STATE:
            return { ...state, isTableUpdated: false };
        case OPEN_EVALUATIONS_CREATION_POPUP:
            return action.payload;
        case PUSH_CLASSES_ONE_WEEK_FORWARD:
            return action.payload
        case OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP:
            return action.payload
        case ASSOCIATE_CAREER_MANAGERS:
            return action.payload
        case OPEN_SEND_SMS_POP_UP:
            return action.payload
        case SEND_MASS_SMS:
            return action.payload
        case OPEN_SEND_EMAIL_POP_UP:
            return action.payload
        case SEND_MASS_EMAIL:
            return action.payload
        case PROCESSING_AUTOMATION:
            return action.payload
        case CHANGE_STUDENTS_CONTRACT_STATUS:
            return action.payload
        case CREATE_PRESENCES_MANUALLY:
            return action.payload
        case DOWNLOAD_QUALIFICA_DOCUMENTS:
            return action.payload
        default:
            return state
    }
}