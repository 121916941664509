
import React from "react";
import { userCanExport } from "../../../../utils/userPermissions";
import { exportDataFromGridInsideDetail } from "../../../../utils/Grid/export";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TalentoButton from "../../TalentoButton";

const RenderExportButton = ({
  auth,
  scene,
  view,
  connected_field,
  userPermissionsField,
  userId,
  filtersFromProps,
  parentProps,
  gridConfig,
}) => {
  const { id } = useParams();

  const handleExport = () => exportDataFromGridInsideDetail(
    scene,
    view,
    id,
    connected_field,
    auth,
    userPermissionsField,
    userId,
    auth.profile_keys,
    filtersFromProps,
    parentProps,
    gridConfig
  )

  if (!userCanExport(auth.profile_keys)) return null;
  return (
    <TalentoButton
      variant="contained"
      color="primary"
      style={{ marginLeft: "auto" }}
      onClick={handleExport}
    >
      Exportar
    </TalentoButton>
  );
};

export default RenderExportButton;
