import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItems from "./MenuItems";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import MenuConfigs from "./MenuConfigs";
import { knackRoles } from "../../utils/KnackAndFrontEndRel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { setMenuOpen } from "../../redux/actions/menu";
import { backendUrl } from "../../utils/config";
import { Typography } from "@material-ui/core";
import { apiCall } from "../../redux/actions/api";
import { METHODS } from "../../redux/actions/knack/types";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: 15,
    backgroundColor: theme.palette.orange.main,
  },
  userSection: {
    height: theme.spacing(10),
    borderRadius: "15px",
    backgroundColor: "transparent",
    transition: "all 200ms ease-in",
    "&:hover": {
      backgroundColor: theme.palette.secondary.lighter,
    },
  },
  name: {
    fontSize: "20px",
  },
}));

const MenuContainer = ({ auth, location, menu, setMenuOpen }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (!auth.token) return;
        const {
          data: { signUrl },
        } = await apiCall({
          action: METHODS.POST.value,
          url: `${backendUrl}/knack/signUrl`,
          data: { type: "get", key: auth?.userPicture || "" },
          token: auth.token,
          shouldRetry: false,
        });
        setProfilePic(signUrl);
      } catch (error) {
        console.log("error in fetch profle pic", error);
      }
    })();
  }, [auth]);

  const getUserEditPageUrl = (knackRoles, auth) => {
    let editPageUrl = "";
    if (!auth.isLoggedIn) return "";
    knackRoles.forEach((knackRole) => {
      if (knackRole.knackRole === auth.profile_keys[0]) {
        editPageUrl = `/${knackRole.path}/${auth.profileId}`;
      }
    });
    return editPageUrl;
  };

  if (location.pathname === "/login" || location.pathname === "/") return null;

  return (
    <div>
      <Drawer
        className={classes.drawer}
        variant={mobile ? "temporary" : "permanent"}
        open={false || menu}
        PaperProps={{ elevation: 3 }}
        onClose={() => setMenuOpen(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        {mobile ? null : <Toolbar />}
        <List onClick={() => setMenuOpen(false)}>
          <ListItem
            button
            component={Link}
            to={getUserEditPageUrl(knackRoles, auth)}
            className={classes.userSection}
          >
            <ListItemAvatar>
              <Avatar src={profilePic} className={classes.avatar} />
            </ListItemAvatar>
            <Typography className={classes.name}>{auth.userName}</Typography>
          </ListItem>
        </List>
        <Divider />
        <List onClick={() => setMenuOpen(false)}>
          <MenuItems />
        </List>
        <List>
          <MenuConfigs />
        </List>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    menu: state.menu,
  };
};

export default connect(mapStateToProps, { setMenuOpen })(MenuContainer);
