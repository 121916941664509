import { IS_EDIT_POPUP_OPEN } from '../../constants/popUps';

export default function editPopUpReducer(state = false, action) {
    switch (action.type) {
        case IS_EDIT_POPUP_OPEN:
            return action.payload
        default:
            return state;
    }
} 
