import { IS_CUSTOM_POPUP_OPEN } from '../../constants/popUps';


export default function customPopUpReducer(state = false, action) {
    switch (action.type) {
        case IS_CUSTOM_POPUP_OPEN:
            return action.payload
        default:
            return state;
    }
} 
